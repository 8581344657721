export const checkImageRatio = (imageFile, expectedWidthRatio = 1, expectedHeightRatio = 1) => {
    console.log("imageValidator", imageFile, expectedWidthRatio, expectedHeightRatio)
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);
  
      img.onload = () => {
        const width = img.width;
        const height = img.height;
  
        // Calculate the actual ratio
        const actualRatio = width / height;
        const expectedRatio = expectedWidthRatio / expectedHeightRatio;
  
        // Check if the actual ratio matches the expected ratio
        if (Math.abs(actualRatio - expectedRatio) < 0.01) { // Allowing a small margin for precision issues
          resolve(true);
        } else {
          resolve(false);
        }
      };
  
      img.onerror = () => {
        resolve(false); // In case of error, assume the ratio is invalid
      };
    });
  };
  