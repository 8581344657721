import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import _ from "lodash";
import SelectProduct from "../cms/SelectProduct";
import ProductsModal from "./ProductsModal";
import API from "../../utils/API";
import CollectionModal from "./CollectionModal";


const ProductSection = (props) => {
  const { dealProducts = [], setDealProducts = () => { }, selectCountry = {} } = props;
  const [dealProduct, setDealProduct] = useState({});
  const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [errors, setErrors] = useState({});
  const [editables, setEditables] = useState({})
  const [loadError, setLoadError] = useState("");
  const [sortColumn, setSortColumn] = useState("productId");
  const [sortDirection, setSortDirection] = useState("asc");
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [allActiveCollections,setAllActiveCollections] = useState([])
  const [showCollectionModal, setShowCollectionModal] = useState(false)

  const isNewDealProduct = !dealProduct.id

  const columns = [
    {
      name: "",
      cell: () => <DragIcon className="drag-handle"></DragIcon>,
      width: "50px",
    },
    {
      name: "ProductID",
      selector: "productId",
      sortable: true,
      width: "132px",
    },
    {
      name: "VariantID",
      selector: "variantId",
      sortable: true,
      width: "132px",
    },
    {
      name: "Product Name",
      width: "151px",
      cell: (row) => {
        return row.variantName ? `${row.productName} pack of ${row.variantName}` : row.productName;
      },
      // selector: "productName",
      sortable: true,
      wrap: true,
    },
    {
      name: "Deal Pricing",
      width: "120px",
      cell: (row) => {
        const handleDealPriceChange = (productId, fieldName, value) => {
          setDealProducts((prevProducts) =>
            prevProducts.map((product) =>
              product.productId === productId ? { ...product, [fieldName]: value } : product
            )
          );
        };

        return (
          <div className="group">
            <label htmlFor="dealListPrice" className="label-title-txt">List*</label>
            <input
              style={{ width: "60px" }}
              type="number"
              name="dealListPrice"
              value={row.dealListPrice}
              onChange={(e) => handleDealPriceChange(row.productId, e.target.name, e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        );
      }
    },
    {
      cell: (row) => {
        const handleDealPriceChange = (productId, fieldName, value) => {
          setDealProducts((prevProducts) =>
            prevProducts.map((product) =>
              product.productId === productId ? { ...product, [fieldName]: value } : product
            )
          );
        };

        return (
          <div className="group">
            <label htmlFor="dealDiscountPrice" className="label-title-txt">Disc*</label>
            <input
              style={{ width: "60px" }}
              type="number"
              name="dealDiscountPrice"
              value={row.dealDiscountPrice}
              onChange={(e) => handleDealPriceChange(row.productId, e.target.name, e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        );
      },
    },
    {
      cell: (row) => {
        const handleDealPriceChange = (productId, fieldName, value) => {
          setDealProducts((prevProducts) =>
            prevProducts.map((product) =>
              product.productId === productId ? { ...product, [fieldName]: value } : product
            )
          );
        };

        return (
          <div className="group">
            <label htmlFor="dealFinalPrice" className="label-title-txt">Final*</label>
            <input
              style={{ width: "60px" }}
              type="number"
              name="dealFinalPrice"
              value={row.dealFinalPrice}
              onChange={(e) => handleDealPriceChange(row.productId, e.target.name, e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        );
      },
    },
    {
      name: "Inventory",
      selector: "normalInventory",
      width: "104px"
    },
    {
      name: "Deal Inventory",
      width: "139px",
      cell: (row) => {
        const handleDealInventoryChange = (productId, fieldName, value) => {
          setDealProducts((prevProducts) =>
            prevProducts.map((product) =>
              product.productId === productId ? { ...product, [fieldName]: value } : product
            )
          );
        };

        return (
          <div className="group">
            <label htmlFor="dealInventory" className="label-title-txt">Final</label>
            <input
              style={{ width: "60px" }}
              type="number"
              name="dealInventory"
              value={row.dealInventory}
              onChange={(e) => handleDealInventoryChange(row.productId, e.target.name, e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        );
      },
    },
    {
      name: "Rank",
      width: "84px",
      selector: "rank",
      // maxWidth: "100px",
      // center: true,
      sortable: "true",
    },
    {
      name: "Actions",
      width: "90px",
      // maxWidth: "100px",
      center: true,
      allowOverflow: true,
      cell: (product) => renderActionBar(product),
    },

  ]

  const hideConfirmDeleteModal = () => {
    setIsConfirmDeleteModalVisible(false);
  }
  const showConfirmDeleteModal = () => {
    setIsConfirmDeleteModalVisible(true);
  }
  
  const renderActionBar = (dealProduct) => {
    return (
      <ActionBar alignRight className="action-menu valeo-dropdown">
        <ActionBar.Toggle variant="">
          <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
        </ActionBar.Toggle>
        <ActionBar.Menu
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
          }}
        >
          <ActionBar.Item
            onClick={() => {
              setProductToDelete(dealProduct.productId);
              showConfirmDeleteModal();
            }
            }
          >
            <DeleteIcon className="icon-small" style={{ fill: "red" }} />
            <span style={{ color: "red" }}>Delete</span>
          </ActionBar.Item>
        </ActionBar.Menu>
      </ActionBar>
    )

  };

  const clearForm = () => {
    setErrors({});
    setDealProduct({});
  };
  const hideAddEditModal = () => {

    setIsAddEditModalVisible(false);
    clearForm();
  }

  const fetchAllProducts = async () => {
    const data = await API.get(`/api/v1/deals/search?searchKey=${""}&countryId=${8}`);
    setAllProducts(data);
  }

  const fetchAllCollections = async()=>{
    try{
      showLoading()
      const res = await API.get(`/api/v1/deals/collections/search?searchKey=${""}&countryId=${selectCountry.id}`);
      console.log("collectionsData",res)
      if(res.status == 200){
        hideLoading();
        setAllActiveCollections(res?.data);
        setShowCollectionModal(true);
      }
      else{
        hideLoading();
      }
    }
    catch(err){
      hideLoading();
      console.log(err);
    }
   
    
  }
  const handleAddNew = () => {
    setShowProductsModal(true);
    fetchAllProducts();
  }

  const handleAddNewCollection = () => {
    fetchAllCollections();
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      updateRank(fromIndex, toIndex);
    },
    nodeSelector: ".rdt_TableRow",
    handleSelector: ".drag-handle",
    enableScroll: true,
  }
  const updateRank = async (fromIndex, toIndex) => {
    const updatedProducts = [...dealProducts];
    const movedProduct = updatedProducts.splice(fromIndex, 1)[0];
    updatedProducts.splice(toIndex, 0, movedProduct);

    updatedProducts.forEach((product, index) => {
      product.rank = index + 1;
    });


    setDealProducts(updatedProducts);
  };


  const handleProductSelect = async (data) => {

    console.log("selectedData", data)
    setShowProductsModal(false);
    let productData = {
      productId: "",
      productName: "",
      dealListPrice: "",
      dealDiscountPrice: "",
      dealFinalPrice: "",
      normalInventory: "",
      dealInventory: "",
      variantId: "",
    }
    if (data.isVariant) {
      productData = {
        productId: data.productId,
        variantId: data.variantId,
        productName: data.variantName,
        dealListPrice: data.variantListPrice,
        dealDiscountPrice: data.variantDiscount,
        dealFinalPrice: data.variantFinalPrice,
        normalInventory: data.normalInventory,
        dealInventory: data.dealInventory,
      }
    }
    else {
      productData = {
        productId: data.productId,
        productName: data.productName,
        dealListPrice: data.productPrice,
        dealDiscountPrice: data.discount || 0,
        dealFinalPrice: data.productSpecialPrice,
        normalInventory: data.inventory,
      }
    }
    try {
      const res = await API.get(`api/v1/deals/existing-products?country_id=${selectCountry.id}`)
      const allExistingProducts = res.data || []
      console.log("existingProducts", allExistingProducts)
      const existingProductIndex = allExistingProducts.findIndex(product => product.productId === productData.productId);
      if (existingProductIndex !== -1) {
        showErrorNotification("Error: This product is already added in another deal.");
        return;
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching existing products:", error);
    }



    const existingProductIndex = dealProducts?.findIndex(product => product.productId === productData.productId);
    if (existingProductIndex !== -1) {
      showErrorNotification("Error: This product is already added in this deal.");
      return;
    }
    setDealProduct(productData);
    createDealProduct(productData);
  };


  const createDealProduct = (productData) => {
    console.log("dealProductvshhsvha", dealProduct)

    const ranks = dealProducts?.length > 0 ? dealProducts.map((it) => it.rank) : [];
    productData.rank = ranks.length > 0 ? Math.max(...ranks) + 1 : 1;
    setDealProducts([...dealProducts, productData]);
    showSuccessNotification("Product Created Successfully!");
  };

  const handleCollectionSelect = async (collectionData = []) => {
    setShowCollectionModal(false);
    console.log("collectionData", collectionData);
    const allProducts = [];
    const ranks = dealProducts?.length > 0 ? dealProducts.map((it) => it.rank) : [];
    const addedProductIds = new Set();
    const alreadyAddedProducts = [];
    const successfullyAddedProducts = [];

    let existingProducts = [];
    try {
        const res = await API.get(`api/v1/deals/existing-products?country_id=${selectCountry.id}`);
        existingProducts = res.data || [];
        console.log("existingProducts", existingProducts);
    } catch (error) {
        console.error("Error fetching existing products:", error);
        return;
    }

    const existingProductIds = new Set(existingProducts.map(product => product.productId));

    collectionData.forEach((collection) => {
        const { collectionId, collectionName, productDtoList } = collection;

        productDtoList.forEach((product) => {
            if (product.variantList && product.variantList.length > 0) {
                product.variantList.forEach((variant) => {
                    if (!addedProductIds.has(variant.productId)) {
                        if (!existingProductIds.has(variant.productId)) {
                            let productData = {
                                productId: variant.productId,
                                variantId: variant.variantId,
                                productName: variant.variantName,
                                dealListPrice: variant.variantListPrice,
                                dealDiscountPrice: variant.variantDiscount,
                                dealFinalPrice: variant.variantFinalPrice,
                                normalInventory: variant.normalInventory,
                                dealInventory: variant.dealInventory,
                                rank: ranks.length > 0 ? Math.max(...ranks) + 1 : 1,
                            };

                            allProducts.push(productData);
                            successfullyAddedProducts.push(variant);
                            ranks.push(productData.rank);
                            addedProductIds.add(variant.productId);
                        } else {
                            alreadyAddedProducts.push(variant);
                        }
                    }
                });
            } else {
                if (!addedProductIds.has(product.productId)) {
                    if (!existingProductIds.has(product.productId)) {
                        let productData = {
                            productId: product.productId,
                            productName: product.productName,
                            dealListPrice: product.productPrice,
                            dealDiscountPrice: product.discount || 0,
                            dealFinalPrice: product.productSpecialPrice,
                            normalInventory: product.inventory,
                            dealInventory: product.dealInventory,
                            variantId: "",
                            rank: ranks.length > 0 ? Math.max(...ranks) + 1 : 1,
                        };

                        allProducts.push(productData);
                        successfullyAddedProducts.push(product);
                        ranks.push(productData.rank);
                        addedProductIds.add(product.productId);
                    } else {
                        alreadyAddedProducts.push(product);
                    }
                }
            }
        });
    });

    if (successfullyAddedProducts.length > 0) {
        const productId = successfullyAddedProducts.map(product => product.productId).join(', ');
        showSuccessNotification(`products were added successfully`);

        setTimeout(() => {
            if (alreadyAddedProducts.length > 0) {
                const productId = alreadyAddedProducts.map(product => product.productId).join(', ');
                showErrorNotification(`Error: The following products are already added in another deal: ${productId}`);
            }
        }, 5000);
    } else if (alreadyAddedProducts.length > 0) {
        const productId = alreadyAddedProducts.map(product => product.productId).join(', ');
        showErrorNotification(`Error: The following products are already added in another deal: ${productId}`);
    }

    console.log("All products", allProducts);
    setDealProducts([...dealProducts, ...allProducts]);
};







  const deleteDealProduct = (productIdToDelete) => {
    const updatedDealProducts = dealProducts.filter(product => product.productId !== productIdToDelete);
    let updatedProductsWithRanks = [];
    updatedDealProducts.map((product, index) => {
      let item = {
        ...product,
        rank: index + 1,
      }
      updatedProductsWithRanks.push(item);

    })
    setDealProducts(updatedProductsWithRanks);
  };

  const showLoading = () => props.toggleLoading({ isLoading: true });
  
  const hideLoading = () => props.toggleLoading({ isLoading: false });

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  return (
    <>
      <div className="container px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...dragProps}>
                <div className="" style={{ display: "flex", backgroundColor: "#F1FAF8", paddingBottom: "10px" }}>
                  <button
                    style={{

                      marginLeft: "24px",
                      width: "200px",
                      cursor: "pointer",
                    }}
                    onClick={handleAddNew}
                    className="greenbackground"
                  >
                    Add Product
                  </button>
                  <button
                    style={{

                      marginLeft: "24px",
                      width: "200px",
                      cursor: "pointer",
                    }}
                    onClick={handleAddNewCollection}
                    className="greenbackground"
                  >
                    Add Collection
                  </button>
                </div>
                <DataTable
                  highlightOnHover
                  columns={columns}
                  data={dealProducts ?? []}
                  onSort={(sortColumn, sortDirection) => {

                    setSortColumn(sortColumn.selector);
                    setSortDirection(sortDirection);
                  }
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                // sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>

            </Card>
          </div>
        </div>
        <Modal
          show={isConfirmDeleteModalVisible}
          onHide={hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                deleteDealProduct(productToDelete);
                hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>


      <ProductsModal showProductsModal={showProductsModal} setShowProductsModal={setShowProductsModal} allProducts={allProducts} setAllProducts={setAllProducts} handleProductSelect={(data) => handleProductSelect(data)} selectCountry={selectCountry} />
      <CollectionModal showCollectionModal={showCollectionModal} setShowCollectionModal={setShowCollectionModal} handleCollectionSelect={(data) => handleCollectionSelect(data)} selectCountry={selectCountry} allActiveCollections={allActiveCollections} />
    </>

  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSection);