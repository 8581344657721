import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Divider, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Card, Form,Dropdown } from "react-bootstrap";
import Select from "react-select";
import OrderNotesCard from "./OrderNotesCard";
import EditOrderNotesViewModal from "./EditOrderNotesViewModal";
import SelectCustomer from "./SelectCustomer";
import API from "../../utils/API";
import { Link } from "react-router-dom";
import EditAddressViewModal from "./EditAddressViewModal";
import EditTaxViewModal from "./EditTaxViewModal";
import EditShippingViewModal from "./EditShippingViewModal";
import EditDiscountViewModal from "./EditDiscountViewModal";
import { calculatePercentage } from "../../utils/common";
import SelectCountry from "../../components/select/SelectCountry";
import SearchBox from "../../components/SearchBox";
import SelectProductsViewModal from "./SelectProductsViewModal";
import ProductSearch from './ProductSearch/ProductSearch';
import ManageAddressViewModal from "./Address/ManageAddressViewModal";
import EditShippingAddressModel from "./Address/EditShippingAddressModel";
import EditBillingAddressModel from "./Address/EditBillingAddressModel";
import { isMobile, isTablet, isAndroid, isIOS } from 'react-device-detect';

const BASE_URL_CUSTOMER = "/api/v1/customer";
const BASE_URL_CUSTOMER_v2 = "api/v1/private/customer/profile";
// const BASE_URL_CUSTOMERS = "/api/v1/private/customers";
// const BASE_URL_PRODUCTS = "/api/v1/products";
const BASE_URL_COUNTRIES = "/api/v1/active/countries";
const BASE_URL_LANGUAGES = "/api/v1/store/languages";
const SELECT_STYLES = { container: (base) => ({ ...base, flex: 1 }) };
const PAYMENT_MODE_OPTIONS = [
  {
    key: "CARD",
    value: "CARD",
    label: "Card",
  },
  {
    key: "TAMARA",
    value: "TAMARA",
    label: "Tamara",
  },
  {
    key: "APPLE_PAY",
    value: "APPLE_PAY",
    label: "Apple Pay",
  },
];
const PAYMENT_GATEWAY_OPTIONS = [
  {
    key: "CHECKOUT",
    value: "CHECKOUT",
    label: "Checkout",
  },
  {
    key: "TAP",
    value: "TAP",
    label: "Tap",
  },
  {
    key: "TABBY",
    value: "TABBY",
    label: "Tabby",
  },
  {
    key: "PAYTABS",
    value: "PAYTABS",
    label: "Paytab",
  },
  {
    key: "MAMOPAY",
    value: "MAMOPAY",
    label: "Mamopay",
  },
  {
    key: "TAMARA",
    value: "TAMARA",
    label: "Tamara",
  },
  {
    key: "OTHERS",
    value: "OTHERS",
    label: "Others",
  },
];

class CreateOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      selectedCountry: null,
      customers: [],
      customerSearchTerm: "",
      customer: null,
      customerOptions: [],
      products: [],
      productSearchTerm: "",
      productOptions: [],
      cart: null,
      order: this.createInitialOrder(),
      isEditAddressViewModalVisible: false,
      isEditOrderNotesViewModalVisible: false,
      isEditTaxViewModalVisible: false,
      isEditShippingViewModalVisible: false,
      isEditDiscountViewModalVisible: false,
      isSelectProductsViewModalVisible: false,
      isManageAddressViewModalVisible: false,
      addressTypeForModal:"",
      isShippingAddressViewModalVisible:false,
      isBillingAddressViewModalVisible: false,
      selectedShippingAddress:{},
      selectedBillingAddress:{},
      totalAmount: 0,
      subTotalAmount:0,
      discountAmount: 0,
      taxAmount:0,
      shippingAmount:0,
      pageType:"",
      prepaidDiscount:0
    };
  }

  componentDidMount() {
    
    this.fetchCountries();
    window.addEventListener('resize', this.handleResize());
    
  }
  
    handleResize = ()=>{
      this.setState({pageType:this.getPageType()})
    }
    

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleResize);
  // }
   getPageType = () => {
    return window.innerWidth > 770 ? 'web' : 'mWeb';
  };
  createInitialOrder = () => {
    return {
      chargeTaxes: true,
      shippingDetails: null,
      discountDetails: null,
      isCashOnDelivery: false,
      paymentDetails: {},
    };
  };

  findPaymentModeOption = (value) => {
    return PAYMENT_MODE_OPTIONS.find(
      (it) => it.value.toLowerCase() === value?.toLowerCase()
    );
  };

  findPaymentGatewayOption = (value) => {
    return PAYMENT_GATEWAY_OPTIONS.find(
      (it) => it.value.toLowerCase() === value?.toLowerCase()
    );
  };

  getDeviceType=()=> {
    if (isMobile) {
      if (isAndroid) {
        return 'Android';
      } else if (isIOS) {
        return 'iOS';
      } else {
        return 'Mobile';
      }
    } else if (isTablet) {
      return 'Tablet';
    } else {
      return 'Desktop';
    }
  }

  // https://api.kuwa.bevaleo.dev/api/v1/cart/08f7b0c413b34a3d9b3219d735003deb/checkout
  createOrder = async () => {
    const { cart, customer, order, selectedCountry } = this.state;
    
    if (!selectedCountry) {
      console.log("createOrder:: Invalid country!", selectedCountry);
      this.showErrorNotification("Please select a country!");
      return;
    }

    if (!cart) {
      console.log("createOrder:: Invalid cart!", cart);
      this.showErrorNotification("Your cart is empty!");
      return;
    }

    if (!customer) {
      console.log("createOrder:: Invalid customer!", cart);
      this.showErrorNotification("Please select a customer!");
      return;
    }
    
    // In case of no cash on delivery
    // Payment mode, Payment Gateway, Payment Reference Number.
    let billing = null;
    if (
      customer &&
      customer.newBillingAddress &&
      customer.newBillingAddress.length
    ) {
      billing = customer.newBillingAddress[0];
    }
    // billing.country = selectedCountry?.id ?? null;

    const { discountType, discountValue, couponCode, couponId } = order?.discountDetails ?? {};
    const isCashOnDelivery = order?.isCashOnDelivery ?? false;

    const paymentDetails = {
      paymentType: isCashOnDelivery ? "UNPAID" : "PAID",
      // paymentType: "COD",
      // COD for cashon delivery // Payment gateway
      transactionType: "OK",
      // COD for cash on delivery. // Payment Reference Number. "paymentModule" is a enum in backend and string is required.
      // paymentModule: order.isCashOnDelivery ? "COD" : "",
      // paymentModule: order?.isCashOnDelivery ? "stripe" : "",
      paymentReferenceNumber: order?.isCashOnDelivery
        ? null
        : order?.paymentDetails?.paymentReferenceNumber ?? "", // Payment Reference Number. (We need to do it)
      amount: this.state.totalAmount, // Final cart amount with.
    };
    
   console.log("customer",customer)

    const payload = {
      orderStatus: "CREATED",
      currency: selectedCountry?.currency ?? "",
      notes: order?.notes ?? "",
      isCashOnDelivery,
      paymentStatus: isCashOnDelivery ? "UNPAID" : "PAID",
      paymentMode: isCashOnDelivery
        ? "COD"
        : order?.paymentDetails?.paymentMode ?? null,
      paymentGateway: isCashOnDelivery
        ? null
        : order?.paymentDetails?.paymentGateway ?? null,
      paymentReferenceNumber: isCashOnDelivery
        ? null
        : order?.paymentDetails?.paymentReferenceNumber ?? null,
        isCouponApplied:discountType?.toLowerCase() === "coupon" ? true : false,
      couponId: discountType?.toLowerCase() === "coupon" ? couponId : null,
      coupon:couponCode,
      discount: this.state.discount ?? null,
      discountValue:this.state.discount ?? null,
      totalAmount:(this.state.totalAmount) +  (this.state.discount) || "",
      finalAmount:this.state.totalAmount || "",
      paymentType: "Regular",
      customerId: customer?.id ?? null,
      orderSource:"ADMIN",
      deviceType:this.getDeviceType(),
      pageType:this.state.pageType,
      customer: {
        country: selectedCountry?.id ?? null,
        customerId: customer?.id ?? null,
        emailAddress: customer?.emailAddress ?? "",
        firstName: customer.firstName ?? null,
        lastName: customer.lastName ?? null,
        billing: this.state.selectedBillingAddress,
        shipping: this.state.selectedShippingAddress
      },
      description: `${customer?.firstName},MULTIPLE_ITEM,${couponCode?? null}`,
      payment: paymentDetails,
      tax:this.state.taxAmount || null,
      taxAmount: this.state.taxAmount ?? null,
      shippingCharge:this.state.shippingAmount,
      customFee : selectedCountry.customFee ?? null,
      codCharge : order.isCashOnDelivery ? selectedCountry.codCharge : null,
      prepaidDiscount: this.state.prepaidDiscount || null,

    };
    const admin_user_id = localStorage.getItem("adminUserId")|| "";

    try {
      this.showLoading();
      await API.post(`/api/v1/cart/${cart.code}/checkout?country=${selectedCountry.id || "222"}&admin_user_id=${admin_user_id}`, payload);
      this.showSuccessNotification("Order placed successfully!");
      this.props.history.goBack();
    } catch (error) {
      console.log("createOrder:: Error on placing order!", error);
      this.showErrorNotification("Error on placing order!" + error.message);
    } finally {
      this.hideLoading();
    }
    console.log("createOrder:: Creating order");
  };

  fetchCountries = async () => {
    try {
      this.showLoading();
      const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
      this.setState({ countries });
    } catch (error) {
      console.log("fetchCountries:: Error on fetching countries!", error);
    } finally {
      this.hideLoading();
    }
  };

  // https://api.kuwa.bevaleo.dev/module/search/customer/?key=anan
  fetchCustomers = async (searchTerm = "") => {
    try {
      this.showLoading();
      const url = `/module/search/customer/?key=${searchTerm}`;
      // const { data = {} } = await API.get(url, {params: {"country": this.state.selectedCountry?.id}});
      // const { customers = [] } = data;
      const { data: customers = [] } = await API.get(url, {
        params: { country: this.state.selectedCountry?.id },
      });
      this.setState({ customers }, () => this.createCustomerOptions());
    } catch (error) {
      console.log("Error on fetching customers!", error);
    } finally {
      this.hideLoading();
    }
  };

  findCountryByCode = (code) => {
    const countries = this.state.countries ?? [];
    return countries === null || !countries.length
      ? null
      : countries.find((country) => country.code === code);
  };

  findCustomerById = (id) => {
    const customers = this.state.customers ?? [];
    return customers.find((customer) => customer.id === id);
  };

  getAddressText = (address) => {
    if (!address) return [];

    const { country, firstName, lastName } = address;
    const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
    const countryName = this.findCountryByCode(country)?.name ?? null;
    const addressItems = [
      countryName,
      fullName,
      address.company ?? null,
      address.address ?? null,
      address.apartment ?? null,
      address.city ?? null,
      address.postalCode ?? null,
      address.stateProvince ?? null,
      address.mobNumber ?? null,
    ];
    return addressItems.filter((it) => it);
  };

  getCartSubtotalAmount = () => {
    const products = this.state.cart?.products;
    let result = 0;
    if (products && products.length) {
      for (let product of products) {
        result += product.subTotal ?? 0;
      }
    }
    return result;
  };

  calculateDiscount = () => {
    let discountAmount = 0;
    const { discountDetails } = this.state.order;
    if (!discountDetails) return "";
    if(!this.state.cart) return discountAmount=0;
    const subtotal = this.state.subTotalAmount;
    const { discountType, discountValue,couponCode } = discountDetails;
    if ("coupon" === discountType?.toLowerCase()) {
      discountAmount = discountValue;
    } else if ("amount" === discountType?.toLowerCase()) {
      discountAmount = Math.min(subtotal, Number(discountValue));
    } else if ("percentage" === discountType?.toLowerCase()) {
      discountAmount = calculatePercentage(
        subtotal,
        Math.min(100, Number(discountValue))
      );
    
    
  }
  return discountAmount;  
  };

  calculateShippingCharge = () => {
    
    const { cart, selectedCountry } = this.state;
    if (!selectedCountry) return 0;
    else if(!cart) return 0;
    return this.getCartSubtotalAmount() >= selectedCountry.minThreshold
      ? 0
      : selectedCountry.deliveryFee ?? 0;
   
     
  };

  getSelectedAddress = (selectedAddressId) =>{
    const { customer  } = this.state || {};
    if(selectedAddressId){
      const listOfShippingAddress = customer.newShippingAddress || [];
      const listOfBillingAddress = customer.newBillingAddress || [];
      const getSelectedShippingAddress = listOfShippingAddress.find((data)=>(data.id == selectedAddressId));
      const asoBillingAddress = getSelectedShippingAddress.asoBillingAddress || ""
      const getSelectedBillingAddress = listOfBillingAddress.find((data)=>(data.id == asoBillingAddress));
      this.setState({selectedShippingAddress:getSelectedShippingAddress, selectedBillingAddress:getSelectedBillingAddress})
    }else{
      const {newShippingAddress=[] , newBillingAddress = []} = this.state.customer || {};
      const defaultShippingAddress = newShippingAddress && newShippingAddress.length
                            ? newShippingAddress.find((it) => it.isDefaultAddress) ??
                              newShippingAddress[0]
                            : null;
                            const defaultBillingAddress =
                            newBillingAddress && newBillingAddress.length
                              ? newBillingAddress.find((it) => it.isDefaultAddress) ??
                                newBillingAddress[0]
                              : null;
                              this.setState({selectedBillingAddress:defaultBillingAddress, selectedShippingAddress:defaultShippingAddress})
    }
    
  }

  getTaxAmount = () => {
    const { selectedCountry } = this.state;
    // const subtotal = this.getCartSubtotalAmount();
    const finalAmout = this.state.totalAmount;
    const tax = Number(finalAmout-(((finalAmout)* (100)) / (100 + (selectedCountry?.vat)))).toFixed(2) || 0
    return tax;
    // return Number(selectedCountry
    //   ? ((subtotal * selectedCountry.vat) / 100).toFixed(2)
    //   : 0)
  };

 
  handleSearchCustomers = (searchTerm) => {
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please Select Country!");
      return;
    }
    this.fetchCustomers(searchTerm);
  };
  handleKeyPress = (event, searchTerm) => {
   console.log("jnsn",searchTerm)
    if (event.key === 'Enter') {
      if (!this.state.selectedCountry) {
        this.showErrorNotification("Please Select Country!");
      } else {
     
        this.fetchCustomers(searchTerm);
      }
    }
  }

  handleSaveNotes = (notes) => {
    console.log("handleSaveNotes:: Notes:", notes);
    const newOrder = { ...this.state.order, notes };
    this.setState({ order: newOrder });
    this.handleHideEditOrderNotesViewModal();
  };
  

  
  componentDidUpdate(prevProp, prevState) {
    if (prevState.order!== this.state.order) {
     
    }
  }

  componentDidUpdate(prevProducts, prevState) {
    const { discountDetails } = this.state.order;
    const { cart , order} = this.state;
    console.log("discountDetailsnsnsj", discountDetails, cart);
  
    if (prevState.cart!== cart) {
      this.setState({order: {
          ...order,
          discountDetails: null
        }
      },()=>{this.calculateTotalAmount()});
    }
  }

  handleSaveDiscountDetails = (updateDiscountDetails) => {
    console.log("bjhbqbbqj",updateDiscountDetails)
    const updatedOrder = {
      ...this.state.order,
      discountDetails: updateDiscountDetails,
    };
    this.setState({ order: updatedOrder },()=>{
      this.calculateTotalAmount()
    });
   
    this.showSuccessNotification("Discount added successfully")
    
    this.handleHideEditDiscountViewModal();
  }

  

 

  handleProductSelect = (option) => {
    console.log("handleProductSelect:: Selected Product", option);
    let payloadData= {}
    if(option.isVariant){
      payloadData= {"product":option.productId,"quantity":1,"isVariant":true,"variantId":option.variantId,}
    }else{
      payloadData= {
        product:option.productId,
        quantity:1,
        "isVariant":false,
        "variantId":null,
      }
    }
    this.addProductToCart(payloadData);
  };
getCartData = async (cartCode) => {
    try {
      this.showLoading();
      const response = await API.get(`/api/v1/admin/cart?code=${cartCode}&country=${this.state.selectedCountry?.id}&user=${this.state.customer?.id || null}`);
      console.log("response", response);
      if (response.status === 200) {
        const { data: cart } = response;
        console.log("cartSanto", cart);
  
        this.setState(
          {
            cart: cart,
            subTotalAmount: cart.displaySubTotal,
            totalAmount: cart.displaySubTotal,
          },
          () => this.calculateTotalAmount()
        );

      } else {
        console.error("Failed to fetch cart data. Status code:", response.status);
      }
    }
     catch (error) {
         console.log("addProductToCart:: Error on deleting cart item!", error);
                this.showErrorNotification(
                  "Error on deleting cart item!" + error.message ?? ""
                );
            }
            finally {
                  this.hideLoading();
                   
                }
            
    }
  
    getPrepaidDiscountPercentage = (subtotal,discount,prepaidDiscountPercentage) =>{
      console.log("wkqkq")
      const prepaidDiscount = (parseFloat((subtotal-discount) * prepaidDiscountPercentage ).toFixed(2))/100;
      return prepaidDiscount;
    }

calculateTotalAmount   = (subTotal) =>{
  const {selectedCountry,order,prepaidDiscount} = this.state
  const paymentoption = order.paymentDetails || {}
      const subtotal = this.getCartSubtotalAmount();
    // const subtotal = displaySubTotal
      const discount = this.calculateDiscount();
      const shippingAmount = this.calculateShippingCharge();
      const taxAmount = this.getTaxAmount();
      let totalAmount = 0;
      let shippingAmnt = 0;
      let taxAmnt = 0;
      let customFee = subtotal > 0 ? selectedCountry.customFee : 0
      let codCharge = (subtotal > 0 && order.isCashOnDelivery) ? selectedCountry.codCharge : 0
     
      let prepaidDiscountPercentage = (subtotal > 0 && Object.keys(paymentoption).length > 0 ) ? selectedCountry.prepaidDiscountPercentage : 0
      console.log("orderUpdate:",order,subtotal,discount,prepaidDiscountPercentage,shippingAmount,taxAmount,prepaidDiscountPercentage)
      // if(subtotal === discount){
      //   totalAmount = 0
      //   shippingAmnt = 0
      //   taxAmnt = 0
      // }else{
      const PrepaidDiscountAmount =  this.getPrepaidDiscountPercentage(subtotal,discount,prepaidDiscountPercentage)
      console.log("PrepaidDiscount",PrepaidDiscountAmount)
        totalAmount = (subtotal + shippingAmount ) - discount + customFee + codCharge - PrepaidDiscountAmount;
        shippingAmnt = shippingAmount
        taxAmnt = taxAmount;
      // }
      console.log("totalAmount",totalAmount)
      this.setState({totalAmount:totalAmount, discount: discount, taxAmount:taxAmount, shippingAmount:shippingAmount,prepaidDiscount:PrepaidDiscountAmount})
  }

  addProductToCart = async (option) => {
    const { cart } = this.state;
    console.log("addProductToCart:: Selected option", { cart, option });
    if (cart) {
      try {
        this.showLoading();
        const { data: updatedCart } = await API.post(
          `/api/v1/cart/?code=${cart.code}&country=${this.state.selectedCountry?.id}&user=${this.state.customer?.id|| null}`,
          option
        );
        this.getCartData(cart.code);
        // this.showSuccessNotification("Item created successfully")
        // this.setState({ cart: updatedCart });
      } catch (error) {
        console.log("addProductToCart:: Error on updating cart!", error);
        this.showErrorNotification(
          "Error on updating cart!" + error.message ?? ""
        );
      } finally {
        this.hideLoading();
       
      }
    } else {
      try {
        this.showLoading();
        const { data: cart } = await API.post(`/api/v1/cart?country=${this.state.selectedCountry?.id}&user=${this.state.customer?.id || null}`, option);
        this.getCartData(cart.code);
        // this.showSuccessNotification("Item created successfully")
      } catch (error) {
        console.log("addProductToCart:: Error on creating cart!", error);
        this.showErrorNotification(
          "Error on creating cart!" + error.message ?? ""
        );
      } finally {
        this.hideLoading();
       
      }
    }
  };


  updateCartItemQuantity = async (cartItem, quantity) => {
    const variantData = cartItem && cartItem.variants || null;
    const variantName = variantData && variantData.variants &&  variantData.variants.name || "";

    let payloadData= {

    }
    if(variantName){
      payloadData= {"product":cartItem.id,"quantity":quantity,"isVariant":true,"variantId":variantData.variants.id,}
     
    }else{
      payloadData= {
        product:cartItem.id,
        quantity:quantity,
      }
    }
    const { cart } = this.state;
    if (!cart) {
      console.log("updateQuantity:: Cart is not available!", { cart });
      this.showErrorNotification("Cart is not available!");
      return;
    }

    if (quantity < 1) {
      this.deleteCartItem(cartItem);
      return;
    }

    try {
      this.showLoading();
      const { data: updatedCart } = await API.put(`/api/v1/cart/${cart.code}?country=${this.state.selectedCountry?.id}&user=${this.state.customer?.id || null}`,payloadData);
      this.setState({ cart: updatedCart },()=>{this.calculateTotalAmount()});
    } catch (error) {
      console.log("addProductToCart:: Error on updating cart!", error);
      this.showErrorNotification(
        "Error on updating cart!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  // https://api.kuwa.bevaleo.dev/api/v1/cart/3a5abf4a88d6462f914509cec94109c2/product/81
  deleteCartItem = async (cartItem) => {
    const { cart } = this.state;
    console.log("cartLength",cartItem)
    if (!cart) {
      console.log("deleteCartItem:: Cart is not available!", { cart });
      this.showErrorNotification("Cart is not available!");
      return;
    }

    try {
      this.showLoading();
      const url = `/api/v1/admin/cart/product/${cartItem.cartItemId || ""}?country=${this.state.selectedCountry?.id}&user=${this.state.customer?.id || null}`;
      await API.delete(url, {params: { code: cart.code }});
      if(cart.products.length===1){
        this.setState({ cart: null,}, () => this.calculateTotalAmount());
      }
      else
       this.getCartData(cart.code)
       
   
    } catch (error) {
      console.log("addProductToCart:: Error on deleting cart item!", error);
      this.showErrorNotification(
        "Error on deleting cart item!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
      // this.showSuccessNotification("item deleted successfully")     
    }
  };

 

  createCustomerOptions = () => {
    const { customers = [] } = this.state;
    const customerOptions = customers.map(this.createCustomerOption);
    this.setState({ customerOptions });
  };

  createCustomerOption = (customer) => {
    if (!customer) return;
    const { firstName, lastName, emailAddress } = customer;
    const fullName = `${firstName || ""} ${lastName || ""}`.trim() || "";
    const label = (
      <>
        <span>{fullName}</span>
        <br />
        <span className="text-muted">{emailAddress}</span>
      </>
    );
    return {
      key: customer.id,
      value: customer.id,
      label: label,
    };
  };

  createProductOptions = () => {
    const { products = [] } = this.state;
    const productOptions = products.map(this.createProductOption);
    this.setState({ productOptions });
  };

  createProductOption = (product) => {
    if (!product) return;

    const varaintData = product.variants || [];

    const { selectedCountry } = this.state;
    const { id, descriptions, images, availabilities } = product && product.product || {};
    const description =
      descriptions && descriptions.length ? descriptions[0] : null;
    const imageUrl =
      images && images.length ? images[0].productImageUrl ?? "" : "";
    const availability =
      availabilities && availabilities.length ? availabilities[0] : null;
    const priceInfo =
      availability && availability.prices && availability.prices.length
        ? availability.prices[0]
        : null;

    const label = (
      <div className="row">
        <div className="col-1 d-flex justify-content-center align-items-center">
          <img
            src={imageUrl}
            alt=""
            className="img-thumbnail"
            style={{
              maxWidth: "50px",
              maxHeight: "50px",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="col-6 text-start align-items-center" >
          <span style={{wordWrap:"break-word"}}>{description?.name ?? ""}</span>
        </div>
        <div className="col-2 text-end align-items-center">
          <span>{`${availability?.onHandQuantity ?? 0} available`}</span>
        </div>
        <div className="col-3 text-start align-items-center">
          <span>{`${selectedCountry?.currency ?? ""} ${
            priceInfo?.productPriceSpecialAmount ?? 0
          }`}</span>
        </div>
      </div>
    );
    return {
      key: id,
      value: id,
      label: label,
    };
  };

  renderCartCard = () => {
    const { cart, productSearchTerm, productOptions } = this.state;
    // console.log("cart",cart)
    // const selectProduct = (
    //   <>
    //     <div className="row mx-2">
    //       <div className="form-group col">
    //         <label htmlFor="productSearchTerm">Search Product</label>
    //         <SearchBox
    //           onSearch={(searchTerm) => this.handleSearchProducts(searchTerm)}
    //         />
    //       </div>
    //     </div> 
    //     <div className="row mx-2">
    //       <div className="form-group col">
    //         <label>Select Product</label>
    //         <Select
    //           key={"select-product"}
    //           onChange={this.handleProductSelect}
    //           options={productOptions ?? []}
              
    //           placeholder="Select Product"
    //         />
    //       </div>
    //     </div> 
    //   </>
    // );

    const cartItems =
      cart && cart.products && cart.products.length ? (
        <div className="container-fluid">
          <div className="row row-cols-4 mx-2 mt-2">
            <div className="col-5">
              <span>Product</span>
            </div>
            <div className="col-2 text-start">
              <span>Quantity</span>
            </div>
            <div className="col-3 text-end">Total</div>
            <div className="col-2"></div>
          </div>
          {cart.products.map(this.renderCartItem)}
        </div>
      ) : (
        ""
      );

      // console.log("cartItemscartItems",cartItems)

      if(cartItems){
        return (
          <Card style={{ background: "#FFFFFF" }}>
            <Card.Body className="mx-0 px-0">
              {/* <div className="row mx-2">
                <div className="col-8">
                  <p className="h6">Products</p>
                </div>
              </div> */}
              {/* {selectProduct} */}
              {cartItems}
            </Card.Body>
          </Card>
        );
      }else{
        return <></>
      }
   
  
  };

  renderCartItem = (cartItem) => {
    if (!cartItem) return null;
    console.log("cartItem",cartItem)
    // console.log("cartItemcartItem",cartItem.variants)

    const { selectedCountry } = this.state;
    const { id, description, sku, quantity, finalPrice, subTotal, images } =
      cartItem;
    const { name } = description ?? {};
    const imageUrl =
    images && images.length ? images[0].externalUrl ?? "" : "";
    const variantData = cartItem && cartItem.variants || null;

    const variantName = variantData && variantData.variants &&  variantData.variants.name || "";

    console.log("variantDatavariantData",variantName)

    return (
      <div className="row mx-2 mt-2">
        <div className="col-1">
          <div>
            <img
              src={imageUrl ||  ""}
              alt=""
              className="img-thumbnail position-relative"
              style={{
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "50px",
                maxHeight: "50px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <div className="col-4 ms-2">
          <div className="row">
            <Link to={`/dashboard/product/${id}`}>
              <span>{name ?? ""}</span>
              {variantName &&<span> (Pack of ({variantName}))</span>}
            </Link>
            <span className="text-muted">{`SKU: ${sku}`}</span>
            <br />
            {/* <span>{`${selectedCountry.currency ?? ""} ${
              finalPrice?.toFixed(2) ?? "0.00"
            }`}</span> */}
          </div>
        </div>
        <div className="col-3 text-end">
          <div className="input-group">
            <button
              type="button"
               disabled={quantity==1}
              onClick={() => {
                this.updateCartItemQuantity(cartItem, quantity - 1);
              }}
              id="decrement-quantity"
              className="btn btn-sm btn-outline-secondary"
            >
              -
            </button>
            <input
              type="number"
              value={quantity ?? ""}
              id="quantity"
              name="quantity"
              style={{paddingLeft:"10px",
                width:"45px",
                textAlign:"center"}}

              
              readOnly
            />
            <button
              type="button"
              onClick={() =>
                this.updateCartItemQuantity(cartItem, quantity + 1)
              }
              id="increment-quantity"
              className="btn btn-sm btn-outline-secondary"
            >
              +
            </button>
          </div>
        </div>
        <div className="col-2 ms-auto">{`${selectedCountry?.currency ?? ""} ${
          subTotal?.toFixed(2) ?? "0.00"
        }`}</div>
        <div className="col-1 text-end ms-auto">
          <IconButton
            onClick={() => this.deleteCartItem(cartItem)}
            className="p-0"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    );
  };

  renderPaymentCard = () => {
    const { cart, order, selectedCountry ,prepaidDiscount} = this.state;
    const { shippingDetails, discountDetails, paymentDetails } = order ?? {};
    console.log("first",prepaidDiscount)
    

    const subtotal = (
     
      <div className="row mx-2 mt-3">
        <div className="col">Subtotal</div>
        <div className="col text-end">{`${
          selectedCountry?.currency ?? ""
        } ${this.getCartSubtotalAmount()}`}</div>
      </div>
    );
       
    const discount = (
      <div className="row mx-2 mt-2">
        <div className="col">
          <button
          disabled={this.state.cart===null}
            type="button"
            onClick={this.showEditDiscountViewModal}
            class="btn btn-sm btn-link p-0"
          >
            {`${discountDetails ? "Edit discount" : "Add discount"}`}
          </button>
        </div>
        <div className="col">
          <span className="text-muted">{`${
            discountDetails
              ? discountDetails.discountReason || "Custom discount"
              : "—"
          }`}</span>
        </div>
        <div className="col text-end">
          <span>

            -{discountDetails
              ? `${
                  selectedCountry?.currency ?? ""
                } ${this.calculateDiscount().toFixed(2)}`
              : `${selectedCountry?.currency ?? ""} 0.00`}
          </span>
        </div>
      </div>
    );

    const prepaidDiscountAmountField = (
      <div className="row mx-2 mt-2">
            <div className="col">  <span>Prepaid Discount</span></div>
            <div className="col text-end">
            - {`${
              selectedCountry?.currency ?? ""
            } ${prepaidDiscount}`}
            </div>
        </div>
    )

    const shipping = (
      <div className="row mx-2 mt-2">
        <div className="col">
          {/* <button
            type="button"
            onClick={this.showEditShippingViewModal}
            class="btn btn-sm btn-link p-0"
          >
            {`${shippingDetails ? "Edit shipping" : "Add shipping"}`}
          </button> */}
          <span>Shipping</span>
        </div>
        <div className="col">
          <span>{`${
            shippingDetails
              ? shippingDetails.isFreeShipping
                ? "Free shipping"
                : shippingDetails.rateName || "Custom"
              : "—"
          }`}</span>
        </div>
        <div className="col text-end">
          {/* <span className={`${shippingDetails ? "" : "text-muted"}`}>
            {shippingDetails?.isCustomShipping
              ? `AED ${shippingDetails.price?.toFixed(2)}`
              : "AED 0.00"}
          </span> */}
          <span className={`${shippingDetails ? "" : "text-muted"}`}>
            {`${
              selectedCountry?.currency ?? ""
            } ${this.calculateShippingCharge()?.toFixed(2)}`}
          </span>
        </div>
      </div>
    );

    const customFee = (
      <div className="row mx-2 mt-2">
            <div className="col">  <span>Custom Fee</span></div>
            <div className="col text-end">
            {`${
              selectedCountry?.currency ?? ""
            } ${selectedCountry?.customFee}`}
            </div>
        </div>
    )

    const codCharge = (
      <div className="row mx-2 mt-2">
            <div className="col">  <span>Extra Cod Charge</span></div>
            <div className="col text-end">
            {`${
              selectedCountry?.currency ?? ""
            } ${selectedCountry?.codCharge}`}
            </div>
        </div>
    )

    const estimatedTax = (
      <div className="row mx-2 mt-2">
        <div className="col">
          {/* <button
            type="button"
            onClick={this.showEditTaxViewModal}
            class="btn btn-sm btn-link p-0"
          >
            Estimated Tax
          </button> */}
          <span>Estimated Tax</span>
        </div>
        <div className="col">
          <span className={`${order.chargeTaxes ? "" : "text-muted"}`}>
            {order.chargeTaxes
              ? `VAT ${selectedCountry?.vat ?? 0}%`
              : "Not collected"}
          </span>
        </div>
        <div className="col text-end">
          <span className={`${order.chargeTaxes ? "" : "text-muted"}`}>
            {order.chargeTaxes
              ? `${selectedCountry?.currency ?? ""} ${this.getTaxAmount()}`
              : `${selectedCountry?.currency ?? ""} 0.00`}
          </span>
        </div>
      </div>
    );

    const total = (
      
      <div className="row mx-2 mt-2 fw-bold">
        <div className="col">Total</div>
        <div className="col text-end">{`${
          selectedCountry?.currency ?? ""
        // } ${this.getFinalTotal().toFixed(2)}`}</div>
      } ${this.state.totalAmount.toFixed(2)}`}</div>
      </div>
    );

    const paymentDueLater = (
      <div className="row mx-2 my-2">
        <div className="form-group col">
          <Form.Check
            type="checkbox"
            checked={order?.isCashOnDelivery ?? false}
            onChange={(event) => {
              const isCashOnDelivery = event.target.checked;
              const updatedOrder = {
                ...order,
                isCashOnDelivery,
                paymentDetails: isCashOnDelivery ? {} : order.paymentDetails,
              };
              this.setState({ order: updatedOrder },() => this.calculateTotalAmount());
            }}
            id="isCashOnDelivery"
            name="isCashOnDelivery"
            label="Is cash on delivery"
            // label="Payment due later"
          />
        </div>
      </div>
    );

    const actionButtons = (
      <div className="d-flex justify-content-end me-3">
        <button style={{borderRadius:"20px"}}
          onClick={(f) => f}
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          Send invoice
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}} 
          onClick={(f) => f}
          type="button"
          className="btn btn-sm btn-success ms-2"
        >
          Collect payment
        </button>
      </div>
    );

    const paymentDetailsView = (
      <>
        <div className="row mx-2 my-2">
          <div className="form-group col-6">
            <label htmlFor="paymentMode">Payment Mode</label>
            <Select
              key="select-payment-mode"
              value={this.findPaymentModeOption(paymentDetails?.paymentMode)}
              onChange={(option) => {
                const updatedPaymentDetails = {
                  ...paymentDetails,
                  paymentMode: option.value,
                };
                this.setState({
                  order: { ...order, paymentDetails: updatedPaymentDetails },
                },()=> this.calculateTotalAmount());
              }}
              id="paymentMode"
              name="paymentMode"
              placeholder="Payment Mode"
              menuPlacement="top"
              search
              selection
              options={PAYMENT_MODE_OPTIONS}
            />
          </div>
          <div className="form-group col-6">
            <label htmlFor="paymentGateway">Payment Gateway</label>
            <Select
              key="select-payment-gateway"
              value={this.findPaymentGatewayOption(
                paymentDetails?.paymentGateway
              )}
              onChange={(option) => {
                const updatedPaymentDetails = {
                  ...paymentDetails,
                  paymentGateway: option.value,
                };
                this.setState({
                  order: { ...order, paymentDetails: updatedPaymentDetails },
                },()=> this.calculateTotalAmount());
              }}
              id="paymentGateway"
              name="paymentGateway"
              placeholder="Payment Gateway"
              menuPlacement="top"
              search
              selection
              options={PAYMENT_GATEWAY_OPTIONS}
            />
          </div>
        </div>
        <div className="row mx-2 mt-2">
          <div className="form-group col">
            <label htmlFor="paymentReferenceNumber">
              Payment Reference Number
            </label>
            <input
              type="text"
              value={paymentDetails?.paymentReferenceNumber}
              onChange={(event) => {
                const updatedPaymentDetails = {
                  ...paymentDetails,
                  paymentReferenceNumber: event.target.value,
                };
                this.setState({
                  order: { ...order, paymentDetails: updatedPaymentDetails },
                });
              }}
              id="paymentReferenceNumber"
              name="paymentReferenceNumber"
              className="form-control"
            />
          </div>
        </div>
      </>
    );

    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body className="px-0">
          <div className="row mx-2">
            <div className="col">
              <p className="h6">Payment</p>
            </div>
          </div>
          {subtotal}
          {discount}
          {this.state.prepaidDiscount > 0 && prepaidDiscountAmountField}
          {(this.state.totalAmount> 0)&& shipping}
          {(this.getCartSubtotalAmount()> 0 && selectedCountry.customFee > 0 ) && customFee}
          {(order.isCashOnDelivery  && selectedCountry.codCharge > 0 ) && codCharge}
          {(this.state.totalAmount> 0)&& estimatedTax}
          {total}
          <hr />
          {paymentDueLater}
          {!order.isCashOnDelivery && (
            <>
              <hr />
              {paymentDetailsView}
            </>
          )}
          {/* {actionButtons} */}
        </Card.Body>
      </Card>
    );
  };

  renderNotesCard = () => {
    const { order } = this.state;
    return (
      <OrderNotesCard
        notes={order?.notes ?? ""}
        onEdit={this.showEditOrderNotesViewModal}
      />
    );
  };

  showManageAddressViewModal = (addressType = "shipping") => {
    this.setState({
      isManageAddressViewModalVisible: true,
      addressTypeForModal: addressType,
    });
  };

  showShippingAddressViewModal = ()=>{
    this.setState({isShippingAddressViewModalVisible:true})
  }

  showBillingAddressViewModal = ()=>{
    this.setState({isBillingAddressViewModalVisible:true})
  }
  handleHideBillingAddressViewModal = ()=>{
    this.setState({isBillingAddressViewModalVisible:false})
  }

  handleHideShippingAddressViewModal = ()=>{
    this.setState({isShippingAddressViewModalVisible:false})
  }
  handleHideManageAddressViewModal = () => {
    this.setState({
      isManageAddressViewModalVisible: false,
      addressTypeForModal: null,
    });
  };
  handleSaveAddress = ( address,billAddress) => {
    
    this.saveCustomerAddress( address, billAddress,() =>
      this.handleHideManageAddressViewModal()
    );
  };

  handleEditShippingAddress = (shippingAddress)=>{
    this.editShippingAddress(shippingAddress, ()=>{
     this.handleHideShippingAddressViewModal()
    })
  }

  fetchCustomerDetails = async () => {
    const { customer } = this.state;
    if (!customer.id) {
   
      return;
    }

    const url = `${BASE_URL_CUSTOMER_v2}/${customer.id}`;
    try {
      this.showLoading();
      const { data: customer } = await API.get(url);
      console.log("customerDetails", customer);
      this.setState({ customer: customer ?? {} },()=>this.getSelectedAddress());
    } catch (error) {
      console.log(
        "fetchCustomerDetails:: Error on fetching customer details!",
        error
      );
      this.showErrorNotification(
        "Error on fetching customer details!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  editShippingAddress = async(shippingAddress,onSuccess = (f) => f)=>{
    const { customer } = this.state;
    try {
    let shippingUrl = null;
    shippingUrl= "/module/address/shipping";
    if(shippingAddress.id) shippingUrl+= `/${shippingAddress.id}`;
    await API.patch(shippingUrl,shippingAddress)
    // onSuccess();
    this.showSuccessNotification("Address saved successfully!");
    this.handleHideShippingAddressViewModal()
    this.fetchCustomers()
    this.fetchCustomerDetails()
    }
    catch (error) {
      console.log("updateCustomerDetails:: Error on saving address!", error);
      this.showErrorNotification(
        "Error on saving address!" + error.message ?? ""
      );
    } finally {
      // this.hideLoading();
    }
  }

  saveCustomerAddress = async ( address,billAddress, onSuccess = (f) => f) => {
    console.log("addressaddressaddress",address)
    console.log("billAddressbillAddressbillAddress",billAddress)
    const { customer } = this.state;
    // try {
      this.showLoading();
      let url = null;
      let payload = {};
       url = "/module/address";
      payload = { billingAddress: {...billAddress, billingAddress:true, isActive:true, isDefaultAddress:true},
                shippingAddress: {...address, isActive:true, isDefaultAddress:true, shippingAddress:true }};
      if (address && address.id){
        url += `/${address.id}`;
        await API.patch(url, address);
      } 
      else{
        url += `?user=${customer.id}`;
        await API.post(url, payload);
      } 
      this.fetchCustomerDetails()
      this.handleHideManageAddressViewModal()
      this.showSuccessNotification("Address saved successfully!");
      // this.fetchCustomerDetails()
    // } catch (error) {
    //   console.log("updateCustomerDetails:: Error on saving address!", error);
    //   this.showErrorNotification(
    //     "Error on saving address!" + error.message ?? ""
    //   );
    // } finally {
    //   this.hideLoading();
    // }
  };
  handleEditBillingAddress = (billingAddress)=>{
    this.editBillingAddress(billingAddress, ()=>{
     this.handleHideBillingAddressViewModal()
    })
  }

  editBillingAddress = async(billingAddress,onSuccess = (f) => f)=>{
    const { customer } = this.state;
    try {
    let billingUrl = null;
    billingUrl= "/module/address/billing";
    if(billingAddress.id) billingUrl+= `/${billingAddress.id}`;
    await API.patch(billingUrl,billingAddress)
    // onSuccess();
    this.handleHideBillingAddressViewModal()
    this.showSuccessNotification("Address saved successfully!");
    this.fetchCustomerDetails()
    this.fetchCustomers()
    }
    catch (error) {
      console.log("updateCustomerDetails:: Error on saving address!", error);
      this.showErrorNotification(
        "Error on saving address!" + error.message ?? ""
      );
    } finally {
      // this.hideLoading();
    }
  }
 
 manageDropdown = () =>{
    return(
      <Dropdown>
      <Dropdown.Toggle variant="link" id="manage-dropdown">
        Manage
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          href="#"
          onClick={() => this.showBillingAddressViewModal()}
        >
          Edit billing addresses
        </Dropdown.Item>
        <Dropdown.Item
          href="#"
          onClick={() => this.showShippingAddressViewModal()}
        >
          Edit shipping addresses
        </Dropdown.Item>
        <Dropdown.Item
          href="#"
          onClick={() => this.showManageAddressViewModal()}
        >
         Add addresses
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    )
  }

  renderCustomerCard = () => {
    const { customer, customerSearchTerm, customerOptions } = this.state;
    const selectCustomer = (
      <>
        <div className="row mx-2">
          <div className="form-group col">
            <label>Search Customer</label>
            {/* <div className="input-group mb-3">
              <input
                value={customerSearchTerm ?? ""}
                onChange={(e) =>
                  this.setState({ customerSearchTerm: e.target.value })
                }
                type="text"
                id="customerSearchTerm"
                name="customerSearchTerm"
                className="form-control"
                placeholder="Search Customer"
              />
              <i
                className="input-group-text"
                onClick={this.handleSearchCustomers}
                style={{ cursor: "pointer" }}
              >
                <SearchIcon />
              </i>
            </div> */}
            <SearchBox
              onSearch={(searchTerm) => this.handleSearchCustomers(searchTerm)}
              onKeyPress={(e,searchTerm) => this.handleKeyPress(e,searchTerm)}

            />
          </div>
        </div>
        <div className="row mx-2">
          <div className="form-group col">
            <label>Select Customer</label>
            <Select
              key={`select-customer-${customer?.id ?? -1}`}
              value={this.createCustomerOption(customer)}
              onChange={(option) => {
                this.setState({
                  customer: this.findCustomerById(option.value),
                },()=>{
                  console.log("fgdkshajld",this.state.customer)
                  const {newShippingAddress=[] , newBillingAddress = []} = this.state.customer || {};
                  const defaultShippingAddress =
                  newShippingAddress && newShippingAddress.length
                    ? newShippingAddress.find((it) => it.isDefaultAddress) ??
                      newShippingAddress[0]
                    : null;
                    const defaultBillingAddress =
                    newBillingAddress && newBillingAddress.length
                      ? newBillingAddress.find((it) => it.isDefaultAddress) ??
                        newBillingAddress[0]
                      : null;
                      this.setState({selectedBillingAddress:defaultBillingAddress, selectedShippingAddress:defaultShippingAddress})
                });
              }}
              options={customerOptions ?? []}
              styles={SELECT_STYLES}
              placeholder="Select Customer"
            />
          </div>
        </div>
      </>
    );

    const {
      id: customerId,
      firstName,
      lastName,
      emailAddress,
      billing,
      newBillingAddress,
      newShippingAddress,
    } = customer ?? {};
    const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
    const contactInformation = (
      <>
        <div className="row mx-2">
          <span className="text fw-semibold">Contact information</span>
        </div>
        <div className="row mt-2 mx-2">
          <span>{emailAddress}</span>
        </div>
      </>
    );

    const defaultShippingAddress = this.state.selectedShippingAddress ? this.state.selectedShippingAddress: null;
 
        console.log("defaultShippingAddressdefaultShippingAddress",defaultShippingAddress)
        const isDefaultShippingAddress = defaultShippingAddress && Object.keys(defaultShippingAddress).length > 0;
        console.log("isDefaultShippingAddressisDefaultShippingAddress",isDefaultShippingAddress)
    const shippingAddressView = (
      <>
        {isDefaultShippingAddress ?<>
        <div className="row mx-2">
          <div className="col-10">
            <span className="text fw-semibold">Shipping address</span>
          </div>
          {/* <div className="col-sm text-end">
            <span
              onClick={() =>
                this.showEditAddressViewModal(
                  defaultShippingAddress,
                  "shipping"
                )
              }
              style={{ cursor: "pointer", color: "#2D9BF0" }}
            >
              Change Address
            </span>
          </div> */}
        </div>
        <div className="row mt-2 mx-2">
          <div className="col">
            {this.getAddressText(defaultShippingAddress)?.map((it) => (
              <>
                <span>{it}</span>
                <br />
              </>
            ))}
          </div>
        </div>
        </>:<button className="greenbackground"  onClick={() => this.showManageAddressViewModal()} style={{textAlign:'center',display:'flex',margin:'0 auto'}}>Add Address</button>}
      </>
    );

    const defaultBillingAddress = this.state.selectedBillingAddress ? this.state.selectedBillingAddress : null;
        console.log("defaultBillingAddressdefaultBillingAddress",defaultBillingAddress)
    const isDefaultBillingAddress = defaultBillingAddress && Object.keys(defaultBillingAddress).length > 0;
    const billingAddress = (
      <>

        {isDefaultBillingAddress && <>
        <div className="row mx-2">
          <div className="col-10">
            <span className="text fw-semibold">Billing address</span>
          </div>
        </div>
        <div className="row mt-2 mx-2">
          <div className="col">
            {this.getAddressText(defaultBillingAddress)?.map((it) => (
              <>
                <span>{it}</span>
                <br />
              </>
            ))}
          </div>
        </div>
        </>}
      </>
    );

    const customerDetails = (
      <>
        <div className="row mx-2">
          <Link to={`/dashboard/customers/${customerId}`}>{fullName}</Link>
        </div>
        <hr />
        {contactInformation}
        <hr />
        {shippingAddressView}
        <hr />
        {billingAddress}
      </>
    );

    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body className="mx-0 px-0">
          <div className="row mx-2">
            {customer &&<div className="col-8">
                <div className="row" style={{alignItems:'end'}}>
                  <p className="col h6">Customer</p>
                  <div className="col">{this.manageDropdown()}</div>
                </div>
             
            </div>}
            {customer && (
              <div className="col text-end">
                <IconButton
                  onClick={(e) => {
                    this.setState({
                      customerSearchTerm: "",
                      customer: null,
                      customerOptions: [],
                    });
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          </div>
          {!customer && selectCustomer}
          {customer && customerDetails}
        </Card.Body>
      </Card>
    );
  };

  showEditOrderNotesViewModal = () => {
    const { order } = this.state;
    this.setState({
      isEditOrderNotesViewModalVisible: true,
      notesForModal: order.notes ?? null,
    });
  };

  handleHideEditOrderNotesViewModal = () =>
    this.setState({
      isEditOrderNotesViewModalVisible: false,
      notesForModal: null,
    });

  showEditAddressViewModal = (address, addressType) => {
    this.setState({
      isEditAddressViewModalVisible: true,
      addressForModal: { ...address, addressType },
    });
  };

  handleHideEditAddressViewModal = () => {
    this.setState({
      isEditAddressViewModalVisible: false,
      addressForModal: null,
    });
  };

  showEditTaxViewModal = () => {
    this.setState({ isEditTaxViewModalVisible: true });
  };

  handleHideEditTaxViewModal = () => {
    this.setState({ isEditTaxViewModalVisible: false });
  };

  showEditShippingViewModal = () => {
    this.setState({
      isEditShippingViewModalVisible: true,
      shippingDetailsForModal: this.state.order.shippingDetails,
    });
  };

  handleHideEditShippingViewModal = () => {
    this.setState({
      isEditShippingViewModalVisible: false,
      shippingDetailsForModal: null,
    });
  };

 
  
  showEditDiscountViewModal = () => {
    if(this.state.cart=== null){
     this.setState({isEditDiscountViewModalVisible: false}) 
      this.showErrorNotification("pleaase select item")
    }
    this.setState({
      isEditDiscountViewModalVisible: true,
      discountDetailsForModal: this.state.order.discountDetails,
      cartDetailsForModal: this.state.cart,
    });
  };

  handleHideEditDiscountViewModal = (showNotification) => {
    this.setState({
      isEditDiscountViewModalVisible: false,
      discountDetailsForModal: null,
      cartDetailsForModal: null,
    });
    if (showNotification) {
      this.showSuccessNotification("Discount removed successfully");
    }
  };

  showSelectProductsViewModal = (searchTerm) => {
    this.setState({
      productSearchTerm: searchTerm,
      isSelectProductsViewModalVisible: true,
    });
  };

  handleHideSelectProductsViewModal = () => {
    this.setState({
      productSearchTerm: "",
      isSelectProductsViewModalVisible: false,
    });
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  renderHeader = () => {
    return (
      <div class="d-flex justify-content-start-0">
        <div style={{ alignSelf: "flex-start" }}>
          <Button
            onClick={() => this.props.history.goBack()}
            color="inherit"
            size="large"
          >
            <ArrowBackIcon />
          </Button>
        </div>
        <span
          className="mx-3 my-2"
          style={{ fontWeight: "600", fontSize: "1.25rem" }}
        >
          Create Order
        </span>
        <div className="col text-end">
          <Button
            onClick={() => this.createOrder()}
            variant="contained"
            size="large"
            sx={{
              bgcolor: "#247A81",
              color: "white", 
              borderRadius: "20px",
              ":hover": {
                bgcolor: "#125443",
              },
              textTransform: "capitalize",
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  renderCountrySelectionCard = () => {
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col">
              <p className="h6">Country Selection</p>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <SelectCountry
                value={this.state.selectedCountry?.code}
                onChange={(option) => {
                  this.setState({
                    selectedCountry: this.findCountryByCode(option.value),
                  });
                }}
                id="selectedCountry"
                name="selectedCountry"
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  render() {
    const {
      order,
      isEditOrderNotesViewModalVisible,
      isEditAddressViewModalVisible,
      isEditTaxViewModalVisible,
      isEditShippingViewModalVisible,
      isEditDiscountViewModalVisible,
      isSelectProductsViewModalVisible,
    } = this.state;

    console.log("this.state.customer",this.state)

    const pageHeader = this.renderHeader();
    const countrySelectionSection = (
      <div class="d-flex justify-content-between mt-4">
        <div className="col-8">{this.renderCountrySelectionCard()}</div>
      </div>
    );
    const pageLeftSection = (
      <div className="col-8" style={{ marginRight: "16px" }}>
        <ProductSearch countryId={this.state.selectedCountry} handleProductSelect={(data)=>this.handleProductSelect(data)} />
        {this.renderCartCard()}
        {this.renderPaymentCard()}
      </div>
    );
    const pageRightSection = (
      <div className="col-4">
        <div className="d-flex flex-column">
          {this.renderNotesCard()}
          {this.renderCustomerCard()}
        </div>
      </div>
    );
    const pageBody = (
      <div class="d-flex justify-content-between mt-4">
        {pageLeftSection}
        {pageRightSection}
      </div>
    );
    return (
      <div className="container">
        {pageHeader}
        {countrySelectionSection}
        {pageBody}
        <EditOrderNotesViewModal
          notes={this.state.notesForModal ?? ""}
          title="Add Notes"
          onHide={this.handleHideEditOrderNotesViewModal}
          onSave={this.handleSaveNotes}
          isVisible={isEditOrderNotesViewModalVisible ?? false}
        />

        <EditAddressViewModal
          address={this.state.addressForModal}
          onSave={(addressForModal) =>{
            console.log(addressForModal,"addressForModal")
            this.getSelectedAddress(addressForModal.id)
            this.handleHideEditAddressViewModal()
          }
            }
          onHide={this.handleHideEditAddressViewModal}
          isVisible={isEditAddressViewModalVisible ?? false}
          customerData ={this.state.customer || {}}
        />

        <EditTaxViewModal
          isVisible={isEditTaxViewModalVisible ?? false}
          chargeTaxes={order.chargeTaxes}
          onHide={this.handleHideEditTaxViewModal}
          onSave={(chargeTaxes) => {
            const updatedOrder = { ...order, chargeTaxes };
            this.setState({ order: updatedOrder });
            this.handleHideEditTaxViewModal();
          }}
        />

         <EditShippingViewModal
          isVisible={isEditShippingViewModalVisible}
          shippingDetails={this.state.shippingDetailsForModal}
          onHide={this.handleHideEditShippingViewModal}
          onSave={(updateShippingDetails) => {
            const updatedOrder = {
              ...order,
              shippingDetails: updateShippingDetails,
            };
            this.setState({ order: updatedOrder });
            this.handleHideEditShippingViewModal();
          }}
          onRemove={() => {
            const updatedOrder = {
              ...order,
              shippingDetails: null,
            };
            this.setState({ order: updatedOrder });
            this.handleHideEditShippingViewModal();   
          }}
        /> 
        <EditDiscountViewModal
          isVisible={isEditDiscountViewModalVisible}
          cartDetails={this.state.cartDetailsForModal}
          discountDetails={this.state.discountDetailsForModal}
          onHide={() => this.handleHideEditDiscountViewModal(false)}
          onSave={this.handleSaveDiscountDetails}
          selectedCountry={this.state.selectedCountry}
          customer= {this.state.customer}
          onRemove={() => {
            const updatedOrder = {
              ...order,
              discountDetails: null,
            };
            this.setState({ order: updatedOrder },()=>this.calculateTotalAmount());
            this.handleHideEditDiscountViewModal(true);
          }}
        />

        <SelectProductsViewModal
          isVisible={isSelectProductsViewModalVisible}
          searchTerm={this.state.productSearchTerm}
          onHide={this.handleHideSelectProductsViewModal}
          onSave={(selectedProducts) => {
            console.log("Selected products:", selectedProducts);
          }}
        />
         <ManageAddressViewModal
          countries={this.state.countries}
          onHide={this.handleHideManageAddressViewModal}
          onSave={this.handleSaveAddress}
          isVisible={this.state.isManageAddressViewModalVisible ?? false}
        /> 
         <EditShippingAddressModel
        title = "Shipping Address"
        onHide={this.handleHideShippingAddressViewModal}
        onSave={this.handleEditShippingAddress}
        addresses = {
          (this.state.customer && this.state.customer.newShippingAddress) ?? []
        }
        countries={this.state.countries}
        isVisible={this.state.isShippingAddressViewModalVisible ?? false}/>
          <EditBillingAddressModel
        title = "Billing Address"
        onHide={this.handleHideBillingAddressViewModal}
        onSave={this.handleEditBillingAddress}
        addresses = {
          (this.state.customer && this.state.customer.newBillingAddress) ?? []
        }
        countries={this.state.countries}
        isVisible={this.state.isBillingAddressViewModalVisible ?? false}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
