import React,{useState,useEffect} from 'react'
import Table from 'react-bootstrap/Table';
import { Modal } from "react-bootstrap";
import API from "../../utils/API";

const ListOfProducts = ({selectedProduct={},product={},countryId={},index=0,setSelectedProduct={}})=>{
    const {productDto, variantList} = product || {}
    console.log("ListOfProducts",product)
const {productId, productName,productImage,productPrice,discount,productSpecialPrice,inventory} = productDto;


const onSelect = ({product,variant})=>{
    let data = {
        productId: "",
        variantId:"",
        productName:"",
        productPrice:"",
        discount:"",
        productSpecialPrice:"",
        inventory:"",
        isVariant:"",
        }
    if(product){
        data = {
            productId:product.productId,
            productName:product.productName,
            productPrice:product.productPrice,
            discount:product.productPrice - product.productSpecialPrice,
            productSpecialPrice:product.productSpecialPrice,
            inventory:product.inventory,
            variantId:"null",
            isVariant:false,
        }
    }

    else if(variant){
        data={
            productId:variant.productId,
            variantId:variant.variantId,
            variantName:variant.variantName,
            variantListPrice:variant.variantListPrice,
            variantDiscount:variant.variantDiscount,
            variantFinalPrice:variant.variantFinalPrice,
            isVariant:true,
        }
    }
    setSelectedProduct(data);

}


    return (
        <Table hover size="lg">
        <tbody>
            <tr>
                    <td colSpan={2} style={{width:"5%"}} onClick={()=>onSelect({product:productDto})}>
                        <input type='checkbox' checked={ selectedProduct.productId && selectedProduct.productId == productId && !selectedProduct.isVariant }/>
                    </td>
                    <td colSpan={2} style={{width:"15%",}}>
                        {productName}
                    </td>
                    <td colSpan={2} style={{textAlign:'center',width:"10%"}}>
                        {productPrice}
                    </td>
                    <td colSpan={2} style={{textAlign:'center',width:"10%"}}>
                        {productPrice-productSpecialPrice}
                    </td>
                    <td colSpan={2} style={{textAlign:'center',width:"10%"}}>
                        {productSpecialPrice}
                    </td>
                    <td colSpan={2} style={{textAlign:'center',width:"10%"}}>
                        {inventory}
                    </td>
                    </tr>
                    {
                     variantList?.map((data,index)=>{
                      const  {variantId,variantName,variantListPrice,variantDiscount,variantFinalPrice} = data || {};
                      return(
                        <tr  onClick={()=>onSelect({variant:data})}>
                            <td colSpan={3} style={{width:'8%',textAlign:'right'}}>
                                <input type="checkbox" checked={selectedProduct && selectedProduct.variantId == variantId  }/>
                            </td>
                            <td colSpan={2} style={{width:'8%',textAlign:'left'}}>{"pack of"+ " " + variantName}</td>
                            <td colSpan={1} style={{width:'8%',textAlign:'center'}}>{variantListPrice}</td>
                            <td colSpan={2} style={{width:'8%',textAlign:'center'}}>{variantDiscount}</td>
                            <td colSpan={2} style={{width:'8%',textAlign:'center'}}>{variantFinalPrice}</td>


                        </tr>
                      )
                     })   
                    }
                    

       
        </tbody>
        </Table>
    )
}

const ProductsModal = ({showProductsModal, setShowProductsModal,allProducts=[],setAllProducts,handleProductSelect,selectCountry={}}) => {
    const [searchTerm,setSearchTerm] = useState();
    const [isSearching , setIsSearching] = useState(false);
    const [listOfProducts,setListOfProduct] =useState(allProducts);
    const [selectedProduct,setSelectedProduct] = useState([])

    const getProduct = async()=>{
        try{
            setIsSearching(true);
            const res = await API.get(`/api/v1/deals/search?searchKey=${searchTerm}&countryId=${selectCountry.id}`);
            if(res.status == 200){
                const data = res?.data || {}
                setListOfProduct(data);
                setIsSearching(false)
                setListOfProduct(data);
            }
            else{
                setIsSearching(false)
                console.log("Error in fetching the data")
            }
        }
        catch(error){
            setIsSearching(false)
            console.log("Error in fetching the data",error)
        }
      }

    const handleKeyPress =(event)=>{
        if(event.key === 'Enter'){
            getProduct(searchTerm)
        }
    }
  return (
    <Modal show={showProductsModal} onHide={()=>setShowProductsModal(!showProductsModal)} size="lg" centered>
        <Modal.Header>
            <div>All products</div>
        </Modal.Header>
        <Modal.Body>
            {
                <>
                 <div className="row">
                <div className="form-group col">
                <div className="input-group mb-3">
                <input type="text" className="form-control"
                id="search-bar"
                name="search-bar"
                placeholder="Search Products"
                value={searchTerm}
                onChange={(e)=>{setSearchTerm(e.target.value)}}
                onKeyPress={handleKeyPress}
                />
                </div>
              
                </div>
                </div>
                <Table>
            <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
                <th style={{width:"5%"}}> </th>
                <th style={{width:"16%",}}>Product Name</th>
                <th style={{textAlign:"center",width:'10%'}}>List Price</th>
                <th style={{textAlign:"center",width:'10%'}}>Discount</th>
                <th style={{textAlign:"center",width:'10%'}}>Final Price</th>
                <th style={{textAlign:"center",width:'10%'}}>Inventory</th>
            </thead>
                </Table>
                {
                    isSearching ? 
                    <div style={{height:'50vh',overflow:"scroll"}}>
                        <div className="search-spinner" ></div> 
                    </div> :
                    (!isSearching && listOfProducts && listOfProducts.length ==0) ?
                    <div>No results found for "{searchTerm}"</div>:
                    <div style={{height:'50vh',overflow:"scroll"}}>
                            {
                                listOfProducts && listOfProducts.map((data,index)=>{
                                    return <ListOfProducts selectedProduct={selectedProduct} product={data} countryId={6} index={index} setSelectedProduct={setSelectedProduct}/>
                                })
                            }
                    </div>
                }
             </>
            }
        </Modal.Body>
        <Modal.Footer>
            <div  style={{display:'flex',flexDirection:'row',justifyContent:"flex-end",gap:"10px",width:'100%'}}>
            <div>
                    <button
                    type="button"
                    className="btn btn-sm btn-success ml-2"
                    onClick={()=>(handleProductSelect(selectedProduct),setSearchTerm(""),setListOfProduct([]))}
                    style={(Object.keys(selectedProduct).length>0)?{}:{opacity:0.2,backgroundColor:'#247A81'}}
                    >
                        Add
                    </button>
                </div>
                <div>
                    <button style={{borderRadius:"20px"}}
                        type="button"
                        className="btn btn-sm btn-outline-secondary"
                        onClick={()=>setShowProductsModal(!showProductsModal)}>
                        Cancel
                    </button>
                </div>
              
            </div>
        </Modal.Footer>
    </Modal>
   
  )
}

export default ProductsModal