import React, { useState, useEffect } from "react";
import Select from "react-select";
import  {ViewProdRelated}  from "./ViewProdRelated";
import API from "../../src/utils/API";
import { RelatedProdsCreate } from "./RelatedProdsCreate";
import { connect } from "react-redux";
 function ProductList(props) {

    const [prodsrelated, setProdsrelated] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectprod, setSelectprod] = useState();
    const [create, setCreate] = useState(false);
    const [specialOfferProds, setSpecialOfferProds] = useState([]);
    const[allIds,setAllIds]=useState([]);
    const[compFlag,setCompFlag]=useState(false);
    const[relProdFlag,setRelProdFlag]=useState(false);
    
    const prodselection = "products";
    useEffect(() => {

        if (props.mode == "View") {
            const url = `/module/related-product/${props.id}`;
            try {
                API.get(url).then((res) => res.data).then((data) => setProdsrelated(data));
              
            }
            catch (error) {
                console.log("error", error);
            }
        }
    }, [props,compFlag])
    
   

    useEffect(() => {
         
        try {
            API.get("/api/v1/products/").then((res) => res.data).then((data) => data.products).then((products) => setSpecialOfferProds(products.map((product) => { return ({ "value": product.id, "label": product?.description?.name }) })));
        }
        catch (error) {
            console.log("error", error);
        }

    }, [])

   

    const handleprod = (e) => {
        setSelectprod(e.target.value);
        console.log(e.target.value);
    }

    const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);
  
  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);
  
  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
    const onhandleCreateRelate = (e) => {
        setCreate(true);
        if(selectprod.value)
        showSuccessNotification("Product Saved Locally");
        else
        showErrorNotification("Select one product");
        
    }


    useEffect(() => {
        if (selectprod && create) {
            console.log("select prod", selectprod);
           // API.post("module/related-product/1",selectprod).then((res)=>console.log(res));
           if(selectprod.value){
            if(props.Relprod.includes(selectprod.value))
            {
              showErrorNotification("Already product selected");
            }
            else{
            props.AllRelprod([...props.Relprod, selectprod.value]);}
            console.log("all prods", props.Relprod);
            showSuccessNotification("Relatedproduct saved");
            setCreate(false);
            setRelProdFlag(true);
           }
        }
    }, [create])
    return (<>
        {props.mode=="View" && props.addRel && prodsrelated ?[prodsrelated.map((prod) => { return (<ViewProdRelated   prodid={props.id} selectprod={prod} componentflag={compFlag} componentflagFun={setCompFlag}  />) }),<RelatedProdsCreate prodid={props.id} relfun={props.addRelFun} componentflag={compFlag} componentflagFun={setCompFlag}  />]
        :props.mode == "View" && prodsrelated ? prodsrelated.map((prod) => { return (<ViewProdRelated   prodid={props.id} selectprod={prod} componentflag={compFlag} componentflagFun={setCompFlag}  />) }) :
            props.mode == "Create" ? <div className="prodselect">
                <Select

                    name={prodselection}
                    options={specialOfferProds}
                    className="basic-single"
                    classNamePrefix="select"
                    oncloseMenuOnSelect={false}


                    onChange={setSelectprod}


                    style={{ width: "100%" }}
                />
                <br/>
                <div><input className="donebtn" type="button" onClick={onhandleCreateRelate} value="Save" disabled={relProdFlag} /></div>
            </div> : <></>
        }
    </>)
}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductList);