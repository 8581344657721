import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../utils/API';
import './bulkEditProduct.scss';
import Select from "react-select";
import { connect } from "react-redux";
const BASE_URL_COUNTRIES = "/api/v1/country/";


const BulkEditProduct = (props) => {
  const { idList } = useParams();
  const productIds = idList.split(',');
  console.log("productIds",productIds)
  const [products, setProducts] = useState([]);
  const [countries,setCountries] = useState([]);
  const [isEdited, setIsEdited] = useState(false);

  const defaultSelectedCountry = { value: 'bahrain', label: 'Bahrain',id:8};
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const statusOptions = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
  ];

  console.log("selectedCountry",selectedCountry)

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await API.get(`api/v1/bulk/products?productIds=${productIds}`);
        const data = res.data;
        setProducts(data);
        setSelectedCountry(defaultSelectedCountry)
      
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);


  useEffect(()=>{
    const fetchCountries =async ()=>
    {
    try{
      const res = await API.get(BASE_URL_COUNTRIES);
     
      const data = res.data;
      setCountries(data.map((ctry)=>{return({label:ctry.name, value:ctry.name,id:ctry.id})}))
      const selectedCountryData = data.find((country) => country.id === selectedCountry.id);

      if (selectedCountryData) {
        // Set the selected currency based on the selected country
        setSelectedCurrency(selectedCountryData.currency);
      }
      }
      catch{
        console.log("fetching the countries")
      }
    }
    fetchCountries();
  },[selectedCountry])

  const handleFieldEdit = (index, field, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index ? { ...product, [field]: value } : product
      )
      
    );

    setIsEdited(true);

  };

  const handleFieldEditPricing = (productIndex, priceIndex, field, value) => {
    setProducts((prevProducts) => {
      return prevProducts.map((product, i) => {
        if (i === productIndex) {
          return {
            ...product,
            priceDto: product.priceDto.map((price, j) =>
              j === priceIndex ? { ...price, [field]: value } : price
            ),
          };
        }
        return product;
      });
    });

    setIsEdited(true);
  };

  const updateProducts =async()=>{
    try{
    const data =  await API.patch("api/v1/bulk/products/update",products);
    if(data.status == 200){
     

    
     props.showNotificationMessage({
      notificationMessage:"Product details Updated successfully!",
      successMessage: true,
      showNotification: true,
    });
    window.location.href='/admin/dashboard/products'
    }
    setIsEdited(false); 
    }
    catch{
      console.log("error in updating the product details")
    }
    }


  return (
    <div>
       <div>
        <button onClick={updateProducts} disabled={!isEdited}>Save</button>
      </div>
        
     
      <br/>
      <div className='inventory-container'>
        <h2>Inventory</h2>
        <div className="table-container">
      <table  className="styled-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Available</th>
            <th>OnHand</th>
          </tr>
        </thead>
        <tbody className="scrollable-tbody">
          {products.map((product, index) => (
            <tr key={product.id}>
               <td>{product.name}</td>
              <td>
                <input
                  type="number"
                  value={product.availQty}
                  onChange={(e) => handleFieldEdit(index, 'availQty', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={product.onHandQty}
                  onChange={(e) => handleFieldEdit(index, 'onHandQty', e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    
    </div>
      <br/>

    <div className='pricing-container'>
    <div style={{display:"flex", justifyContent:"space-between"}}>
      <h2>Status & Pricing</h2>
      <div className='countries' style={{width:"235px"}}>
        <Select
         options={countries}
         value={selectedCountry}
         onChange={(selectedOption) => {
          console.log("bsbja",selectedOption)
           setSelectedCountry(selectedOption);
         }}
        />
      </div>
    </div>


    <table  className="styled-table">
        <thead>
          <tr>
            <th>Product Title </th>
            <th>Status</th>
            <th><div className='pricing-header'>List Price <div className="currency"> {selectedCurrency}</div></div></th>
            <th><div className='pricing-header'>Discount <div className="currency">{selectedCurrency}</div></div></th>
            <th><div className='pricing-header'>FinalPrice <div className="currency">{selectedCurrency}</div></div></th>



          </tr>
        </thead>


       <tbody>
            {products.map((product, index) => (
              <React.Fragment key={product.productId}>
                {product.priceDto
                  .filter((price) => price.countryId === selectedCountry.id)
                  .map((price, priceIndex) => (
                    <tr key={priceIndex}>
                      <td>{product.name}</td>
                      <td>
                  <Select
                    options={statusOptions}
                    value={statusOptions.find((option) => option.value === price.status)}
                    onChange={(selectedOption) =>
                      handleFieldEditPricing(index, priceIndex, 'status', selectedOption.value)
                    }
                  />
                </td>
                      <td>
                        <input
                        className='price'
                          type="number"
                          value={price.price}
                          onChange={(e) =>
                            handleFieldEditPricing(index, priceIndex, 'price', parseFloat(e.target.value))
                          }
                        />
                      </td>
                       <td ><div className='price'>{price.price - price.specialPrice || 0} </div> </td>
                      <td>
                        <input
                        className='price'
                          type="number"
                          value={price.specialPrice}
                          onChange={(e) =>
                            handleFieldEditPricing(
                              index,
                              priceIndex,
                              'specialPrice',
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
      </table>
    </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkEditProduct);
