import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

function EditContactInfoViewModal(props) {
  const {
    title = "",
    contactInfo: customerContactInfo = {},
    onHide = (f) => f,
    onSave = (f) => f,
    isVisible = false,
  } = props;

  const [contactInfo, setContactInfo] = useState(customerContactInfo);

  useEffect(
    () => setContactInfo(customerContactInfo),
    [customerContactInfo, isVisible]
  );

  function handleChange({ currentTarget: input }) {
    setContactInfo((contactInfo) => ({
      ...contactInfo,
      [input.name]: input.value,
    }));
  }

  function handleCheckboxChange(name, isChecked) {
    setContactInfo((contactInfo) => ({
      ...contactInfo,
      [name]: isChecked,
    }));
  }

  const formCustomerOverview = (
    <>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="emailAddress">Email</label>
          <input
            type="emailAddress"
            value={contactInfo.emailAddress ?? ""}
            onChange={handleChange}
            id="emailAddress"
            name="emailAddress"
            className="form-control"
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col">
          <Form.Check
            type="checkbox"
            checked={contactInfo.isUpdateCustomerProfile}
            onChange={(event) =>
              handleCheckboxChange(
                "isUpdateCustomerProfile",
                event.target.checked
              )
            }
            id="isUpdateCustomerProfile"
            label="Update customer profile"
          />
        </div>
      </div>
    </>
  );

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{formCustomerOverview}</Modal.Body>
      <Modal.Footer>
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-md btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}}
          onClick={(event) => onSave(contactInfo)}
          type="button"
          className="btn btn-md btn-success ml-2"
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditContactInfoViewModal;
