import React from 'react'
import { Modal } from "react-bootstrap";

const BulkCancelOrderPopup = (props) => {
    const {
        selectedRows=[],
        title = "",
        notes = "",
        onHide = (f) => f,
        onSave = (f) => f,
        isVisible = false,
      } = props;
  return (
    <Modal show={isVisible} onHide={onHide} size="sm" centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <>
       {selectedRows.length>1 ?
       <div>Are you Sure to confirm Mark as Cancel {selectedRows.length} orders</div>
    :<div>Order Id: {selectedRows[0]} to confirm Mark as Cancel order </div>
    }
      </>
    </Modal.Body>
    <Modal.Footer>
      <button style={{borderRadius:"20px",background:"#247A81"}}
        onClick={onHide}
        type="button"
        className="btn btn-md btn-success ml-2"
      >
        Cancel
      </button>
      <button style={{borderRadius:"20px"}}
        onClick={(event) => onSave()}
        type="button"
        className="btn btn-md btn-outline-secondary "
      >
        Okay
      </button>
    </Modal.Footer>
  </Modal>
  )
}

export default BulkCancelOrderPopup
