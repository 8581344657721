import React, { Component } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import _ from "lodash";
import SelectProduct from "./SelectProduct";
import API from "../../utils/API";
import Select from "react-select";

const base_url_bestSellingCollection = 'cms/collectionBestSeller'
class BestSellingProductsTable extends Component {
  constructor(props) {
    super(props);

    const { bestSellingProducts = [], onProductsUpdated = (f) => f ,handleProductOption=(f)=>f,collectionOptions = [] ,} = props;
    this.onProductsUpdated = onProductsUpdated;
    console.log("bestSellingProducts",bestSellingProducts)
    this.handleProductOption = handleProductOption;
    this.state = {
      bestSellingProducts,
      bestSellingProduct: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
      collectionOptions ,
      selectedBestSellingCollectionOption : {}
    };

    // this.columns = [
      
    //   {
    //     name: "",
    //     cell: () => <DragIcon className="drag-handle"></DragIcon>,
    //     width: "50px",
    //   },
    //   {
    //     name: "ID",
    //     selector: "id",
    //     sortable: true,
    //     maxWidth: "100px",
    //   },
    //   {
    //     name: "Product ID",
    //     selector:  "productId",
    //     sortable: true,
    //   },
    //   {
    //     name: "Product",
    //     selector: "productName",
    //     sortable: true,
    //     // wrap: true,
    //     cell:(product)=>this.renderProductName(product)
    //   },
    //   {
    //     name: "Rank",
    //     selector: "rank",
    //     maxWidth: "100px",
    //     center: true,
    //     sortable: "true",
    //   },
    //   {
    //     name: "Actions",
    //     maxWidth: "100px",
    //     center: true,
    //     allowOverflow: true,
    //     cell: (product) => this.renderActionBar(product),
    //   },
    // ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.bestSellingProducts !== prevProps.bestSellingProducts) {
      this.setState({ bestSellingProducts: this.props.bestSellingProducts });
      console.log("bestSellingProducts", this.props.bestSellingProducts);
    }
  
    if (this.props.collectionOptions !== prevProps.collectionOptions) {
      this.setState({
        collectionOptions: this.props.collectionOptions.map((data) => ({
          label: data.collectionName,
          value: data.collectionName,
          id: data.collectionId,
        })),
      });
      
    }
  }
  

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      bestSellingProduct: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const bestSellingProduct = { ...this.state.bestSellingProduct };
    bestSellingProduct[input.name] = input.value;
    this.setState({ bestSellingProduct });
  };

  handleAddNew = () => {
    const bestSellingProduct = {};
    this.setState({ bestSellingProduct }, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
    this.clearForm();
    const bestSellingProducts = this.state.bestSellingProducts ?? [];
    const bestSellingProduct = bestSellingProducts.find((it) => it.id === id);
    if (!bestSellingProduct) return;

    this.setState({ bestSellingProduct }, () => this.showAddEditModal());
  };

  showAddEditModal =  () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleDelete = (id) => this.deleteProduct(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { bestSellingProduct = {} } = this.state;
    if (bestSellingProduct.id === null || bestSellingProduct.id === undefined)
      this.createProduct();
    else this.updateProduct(bestSellingProduct.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { productId } = this.state.bestSellingProduct ?? {};
    if (!productId) errors.productId = "Product is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createProduct = () => {
    const { bestSellingProducts = [], bestSellingProduct } = this.state;

    const ids =
      bestSellingProducts.length > 0
        ? bestSellingProducts.map((it) => it.id)
        : null;
    bestSellingProduct.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      bestSellingProducts.length > 0
        ? bestSellingProducts.filter((it) => it.rank).map((it) => it.rank)
        : null;
    bestSellingProduct.rank = ranks ? Math.max(...ranks) + 1 : 1;
    bestSellingProduct.isActive = true;
    bestSellingProduct.is_new = true;

    this.setState(
      { bestSellingProducts: [...bestSellingProducts, bestSellingProduct] },
      () => {
        this.showSuccessNotification("Product Created Successfully!");
        this.onProductsUpdatedLocally();
      }
    );
  };

  updateProduct = (id) => {
    const { bestSellingProducts = [], bestSellingProduct: product } =
      this.state;
    const newBestSellingProducts = bestSellingProducts.map((it) =>
      it.id === id ? product : it
    );
    this.setState({ bestSellingProducts: newBestSellingProducts }, () => {
      this.showSuccessNotification("Product Updated Successfully!");
      this.onProductsUpdatedLocally();
    });
  };

  deleteProduct = (id) => {
    const { bestSellingProducts = [] } = this.state;
    const productToDelete =
      bestSellingProducts.find((it) => it.id === id) || {};
    if (!productToDelete) {
      console.log("deleteProduct:: Product not found with id=", id);
      this.showErrorNotification("Product not found!");
      return;
    }

    const newBestSellingProducts = bestSellingProducts
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > productToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ bestSellingProducts: newBestSellingProducts }, () => {
      this.showSuccessNotification("Product Deleted Successfully!");
      this.onProductsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { bestSellingProducts = [], sortColumn, sortDirection } = this.state;
    const sortedProducts = _.sortBy(
      bestSellingProducts,
      sortColumn,
      sortDirection
    );
    const fromProduct = { ...sortedProducts[fromIndex] };
    const toProduct = { ...sortedProducts[toIndex] };

    if (fromProduct && toProduct) {
      const tempRank = fromProduct.rank;
      // const tempName = fromProduct.productName;
      fromProduct.rank = toProduct.rank;
      // fromProduct.productName = toProduct.productName;
      toProduct.rank = tempRank;
      // toProduct.productName = tempName;

      const newProducts = bestSellingProducts.map((it) => {
        if (it.id === fromProduct.id) return fromProduct;
        else if (it.id === toProduct.id) return toProduct;
        else return it;
      });
      this.setState({ bestSellingProducts: newProducts }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onProductsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onProductsUpdatedLocally = () => {
    this.clearForm();
    this.onProductsUpdated(
      this.state.bestSellingProducts.map((product) =>({ ...product }) )
    );
  };

  findProductById = (id) => {
    const products = this.props.products ?? [];
    return products.find((it) => it.id === Number(id));
  };

  
  renderProductName=(product)=>{
    if(product){
      return product.productName
    }
    else{
    this.state.bestSellingProducts.forEach  ((it) => {
      it.productName
      = this.findProductById(it.productId)?.productName ?? "";
    });
  }
}

  renderActionBar = (bestSellingProduct) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu
        popperConfig={{
          strategy: "fixed",
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
        }}
      >
        <ActionBar.Item onClick={() => this.handleEdit(bestSellingProduct.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          // onClick={() => this.handleDelete(product.id)}
          onClick={() =>
            this.setState({ productToDelete: bestSellingProduct.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    return this.state.bestSellingProduct.id ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-text"
      >
        <EditIcon />
      </div>
    ) : (
      ""
    );
  };

  handleSelectCollection = (selectedOption)=>{
    const {selectedBestSellingCollectionOption} = this.state;
    this.setState({ selectedBestSellingCollectionOption: selectedOption}, () => {this.handleProductOption(selectedOption)});
  } 

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

     
    
 

  render() {
    const {
      bestSellingProducts = [],
      bestSellingProduct = {},
      editables,
      errors,
      sortColumn,
      collectionOptions = [],
      selectedBestSellingCollectionOption={}
    } = this.state;

    const isNewBestSellingProduct = !bestSellingProduct.id;
    this.renderProductName()
    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="productName">Product*</label>
            <div className="input-group">
              <SelectProduct
                id="productName"
                products={this.props.products ?? []}
                value={bestSellingProduct.productId}
                onChange={(value) =>
                  this.setState({
                    bestSellingProduct: {
                      ...bestSellingProduct,
                      productId: value.value,
                    },
                  })
                }
                isDisabled={!isNewBestSellingProduct && !editables.productName}
                className={
                  "form-control" + (errors.productName ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("productName")}
            </div>
            <span className="help-block">{errors.productName}</span>
          </div>
        </div>
      </>
    );
    this.columns = [
      
      {
        name: "",
        cell: () => <DragIcon className="drag-handle"></DragIcon>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Product ID",
        selector:  "productId",
        sortable: true,
      },
      {
        name: "Product",
        selector: "productName",
        sortable: true,
        // wrap: true,
        cell:(product)=>this.renderProductName(product)
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (product) => this.renderActionBar(product),
      },
    ];
      
    return (
      <>

      <div>
        <div>Select Collection</div>
        <Select
        classNamePrefix="select"
          oncloseMenuOnSelect={false}
         value={selectedBestSellingCollectionOption}
        
        onChange = {(selectedOption)=>this.handleSelectCollection(selectedOption)}
         options={collectionOptions}
        />
      </div>
      <div className="container px-0">
        <div className="row">
          <div className="form-group col">
            <Card style={{ background: "#F1FAF8",paddingTop:"20px"}}>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={bestSellingProducts ?? []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  // sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                // style={{cursor:(this.state.bestSellingProducts.length)>=8? "not-allowed":"pointer"}}
                onClick={this.handleAddNew}
                className="greenbackground"
                
              >
                Add Product
              </button>
            </Card>
          </div>
        </div>
        <Modal
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {bestSellingProduct.id === null ||
              bestSellingProduct.id === undefined
                ? "Add Product"
                : "Edit Product"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button style={{borderRadius:"20px"}}
              type="button"
              onClick={this.hideAddEditModal}
              className="btn btn-sm btn-outline-secondary px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.handleSubmit}
              className="greenbackground"
            >
              {bestSellingProduct.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteProduct(this.state.productToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BestSellingProductsTable);
