import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Dropdown } from "react-bootstrap";
import { Button, Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import API from "../../utils/API";
import { formatDateTime } from "../../utils/common";
import OrderNotesCard from "./OrderNotesCard";
import EditOrderNotesViewModal from "./EditOrderNotesViewModal";
import { Link } from "react-router-dom";
import EditContactInfoViewModal from "./EditContactInfoViewModal";
import EditAddressViewModal from "./EditAddressViewModal";
import { titleCase } from "../../utils/common";
import CancelOrderpopup from "./CancelOrderpopup";
import { Troubleshoot } from "@mui/icons-material";
import moment from 'moment';

const BASE_URL_ORDERS = "/api/v1/private/orders";
const BASE_URL_COUNTRIES = "/api/v1/country/";
const BASE_URL_LANGUAGES = "/api/v1/store/languages";

const courier_partner_options = [
  {value:"Aramex",label:"Aramex"},
  {value:"SMSA",label:"SMSA"},
  {value:"DHL",label:"DHL"},
  {value:"Fenix",label:"Fenix"},
  {value:"IQ",label:"IQ"},
  {value:"Careem",label:"Careem"},
  {value:"Shipa",label:"Shipa"},
  {value:"Postaplus",label:"Postaplus"},
  {value:"Carson",label:"Carson"}


]

class Order extends Component {
  constructor(props) {
    super(props);
    let orderId = null;
    try {
      orderId = Number(props.match.params.id);
    } catch (error) {
      console.log("Error on extracting id from props!", error);
    }

    this.state = {
      orderId,
      order: {},
      countries: [],
      languages: [],
      isEditOrderNotesViewModalVisible: false,
      isEditContactInfoViewModal: false,
      isCancelOrderpopupVisible:false,
      trackingNumber:"",
      shippingNumber:"",
      courierPartner:"",
      errorMsgTracking:"",
      errorMsgCourierPartner:"",
      isTrackingNumber: false,
      isShippingNumber: false,
      remarks:"",
      updatedNote:"",
      timeLineData: []
    };
   const admin_user_id = localStorage.getItem("adminUserId");
    
  }
  
  
  async componentDidMount() {
    this.fetchOrderDetails();
    this.fetchDetailsTimeline();
  }

  fetchOrderDetails = async () => {
    const { orderId } = this.state;
    if (!orderId) {
      console.log("fetchOrderDetails:: Invalid order id!", { orderId });
      return;
    }

    const url = `${BASE_URL_ORDERS}/${orderId}`;
    try {
      this.showLoading();
      const { data: order } = await API.get(url);
      console.log("orderorder",order)
      this.setState({ order: order ?? {}, trackingNumber:order.trackingNumber , shippingNumber:order.shippingNumber , isTrackingNumber: (order.trackingNumber)?true:false, isShippingNumber:(order.shippingNumber)?true:false ,courierPartner:(order.courierPartner)});
    } catch (error) {
      console.log(
        "fetchOrderDetails:: Error on fetching order details!",
        error
      );
      this.showErrorNotification(
        "Error on fetching order details!" + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchDetailsTimeline = async () => {
    const { orderId } = this.state;
    try{
      this.showLoading();
      const data = await API.get(`api/v1/order-timeline/${orderId}`);
      const timeLineDetails = data?.data;
      this.setState({timeLineData:timeLineDetails})
      console.log("timeline",data)
    }
    catch(error){
      console.log("fetching the timeLine data",error)
    }
    finally {
      this.hideLoading();
    }
  }

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showEditOrderNotesViewModal = () => {
    const { order } = this.state;
    this.setState({
      isEditOrderNotesViewModalVisible: true,
      notesForModal:order?.notes ?? null,
    });
  };

  showCancelOrderpopup = () =>{
    this.setState({
      isCancelOrderpopupVisible:Troubleshoot
    })
  }

  

  handleHideEditOrderNotesViewModal = () =>
  
    this.setState({
      isEditOrderNotesViewModalVisible: false,
      notesForModal:this.state.updatedNote,
    });
    handleHideCancelOrderpopup = async(setError) =>{
      this.setState({
        isCancelOrderpopupVisible:false,
      })
      // setError("");
    }

  showEditContactInfoViewModal = () => {
    this.setState({ isEditContactInfoViewModal: true });
  };

  handleHideEditContactInfoViewModal = () =>
    this.setState({ isEditContactInfoViewModal: false });

  showEditAddressViewModal = (address, addressType) => {
    this.setState({
      isEditAddressViewModalVisible: true,
      addressForModal: { ...address, addressType },
    });
  };

  handleHideEditAddressViewModal = (note) => {
    this.setState({
      isEditAddressViewModalVisible: false,
      addressForModal: null,
    });
  };

  changeShippingAddress = async(address)=>{
    this.handleHideEditAddressViewModal()
    try{
      const res = await API.patch(`module/address/shipping/${address.id}`, address);
      if(res.status == 202){
        window.location.reload();
      }
      
      }
    
    catch(error){
      console.log(error)

    }
  }

  findCountryByCode = (code) => {
    const countries = this.state.countries ?? [];
    return countries === null || !countries.length
      ? null
      : countries.find((country) => country.code === code);
  };

  handleSaveOrderNotes = async(notes) => {
    // TODO Call backend API to save notes.
    
    const payload = {
      "notes": notes
    }
    const admin_user_id = localStorage.getItem("adminUserId") || ""

    const {order} = this.state
    const {  id="" } = order || {};
    const orderId = id;
    await API.patch(`/api/v1/notes/${orderId}?admin_user_id=${admin_user_id}`,payload).then((res)=>
    {this.setState({updatedNote:res.data.notes},()=>this.handleHideEditOrderNotesViewModal(),window.location.reload())
     })
     console.log("",this.state.updatedNote)
    // const note = await response.json();
    // console.log("santo",note.notes)
    // console.log("notees",note)
    // this.handleHideEditAddressViewModal()
  
    console.log("handleSaveOrderNotes:: Handle save order notes!", notes);
  };

  handleCancelOrder = async(reason,setError,selectedProductsId)=>{
    const { orderId } = this.state;
    const admin_user_id = localStorage.getItem("adminUserId") || ""
  //  const payload={
  //     orderId:orderId,
  //     cancelReason:reason
  //   }
    console.log("selectedProductsId",selectedProductsId)
    const payload = selectedProductsId.map((data, index) => ({
      status: "CANCELED",
      cancelReason: reason,
      product: data.id
    }));
    
    if(reason && selectedProductsId.length >0 ){
      setError("")
      this.handleHideCancelOrderpopup();
    try{
      this.showLoading();
      const data = await API.put(`api/v1/orders/status?admin_user_id=${admin_user_id}`,payload)
        this.showLoading();
      if(data.status===200){
       
        this.hideLoading();
        this.showSuccessNotification("order cancel successfully")
   
        window.location.reload()
        
      }

      else{
        this.hideLoading();
        console.log("error in cancel order")
      }

  
    }
    catch{
      this.hideLoading();
    
    }
  }
  else{
    if(selectedProductsId.length == 0){
      setError("Please Select the Product")
    
    }
    else {
      setError("Please Enter the Reason")
    }
     

    

    // window.location.reload();
  }

    
  }

  getAddressText = (address) => {
    if (!address) return [];
    console.log("address++++",address);
    const { country, firstName, lastName } = address;
    const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
    // const countryName = this.findCountryByCode(country)?.name ?? null;
    const addressItems = [
      country,
      fullName,
      // address.countryName ?? null,
      address.address ?? null,
      address.apartment ?? null,
      address.city ?? null,
      address.postalCode ?? null,

      address.stateProvince ?? null,
      address.mobNumber ?? null,
    ];
    return addressItems.filter((it) => it);
  };

  onFullFillItem = async() =>{
    const { order, trackingNumber="", shippingNumber="",courierPartner="" } = this.state;
    
    const admin_user_id = localStorage.getItem("adminUserId") || ""
    const {  products } = order || {};
     const payload = [];
      products.map((data,index)=>{
        payload.push((
          {
            "status":"SHIPPED",
            "cancelReason":"",
            "product":data.id || ""
          }
        ))
      })
      if(trackingNumber && courierPartner)

    await API.put(`/api/v1/orders/status?admin_user_id=${admin_user_id}`,payload).then((data)=>{

          console.log("status");
      window.location.reload();

    })

  }

  handleDownloadInVoice = async () => {
    const { order = {} } = this.state;
    const orderId = order.id ?? null;
     if(!orderId){
      console.log("Invalid OrderId");
      return;
     }
   
      try {
        const response = await API.get(`api/v1/generate-invoice/${orderId}`)
        const data = response && response.data || {};
        console.log("shjba",data)
        const { message = "", status = "",invoice_link="" } = data;
        if(typeof invoice_link==="string" && invoice_link.toLowerCase().endsWith(".pdf")){
          const url = invoice_link;
          const lastIndex = url.lastIndexOf("/");
          if(lastIndex < url.length){
            const filename = url.substring(lastIndex + 1);
            const link = document.createElement('a');
            link.href = url;
            link.target = "_blank";
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
        else{
          this.showErrorNotification(message)
        }
      }
      catch (error) {
        console.log("error:", error.response)
        const data = error.response && error.response.data || {}
        const errorMessage = data.message ?? "";
        this.showErrorNotification("Error in Downloading Invoice: " + errorMessage)
      }
    
    
  }

  onSaveTrackingAndShippingNo = async()=>{
    const { order, trackingNumber="", shippingNumber="",courierPartner="" } = this.state;
    const admin_user_id = localStorage.getItem("adminUserId") || ""
    const {  id="" } = order || {};
    const orderId = id;
    this.onFullFillItem();
    const payload = {
        "trackingNumber":trackingNumber,
        "shippingNumber":shippingNumber,
        "courierPartner":courierPartner
    }
    if( trackingNumber && courierPartner){

      await API.patch(`/api/v1/shipping/status/${orderId}?admin_user_id=${admin_user_id}`,payload).then((data)=>{

        console.log("status");
        window.location.reload()
      })
    } if
    
    (!trackingNumber){
      this.setState({errorMsgTracking:"Please enter the Tracking No."})

    }
    if(!courierPartner){
      this.setState({errorMsgCourierPartner:"Please select the Courier Parnner"})
    }

   

  
  }

  onMarkAsPaid = async()=>{
    const { order } = this.state;
    const {  id="" } = order || {};
    const admin_user_id = localStorage.getItem("adminUserId") || ""
    const orderId = id;
    const payload = {
      "remark": this.state.remarks
    }
    await API.patch(`/api/v1/payment/status/${orderId}?admin_user_id=${admin_user_id}`,payload).then((data)=>{
        window.location.reload();
    })
  }

  renderOrderItemsCard = () => {
    const { order } = this.state;
    if (!order) {
      console.log("renderOrderItemsCard:: Invalid order!", { order });
      return;
    }

    const { id, orderStatus, products,currency} = order;
    const orderItems =
      products && products.length
        ? products.map((product, index) => {
            const view = this.renderOrderItem(product,currency);
            if (index === 0) return view;
            return (
              <>
                <hr />
                {view}
              </>
            );
          })
        : null;

    return (
      <Card key={id} style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col-8">
              {/* <p className="h6">{`${orderStatus ?? ""} (${
                products?.length ?? 0
              })`}</p> */}
            </div>
          </div>
          {orderItems}
        </Card.Body>
        <Card.Footer style={{ background: "#FFFFFF" }}>
             <div className="row" style={{paddingTop:'15px',paddingBottom:'15px'}}>

             <div style={{display:'flex',flexDirection:'column',width:'30%',gap:'10px'}} >
                <label>Tracking Number *</label>
                <input disabled={(this.state.trackingNumber && (orderStatus=="SHIPPED" || orderStatus== "CANCELED")) ||
                (!this.state.trackingNumber && (orderStatus == "SHIPPED"|| orderStatus== "CANCELED"))}  id="img" type="text" name="uploadfile" value={this.state.trackingNumber} onChange={(e)=>{
                      if(e.target.value){
                        this.setState({errorMsgTracking:""})
                      }
                      this.setState({trackingNumber:e.target.value})
                }}   />
                <span style={{color:'red',fontSize:'14px',marginTop:'5px'}}>{this.state.errorMsgTracking}</span>
              </div>

              <div style={{display:"flex",flexDirection:"column",gap:"10px",width:"30%"}}>
                {/* <br/> */}
                <label>Courier Partner *</label>
                <select disabled={(this.state.courierPartner && (orderStatus == "SHIPPED"|| orderStatus== "CANCELED")) || 
              
              (!this.state.courierPartner && (orderStatus == "SHIPPED"|| orderStatus== "CANCELED"))} value={this.state.courierPartner} style={{height:"35px"}} onChange={(e)=> {
                if(e.target.value){
                  this.setState({errorMsgCourierPartner:""})
                }
              this.setState({courierPartner:e.target.value})
              }}>
                  <option>select Option</option>
                 {courier_partner_options.map((item)=>{
                  return  (<option value={item.value}>{item.label}</option>)
                 })} 
                  </select>
                  <span style={{color:'red',fontSize:'14px',marginTop:'5px'}}>{this.state.errorMsgCourierPartner}</span>
              </div>
                  
              <div style={{width:'17%',marginTop:'30px'}}>
              
              
              <button
              disabled={orderStatus=="SHIPPED" || orderStatus=="CANCELED" || orderStatus== "DELIVERED"}
              onClick={(f) =>{
             
                this.onSaveTrackingAndShippingNo()
              }}
              type="button"
              className="btn btn-md btn-success "
            >
              {`Fullfill ${products?.length > 1 ? "items" : "item"}`}
            </button>
              </div>

              <div style={{width:'23%',marginTop:'30px'}}>
                <button
                disabled={orderStatus=="CREATED"}
                onClick={this.handleDownloadInVoice}
                type="button"
                className="btn btn-md btn-success "
                >Download Invoice
                </button>
              </div>
             
            </div>
          
        </Card.Footer>
      </Card>
    );
  };

  renderOrderItem = (orderItem,currency) => {
    const {
      id,
      sku,
      orderedQuantity,
      product,
      productName,
      price,
      subTotal,
      image,
      itemStatus
      
    } = orderItem;

    console.log("renderOrderItem::", { orderItem });
    // const currency  = order.currency
    const priceText = `${currency} ${price?.toFixed(2)} * ${orderedQuantity}`;
     this.getItemStatusCellStyle = (itemStatus) => {
      if (itemStatus === 'CREATED') {
        return {
          color: 'purple', 
          fontWeight:"700"
        };
      }  else if (itemStatus === 'PROCESSING') {
        return {
          color: '#EEB00C', 
          fontWeight:"700"
        }
      }
      else if (itemStatus === 'SHIPPED') {
        return {
          color: '#35C359', 
          fontWeight:"700"
        };
      } else if (itemStatus === 'CANCELED') {
        return {
        color: '#FF4F50', 
        fontWeight:"700"
        };
      } else if (itemStatus === 'DELIVERED') {
        return {
        color: '#0091A6', 
        fontWeight:"700"
        };
      }
      else if(itemStatus === "PARTIALLY_CANCELED"){
        return{
          color: '#EA1EA5', 
          fontWeight:"700"
          
        }
      }
      return {};
    };

    return (
      <div className="row">
        <div className="col-1">
          <div>
            <span class="position-relative">
              <img
                src={image || product?.image?.imageUrl || ""}
                alt=""
                className="img-thumbnail position-relative"
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "50px",
                  maxHeight: "50px",
                  objectFit: "cover",
                }}
              />
              <span
                class="position-absolute top-0 start-100 translate-middle p-2 badge-cart-item-quantity"
                style={{
                  color: "black",
                  backgroundColor: "#DDE0E4",
                  borderRadius: "50%",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "20px",
                  height: "20px",
                }}
              >
                {orderedQuantity ?? ""}
              </span>
            </span>
          </div>
        </div>
        <div className="col-6 ms-2">
          <div className="row">
            <Link to={`/dashboard/product/${product?.id}`}>
              <span>{productName}</span>
            </Link>
            <div style={{display:"flex",gap:"20px"}}>
            <span>{`SKU: ${sku}`}</span>
            <span style={this.getItemStatusCellStyle(itemStatus)}>{itemStatus}</span>
            </div>
          </div>
          
        </div>
       
        <div className="col text-end">{priceText}</div>
        <div className="col text-end">{`${currency} ${subTotal?.toFixed(2)}`}</div>
      </div>
    );
  };

  renderPaymentDetailsCard = () => {
    const { order } = this.state;
    if (!order) {
      console.log("renderPaymentDetailsCard:: Invalid order!", order);
    }



    const { products, totals, paymentType,paymentStatus,vatTax,shippingCharge,currency,totalAmount,discount,finalAmount,subTotal,coupon,paymentMode,codCharge,prepaidDiscountAmount,prepaidDiscountPercentage,customFee} = order;

    const numberOfItems = products ? products.length : 0;

    // const subtotal =
    //   totals && totals.length
    //     ? totals.find((it) => it.module === "subtotal")?.value ?? 0
    //     : 0;
    // const total =
    //   totals && totals.length
    //     ? totals.find((it) => it.module === "total")?.value ?? 0
    //     : 0;
    const subtotal = subTotal === null ? 0 : subTotal
    const Totaldiscount = discount === null ? 0 : discount
    const prepaidDiscount = prepaidDiscountAmount === null ? 0 : prepaidDiscountAmount
    const total = finalAmount === null ? 0 : finalAmount
    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div className="col-8">
              <p className="h6">{titleCase(paymentStatus)}</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3">Subtotal</div>
            <div className="col">{`${numberOfItems} ${
              numberOfItems > 1 ? "items" : "item"
            }`}</div>
            <div className="col-3 text-end">{`${currency} ${subtotal?.toFixed(2)}`}</div>
          </div>

         {Totaldiscount > 0 && <div className="row mt-2">
            <div className="col-3">Discount</div>
            <div className="col">{ coupon ? coupon : "" }</div>
            <div className="col-3 text-end">-{`${currency} ${Totaldiscount?.toFixed(2)}`}</div>
          </div>}
          {(paymentMode=="COD" && codCharge > 0 ) && <div className="row mt-2">
            <div className="col-3">Additional COD Charges</div>
            <div className="col"></div>
            <div className="col-3 text-end">{`${currency} ${codCharge?.toFixed(2)}`}</div>
          </div>}
          {/* <div className="row mt-2">
            <div className="col-3"></div>
            <div className="col"></div>
            <div className="col-3 text-end"></div>
          </div> */}

          <div className="row mt-2">
            <div className="col-3">Shipping Charges </div>
            <div className="col"></div>
            <div className="col-3 text-end"> {shippingCharge ? `${currency} ${shippingCharge.toFixed(2)}` : ''}</div>
          </div>
         {prepaidDiscount >0 && <div className="row mt-2">
            <div className="col-3">{prepaidDiscountPercentage}% Extra Off on paying online applied </div>
            <div className="col"></div>
            <div className="col-3 text-end">-{prepaidDiscount ? `${currency} ${prepaidDiscount.toFixed(2)}` : ''}</div>
          </div>}

          {customFee >0 && <div className="row mt-2">
            <div className="col-3">Custom Fee</div>
            <div className="col"></div>
            <div className="col-3 text-end">{customFee ? `${currency} ${customFee.toFixed(2)}` : ''}</div>
          </div>}

          <div className="row mt-4">
            <div className="col-3">Tax</div>
            <div className="col">{"VAT 5% (Included)"}</div>
            <div className="col-3 text-end">{vatTax? `${currency} ${(vatTax?.toFixed(2))}`:""}</div>
          </div>

          <div className="row mt-2 fw-bold">
            <div className="col-auto">Total</div>
            <div className="col-auto ms-auto">{`${currency} ${total?.toFixed(2)}`}</div>
          </div>

          <hr />

          {/* <div className="row mt-2">
            <div className="col-auto">Paid by customer</div>
            <div className="col-auto ms-auto"></div>
          </div> */}

          {/* <div className="row mt-2">
            <div className="col-auto">No payment due date</div>
            <div className="col-auto ms-auto">Add payment terms</div>
          </div> */}
        </Card.Body>
        {/* <Card.Footer>
          <div className="d-flex justify-content-end my-2">
            <button
              onClick={(f) => f}
              type="button"
              className="btn btn-md btn-outline-secondary"
            >
              Send invoice
            </button>
          { order.paymentType !="PAID" && <button
              onClick={(f) => f}
              type="button"
              className="btn btn-md btn-success ms-2"
            >
              Collect payment
            </button>}
          </div>
        </Card.Footer> */}
      </Card>
    );
  };

  renderPaymentStatusCard = () =>{
    const { order={}} = this.state;
    console.log("orderorder",order)
    const paymentMode = order.paymentMode == "COD"?"Cash on delivery":order.paymentMode;
    // const paymnetStatus = order.paymentType == "UNPAID"?"Unpaid":"Paid";
    const paymnetStatus = order?.paymentStatus
    const orderStatus = order?.orderStatus;
   
    // const totalAmount = order.total && order.total.value  || "";
    const totalAmount  = order.finalAmount=== null ? "" : order.finalAmount || ""
    const currency = order && order.currency || "";
    const paymentGateway = order.paymentGateway || "";
    const transactionReference = order && order.transactionReference || ""
    const paymentOrderId = order && order.paymentOrderId || ""
    const transactionDate = order && order.transactionDate || ""
    return ( 
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row mt-4">
            <div className="col-6">Payment status </div>
            <div className="col-6 text-end font-weight-bold"><strong>{paymnetStatus}</strong></div>
          </div>
          <div className="row mt-4">
            <div className="col-6">Payment collection amount </div>
            {/* <div className="col-6 text-end font-weight-bold"><strong>{(paymnetStatus == "Paid")?currency+" "+totalAmount+".00":currency+" "+"00.0"}</strong></div> */}
            <div className="col-6 text-end font-weight-bold"><strong>{totalAmount? (currency + " " + parseFloat(totalAmount).toFixed(2)) : ""}</strong></div>
          </div>
          <div className="row mt-4">
            <div className="col-6">Payment mode </div>
            <div className="col-6 text-end font-weight-bold"><strong>{paymentMode}</strong></div>
          </div>
          {paymentGateway && <div className="row mt-4">
            <div className="col-6">Payment gateway </div>
            <div className="col-6 text-end font-weight-bold"><strong>{paymentGateway}</strong></div>
          </div>}
          {transactionReference && <div className="row mt-4">
            <div className="col-6">Transaction Reference </div>
            <div className="col-6 text-end font-weight-bold"><strong>{transactionReference}</strong></div>
          </div>}
          {paymentOrderId && <div className="row mt-4">
            <div className="col-6">Payment OrderId </div>
            <div className="col-6 text-end font-weight-bold"><strong>{paymentOrderId}</strong></div>
          </div>}
          {transactionDate && <div className="row mt-4">
            <div className="col-6">Transaction Date </div>
            <div className="col-6 text-end font-weight-bold"><strong>{transactionDate}</strong></div>
          </div>}
       { (paymnetStatus?.toLowerCase() == "unpaid" || paymnetStatus?.toLowerCase() == "pending" ) &&  
       <>
          <div className="row mt-4">
            <div className="col-6">Remarks </div>
          </div>
          <div className="row mt-1" style={{alignItems:'center'}}>
            <div className="col-8">
              <input style={{width:'100%'}}  id="img" type="text" name="" value={this.state.remarks} onChange={(e)=>
              this.setState({remarks:e.target.value})
              } />
              </div>
              <div className="col-4">
            <button
                onClick={(f) => this.onMarkAsPaid()}
                type="button"
                className="btn btn-md btn-success my-2 float-end"
                style={{width:'100%'}}
                disabled={orderStatus?.toLowerCase() == "canceled"}
              >
              Mark as paid
              </button>
          </div>
          </div>
          </>
          }
         
        </Card.Body>
      </Card>
    );
  }

  // formatTime = (timestamp) => {
  //   const date = moment(timestamp).format("MMM DD, YYYY");
  //   const time = moment(timestamp).format("hh:mm A");
  //   return `${date} at ${time}`;
  // };

  formatTime = (timestamp) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const messageDate = moment(timestamp);

    if (messageDate.isSame(today, 'day')) {
      const time = moment(timestamp).format("hh:mm A");
      return `Today at ${time}`;
    } else if (messageDate.isSame(yesterday, 'day')) {
      const time = moment(timestamp).format("hh:mm A");
      return `Yesterday at ${time}`;
    } else {
      const date = moment(timestamp).format("MMM DD, YYYY");
        const time = moment(timestamp).format("hh:mm A");
        return `${date} at ${time}`;
     
    }
  };
 

  renderTimeLineDetails = () =>{
    const { timeLineData } = this.state;
    return(
      <Card>
        <Card.Body>
      <>
      <div><b>TimeLine</b></div>
      <ul>
      {timeLineData.map((data)=>{
         const formattedDate = this.formatTime(data.timestamp);
        // const formattedDate = (data.timestamp);
        return <div style={{width:"100%"}}>
        <li style={{marginTop:"20px"}}><div style={{display:"flex"}} >
        
          <div style={{width:"77%"}}>{data.message}</div>
          <div>{formattedDate}</div>
          </div>
          </li>
          </div>
      })}
      </ul>
      </>
      </Card.Body>
      </Card>
    )
  }

  renderNotesCard = () => {
    const { order } = this.state;
    return (
      <OrderNotesCard
        notes={order?.notes ?? ""}
        onEdit={this.showEditOrderNotesViewModal}
      />
    );
  };

  renderCustomerDetailsCard = () => {
    const { order } = this.state;
    const { billingAddress:billing, shippingAddress:shipping, delivery} = order;
    const {
      id: customerId,
      firstName,
      lastName,
      emailAddress,
    } = order?.customer ?? {};
    const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();

    const contactInformation = (
      <>
        <div className="row mx-2">
          <div className="col-10">
            <span className="text fw-semibold">Contact information</span>
          </div>
          {/* <div className="col-sm text-end">
            <span
              onClick={this.showEditContactInfoViewModal}
              style={{ cursor: "pointer", color: "#2D9BF0" }}
            >
              Edit
            </span>
          </div> */}
        </div>
        <div className="row mt-2 mx-2">
          <span>{emailAddress}</span>
        </div>
      </>
    );

    const shippingAddress = (
      <>
        <div className="row mx-2">
          <div className="col-10">
            <span className="text fw-semibold">Shipping address</span>
          </div>
          <div className="col-sm text-end">
            {shipping && (
              <span
                onClick={() =>
                  this.showEditAddressViewModal(shipping, "shipping")
                }
                style={{ cursor: "pointer", color: "#2D9BF0" }}
              >
                Edit
              </span>
            )}
          </div>
        </div>
        <div className="row mt-2 mx-2">
        <div className="col">
            {shipping == null ? 
             this.getAddressText(delivery)?.map((it) => (
              <>
                <span>{it}</span>
                <br />
              </>
            )):
            this.getAddressText(shipping)?.map((it) => (
              <>
                <span>{it}</span>
                <br />
              </>
            ))}
          </div>
        </div>
      </>
    );

    const billingAddress = (
      <>
        <div className="row mx-2">
          <div className="col-10">
            <span className="text fw-semibold">Billing address</span>
          </div>
          {/* <div className="col-sm text-end">
            {billing && (
              <span
                onClick={() =>
                  this.showEditAddressViewModal(billing, "billing")
                }
                style={{ cursor: "pointer", color: "#2D9BF0" }}
              >
                Edit
              </span>
            )}
          </div> */}
        </div>
        <div className="row mt-2 mx-2">
          <div className="col">
            {this.getAddressText(billing)?.map((it) => (
              <>
                <span>{it}</span>
                <br />
              </>
            ))}
          </div>
        </div>
      </>
    );

    return (
      <Card style={{ background: "#FFFFFF" }}>
        <Card.Body className="mx-0 px-0">
          <div className="row mx-2">
            <div className="col">
              <p className="h6 fw-semibold">Customer</p>
            </div>
          </div>
          <div className="row mx-2">
            <Link to={`/dashboard/customers/${customerId}`}>{fullName}</Link>
          </div>
          <Divider className="mt-4 mb-3" sx={{ borderWidth: 2 }} />
          {contactInformation}
          <Divider className="mt-4 mb-3 mx-0 px-0" sx={{ borderWidth: 2 }} />
          {shippingAddress}
          <Divider className="mt-4 mb-3 mx-0 px-0" sx={{ borderWidth: 2 }} />
          {billingAddress}
          {/* {contactInfo}
          {defaultAddressInfo}
          {marketingInfo} */}
        </Card.Body>
      </Card>
    );
  };
  getOrderStatusCellStyle = (order) => {
    if (order.orderStatus === 'CREATED') {
      return {
        // padding:"10px",
        color: 'purple', 
        fontWeight:"700"
      };
    } 
    else if (order.orderStatus === 'PROCESSING') {
      return {
        // padding:"10px",
        color: '#EEB00C', 
        fontWeight:"700"
      };
    } 
    else if (order.orderStatus === 'SHIPPED') {
      return {
        // padding:"10px",
        color: '#35C359', 
        fontWeight:"700"
      };
    } else if (order.orderStatus === 'CANCELED') {
      return {
        // padding:"10px",
        color: '#FF4F50', 
        fontWeight:"700"
      };
    } else if (order.orderStatus === 'DELIVERED') {
      return {
        // padding:"10px",
        color: '#0091A6', 
        fontWeight:"700"
      };
    }
    else if(order.orderStatus === "PARTIALLY_CANCELED"){
      return{
        // padding:"10px",
        color: '#EA1EA5', 
        fontWeight:"700"
      }
    }
    return {};
  };

  render() {
    const {
      order = {},
      addressForModal,
      isEditOrderNotesViewModalVisible,
      isCancelOrderpopupVisible,
      isEditContactInfoViewModal,
      isEditAddressViewModalVisible,
    } = this.state;

    const orderItems = order.products ?? [];
    
    const orderItemsCard = this.renderOrderItemsCard();
    const paymentDetailsCard = this.renderPaymentDetailsCard();
    const paymentStatusCard = this.renderPaymentStatusCard();
    const notesCard = this.renderNotesCard();
    const customerDetailsCard = this.renderCustomerDetailsCard();
    const renderTimeLineDetails = this.renderTimeLineDetails();

    
    return (
      <div className="container">
        <div class="d-flex justify-content-between">
          <div class="d-flex justify-content-start-0">
          <div style={{ alignSelf: "flex-start", marginRight: "1rem" }}>
            <Button
            
              onClick={() => this.props.history.goBack()}
              
              color="inherit"
              size="large"
            >
              <ArrowBackIcon />
            </Button>
          </div>
          <div className="d-flex flex-column  mt-1">
            <div className="d-flex justify-content-start-0">
              <h4>{order.id ? `#${order.id}` : ""}</h4>
              <span className="mx-2 my-1">{order.paymentStatus ?? ""}</span>
              <span className="my-1" style={this.getOrderStatusCellStyle(order)}>{order.orderStatus ?? ""}</span>
            </div>
            {order.orderNumber && <h5>Order NO.- {order.orderNumber}</h5>}
            <span className="text-muted">{`${formatDateTime(
              order.datePurchased
            )} from Online Store`}</span>
          </div>
          </div>
          <div className="d-flex justify-content-between mt-4 col-4">
              <div className="d-flex flex-column" >
            <button  onClick={this.showCancelOrderpopup}
              // variant="outlined" 
              disabled={order.orderStatus === "CANCELED"}
              style={{
                cursor:((order.orderStatus === "CANCELED")) ? "not-allowed" : "pointer",
                border: '2px solid rgba(0,0,0,0.15)', 
                borderRadius: '4px', 
                marginLeft: '15px',
                marginTop:"11px",
                background:'white',
                color:'#000000cf'
              }}
              // color="inherit"
              // size="small"
              ><b>Cancel</b></button>
              </div>
          </div>
        </div>

        <div class="d-flex justify-content-between mt-4">
          <div className="col-8" style={{ marginRight: "16px" }}>
            {orderItemsCard}
            {paymentDetailsCard}
            {paymentStatusCard}
            {renderTimeLineDetails}
          </div>
          <div className="col-4">
            <div className="d-flex flex-column">
              {notesCard}
              {customerDetailsCard}
            </div>
          </div>
        </div>

        <EditOrderNotesViewModal
          notes={this.state.notesForModal ?? ""}
          title="Add note"
          onHide={this.handleHideEditOrderNotesViewModal}
          onSave={this.handleSaveOrderNotes}
          isVisible={isEditOrderNotesViewModalVisible ?? false}
        />
        <CancelOrderpopup
        order= {this.state.order}
        title="Cancel Order"
        reason=""
        onHide={(setError)=>this.handleHideCancelOrderpopup(setError)}
        onCancel = {(reason,setError,selectedProductsId)=>this.handleCancelOrder(reason,setError,selectedProductsId)}
        isVisible = {isCancelOrderpopupVisible ?? false}
        />

        <EditContactInfoViewModal
          title="Edit contact information"
          contactInfo={{
            emailAddress: order?.customer?.emailAddress,
            isUpdateCustomerProfile: true,
          }}
          onHide={this.handleHideEditContactInfoViewModal}
          onSave={this.handleSaveContactInfo}
          isVisible={isEditContactInfoViewModal ?? false}
        />

        <EditAddressViewModal
          title=""
          address={addressForModal}
          onSave={(addressForModal) => this.changeShippingAddress(addressForModal)}
          onHide={this.handleHideEditAddressViewModal}
          isVisible={isEditAddressViewModalVisible ?? false}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
