import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function EditOrderNotesViewModal(props) {
  const {
    title = "",
    notes = "",
    onHide = (f) => f,
    onSave = (f) => f,
    isVisible = false,
  } = props;
  const [customerNotes, setCustomerNotes] = useState(notes);

  useEffect(() => setCustomerNotes(notes), [notes, isVisible]);

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="notes">Notes</label>
              <input
                type="text"
                value={customerNotes ?? ""}
                onChange={(event) => setCustomerNotes(event.target.value)}
                id="customerNotes"
                name="customerNotes"
                className="form-control py-2"
              />
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-md btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}}
          onClick={(event) => onSave(customerNotes)}
          type="button"
          className="btn btn-md btn-success ml-2"
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditOrderNotesViewModal;
