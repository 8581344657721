import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import { Link } from "react-router-dom";


const reason_for_cancel = [
  { value: 'Customer changed/canceled order', label: 'Customer changed/canceled order' },
  { value: 'Fraudulent Order', label: 'Fraudulent Order' },
  { value: 'Items Unavailable', label: 'Items Unavailable' },
  { value: 'Payment declined', label: 'Payment declined' },
  { value: 'Staff Error', label: 'Staff Error' },
  { value: 'Others', label: 'Others' },
];

const CancelOrderpopup = (props) => {
  const { title = '', reason = '', onHide = (f) => f, onCancel = (f) => f, isVisible = false, order } = props;
  const [cancelReason, setCancelReason] = useState(reason);
  const [error,setError] = useState("")
  const [selectedProductsId, setSelectedProductsId] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { products } = order || []
  const handleSelectChange = (selectedOption) => {
    setCancelReason(selectedOption.value);
  }; 
  useEffect(() => {
    // Update selectedProductsId when the "Select All" checkbox is toggled
    if (selectAll) {
      setSelectedProductsId(products.map((product) => ({ id: product.id })));
    } else {
      setSelectedProductsId([]);
    }
  }, [selectAll, products]);

  const handleSelectAllChange = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };
  const handleCheckboxChange = (productId) => {
    setSelectedProductsId((prevSelected) => {
      if (prevSelected.some((item) => item.id === productId)) {
        return prevSelected.filter((item) => item.id !== productId);
      } else {
        return [...prevSelected, { id: productId }];
      }
    });
  };

  const handleCancelOrder = () => {
    const activeProductIds = selectedProductsId.filter((item) =>
      products.some((product) => product.id === item.id && product.itemStatus !== "CANCELED")
    );
    onCancel(cancelReason, setError, activeProductIds);
  };
  return (
    <Modal show={isVisible} onHide={(setError)=>onHide(setError)} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
        <div style={{height:"250px",overflowY:"scroll",scrollbarWidth:"none",msOverflowStyle:"none",}}>
          <div >
        <div className="col text-end" style={{
          marginRight:"24px",
          color: "#07141A",
          fontWeight:500,
          fontSize:"14px"}}>
                <input type="checkbox" checked={selectAll}
                  onChange={handleSelectAllChange}/>
                <span style={{marginLeft:"10px"}}>Select All</span>
              </div>
              </div>
              
      {products && products.length > 0 &&
        products.map((product, index) => {
          const isSelected = selectedProductsId.some((item) => item.id === product.id);

          return (
            
            product.itemStatus==="CANCELED" ? ""  :
            
            (
              <div>
            
            <div className="row" style={{ marginBottom: "10px" }} key={index}>
              
              <div className="col-1">
                <div>
                  <span className="position-relative">
                    <img
                      src={product.image || product.product?.image?.imageUrl || ""}
                      alt=""
                      className="img-thumbnail position-relative"
                      style={{
                        minWidth: "100%",
                        maxWidth: "100%",
                        minHeight: "50px",
                        maxHeight: "50px",
                        objectFit: "cover",
                      }}
                    />
                    <span
                      className="position-absolute top-0 start-100 translate-middle p-2 badge-cart-item-quantity"
                      style={{
                        color: "black",
                        backgroundColor: "#DDE0E4",
                        borderRadius: "50%",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      {product.orderedQuantity ?? ""}
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-6 ms-2">
                <div className="row">
                  <Link to={`/dashboard/product/${product.product?.id}`}>
                    <span>{product.productName}</span>
                  </Link>
                  <span>{`SKU: ${product.sku}`}</span>
                </div>
              </div>
              <div className="col text-end">
                <input
                  className="col-8"
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => handleCheckboxChange(product.id)}
                />
              </div>
            </div>
            </div>
          )
          );
        })}
    </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="notes">Reason for cancellation *</label>
              <div className="custom-dropdown">
                <Select
                 value={cancelReason.value}
                 onChange={handleSelectChange}
                 options={reason_for_cancel}/>
           
              </div>
            </div>
          </div>
         {error && <span style={{color:"red"}}>{error}</span> } 
        </>
      </Modal.Body>
      <Modal.Footer>
        <button
          style={{ borderRadius: '20px' }}
          onClick={(setError)=>onHide(setError)}
          type="button"
          className="btn btn-md btn-outline-secondary"
        >
          Keep Order
        </button>
        <button
          style={{ background: '#FF0000', borderRadius: '20px' }}
          onClick={handleCancelOrder}
          type="button"
          className="btn btn-md btn-success ml-2"
        >
          Cancel Order
        </button>
        
      </Modal.Footer>
      
    </Modal>
  );
};

export default CancelOrderpopup;
