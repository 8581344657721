import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

 function VariantPriceCreate(props) {


    const [variantname, setVariantname] = useState();
    const [variantList, setVariantList] = useState();
    const [variantDiscount, setVariantDiscount] = useState();
    const [variantFinal, setVariantFinal] = useState();
    const [variantprice, setVariantprice] = useState({
        "countryId": "", "name": "", "retailPrice": 0, "discount": 0, "finalPrice": 0
    });
    const[validationError,setValidationError]=useState({});
    const[variantPriceFlag,setVariantPriceFlag]=useState(false);
    const { CountryId, Allvariantprice, FunAllvariantprice,SelectedVariantname } = props;

    const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
      return emptyval;
      }

    const validateAll=(name,Discount,Final,List)=>{
        const validationError={}
         if(isEmpty(name))
         validationError.name="variant name is required field";
         if(isEmpty(Discount))
         validationError.discount="Discount is required field";
         if(isEmpty(Final))
         validationError.final="Final is required field";
         if(isEmpty(List))
            validationError.list ="List is required field";        
       return validationError;
    }

    useEffect(()=>{
        console.log("variant name",SelectedVariantname);
     setVariantname(SelectedVariantname);
    },[SelectedVariantname])
    const handleVariantname = (e) => {
        setVariantname(e.target.value);
        
    }
    const handleVariantDisc = (e) => {
        setVariantDiscount(e.target.value);
        if(isEmpty(e.target.value)){
            setValidationError({...validationError,discount:"discount is required field"});

        }
        else{
            setValidationError({...validationError,discount:""});  
        }
    }
    const handleVariantFinal = (e) => {
        setVariantFinal(e.target.value);
        if(isEmpty(e.target.value)){
            setValidationError({...validationError,final:"final is required field"});

        }
        else{
            setValidationError({...validationError,final:""});  
        }
    }

    const handleVariantList = (e) => {
        setVariantList(e.target.value);
        if(isEmpty(e.target.value)){
            setValidationError({...validationError,list:"list is required field"});

        }
        else{
            setValidationError({...validationError,list:""});  
        }
    }

    const handleSave = (e) => {
        console.log("in VariantPriceCreate");
       const error= validateAll(variantname,variantDiscount,variantFinal,variantList);
       setValidationError(error);
       if(Object.keys(error).length==0){
        FunAllvariantprice([...Allvariantprice, {
            ...variantprice,
            "countryId": CountryId,
            "name": variantname,
            "retailPrice": variantList,
            "discount": variantDiscount,
            "finalPrice": variantFinal

        }])
        showSuccessNotification("variant price saved");
        setVariantPriceFlag(true);
    }
    else{
        showErrorNotification("Some fields are Invalid");
    }
    }
    useEffect(()=>{
        console.log("variant price",Allvariantprice);
    },[Allvariantprice])

    const showSuccessNotification = (notificationMessage) =>
showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});

    return (
        <div className="alignrow variantpriceblock">

<div className="columnalignactive"><div className="group"><label for="name">Variant Name*</label>   <input type="number" placeholder="variant name" value={variantname} onChange={handleVariantname} /></div>
<span className="help-block">{validationError.name}</span>
</div>
<div className="columnalignactive">         <div className="group"> <label for="name">List Price*</label> <input type="number" style={{ width: "7em" }} value={variantList} onChange={handleVariantList} placeholder="list price" /></div>
<span className="help-block">{validationError.list}</span>
</div>
<div className="columnalignactive">            <div className="group"><label for="name">Discount*</label>
                <input type="number" style={{ width: "7em" }} value={variantDiscount} onChange={handleVariantDisc} placeholder="discount price" />
            </div>
            </div>
            <div className="columnalignactive">  <div className="group"><label for="name">Final*</label>
                <input type="number" style={{ width: "7em" }} value={variantFinal} onChange={handleVariantFinal} placeholder="final price" />
            </div>
            <span className="help-block">{validationError.final}</span>
            </div>
            <div><input className="donebtn" type="button" value="Save" onClick={handleSave} disabled={variantPriceFlag}/></div>
        </div>
    )
}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(VariantPriceCreate);