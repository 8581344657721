import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import { connect } from "react-redux";


function CreateReview(props) {

    const [reviewCreate, setReviewCreate] = useState({});
    const [custName, setCustName] = useState();
    const [headline, setHeadline] = useState();
    const [reviewbody, setReviewbody] = useState();
    const [doneStatus, setDoneStatus] = useState(false);
    const[reviewStatus,setReviewStatus]=useState(true);
    const[validationError,setValidationError]=useState({});
    const[rating,setRating]=useState();
    const[reviewFlag,setReviewFlag]=useState(false);
    const ratingValues=[1,2,3,4,5];
    console.log("In review create");

    const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
      return emptyval;
      }

    const validateAll=(headline,customer,review,status,rating)=>{
        const validationError={};

        if(isEmpty(headline)){
            validationError.headline="HeadLine is required field";

        }
        if(isEmpty(rating))
        {
            validationError.rating="rating is required field";
        }
        if(isEmpty(customer)){
            validationError.name="CustomerName is required field";

        }
        if(isEmpty(review)){
            validationError.body="review is required field";

        }
       
        if(!status){
            validationError.status="status Active"
        }
      return validationError;
     
    }
    const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });


    const onhandleCreate = (e) => {
         const validateAllFields=validateAll(headline,custName,reviewbody,reviewStatus,rating) ;
         setValidationError(validateAllFields);
         if(Object.keys(validateAllFields).length===0){
        if (props.mode == "EditCreate") {
            setReviewCreate(
                {
                    "customerName": custName,
                    "headLine": headline,
                    "reviewBody": reviewbody,
                    "rating":rating,
                    "isActive": reviewStatus,
                    "modifiedAt": "1997-09-09T00:00:00",
                    "productId": props.prodid
                })
                
        }

        if (props.mode == "Create") {
            setReviewCreate(
                {
                    "customerName": custName,
                    "headLine": headline,
                    "reviewBody": reviewbody,
                    "rating":rating,
                    "isActive": reviewStatus,
                    "modifiedAt": "1997-09-09T00:00:00",
                    "productId": ""
                })
            console.log("In create mode")
        }
        setDoneStatus(true);
        setReviewFlag(true);
    }
    else{
        showErrorNotification("Some fields are invalid ");
    }
    }
    useEffect(() => {
        try {
            if (reviewCreate && doneStatus) {
                if (props.mode == "Create") {
                    console.log("create Review", reviewCreate);
                    props.allReviews([...props.allReview, reviewCreate]);
                    showSuccessNotification("locally saved review");
                }
                if (props.mode == "EditCreate"){
                    API.post("/module/product/review", reviewCreate).then((res) => console.log(res));
                    showSuccessNotification("created review");
                }
                props.addfun(false);
            }

           
        }
        catch (error) {
            console.log("error is", error);
            showErrorNotification("Error create review");
        }
        setDoneStatus(false);
    }, [reviewCreate])
    const handlecustomer = (e) => {
        setCustName(e.target.value);
        if(isEmpty(e.target.value))
        setValidationError({...validationError,name:"Name is required"});
        else{
            setValidationError({...validationError,name:""}); 
        }

    }
    const handleheadline = (e) => {
        setHeadline(e.target.value);
        if(isEmpty(e.target.value))
        setValidationError({...validationError,headline:"headline is required"});
        else{
            setValidationError({...validationError,headline:""}); 
        }
     
    }
    const handlereview = (e) => {
        setReviewbody(e.target.value);
        if(isEmpty(e.target.value))

           setValidationError({...validationError,body:"body is required field"});
           else{
             setValidationError({...validationError,body:""});
           }
        
    }
    const handleReviewStatus=(e)=>{
        if(e.target.checked){
        setReviewStatus(true);
        }
        else{
         setReviewStatus(false);
        }
        
    }
    const handleRating=(e)=>{
        setRating(e.target.value);
        if(isEmpty(e.target.value)){
            setValidationError({...validationError,rating:"rating is required "});
        }
        else{
            setValidationError({...validationError,rating:""});
        }
        
    }

    return (
        <div>
            <div className="alignrow">
              <div className="columnalignactive"> 
              <div className="group"><label for="name">Customer Name*</label> 
              <input type="text"  placeholder="Customer Name" onChange={handlecustomer} /></div>
              <span className="help-block">{validationError.name}</span>
              </div>
              <div className="columnalignactive">  
              <div className="group"><label for="name">Headline*</label> 
              <input type="text" style={{width:"100%"}}
               placeholder="Headline" onChange={handleheadline} /></div>
              <span className="help-block">{validationError.headline}</span>
               </div>
                <div className="columnalignactive"><select onChange={handleRating} value={rating}><option default>Select Rating</option>
                {ratingValues.map((val)=><option value={val}>{val}</option>)}
                </select>
                <span className="help-block">{validationError.rating}</span>
                </div>
                
               <div className="columnalignactive">    <div className="columnalignactive"> Active <input type="checkbox" value={reviewStatus} onChange={handleReviewStatus} checked={reviewStatus} /></div>
               <span className="help-block">{validationError.status}</span>
               </div>
               
            </div>
            <br />
            <div className="columnalignactive">
            <div className="group"><label for="name">Review*</label> 
                <textarea style={{ width: "85%" }} rows="10" placeholder="Review body" onChange={handlereview}></textarea></div>
                <span className="help-block">{validationError.body}</span>
            </div> <br/>
            <input className="donebtn" type="button" value="Save" onClick={onhandleCreate} disabled={reviewFlag} />
        </div>

    )

}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateReview);