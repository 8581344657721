import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";

function EditShippingViewModal(props) {
  const {
    isVisible = false,
    currency = "AED",
    shippingDetails,
    onHide = (f) => f,
    onSave = (f) => f,
    onRemove = (f) => f,
  } = props;

  const [isFreeShipping, setFreeShipping] = useState(
    shippingDetails?.isFreeShipping ?? false
  );
  const [isCustomShipping, setCustomShipping] = useState(
    shippingDetails?.isCustomShipping ?? false
  );
  const [rateName, setRateName] = useState(shippingDetails?.rateName ?? "");
  const [price, setPrice] = useState(shippingDetails?.price ?? 0.0);

  useEffect(() => {
    setFreeShipping(shippingDetails?.isFreeShipping ?? false);
    setCustomShipping(shippingDetails?.isCustomShipping ?? false);
    setRateName(shippingDetails?.rateName ?? "");
    setPrice(shippingDetails?.price ?? 0.0);
  }, [isVisible, shippingDetails]);

  const title = "Add shipping";
  const form = (
    <>
      <div className="row">
        <div className="form-group col">
          <Form.Check
            type="checkbox"
            checked={isFreeShipping}
            onChange={(event) => {
              setCustomShipping(false);
              setPrice(0);
              setFreeShipping(event.target.checked);
            }}
            id="isFreeShipping"
            name="isFreeShipping"
            label="Free shipping"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="form-group col">
          <Form.Check
            type="checkbox"
            checked={isCustomShipping}
            onChange={(event) => {
              setFreeShipping(false);
              setCustomShipping(event.target.checked);
              setPrice(0);
            }}
            id="isCustomShipping"
            name="isCustomShipping"
            label="Custom"
          />
        </div>
      </div>
      {isCustomShipping && (
        <div className="row ms-2">
          <div className="form-group col-6">
            <label htmlFor="rateName">Rate name</label>
            <div className="input-group">
              <input
                type="text"
                value={rateName}
                onChange={(e) => setRateName(e.currentTarget.value)}
                id="rateName"
                name="rateName"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group col-6">
            <label htmlFor="price">Price</label>
            <div className="input-group">
              <span class="input-group-text">{currency}</span>
              <input
                value={price}
                onChange={(e) => setPrice(e.currentTarget.valueAsNumber)}
                type="number"
                name="price"
                id="price"
                className="form-control"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{form}</Modal.Body>
      <Modal.Footer>
        {shippingDetails && (
          <button
            onClick={onRemove}
            type="button"
            className="btn btn-sm btn-danger me-auto"
          >
            Remove shipping
          </button>
        )}
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}} 
          onClick={(event) =>
            onSave({ isFreeShipping, isCustomShipping, rateName, price })
          }
          type="button"
          className="btn btn-sm btn-success ml-2"
        >
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditShippingViewModal;
