import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import { VariantCountry } from "./VariantCountry";
import  VariantCountryView  from "./VariantCountryView";
import VariantCountryEdit from "./VariantCountryEdit";
import _ from "lodash";
export function VariantCountryLogic(props) {
  const { mode, prodid, add, addFun, priceEdit, priceEditFun } = props;
  //console.log("mode is",mode);
  const [countries, setCountries] = useState([]);
  const [price, setPrice] = useState([]);
  const [productPrice, setProductPrice] = useState();
  const [countryids, setCountryids] = useState([1, 2, 3]);

  const [prodflag, setProdflag] = useState(false);
  const [prods, setProds] = useState([]);
  const [finalPrice, setFinalPrice] = useState([]);
  const [countryIds, setCountryIds] = useState([]);
  const [count, setCount] = useState(0);
  const [checkFinal, setCheckFinal] = useState([]);
  const [variantsPrice, setVariantsPrice] = useState([]);
  const [checkVariants, setCheckVariants] = useState([]);
  const [allFinal, setAllFinal] = useState([]);
  const[allSeo,setAllSeo]=useState([]);
  const [variantFull, setVariantFull] = useState([]);
  const[addVariantPriceEdit,setAddVariantPriceEdit]=useState(0);
  //filter for prodid

  useEffect(() => {
    try {
      API.get("/api/v1/country").then((res) => res.data).then((data) => setCountryIds(data.map((ctry) => ctry.id)));
      console.log("props", props);
    }
    catch (error) {
      console.log("error msg", error);
    }


  }, [])
  useEffect(() => {
    if (countryIds.length >0) {
      
      getNames();
    }

  }, [countryIds])

  const getNames = () => {
    const promises = [];

    countryIds.forEach((countryId) => {
      promises.push(API.get(`api/v1/product/${prodid}?country=${countryId}`));
    })

    // Once all promises are resolved, update the state
    Promise.all(promises).then((responses) => {
      const prices = responses.map((response) => response.data.productPrices);
      const seoUrlDetails=responses.map((response)=>response.data.seoUrl);
      const variants = responses.map((response) => response.data.newVariants.map((variant) => { return ({ "name": variant.variants.name, "varid": variant.variants.id, "pricing": variant.pricings[0] }) }));
      const variantDet = responses.map((response) => response.data.newVariants.map((variant) => { return ({ "variant": variant.variants }) }));
      console.log("prices", prices);
      setFinalPrice(prices);
      setVariantsPrice(variants);
      console.log("response seo",seoUrlDetails);
      setVariantFull(variantDet.flat());
      setAllSeo(seoUrlDetails);
      console.log("variantprice", variants);
    })

  }


  /* const fetchPriceDetails = async(countryId) => {
     
  
    
     const { data: priceDetails }= await API.get(`/api/v1/product/${prodid}?country=${countryId}`);
     console.log("priceDetails",priceDetails)
     if(priceDetails.productPrices !== null ) {
      
       {
       setFinalPrice((prices) => [...prices,priceDetails.productPrices]);
       setVariantsPrice((variant)=>[...variant,priceDetails.newVariants]);
       setCount(count+1);
       console.log("count is",count);
       }   
       
     }
       
   }*/
  useEffect(() => {

    //setCheckFinal(finalPrice.filter((price,index)=>finalPrice.indexOf(price)==index));
    setCheckFinal(finalPrice.filter((price) => price !== null));

    console.log("checkfinal", checkFinal);

  }, [finalPrice])

  useEffect(() => {
    setCheckVariants(variantsPrice.map(variant => variant.filter((variant) => variant.pricing)));
    // variantPriceEditFun(variantsPrice.map(variant=> variant.filter((variant)=>variant.pricing)));
    console.log("real variant prices", variantsPrice);
  }, [variantsPrice,addVariantPriceEdit])

  useEffect(() => {
    console.log("variantDetails all", variantFull)
  }, [variantFull])







  return (<>
    {mode == "View" & add ? [checkFinal.map((price) =><> <VariantCountryView mode="View" prodid={prodid} details={price} variantPriceDetails={checkVariants} priceprod={priceEdit} priceprodFun={priceEditFun}  allVariantdet={variantFull} VariantViewAddFun={setAddVariantPriceEdit} variantViewAdd={addVariantPriceEdit} seoDetails={allSeo} /><br/></>), <VariantCountryEdit mode={"CountryEditCreate"} productId={prodid}  variantPriceDetails={checkVariants} />]
      : mode == "View" ? checkFinal.map((price) => <><VariantCountryView mode="View" prodid={prodid} details={price} variantPriceDetails={checkVariants} priceprod={priceEdit} priceprodFun={priceEditFun}  allVariantdet={variantFull} VariantViewAddFun={setAddVariantPriceEdit} variantViewAdd={addVariantPriceEdit} seoDetails={allSeo}
      /><br/></>)
        : <></>
    }
  </>)

}