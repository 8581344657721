import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SelectCountry from "../../components/select/SelectCountry";
import PhoneInput from "react-phone-input-2";

const EditShippingAddressModel = (props) => {
    const {
        addresses = [],
        countries = [],
          title="",
          onHide = (f) => f,
          onSave = (f) => f,
          isVisible = false,
        } = props;
       const [shippingAddress,setShippingAddress] = useState({})
       const [isEditMode, setEditMode] = useState(false);
       const [errors, setErrors] = useState({});
       const [state,setState] = useState()
       
     useEffect(() => {
      setEditMode(false);
      setShippingAddress({});
      setErrors({});
    }, [isVisible]);
  
       const numberCheck = async (value, data, event, formattedValue) => {
          if (value.slice(data.dialCode.length).length > 6) {
            const contact = await (data.dialCode + value.slice(data.dialCode.length));
            setState({ shippingAddress: { ...shippingAddress, mobNumber: contact } });
          } else {
            // setPhone("");
          }
        }
  
      function handleChange({ currentTarget: input }) {
          setShippingAddress((shippingAddress) => ({ ...shippingAddress, [input.name]: input.value }));
          validateField(input.name, input.value)
      }
  
      function handleDropdownChange(name, value) {
          setShippingAddress((shippingAddress) => ({ ...shippingAddress, [name]: value }));
          validateField(name, value)
        }
  
  
  
  
        const validateField = (name, value) => {
          const errorsCopy = { ...errors };
          switch (name) {
            case "countryCode":
              errorsCopy.countryCode = value ? "" : "Country Code is required.";
              break;
            case "firstName":
              errorsCopy.firstName = value ? "" : "First Name is required.";
              break;
            case "lastName":
              errorsCopy.lastName = value ? "" : "Last Name is required.";
              break;
            case "address":
              errorsCopy.address = value ? "" : "Address is required.";
              break;
            case "apartment":
              errorsCopy.apartment = value ? "" : "Apartment is required.";
              break;
              case "city":
                errorsCopy.city = value ? "" : "City is required.";
                break;
                case "postalCode":
                  errorsCopy.postalCode = value ? "" : "Postal Code is required.";
                  break;
            case "stateProvince":
              errorsCopy.stateProvince = value ? "" : "State/Province is required.";
              break;
            default:
              break;
          }
          setErrors(errorsCopy);
        };
        function validateAllFields(event) {
            const {countryCode,firstName,lastName,address,apartment,stateProvince,city,postalCode} = event;
            const errors = {};
        
          //   if (!countryCode) {
          //     errors.countryCode = "Country Code is required.";
          // }
          if (!firstName) {
              errors.firstName = "First Name is required.";
            }
          if (!lastName) {
              errors.lastName = "Last Name is required.";
            }
          if (!address) {
              errors.address = "Address is required.";
            }
            if (!apartment) {
              errors.apartment = "Apartment is required.";
            }
            if (!city) {
              errors.city = "City is required.";
            }
            if (!postalCode) {
              errors.postalCode = "PostalCode is required.";
            }
            if (!stateProvince) {
              errors.stateProvince = "State/Province is required.";
            }
            setErrors(errors);
            return Object.keys(errors).length === 0;
          }
        function handleSave() {
            if (validateAllFields(shippingAddress)) {
            onSave(shippingAddress);
            }
        }
  
        function renderAddressPreview(shippingAddress, index) {
      return (
        <fieldset
          className={`border p-3 ${index > 0 ? "mt-3" : ""}`}
          style={{ borderRadius: "5px" }}
          key={index}
        >
          {shippingAddress.isDefaultAddress && (
            <legend className="float-none w-auto">
              <span className="valeo-badge">Default address</span>
            </legend>
          )}
          <div className="container">
            {getAddressText(shippingAddress)?.map((it, index) => (
              <React.Fragment key={index}>
                <span>{it}</span>
                <br />
              </React.Fragment>
            ))}
            <button
              onClick={() => {
                setShippingAddress(shippingAddress);
                setEditMode(true);
              }}
              className="btn btn-link px-0"
            >
              Edit
            </button>
          </div>
        </fieldset>
      );
    }
  
    
    function getAddressText(shippingAddress) {
      if (!shippingAddress) return [];
  
      const { country, firstName, lastName } = shippingAddress;
      const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
      const countryName = findCountryByCode(country)?.name ?? null;
      const addressItems = [
     
      fullName,
      // address.company ?? null,
      shippingAddress.email ?? null,
      shippingAddress.address ?? null,
      shippingAddress.apartment ?? null,
      shippingAddress.city ?? null,
      shippingAddress.postalCode ?? null,
      shippingAddress.stateProvince ?? null,
      shippingAddress.mobNumber ?? null,
      countryName,
      // shippingAddress.country ?? null
      ];
      return addressItems.filter((it) => it);
    }
     function findCountryByCode(code) {
      return countries === null || !countries.length
        ? null
        : countries.find((country) => country.code === code);
    }
      const formAddress = (
          <>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="countryCode">Country/region*</label>
              <SelectCountry
                value={shippingAddress.country}
                onChange={(option) =>
                  handleDropdownChange("countryCode", option.value)}
                  isDisabled={true}
                  isCountryName={true}
                id="countryCode"
                name="countryCode"
  
              />
              {errors.countryCode && (
  
                <div style={{ color: 'red', fontSize: '12px' }} >{errors.countryCode}</div>
              )}
  
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="firstName" name="firstName">First Name*</label>
              <input
                value={shippingAddress.firstName ?? ""}
                onChange={handleChange}
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First name"
                className={`form-control ${errors.firstName ? "is-invalid" : ""
                  }`}
              />
              {errors.firstName && (
                <div className="invalid-feedback">{errors.firstName}</div>
              )}
            </div>
            <div className="form-group col">
              <label htmlFor="lastName">Last Name*</label>
              <input
                value={shippingAddress.lastName ?? ""}
                onChange={handleChange}
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last name"
                className={`form-control ${errors.lastName ? "is-invalid" : ""
                  }`}
              />
              {errors.lastName && (
                <div className="invalid-feedback">{errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="address">Area name, Colony*</label>
              <input
                value={shippingAddress.address ?? ""}
                onChange={handleChange}
                type="text"
                id="address"
                name="address"
                placeholder="Area name, Colony"
                className={`form-control ${errors.address ? "is-invalid" : ""
                  }`}
              />
              {errors.address && (
                <div className="invalid-feedback">{errors.address}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="apartment">Appartment name, Floor, Room no, City*</label>
              <input
                value={shippingAddress.apartment ?? ""}
                onChange={handleChange}
                type="text"
                id="apartment"
                name="apartment"
                placeholder="Appartment name, Floor, Room no, City"
                className={`form-control ${errors.apartment ? "is-invalid" : ""
                  }`}
              />
              {errors.apartment && (
                <div className="invalid-feedback">{errors.apartment}</div>
              )}
  
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="stateProvince">City*</label>
  
              <input
                required
  
                value={shippingAddress.city ?? ""}
  
                onChange={handleChange}
                type="text"
                id="city"
                name="city"
                placeholder="City"
                className={`form-control ${errors.city ? "is-invalid" : ""}`}
              />
              {errors.city && (
                <div className="invalid-feedback">{errors.city}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="stateProvince">Postal Code*</label>
  
              <input
                required
  
                value={shippingAddress.postalCode ?? ""}
  
                onChange={handleChange}
                type="text"
                id="postalCode"
                name="postalCode"
                placeholder="Postal Code"
                className={`form-control ${errors.postalCode ? "is-invalid" : ""}`}
              />
              {errors.postalCode && (
                <div className="invalid-feedback">{errors.postalCode}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="stateProvince">State Province*</label>
  
              <input
                required
  
                value={shippingAddress.stateProvince ?? ""}
  
                onChange={handleChange}
                type="text"
                id="stateProvince"
                name="stateProvince"
                placeholder="State Province"
                className={`form-control ${errors.stateProvince ? "is-invalid" : ""}`}
              />
              {errors.stateProvince && (
                <div className="invalid-feedback">{errors.stateProvince}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="phone">Phone</label>
  
              <PhoneInput
                inputClass="font-poppins"
                country={"ae"}
                value={shippingAddress.mobNumber || ""}
                placeholder={"Enter Phone Number"}
                onChange={(value, data, event, formattedValue) => {
                  numberCheck(value, data, event, formattedValue);
                }}
              />
            </div>
          </div>
        </>
        )
      const addressPreviews = (
      <div>
        {addresses.map((shippingAddress, index) => renderAddressPreview(shippingAddress, index))}
      </div>
    );
        // const modalTitle = isEditMode ? `${shippingAddress?.id? "edit":"Add"}`
    
    return (
      <>
      <Modal show={isVisible} onHide ={onHide} size="lg" centered> 
       <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      <Modal.Body>{isEditMode ? formAddress : addressPreviews}</Modal.Body>
      {/* <Modal.Body>{formAddress}</Modal.Body> */}
       <Modal.Footer>
          <button style={{borderRadius:"20px"}}
            onClick={onHide}
            type="button"
            className="btn btn-md btn-outline-secondary"
          >
            Cancel
          </button>
          <button style={{background:"#247A81",borderRadius:"20px"}}
            onClick={handleSave}
            type="button"
            className="btn btn-md btn-success ml-2"
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      </>
    )
  }


export default EditShippingAddressModel