import API from "../utils/API";

export async function uploadFile(imageFile) {
  const formData = new FormData();
  formData.append("file", imageFile);

  const config = {
    method: "POST",
    url: "/s3/file/upload",
    data: formData,
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  };
  
  return await API.request(config);
}
