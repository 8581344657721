import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SearchBox from "../../components/SearchBox";

function SelectProductsViewModal(props) {
  const {
    title = "All products",
    onHide = (f) => f,
    onSave = (f) => f,
    isVisible = false,
  } = props;

  const [searchTerm, setSearchTerm] = useState(props.searchTerm ?? "");
  const [searchedProducts, setSearchedProducts] = useState([]);

  useEffect(
    () => setSearchTerm(props.searchTerm ?? ""),
    [isVisible, props.searchTerm]
  );

  const body = (
    <>
      <div className="row">
        <div className="form-group col">
          <SearchBox onSearch={(searchTerm) => setSearchTerm(searchTerm)} />
        </div>
      </div>
    </>
  );

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}} 
          onClick={(event) => onSave(null)}
          type="button"
          className="btn btn-sm btn-success ml-2"
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectProductsViewModal;
