import React,{useState} from "react";
import { Card, Dropdown } from "react-bootstrap";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from "@mui/icons-material/Visibility";
import { useEffect } from "react";
import { Button } from "@mui/material";
import API from "../../utils/API";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Emailsignup(props){

const[email,setEmail]=useState("");
const[password,setPassword]=useState("");
const[confirmpassword,setConfirmPassword]=useState("");
const[firstname,setfirstname]=useState("");
const[lastname,setlastname]=useState("");
const[formfill,setformfill]=useState(false);
const[passwordmatch,setpasswordmatch]=useState(true);
const[signupdata,setsignupdata]=useState({});
const[userName,setUserName]=useState("");
const [passwordVisible, setPasswordVisible] = useState(false);
const[ confirmPasswordVisible,setConfirmPasswordVisible]=useState(false);
const[validationError,setValidationError]=useState({});
const [view,setView] = useState(false)

const params = useParams();
const userId = params.id

const btnName = view ? "Update Kuwa Admin User" : "Create Kuwa Admin User"
console.log("userId", userId)
useEffect(()=>{
  if(userId){
    setView(true)
    viewUser();
  }
  },[userId,view])
  const viewUser = async()=>{
    const res = await API.get(`/api/v1/users/${userId}`)
    const data = res?.data;
    setEmail(data?.emailAddress);
    setfirstname(data?.firstName);
    setlastname(data?.lastName)
    console.log("viewUser",data)
    }
    
const handleemail=(e)=>{
    setEmail(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,"email":"Email is required field"});
    }
    else{
      setValidationError({...validationError,"email":""});
    }
}
const handlefirstname=(e)=>{
    setfirstname(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,"firstname":"firstname is required field"});
    }
    else{
      setValidationError({...validationError,"firstname":""});
    }
}


const handlelastname=(e)=>{
    setlastname(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,"lastname":"lastname is required field"});
    }
    else{
      setValidationError({...validationError,"lastname":""});
    }
}
const handlepassword=(e)=>{
    setPassword(e.target.value);
    if(!view){
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,"password":"password is required field"});
    }
    else{
      setValidationError({...validationError,"password":""});
    }
  }
    
}

const isEmpty = (value) => {
  let emptyval=false;
 if(typeof(value) ==="undefined")
  emptyval=true;
 else if(value === "")
 emptyval=true;
 else if( value === null )
 emptyval=true;
 else if(value=="undefined")
 emptyval=true;
return emptyval;
}
console.log("hbsb",view)

const validateAll=(email,firstname,lastname,password,confirmpassword,view)=>{
  
   const error={}
    var capitalPattern = /[A-Z]/;
    var numberPattern = /[0-9]/;
    var specialCharPattern = /[!@#$%^&*()]/;
    console.log("hdbsjhbs",view)
    // Test the password against the patterns
    if(!view){
     
    if(isEmpty(password))
    {
      error.password="Password is required field";
    }
    else{ 
    var hasCapital = capitalPattern.test(password);
    var hasNumber = numberPattern.test(password);
    var hasSpecialChar = specialCharPattern.test(password);
    if( !(hasCapital && hasNumber && hasSpecialChar))
    {
        error.password="Password should be one capital,one number,one special character"
    }
  }
    if(password !== confirmpassword){
      error.confirmpassword="confirmpassword should match password";
    }
  }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var emailVerified=emailRegex.test(email);
    if(!emailVerified)
    {
      error.email="Email is not valid ";
    }
    if(isEmpty(email))
    {
      error.email="Email is required field";
    }
    if(!view){
    if(isEmpty(password))
    {
      error.password="Password is required field";
    }
    if(isEmpty(confirmpassword)){
      error.confirmpassword="confirm password is required field"
    }
  }
  

   if(isEmpty(firstname))
   {
    error.firstname="first name is required field";
   }
   if(isEmpty(lastname))
   {
    error.lastname="last name is required field";
   }
return error;
}



useEffect(()=>{
 
    if(formfill){
    try{
        console.log(signupdata);
        console.log("formfill",formfill);
        if (view) {
          API.put(`api/v1/user/${userId}`, signupdata)
            .then((res) => {
              showSuccessNotification("Account Updated Successfully");
              setTimeout(() => {
               props.history.goBack();
                
              }, 1000);
              
            })
            .catch((error) => {
              console.error("Error updating account: ", error);
              // Handle error and show appropriate notification
            });
        }
        else{
  API.post("/api/v1/private/user/",signupdata).then((res)=>{showSuccessNotification("Account Created Successfully"); props.history.goBack();console.log(res)});
    }
  }
    catch(error){
        console.log("create shopify id ", error);
        showErrorNotification(
            "Error on  create account " + error.message ?? ""
          );
    }
   }
    setformfill(false);
    setpasswordmatch(true);
  }
  
  
,[signupdata])

const handleConfirmpassword=(e)=>{
    setConfirmPassword(e.target.value);
    if(password==e.target.value){
    setpasswordmatch(true)}
    else{
        setpasswordmatch(false);
    }
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,"confirmpassword":"confirmpassword is required field"});
    }
    else{
      setValidationError({...validationError,"confirmpassword":""});
    }
}

const showSuccessNotification = (notificationMessage) =>
showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});



const onhandlesubmit=(e)=>{
  console.log("dhbbs",view)
    e.preventDefault();
    const valid= validateAll(email,firstname,lastname,password,confirmpassword,view);
    console.log("validate signup",valid);
    setValidationError(valid);
    if(Object.keys(valid).length==0){
    setformfill(true);
    setsignupdata({
        "active": true,
        "defaultLanguage": "en",
        "emailAddress": email,
        "firstName": firstname,
        "groups": [
          {
            "name": "SUPERADMIN",
            "type": "ADMIN"
          }
        ],
        "lastName": lastname,
        "password": password,
        "repeatPassword": confirmpassword,
        "userName": email
      });
    }
    else{
      showErrorNotification("Some Invalid Fields ");
    }
}
const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };
  const redirectToBack = ()=>{
    window.location.href = `/admin/dashboard/users`
  }


return(
 
  <>
    <div style={{ alignSelf: "flex-start" }}>
            <Button
              onClick={redirectToBack}
              color="inherit"
              size="large"
            >
              <ArrowBackIcon />
            </Button>
            </div>
     
    <div >

      <div className="shopifysignup" >
       <div className="Namestyle"><b>Kuwa Admin</b> </div>
       <div> <div className="createsen">Create a Kuwa Admin User </div>
       <div className="subhead">One last step before starting your free trial </div>
       </div>
       <form onSubmit={onhandlesubmit} noValidate>
      <div> 
        
        <div className="lablestyle">Email *</div>
       <div className="columnalignactive"> <input className="inputpadding" value={email} onChange={handleemail} type="email" autoComplete="off"/>
       <span className="help-block">{validationError.email}</span>
       </div>
        <br/>
       <div className="flextwocolums "> <div className="lablestyle"> FirstName * <br/> 
       <div className="columnalignactive">
       <input className="inputpadding"onChange={handlefirstname} value ={firstname} type="text" autoComplete="off"/>
       <span className="help-block">{validationError.firstname}</span>
       </div>
       </div>
       <div className="lablestyle">LastName *<br/>
       <div className="columnalignactive"><input className="inputpadding" onChange={handlelastname} value ={lastname} type="text" autoComplete="off"/>
       <span className="help-block">{validationError.lastname}</span>
       </div>
        </div>
       </div>
       <br/>
       <div  className="sentence"> Enter your first and last name as they appear on your government-issued ID.</div>
       </div>
       
       <br/>
       <div> <div className="lablestyle">Password *</div>
      <div className="columnalignactive">
       <div className="alignrowpassword borderparent"> <input className="passwordBorder  inputpadding noFocusOutline" type={passwordVisible ? 'text' : 'password'}  onChange={handlepassword} autoComplete="new-password" />
      <div className="passwordRight"> {passwordVisible ? (
        <Visibility onClick={togglePasswordVisibility} />
       
      ) : (
        <VisibilityOff onClick={togglePasswordVisibility} />
      )} </div>
       
       </div>
       <span className="help-block">{validationError.password}</span>
       </div>
       </div>
       <br/>
       <div> <div className="lablestyle">Confirm Password *</div>
       <div className="columnalignactive">
       <div className="alignrowpassword borderparent">  <input className="passwordBorder  inputpadding noFocusOutline" type={confirmPasswordVisible ? 'text' : 'password'} onChange={handleConfirmpassword} />
       <div className="passwordRight" > {confirmPasswordVisible ? (
        <Visibility onClick={toggleConfirmPasswordVisibility} />
        
      ) : (
        <VisibilityOff onClick={toggleConfirmPasswordVisibility} />
        
      )}
      </div>
       </div>
       <span className="help-block">{validationError.confirmpassword}</span>
       </div>
       {passwordmatch?<></>:<div>password doesnot match</div>}
       </div>
       <br/>
       <div className="lablestyle"> 
       
       <button   type="submit" className="greenbackground" >{btnName}</button>
       </div>
       


       </form>
       
       </div>
       </div>
      
       </>
)

}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(Emailsignup);