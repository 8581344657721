import React, { Component } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import _ from "lodash";
import SelectProductType from "./SelectProductType";

class BestSellingCollectionTable extends Component{
    constructor(props){
        super(props)
        const { collections = [], onCollectionsUpdated = (f) => f,saveBestSellingCollections } =

        props;
            console.log("props",props)
        console.log("bestSellingCollection",collections)
        this.onCollectionsUpdated = onCollectionsUpdated
        this.state = {
            collections,
            collection: {},
            errors: {},
            editables: {},
            loadError: "",
            sortColumn: "rank",
            sortDirection: "asc",
          };

          this.columns = [
            {
              name: "",
              cell: () => <DragIcon className="drag-handle"></DragIcon>,
              width: "50px",
            },
            {
              name: "ID",
              selector: "id",
              sortable: true,
              maxWidth: "100px",
            },
            {
                name: "Collection ID",
                selector: "collectionId",
                sortable: true,
                width: "150px",
              },
            {
              name: "Collection Name",
              selector: "collectionName",
              sortable: true,
              wrap: true,
            },
            {
              name: "Rank",
              selector: "rank",
              maxWidth: "100px",
              center: true,
              sortable: "true",
            },
            {
              name: "Actions",
              maxWidth: "100px",
              center: true,
              allowOverflow: true,
              cell: (row) => this.renderActionBar(row),
            },
          ];
          const updateRank = this.updateRank;
          this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
              updateRank(fromIndex, toIndex);
            },
            nodeSelector: ".rdt_TableRow",
            handleSelector: ".drag-handle",
            enableScroll: true,
          };
        }
        componentDidUpdate(prevProps, prevState) {
            if (this.props.collections !== this.state.collections) {
              this.setState({ collections: this.props.collections });
            }
          }
    
          clearForm = () => {
            Array.from(document.querySelectorAll("input")).forEach(
              (it) => (it.value = "")
            );
            Array.from(document.querySelectorAll("textarea")).forEach(
              (it) => (it.value = "")
            );
            this.setState({
              errors: {},
              editables: {},
              collection: {},
            });
          };
        
          handleChange = ({ currentTarget: input }) => {
            const collection = { ...this.state.collection };
            collection[input.name] = input.value;
            this.setState({ collection });
          };
        
          handleAddNew = () => {
            const collection = {};
            this.setState({ collection }, () => this.showAddEditModal());
          };
          handleSaveCollection = () =>{
            this.props.saveBestSellingCollections()
          }
          handleEdit = (id) => {
            this.clearForm();
            const collections = this.state.collections ?? [];
            const collection = collections.find((it) => it.id === id);
            if (!collection) return;
        
            this.setState({ collection }, () => this.showAddEditModal());
          };
        
          showAddEditModal = () => {
            this.setState({ isAddEditModalVisible: true });
          };
        
          hideAddEditModal = () => {
            this.clearForm();
            this.setState({ isAddEditModalVisible: false });
          };
        
          showConfirmDeleteModal = () => {
            this.setState({ isConfirmDeleteModalVisible: true });
          };
        
          hideConfirmDeleteModal = () => {
            this.setState({ isConfirmDeleteModalVisible: false });
          };
        
          handleDelete = (id) => this.deleteCollection(id);
        
          handleSubmit = (event) => {
            event.preventDefault();
        
            const errors = this.validate();
            this.setState({ errors: errors || {} });
            if (errors) {
              this.showErrorNotification("There are some invalid form fields!");
              return;
            }
        
            const { collection = {} } = this.state;
            if (collection.id === null || collection.id === undefined)
              this.createCollection();
            else this.updateCollection(collection.id);
            this.hideAddEditModal();
          };
        
          validate = () => {
            const errors = {};
            const { collectionId } = this.state.collection ?? {};
            if (!collectionId) errors.collectionId = "Collection Name is a required field!";
        
            return Object.keys(errors).length === 0 ? null : errors;
          };
        
          createCollection = () => {
            const { collections = [], collection } = this.state;
            const ids =
              collections.length > 0 ? collections.map((it) => it.id) : null;
            collection.id = ids ? Math.max(...ids) + 1 : 1;
        
            const ranks =
              collections.length > 0
                ? collections.filter((it) => it.rank).map((it) => it.rank)
                : null;
            collection.rank = ranks ? Math.max(...ranks) + 1 : 1;
            collection.isActive = true;
            collection.is_new = true;
        
            this.setState(
              { collections: [...collections, collection] },
              () => {
                this.showSuccessNotification("Collection Created Successfully!");
                this.onCollectionsUpdatedLocally();
              }
            );
          };
        
          updateCollection = (id) => {
            const { collections = [], collection } = this.state;
            const updatedCollections = collections.map((it) =>
              it.id === id ? collection : it
            );
            this.setState({ collections: updatedCollections }, () => {
              this.showSuccessNotification("Collection Updated Successfully!");
              this.onCollectionsUpdatedLocally();
            });
          };
        
          deleteCollection = (id) => {
            const { collections = [] } = this.state;
            const collectionToDelete =
              collections.find((it) => it.id === id) || {};
            if (!collectionToDelete) {
              console.log(
                "deleteCollection:: Collection not found with id=",
                id
              );
              this.showErrorNotification("Collection not found!");
              return;
            }
        
            collectionToDelete.isActive = false;
            const updatedCollections = collections
              .filter((it) => it.id !== id)
              .map((it) =>
                it.rank > collectionToDelete.rank
                  ? { ...it, rank: it.rank - 1 }
                  : it
              );
            this.setState({ collections: updatedCollections }, () => {
              this.showSuccessNotification("collection Deleted Successfully!");
              this.onCollectionsUpdatedLocally();
            });
          };
        
          updateRank = (fromIndex, toIndex) => {
            if (
              fromIndex === null ||
              fromIndex === undefined ||
              toIndex === null ||
              toIndex === undefined
            )
              return;
        
            const { collections = [], sortColumn, sortDirection } = this.state;
            const sortedCollections = _.sortBy(
              collections,
              sortColumn,
              sortDirection
            );
            const fromCollection = { ...sortedCollections[fromIndex] };
            const toCollection = { ...sortedCollections[toIndex] };
             if (fromCollection && toCollection) {
              // Swap rank and other properties
              const tempRank = fromCollection.rank;
              // const tempId = fromCollection.id;
              // const tempCollectionId = fromCollection.collectionId;
              // const tempCollectionName = fromCollection.collectionName;
          
              fromCollection.rank = toCollection.rank;
              // fromCollection.id = toCollection.id;
              // fromCollection.collectionId = toCollection.collectionId;
              // fromCollection.collectionName = toCollection.collectionName;
          
              toCollection.rank = tempRank;
              // toCollection.id = tempId;
              // toCollection.collectionId = tempCollectionId;
              // toCollection.collectionName = tempCollectionName;
          
              const updatedCollections = collections.map((it) => {
                if (it.id === fromCollection.id) return fromCollection;
                else if (it.id === toCollection.id) return toCollection;
                else return it;
              });
              this.setState({ collections: updatedCollections }, () => {
                this.showSuccessNotification("Rank Updated Successfully!");
                this.onCollectionsUpdatedLocally();
              });
            } else {
              this.showErrorNotification("Rank Updation Failed!");
            }
          };
        
          onCollectionsUpdatedLocally = () => {
            this.clearForm();
            this.onCollectionsUpdated(
              this.state.collections.map((item) => ({ ...item }))
            );
          };
        
          findProductTypeById = (id) => {
            const productTypes = this.props.ProductTypeAll ?? [];
            return productTypes.find((it) => it.id === Number(id));
          };

        renderActionBar = (collection) =>(
         
            <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu
              popperConfig={{
                strategy: "fixed",
                onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
              }}
            >
              <ActionBar.Item onClick={() => this.handleEdit(collection.id)}>
                <EditIcon className="icon-small" />
                Edit
              </ActionBar.Item>
              <ActionBar.Item
                onClick={() =>
                  this.setState({ collectionToDelete: collection.id }, () =>
                    this.showConfirmDeleteModal()
                  )
                }
              >
                <DeleteIcon className="icon-small" style={{ fill: "red" }} />
                <span style={{ color: "red" }}>Delete</span>
              </ActionBar.Item>
            </ActionBar.Menu>
          </ActionBar>
        )

        renderPencilIcon = (fieldName) => {
            const setEditable = (field) => {
              const editables = { ...this.state.editables };
              editables[field] = true;
              this.setState({ editables });
            };
            return this.state.collection.id ? (
              <div
                onClick={() => setEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-text"
              >
                <EditIcon />
              </div>
            ) : (
              ""
            );
          };
          
    showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

    showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

    showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

          render() {
           const {
                collections = [],
                collection = {},
                errors,
                editables,
                sortColumn,
             
              } = this.state;
              const isNewCollection = !collection.id;

              collections.forEach((it)=>{
                it.collectionName = this.findProductTypeById(it.collectionId)?.description?.name ?? "";
              })
            const form = (
                <>
                  <div className="row">
                    <div className="form-group col">
                      <label htmlFor="collectionName">Collections*</label>
                      <div className="input-group">
                        <SelectProductType
                          id="collectionName"
                          productTypes={this.props.productTypes ?? []}
                          value={collection.collectionId}
                          onChange={(value) =>
                            this.setState({
                              collection: {
                                ...collection,
                                collectionId: value.value,
                              },
                            })
                          }
                          isDisabled={!isNewCollection && !editables.collectionName}
                          className={
                            "form-control" + (errors.collectionName ? " has-error" : "")
                          }
                        />
                        {this.renderPencilIcon("collectionName")}
                      </div>
                      <span className="help-block">{errors.collectionName}</span>
                    </div>
                  </div>
                </>
              );
          
              return (
                <div className="container px-0">
                  <div className="row">
                    <div className="form-group col">
                      <Card>
                        <ReactDragListView {...this.dragProps}>
                          <div className="" style={{display:"flex",justifyContent:"space-between"}}>
                        <button
                        style={{
                          width:"200px",
                          cursor:"pointer"}}
                          onClick={this.handleAddNew}
                          className="greenbackground"
                          // disabled={(this.state.collections.length)>=5}
                        >
                          Add Collection
                        </button> 
                        {/* <button
                        onClick={this.handleSaveCollection}
                        className="greenbackground"
                        style={{width:"200px"}}>
                          Save Collection
                          </button>   */}
                          </div>                        
                          <DataTable
                            highlightOnHover
                            columns={this.columns}
                            data={collections ?? []}
                            onSort={(sortColumn, sortDirection) =>
                              this.setState({
                                sortColumn: sortColumn.selector,
                                sortDirection,
                              })
                            }
                            pagination
                            responsive
                            defaultSortField={sortColumn}
                            // sortIcon={<ArrowDownward></ArrowDownward>}
                          />
                        </ReactDragListView>
                      
                      </Card>
                    </div>
                  </div>
                  <Modal
                    show={this.state.isAddEditModalVisible}
                    onHide={this.hideAddEditModal}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {collection.id === null || collection.id === undefined
                          ? "Add Collection"
                          : "Edit Collection"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{form}</Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button" style={{borderRadius:"20px"}}
                        onClick={this.hideAddEditModal}
                        className="btn btn-sm btn-outline-secondary px-4 py-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={this.handleSubmit}
                        className="greenbackground"
                      >
                        {collection.id ? "Save" : "Add"}
                      </button>
                    </Modal.Footer>
                  </Modal>
                  <Modal
                    show={this.state.isConfirmDeleteModalVisible}
                    onHide={this.hideConfirmDeleteModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Collection</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete?</Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-secondary rounded-pill px-4 py-1"
                        onClick={this.hideConfirmDeleteModal}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          this.deleteCollection(this.state.collectionToDelete);
                          this.hideConfirmDeleteModal();
                        }}
                        className="btn btn-danger rounded-pill px-4 py-1"
                      >
                        Yes
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                
              );
          }
        }


function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(BestSellingCollectionTable);