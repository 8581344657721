import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@mui/material";

class Loader extends Component {
  render() {
    const { menuExpanded } = this.props;

    return (
      <div>
        <div
          className={
            "loader " +
            (menuExpanded ? "" : "wide ") +
            (this.props.isLoading ? "active" : "")
          }
        >
          <CircularProgress className="valeo-loader"></CircularProgress>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
