import React, { useState, useEffect } from "react";
import Select from "react-select";
import API from "../../src/utils/API";

export function CollectionViewComponent(props){
    const{name,prodid,FinalCollection,FinalCollectionFun}=props;
    const[collections,setCollections]=useState([]);
    const[collectionIds,setCollectionIds]=useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryInputvalue, setCategoryInputValue] = useState("");
    const [selectedoption, setSelectedoption] = useState([]);
    const [superCollectionDetails, setSuperCollectionDetails] = useState([]);
    const [listAllCollections, setListAllCollections] = useState([]);
    const[brandId,setBrandId]=useState({});
    const [categoryDetails, setCategoryDetails] = useState([]);
    const[healthId,setHealthId]=useState({});
    const[generalId,setGeneralId]=useState({});
    const [brandTypeCollection, setBrandTypeCollection] = useState([]);
    const[viewCategories,setViewCategories]=useState([]);
    const[prods,setProds]=useState([]);
    const[finalProds,setFinalProds]=useState([]);
    const[editFlag,setEditFlag]=useState(false);
    const[removeIds,setRemoveIds]=useState([]);
    const[addIds,setAddIds]=useState([]);
    const[previousIds,setPreviousIds]=useState([]);
    const[initialProds,setInitialProds]=useState([]);
    useEffect(()=>{
        try{
         API.get(`/api/v1/product/${prodid}?country=1`).then(res=>res.data).then(data=>setCollections(data.collection));
          
         
        }
        catch(error){
            console.log("error is",error);
        }

    },[])

    

   useEffect(()=>{
    if(collections.length>0){
    const promises=[];
    collections.forEach((coll)=> {promises.push(API.get(`/api/v1/private/product/type/${coll.id}`))});
    Promise.all(promises).then((responses) => {
        
        const productDetail = responses.map((response) =>response.data.products.map((Prod)=>{return( {"collid":response.data.id,"prods":Prod.product})}))
        console.log("product detail",productDetail);  
        setInitialProds(productDetail.flat());
    })
    }

   },[collections])

  useEffect(()=>{
    if(initialProds.length==collections.length)
     FinalCollectionFun(collections.map((id)=>initialProds.filter((coll)=> coll.collid ==id)[0]).map((coll)=>{if(coll)return({
            "collectionId":coll.collid,
            "rank":coll?.prods.filter((prod)=>prod.id==prodid)[0]?.rank ==null?1:coll.prods.filter((prod)=>prod.id==prodid)[0]?.rank})
        }).map((prevcoll)=>prevcoll))
    }

  ,[initialProds])

  useEffect(()=>{

  console.log("final coll",FinalCollection);
  },[FinalCollection])


    useEffect(() => {
        try {
            API.get("module/super/collection").then((res) => (res.data)).then((data) => setSuperCollectionDetails(data.map((coll) => { return ({ "id": coll.id, "name": coll.name }) })));
        
            API.get("api/v1/product/types").then((res) => res.data).then((data) => data.list).then((list) => setListAllCollections(list));

        }
        catch (error) {
            console.log("error is", error)

        }
    }, [])
  
    const handleInputCategoryChange = (value, e) => {
        if (e.action === "input-change") {
            console.log(value);
           
            console.log(categories);
            setCategoryInputValue(value);
        }

    }
  
    useEffect(() => {
        if (superCollectionDetails.length > 0) {
            console.log("supercoll", superCollectionDetails);
            setBrandId(superCollectionDetails.filter((supercollection) => supercollection.name == "BRAND")[0]);
           
            setHealthId(superCollectionDetails.filter((supercollection) => supercollection.name == "HEALTH GOAL")[0]);
            setGeneralId(superCollectionDetails.filter((supercollection) => supercollection.name == "GENERAL")[0])
        }

    }, [superCollectionDetails])


    useEffect(() => {
        if (brandTypeCollection.length > 0) {
            console.log("brandtypecoll", brandTypeCollection);
            setCategoryDetails(brandTypeCollection.map((category) => { return ({ "id": category.id, "code": category.code, "name": category.code }) }))
        }
    }, [brandTypeCollection])


    useEffect(() => {
        if (categoryDetails) {
            console.log("category det", categoryDetails);
            setCategories(categoryDetails.map((category) => { return ({ "value": category.id, "label": category.code }) }));
            
        
        }
    }, [categoryDetails])

    useEffect(()=>{
      if(categories.length>0){
        console.log("collections",collections);
       setViewCategories(categories.map((category)=>collections.map((coll)=>{ if(coll.id==category.value)
            return({"value":coll.id,
            "label":category.label
        })
        })).flat().filter((collfinal)=>collfinal));
      }

    },[categories])

   
   
   

    useEffect(()=>{
        if (listAllCollections.length > 0) {
            if (name == "Brandnames")
                setBrandTypeCollection(listAllCollections.filter((coll) => coll?.superCollection[0]?.id == brandId.id));
            if (name == "HealthGoals")
                setBrandTypeCollection(listAllCollections.filter((coll) => coll?.superCollection[0]?.id == healthId.id));
            if(name=="General")
            setBrandTypeCollection(listAllCollections.filter((coll) => coll?.superCollection[0]?.id == generalId.id));
        }
    },[listAllCollections])

const handleSeletedOption=(e,val)=>{
    if(val.action=="remove-value"){
        viewCategories.map((category)=>console.log("category",category.value ));

     const selected=viewCategories.filter((category)=>category.value !== val.removedValue.value);
       setViewCategories(selected);
       setRemoveIds([...removeIds,val.removedValue.value]);
      
        console.log("final selected",viewCategories);
    }

    else if(val.action=="select-option"){
    setViewCategories([...viewCategories,val.option]);
   // setSelectedoption(value.option);
   setAddIds([...addIds,val.option.value]);
    console.log("In viewvalue",val);
    }
}



const handlecollectionedit=(e)=>{
    setEditFlag(true);
    console.log("When edit",viewCategories);
    const removenewids=viewCategories.filter((allIds)=>!addIds.includes(allIds.value));
    const previousIds=removenewids.filter((id)=>!removeIds.includes(id.value));
 
    console.log("new ids,previous",removenewids,previousIds);

     setPreviousIds(previousIds);
     const promises=[];
     viewCategories.forEach((finalcoll) => {
        promises.push(API.get(`/api/v1/private/product/type/${finalcoll.value}`));
      })
  
      // Once all promises are resolved, update the state
      Promise.all(promises).then((responses) => {
        
        const productDetail = responses.map((response) =>response.data.products.map((Prod)=>{return( {"collid":response.data.id,"prods":Prod.product})}))
        console.log("product detail",productDetail);  
        setFinalProds(productDetail.flat());
    })



}


const minRank=(arr)=>{
    let min;
    console.log("arr",arr);
    min=arr.length>0?arr[0]:1;
    for(let i=0;i<arr.length;i++){
        
        
        if(min>arr[i+1]){
            min=arr[i+1];
            
        }
    }
    console.log("min",min);
    return min;
}

useEffect(()=>{
    console.log("viewCategories",viewCategories);
    console.log("final prod length",finalProds.length,finalProds);
if(finalProds.length==viewCategories.length && finalProds.length>0 && addIds && previousIds ){
    console.log("previousIds view",previousIds,viewCategories);
    console.log("final coll",FinalCollection);
    

    // let brandNames = FinalCollection.map((coll)=>coll)
 FinalCollectionFun([...addIds.map((id)=>finalProds.filter((coll)=> coll.collid ==id)[0]).map((coll)=>{if(coll)return({
        "collectionId":coll.collid,
        "rank":minRank(coll.prods.map((prod)=>prod.rank))==null?1:minRank(coll.prods.map((prod)=>prod.rank))
    })}).map((prevcoll)=>prevcoll),
    ...previousIds.map((id)=>finalProds.filter((coll)=> coll.collid ==id)[0]).map((coll)=>{ if(coll)return({
        "collectionId":coll?.collid,
        "rank":coll?.prods.filter((prod)=>prod.id==prodid)[0]?.rank ==null?1:coll.prods.filter((prod)=>prod.id==prodid)[0]?.rank})
    }).map((finalcoll)=>finalcoll)]);
    




}
},[finalProds])




return(
    <div className="prodselect">
            <div> Add {name}</div>
    <Select
    isMulti
    name={name}
    options={categories}
    className="basic-multi-select"
    classNamePrefix="select"

    oncloseMenuOnSelect={false}

    value={viewCategories}
    onInputChange={handleInputCategoryChange}
    inputValue={categoryInputvalue}
    onChange={handleSeletedOption}

    style={{ width: "100%" }}
/>
<br />
            <div> <input className="donebtn" type="button" value="Edit" onClick={handlecollectionedit} /></div>
</div>
)



}