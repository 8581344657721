import React,{useState} from 'react'
import Table from 'react-bootstrap/Table';
import { Modal } from "react-bootstrap"; 
import API from "../../../utils/API"

const ListOfProducts = ({selectedProduct={},product={},index=0,toggleProductSelection})=>{
    const {id,name,sku,status,title} = product;
    const isSelected = selectedProduct.some(selectedProduct => selectedProduct.id === id);
return (
    <Table hover size="lg">
        <tbody>
            <tr>
            <td colSpan={2} style={{width:"1%"}}>
            <input type='checkbox'  checked={isSelected} onChange={() => toggleProductSelection(product)} />
            </td>
            <td colSpan={2} style={{width:"20%",}}>{name}</td>
            <td colSpan={2} style={{textAlign:'center',width:"10%", color:status=="active" ? "green" : "red"}}>
                        {status}
                    </td>
            </tr>
        </tbody>
    </Table>
)
}

const ProductSearchModal = ({showProductsModal, setShowProductsModal,allProducts=[],handleProductSelect}) => {
    const [searchTerm,setSearchTerm] = useState();
    const [isSearching , setIsSearching] = useState(false);
    const [listOfProducts,setListOfProduct] =useState(allProducts);
    const [selectedProduct,setSelectedProduct] = useState([])
    const getProduct = async()=>{
        try{
            setIsSearching(true);
            const payload = {
                "message":searchTerm
            }
            const res = await API.post("api/v1/collection-page/product/search",payload);
            console.log("collectionProduct",res)
            if(res.status == 200){
                const data = res?.data || {}
                setListOfProduct(data);
                setIsSearching(false)
            }
            else{
                setIsSearching(false)
                console.log("Error in fetching the data")
            }
        }
        catch(error){
            setIsSearching(false)
            console.log("Error in fetching the data",error)
        }
      }
    const handleKeyPress =(event)=>{
        if(event.key === 'Enter'){
            getProduct(searchTerm)
        }
    }

    const toggleProductSelection = (product) => {
        const isAlreadySelected = selectedProduct.some(selectedProduct => selectedProduct.id === product.id);
        if (isAlreadySelected) {
          setSelectedProduct(selectedProduct.filter(selectedProduct => selectedProduct.id !== product.id));
        } else {
          setSelectedProduct([...selectedProduct, product]);
        }
      };
      
  return (
    <Modal show={showProductsModal} onHide={()=>setShowProductsModal(!showProductsModal)} size="lg" centered>
    <Modal.Header>
        <div>All products</div>
    </Modal.Header>
    <Modal.Body>
        {
            <>
             <div className="row">
            <div className="form-group col">
            <div className="input-group mb-3">
            <input type="text" className="form-control"
            id="search-bar"
            name="search-bar"
            placeholder="Search Products"
            value={searchTerm}
            onChange={(e)=>{setSearchTerm(e.target.value)}}
            onKeyPress={handleKeyPress}
            />
            </div>
          
            </div>
            </div>
            <Table>
        <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
            <th style={{width:"5%"}}> </th>
            <th style={{width:"20%",}}>Product Name</th>
            <th style={{textAlign:"center",width:'10%'}}>status</th>
        </thead>
            </Table>
            {
                isSearching ? 
                <div style={{height:'50vh',overflow:"scroll"}}>
                    <div className="search-spinner" ></div> 
                </div> :
                (!isSearching && listOfProducts && listOfProducts.length ==0) ?
                <div>No results found for "{searchTerm}"</div>:
                <div style={{height:'50vh',overflow:"scroll"}}>
                        {
                            listOfProducts && listOfProducts.map((data,index)=>{
                                return <ListOfProducts 
                                selectedProduct={selectedProduct}
                                product={data} 
                                index={index} 
                                toggleProductSelection={toggleProductSelection}/>
                            })
                        }
                </div>
            }
         </>
        }
    </Modal.Body>
    <Modal.Footer>
        <div  style={{display:'flex',flexDirection:'row',justifyContent:"flex-end",gap:"10px",width:'100%'}}>
        <div>
                <button
                type="button"
                className="btn btn-sm btn-success ml-2"
                onClick={()=>(handleProductSelect(selectedProduct),setSearchTerm(""),setListOfProduct([]),setSelectedProduct([]))}
                style={(Object.keys(selectedProduct).length>0)?{}:{opacity:0.2,backgroundColor:'#247A81'}}
                >
                    Add
                </button>
            </div>
            <div>
                <button style={{borderRadius:"20px"}}
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={()=>setShowProductsModal(!showProductsModal)}>
                    Cancel
                </button>
            </div>
          
        </div>
    </Modal.Footer>
</Modal>
  )
}

export default ProductSearchModal