import React, { useState, useEffect } from "react";
import API from "../../utils/API";
import Select from "react-select";
import { Editor } from '@tinymce/tinymce-react';
import { Card } from 'react-bootstrap';
import { Button } from "@mui/material";
import { VariantComponent } from "../../components/variantcomponent";
import  VariantCountry  from "../../components/VariantCountry";
import  QuestionComponent  from "../../components/QuestionComponent";
import { ReviewComponent } from "../../components/ReviewComponent";
import ProductList  from "../../components/ProductList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { connect } from "react-redux";
import  CollectionComponent  from "../../components/CollectionComponent";
import { BodyTextEditor } from "../../components/RichEdittorComp";
import { useParams } from "react-router-dom";
import { VariantCountryLogic } from "../../components/VariantCountryLogic";
import  ImageComponent  from "../../components/ImageComponent";
import  CreateVariant  from "../../components/CreateVariant";
import CreateReview  from "../../components/CreateReview";
import  QuestionCreateComponent  from "../../components/QuestionCreateComponent";
import { BodyTextEditorCreate } from "../../components/RichEditorCreateComp";
import { ImageComponentLogic } from "../../components/ImageComponentLogic";
import { Modal } from "react-bootstrap";
import { CollectionViewComponent } from "../../components/CollectionViewComponnet";
import {uploadFile} from "../../services/fileUploadService";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
 function CreateProductForm(props) {

  const params = useParams();
  const viewprodid = params.Viewprodid;
  const editprodid = params.Editprodid;
  console.log("edit", editprodid);
  const [variantcount, setVariantCount] = useState([1]);
  const [title, setTitle] = useState();
  const [variantdetails, setVariantdetails] = useState([]);
  const [variantpricedetails, setVariantpricedetails] = useState([]);
  const [formfill, setFormfill] = useState(false);
  const [chargetax, setChargetax] = useState(true);
  const [variantcountryprice, setVariantcountryprice] = useState([1]);
  const [track, settrack] = useState(true);
  const [sku, setSKU] = useState(false);
  const [skuValue, setSkuValue] = useState();
  const [barcode, setbarcode] = useState();
  const [commited, setCommited] = useState(0);
  const [available, setAvailable] = useState(0);
  const [onhandQuantity, setOnhandQuantity] = useState(0);
  const [questionscount, setQuestionscount] = useState([1]);
  const [reviewcount, setReviewcount] = useState([1]);
  const [reviews, setReviews] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [prodcount, setProdcount] = useState([1]);
  const [description, setDescription] = useState('');
  const [ingrediants, setIngrediants] = useState('');
  const [benefits, setBenefits] = useState('');
  const [countries, setCountries] = useState([]);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const [addVariant, setAddVariant] = useState(false);
  const [addReview, setAddReview] = useState(false);
  const [resProdId, setResProdId] = useState();
  //const[variantcount,setVarcount]=useState();
  const [viewproduct, setViewProduct] = useState();
  const [imagecount, setImageCount] = useState([1]);
  const [addimage, setAddImage] = useState(false);
  const [viewEditFlag, setViewEditFlag] = useState(false);
  const [statusprod, setStatusprod] = useState("Active");
  const [productdetails, setProductdetails] = useState({});
  const [brandIds, setBrandIds] = useState([]);
  const [brandNames, setBrandNames] = useState([]);
  const [healthNames, setHealthNames] = useState([]);
  const [healthIds, setHealthIds] = useState([]);
  const[generalIds,setGeneralIds]=useState([]);
  const[genaeralNames,setGeneralNames]=useState([]);
  const [allVariant, setAllVariant] = useState([]);
  const [relatedprods, setRelatedProds] = useState([]);
  const [seoURL, setSeoURL] = useState();
  const[variantSelectedImage,setVariantSelectedImage]=useState([]);
  const[prodRelFlag,setProdRelFlag]=useState(false);
  const[editProductDetails,setEditProductDetails]=useState({});
  const[pageTitle,setPageTitle]=useState();
  const[metaDescription,setMetaDescription]=useState();
  const[validationError,setValidationError]=useState({});
  const[allViewImages,setAllViewImages]=useState([]);
  const[editPrices,setEditPrices]=useState([]);
  const[editDescription,setEditDescription]=useState("");
  const[editIngrediants,setEditIngrediants]=useState();
  const[editBenifits,setEditBenefits]=useState();
  const[imageModelPopup,setImageModelPopup]=useState(false);
  const[collectionFinalRank,setCollectionFinalRank]=useState([]);
  const [pricedetail, setPricedetail] = useState([]);
  const[priceEditDetail,setPriceEditDetail]=useState([]);
  const [variantPriceEditdetails, setVariantPriceEditdetails] = useState([]);
  const[allEditVariant,setAllEditVariant]=useState([]);
  const[variantModalPopup,setVariantModalPopup]=useState(false);
  const [prodimage, setProdImage] = useState([]);
  const[postRequest,setPostRequest]=useState(false);
  const brand = "Brandnames";
  const health = "HealthGoals";
  const general="General";
  const [resProdFlag, setResProdFlag] = useState(false);
  const[addPrice,setAddPrice]=useState(false);
  const[editFill,setEditFill]=useState(false);
  const[responseImage,setResponseImage]=useState([]);
  const[temporaryVariant,setTemporaryVariantImage]=useState([]);
  const[borderImageIds,setBorderImageIds]=useState([]);
  const[primaryImageStatus,setPrimaryImageStatus]=useState();
  const[selectedCountries,setSelectedCountries]=useState([]);
  const[brandFinalRank,setBrandFinalRank]=useState([]);
  const[healthFinalRank,setHealthFinalRank]=useState([]);
  const[generalFinalRank,setGeneralFinalRank]=useState([]);
  useEffect(() => {
    try {
      API.get("/api/v1/country").then((res) => res.data).then((data)=>setCountries(data.map((ctry)=>ctry.id)));

    }
    catch (error) {
      console.log("error msg", error);
    }
   }, [])
  useEffect(() => {
    if (viewEditFlag) {

      const urlstr = view ? `/api/v1/product/${viewprodid}?country=1` : `/api/v1/product/${editprodid}?country=1`;
      console.log("url", urlstr);
      try {
        API.get(urlstr).then((res) => res.data).then((data) => setViewProduct(data));

       } catch (error) {
        console.log("fetchProducts:: Error on fetching products!", error);
      }
    }
  }, [viewEditFlag])

  useEffect(() => {
    if (viewproduct) {
      setTitle(viewproduct.description?.title);
      setDescription(viewproduct?.description.description);

      setBenefits(viewproduct?.benifits);
      setIngrediants(viewproduct?.ingredients ? viewproduct?.dosages:"");
      
      setSkuValue(viewproduct?.sku);
      setStatusprod(viewproduct?.available ? "Active" : "Inactive");
      setAllViewImages(viewproduct?.images.map((image)=>image.imageUrl));
      setOnhandQuantity(viewproduct?.quantity);
      

    }
  }, [viewproduct])

  console.log("statusprodstatusprod",statusprod)

  useEffect(() => {
    if (params.Viewprodid) {
      setView(true);
      setViewEditFlag(true);
      console.log("in create", viewprodid);
    }
  }, [viewprodid])

  useEffect(() => {
    if (params.Editprodid) {
      setEdit(true);
      setViewEditFlag(true);
      console.log(edit);
    }
  }, [editprodid])

  const handlestatusProd = (e) => {
    setStatusprod(e.target.value);
   
    if(isEmpty(statusprod))
   setValidationError({...validationError,prodstatus:"Status should active"});
    else if(statusprod=="Inactive")
    setValidationError({...validationError,prodstatus:"Status should active"});
    else
    setValidationError({...validationError,prodstatus:""});
    
  }
  const isEmpty = (value) => {
    let emptyval=false;
   if(typeof(value) ==="undefined")
    emptyval=true;
   else if(value === "")
   emptyval=true;
   else if( value === null )
   emptyval=true;
  return emptyval;
  }
  const onhandleaddVariant = (e) => {
    console.log("add variant");
    setVariantCount([...variantcount, variantcount.length + 1]);
    setAddVariant(true);
    setVariantModalPopup(true);  
    

  }

  const hideImageModal=()=>{
    setImageModelPopup(false);
  }

  const onhandleaddImage = (e) => {
    console.log("add Image");
    setImageCount([...imagecount, imagecount.length + 1]);
    setAddImage(true);
    setImageModelPopup(true);

  }
  const handleOnhand = (e) => {
    setOnhandQuantity(e.target.value);
   
   if(isEmpty(e.target.value))
   setValidationError({...validationError,quantity:"quantity is required field"})
   else{
    setValidationError({...validationError,quantity:""});
   }
   
  }

  const onhandleaddReview = (e) => {

    setReviewcount([...reviewcount, reviewcount.length + 1])
    setAddReview(true);
  }

  const onhandleaddQuestion = (e) => {
    console.log("add question");
    setQuestionscount([...questionscount, questionscount.length + 1]);
    setAddQuestion(true);

  }
  const handleskuvalue = (e) => {
    setSkuValue(e.target.value);
  }

  const handlebarcode = (e) => {
    setbarcode(e.target.value);
  }

  const onhandlePrice = (e) => {
    setVariantcountryprice([...variantcountryprice, variantcountryprice.length + 1]);
    setAddPrice(true);
  }

  useEffect(() => {
    if (formfill && !resProdFlag) {
      console.log(productdetails);
      try {
        API.post("api/v1/private/product", productdetails).then((res) => (res.data)).then((data) =>{ setResProdId(data.id);setResProdFlag(true);}).then(showSuccessNotification("successfully created Product"));
        
      } catch (error) {
        console.log("fetchProducts:: Error on sending products details!", error);
        showErrorNotification(
          "Error on Creating Product" + error.message ?? ""
        );
      }
      setFormfill(false);
    }
  }, [productdetails]);

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });

console.log("In edit create product",editDescription);
  useEffect(() => {

    try {
      if (resProdId) {
        console.log(reviews);
        if(reviews.length>0){
        reviews.map((review) => { review.productId = resProdId });
        reviews.map((review) => API.post("/module/product/review", review).then((res) =>showSuccessNotification("successfully created review") ));
        }
        
        if(faqs.length>0){
          faqs.map((faq) => { faq.productId = resProdId });
        faqs.map((faq) => API.post("/module/product/faqs", faq).then((res) => showSuccessNotification("successfully created faq")));
        }
        console.log("variants details", allVariant);
       // allVariant.map((variant) => { variant.productId = resProdId });
       // allVariant.map((variant) => API.post("/module/variants", variant).then((res) => console.log(res)));
        console.log("related prods", relatedprods);
        if (relatedprods.length > 0) {
          API.post(`module/related-product/${resProdId}`, relatedprods).then((res) =>showSuccessNotification("successfully created relatedprod"));
        }
        
        if(brandIds.length>0){
          API.post(`/module/super/collection/type/${resProdId}`,brandIds ).then((res) => console.log(res));
        } 
        if(healthIds.length>0){
          API.post(`/module/super/collection/type/${resProdId}`,healthIds ).then((res) => console.log(res));
        }
        if(generalIds.length>0){
          API.post(`/module/super/collection/type/${resProdId}`,generalIds ).then((res) => console.log(res));
        }

      }
    }
  catch (error) {
      console.log("error is", error);
      showErrorNotification(
        "Error on creating " + error.message ?? ""
      );
    }



  }, [resProdId,])

   const onhandleEditProduct=(e)=>{
    console.log("description in edit",description);
    console.log("brandFinalRank",brandFinalRank);
    console.log("health",healthFinalRank);
    console.log("edit descr",editDescription);
          setEditProductDetails({
            "canBePurchased": true,
        "categories": [
          { "id": 1, "code": "12" }
        ],
        "image": allViewImages,
       

        "seoUrl": seoURL,

        "prices": priceEditDetail.map((country)=>{return(country)}),
        "availability": {
          "available": true,
          "onHandQuantity": parseInt(onhandQuantity),
          "availableQuantity": 23,
          "commitedQuantity": 33,
          "productQuantityOrderMax": 3,
          "productQuantityOrderMin": 1,
          "status": 1,
          "region": "*",

        },
        "collection":[...brandFinalRank.map((rank)=>rank),...healthFinalRank.map((rank)=>rank),...generalFinalRank.map((rank)=>rank)],
        "descriptions": [
          {
            "description": editDescription?editDescription:description,
            "friendlyUrl": "string",
            "highlights": "string",
            "id":viewproduct?.description.id,
            "keyWords": "string",
            "language": "en",
            "metaDescription": "string",
            "name": title,
            "title": title
          }
        ],
        "id": viewprodid,
        "identifier": "string",
        "manufacturer": "DEFAULT",
        "price": pricedetail.final,
        "productSpecifications": {
          "dimensionUnitOfMeasure": "cm",
          "height": 0,
          "length": 0,
          "manufacturer": "DEFAULT",
          "model": "string",
          "weight": 0,
          "weightUnitOfMeasure": "g",
          "width": 0
        },
        "properties": [
          {
            "attributeDefault": true,
            "attributeDisplayOnly": true,
            "id": 0,
            "option": {
              "code": "0",
              "id": 0,
              "readOnly": true,
              "type": "string"
            },
            "optionValue": {
              "code": "string",
              "defaultValue": true,
              "descriptions": [
                {
                  "description": "string",
                  "friendlyUrl": "string",
                  "highlights": "string",
                  "id": 0,
                  "keyWords": "string",
                  "language": "en",
                  "metaDescription": "string",
                  "name": "string",
                  "title": "string"
                }
              ],
              "id": 0,
              "image": "",
              "name": "string",
              "order": 0,
              "sortOrder": 0
            },
            "productAttributePrice": 0,
            "productAttributeWeight": 0,
            "productId": 0,
            "sortOrder": 0
          }
        ],
        "quantity": onhandQuantity,
        "shipeable": true,
        "sku": skuValue,
        "sortOrder": 0,
        "type": brandNames.length > 0 ? brandNames[0] : healthNames[0],
        "virtual": true,
        "visible": true,
        "ingredients":editIngrediants?editIngrediants:ingrediants,
        "benifits":editBenifits?editBenifits:benefits,
        "dosages":editIngrediants?editIngrediants:ingrediants,
      }
);
setEditFill(true);
    }


  useEffect(()=>{
    if(editFill && !resProdFlag){
      try{
      API.put(`/api/v1/product/${viewprodid}`,editProductDetails).then(res=>{setResProdFlag(true);showSuccessNotification("Product updated successfully")})
      }
      catch(error){
      console.log("error",error);
      showErrorNotification("Error on updating product" + error.message ?? "");
    }

  }
  setEditFill(false);
  },[editProductDetails])
   
  
  useEffect(()=>{
  console.log("in prod",variantpricedetails);
   },[variantpricedetails])

  
   
   const  validateAll=(title,description,image,prodstatus,quantity)=>{
    const validationError={};
    if(isEmpty(title)){
  validationError.title="Title field is required";
    }
    if(description ===""){
      validationError.description="Description field is required";
    }
    if(image.length==0)
     {
      validationError.image="Atleast One image is required";
     }
     if(isEmpty(prodstatus))
    {
      validationError.ProdStatus="When Create Prod Active";
    }

    else if(prodstatus=="Inactive"){
      validationError.ProdStatus="When Create Prod Active";
    }
    if(isEmpty(quantity) || isNaN(quantity)){
      validationError.ProdQuantity="Quantity is Required field";
    }
    else if(quantity<=0){
      validationError.ProdQuantity="Quantity should greater than 0";
    }
  return validationError;
   }
 

  
  const showLoading = () => props.toggleLoading({ isLoading: true });

const hideLoading = () => props.toggleLoading({ isLoading: false });
 
const  uploadFileAll =(images)=>
{
const promises = [];
console.log("length" ,images.length);
for(let i=0;i<images.length;i++)
 {
  const formData = new FormData();
    formData.append("file", images[i]);
    
    const config = {
      method: "POST",
      url: "/s3/file/upload",
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
  promises.push(API.request(config));
}

// Once all promises are resolved, update the state
Promise.all(promises).then((responses) =>  { const images = responses.map((response) => response.data.s3Url);
  setResponseImage(images);
  

});


}
useEffect(()=>{
  if(responseImage.length>0){
  console.log("resin useEffect",responseImage);
  if(view){
    setAllViewImages([...allViewImages,...responseImage]);
  }
  else{
  setProdImage([...prodimage,...responseImage]);
  }
  }
},[responseImage])
  const handleImages =  (event) => {
    if (event.target.files && event.target.files.length) {
      console.log("all images",event.target.files);
      const imageFile = event.target.files;
      

      try {
        showLoading();
        uploadFileAll(imageFile);
        
       // console.log("upload details",uploadDetails);
       // setProdImage([...prodimage, uploadDetails?.s3Url]);
        
        }
       
       catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );
        showErrorNotification(
          "Error on uploading image file! " + error.message ?? ""
        );
      } finally {
        hideLoading();
      }
    }
  };
  const handleDeleteImage=(image)=>{
    const allImages=prodimage.filter((img)=>img !== image);
    setProdImage(allImages);
        
  }
  const handleSelect=(img,id)=>{
    console.log("ids for select",img,id,borderImageIds);
    if(temporaryVariant.includes(img)){
      const images=temporaryVariant.filter((image)=>img!==image)
    setTemporaryVariantImage(images);
    }
    else{

   setTemporaryVariantImage([...temporaryVariant,img]);
    }
   if(borderImageIds.includes(id)){
    const selectedImageIds=borderImageIds.filter((imageId)=>imageId!==id);
    setBorderImageIds(selectedImageIds);
   }
    
   else{
   setBorderImageIds([...borderImageIds,id]);
   }
    console.log("select",temporaryVariant);
  }
  const handleDeleteVariantImage=(img)=>{
    const temp=temporaryVariant.filter((image)=>img!==image);
    setTemporaryVariantImage(temp);
  }

  const handleVariantSaveImages=()=>{
    console.log("temporary",temporaryVariant);
   setVariantSelectedImage([...temporaryVariant]);
   if(temporaryVariant.length>0){
   showSuccessNotification("variant Image saved");
   }
   else{
    showErrorNotification("select one variant image");
   }

  }

 const handleSaveImages=()=>{
        if(prodimage.length>0){
        showSuccessNotification("Images Saved");
        setPrimaryImageStatus(prodimage[0]);
        setValidationError({...validationError,image:""});

        }
       
        else{
          showErrorNotification("please select one image");
          setValidationError({...validationError,image:"One image is required"});

        }
 }
 const variantform=(<>
 <div className="gridalign">
   { prodimage.map((img,index)=><div onClick={()=>handleSelect(img,index)}><img className="imageshowsizeborder" style={(borderImageIds.includes(index)?{border:'1px solid blue'}:{border:'1px solid black'})} src={img}   /><Button  className="aligniconborder"  onClick={ ()=>handleDeleteVariantImage(img)}> <HighlightOffIcon  fontSize="small"    /> </Button> </div> )}
   </div>
   <div><input type="button" className="newvaraintbutton"  value="Save" onClick={handleVariantSaveImages}/></div>

 </>)

  const form=(<>
  <div className="alignrowcenter"> <label className="label"><div className="newvaraintbutton">+Upload Image</div> <input type="file" className="variantbutton" multiple onChange={handleImages}/></label>
  <div><input type="button" className="newvaraintbutton"  value="Save" onClick={handleSaveImages}/></div>
  </div>
  <div className="gridalign">

   {view?allViewImages.map((img)=><div><img className="imageshowsizeborder" src={img}  /><Button  className="aligniconborder"  onClick={ ()=>handleDeleteImage(img)}> <HighlightOffIcon  fontSize="small"    /> </Button> </div> )
   : prodimage.map((img)=><div><img className="imageshowsizeborder" src={img}  /><Button  className="aligniconborder"  onClick={ ()=>handleDeleteImage(img)}> <HighlightOffIcon  fontSize="small"    /> </Button> </div> )}
   </div>
 
  

  </>)
  console.log("imageprimary",primaryImageStatus);
    const handlesave = (e) => {
   
   // console.log("country", pricedetail[0].country);
    //console.log("seo url", seoURL);
    //console.log("allVariants",allVariant);
   const validate= validateAll(title,description,prodimage,statusprod,onhandQuantity) ; 
   console.log("validate in save",validate)
   setValidationError(validate);
   
    console.log("validate",validate);
    if (pricedetail.length > 0 && Object.keys(validate).length===0) {
      setProductdetails({

        "canBePurchased": true,
        "categories": [
          { "id": 1, "code": "12" }
        ],
        "image": [primaryImageStatus,...prodimage.filter((img)=>img!==primaryImageStatus)],
        "variant": allVariant.map((variant) => {
          return ({
            "variants": variant, "pricings": variantpricedetails.filter((variantprice) => variantprice.name == variant.name).
              map((filtervar) => {
                return ({
                  "countryId": filtervar.countryId,
                  "retailPrice": filtervar.retailPrice,
                  "finalPrice": filtervar.finalPrice,
                  "discount": filtervar.discount
                })
              })
          })
        }),


        "seoUrl": seoURL,

        "priceSeo": pricedetail.map((prodprice) => {
          return (
           prodprice
          )
        })
        ,
        "availability": {
          "available": true,
          "onHandQuantity": parseInt(onhandQuantity),
          "availableQuantity": 0,
          "commitedQuantity": 0,
          "productQuantityOrderMax": 3,
          "productQuantityOrderMin": 1,
          "status": 1,
          "region": "*",

        },
        "descriptions": [
          {
            "description": description.bodyText,
            "friendlyUrl": "string",
            "highlights": "string",
            "id": 0,
            "keyWords": "string",
            "language": "en",
            "metaDescription": "string",
            "name": title,
            "title": title
          }
        ],
        "id": 0,
        "identifier": "string",
        "manufacturer": "DEFAULT",
        "price": pricedetail.final,
        "productSpecifications": {
          "dimensionUnitOfMeasure": "cm",
          "height": 0,
          "length": 0,
          "manufacturer": "DEFAULT",
          "model": "string",
          "weight": 0,
          "weightUnitOfMeasure": "g",
          "width": 0
        },
        "properties": [
          {
            "attributeDefault": true,
            "attributeDisplayOnly": true,
            "id": 0,
            "option": {
              "code": "0",
              "id": 0,
              "readOnly": true,
              "type": "string"
            },
            "optionValue": {
              "code": "string",
              "defaultValue": true,
              "descriptions": [
                {
                  "description": "string",
                  "friendlyUrl": "string",
                  "highlights": "string",
                  "id": 0,
                  "keyWords": "string",
                  "language": "en",
                  "metaDescription": "string",
                  "name": "string",
                  "title": "string"
                }
              ],
              "id": 0,
              "image": "",
              "name": "string",
              "order": 0,
              "sortOrder": 0
            },
            "productAttributePrice": 0,
            "productAttributeWeight": 0,
            "productId": 0,
            "sortOrder": 0
          }
        ],
        "quantity": parseInt(onhandQuantity),
        "shipeable": true,
        "sku": skuValue,
        "sortOrder": 0,
        "type": brandNames.length > 0 ? brandNames[0] : healthNames[0],
        "virtual": true,
        "visible": true,
        "ingredients":ingrediants.bodyText,
        "benifits":benefits.bodyText,
        "metaTitle":pageTitle,
        "metaDescription":metaDescription,
        "dosages":ingrediants.bodyText

      }
      )
      setFormfill(true);
      

    }

  }
  const hideVariantModal=()=>{
    setVariantModalPopup(false);
  }

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  
   if(isEmpty(e.target.value))
  setValidationError({...validationError,title:"Title is required field"});
  else{
    setValidationError({...validationError,title:""});
  }
  }
   
  const handleSKUcheck = (e) => {
    if (e.target.checked)
      setSKU(true);
    else
      setSKU(false);
  }

  const onhandleProduct = (e) => {
    setProdcount([...prodcount, prodcount.length + 1]);
    setProdRelFlag(true);
  } 

useEffect(()=>{
  console.log("desccc",description)
},[description])
  return (

    <div>

      <div className="saveback">
        <div><Button
          onClick={() => { setView(false); setEdit(false); setViewEditFlag(false); setResProdFlag(false); props.history.goBack(); }}
          
          color="inherit"
          size="small"
        >
          <ArrowBackIcon />
        </Button></div>
        {view ? <div ><button  disabled={resProdFlag} className={resProdFlag ? 'disabled-button' : 'savebtn'} onClick={onhandleEditProduct}>Save</button></div> : <></>}
       {!view && !edit? <div ><button className="savebtn"  disabled={resProdFlag}  onClick={handlesave}>Save</button></div>:<></> }
       </div>

      <div className="fullpage">

        <div className="firstblock">

          <Card body>
            <div>
              <b>Product Details</b>
              <br/><br/>
              <div > <label for="title">Title*</label><br />
                <input id="title" type="text" className="inputfieldsize" value={title} onChange={onTitleChange} required /></div>
                <span className="help-block">{validationError.title}</span>
              <br />
               
        
              <div ><p className="textcolor">Description* </p>
                {view ? <BodyTextEditor 
                  value={description} setValue={setEditDescription}/>
                  
                 : <BodyTextEditorCreate 
                  value={description}
                  setValue={bodyText => setDescription({ ...description, bodyText })}
                />}  </div>
                 <span className="help-block">{validationError.description}</span>
                 <br/>
              <div className="alignrow ">
                <div > <p className="textcolor">Ingrediants &amp; Dosage</p>
                {view ? <BodyTextEditor 
                  value={ingrediants} setValue={setEditIngrediants}/>:
                 <BodyTextEditorCreate 
                  value={ingrediants}
                  setValue={bodyText => setIngrediants({ ...ingrediants, bodyText })}
                /> } </div>

                <div> <p className="textcolor">Benefits</p> 
                {view ? <BodyTextEditor 
                  value={benefits} setValue={setEditBenefits}/>:
                <BodyTextEditorCreate
                  value={benefits}
                  setValue={bodyText => setBenefits({ ...benefits, bodyText })}
                /> } </div>
              </div>

            </div>
          </Card>
          <Card body>
            <div className="columnalign">
              <div className="alignrowcoupon">
                <b> Media*</b>
                 <input className="newvaraintbutton" type="button" value="+ Add Image"  onClick={onhandleaddImage} />
              </div>
              <Modal
           size="lg"
          show={imageModelPopup}
          onHide={hideImageModal}
        >
            <Modal.Header closeButton>
                <Modal.Title className="aligncenter">
                    <div>product image</div> 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{form}</Modal.Body>


        </Modal>

             {view?<ImageComponentLogic mode={"View"}  viewImages={allViewImages} viewImagesFun={setAllViewImages} imageAdd={addimage} imageAddFun={setAddImage} />
             : <div className="alignrowwrap">  
              {prodimage.map((image,index) => { return (<ImageComponent id={index} mode={"Create"} imageprod={prodimage}  imageprodfun={setProdImage} imageurl={image} primaryCheckbox={primaryImageStatus} primaryCheckboxFun={setPrimaryImageStatus} />) })}
              </div>}
              <span className="help-block">{validationError.image}</span>
            </div>
          </Card>


          <Card body>
            <div><b>Variant Management </b> &nbsp;&nbsp;

               <input className="newvaraintbutton" type="button" value="+ Add Variants" onClick={onhandleaddVariant} /></div><br />
               
               <Modal
           size="lg"
          show={variantModalPopup}
          onHide={hideVariantModal}
        >
            <Modal.Header closeButton>
                <Modal.Title className="aligncenter">
                    <div>Variant Image</div> 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{variantform}</Modal.Body>


        </Modal>


            <div className="columngap">
              {view ? <VariantComponent id={viewprodid} mode={"View"} add={addVariant} addfun={setAddVariant} variant={allEditVariant} variantFun={setAllEditVariant} variantImages={allViewImages} variantImagesFun={setAllViewImages} popupFlag={variantModalPopup} popFun={setVariantModalPopup}/> : edit ? <VariantComponent id={editprodid} mode={"Edit"} add={addVariant} addfun={setAddVariant} />

                : variantSelectedImage.map((img,index) => { return (<CreateVariant id={index+1} mode={"Create"} variant={allVariant} variants={setAllVariant} addfun={setAddVariant} imgname={img} allImages={prodimage} />) })}

            </div>

          </Card>

          <Card body>
            <div><b>Pricing on Country Level </b> &nbsp;&nbsp;

              <input className="newvaraintbutton" type="button" value="+ Add Country" onClick={onhandlePrice} /> &nbsp;&nbsp;
              <input type="checkbox" defaultChecked={chargetax} /> Charge tax on this product
            </div>

            <br />
            {view ? <VariantCountryLogic mode="View" prodid={viewprodid}  add={addPrice} addFun={setAddPrice} priceEdit={priceEditDetail} priceEditFun={setPriceEditDetail}    /> : edit ? <VariantCountryLogic mode="Edit" prodid={editprodid} add={addPrice} addFun={setAddPrice} />
              : variantcountryprice.map((count) => { return (<><VariantCountry mode={"Create"} id={count} priceallChange={setPricedetail} priceDetail={pricedetail} variantprice={variantpricedetails} SEOURL={setSeoURL} variantpricefun={setVariantpricedetails} variantdet={allVariant}  seoPageTitle ={setPageTitle} seoMetaDescription ={setMetaDescription}  countriesSelected={selectedCountries} countriesSelectedFun={setSelectedCountries} /> <br/></>) })}


          </Card>

          <Card body>
            <div className="alignrow">
              <div style={{ width: "50%" }}><b>Inventory Management </b></div>

              <div><input type="checkbox" defaultChecked={track} /> Track Quantity</div>
            </div><br />
            <div>
              <input type="checkbox" defaultChecked={track} /> Continue selling when out of stock
            </div><br />
            <div>
              <input type="checkbox" onChange={handleSKUcheck} /> This product has a SKU Or barcode
            </div><br />
            {sku ? <div className="alignrow">
              <div><input type="text" onChange={handleskuvalue} value={skuValue} placeholder="SKU (Stock Keeping Unit)" /></div>
              <div><input type="text" onChange={handlebarcode} value={barcode} placeholder="Barcode (ISBN, UPC, GTIN, etc.)" /></div>

            </div> : <></>}<br />
            <div>
              Quantity*

            </div><br />
            <div className="alignrow">
              <div style={{ width: "50%" }}>Location</div>
              <div style={{ width: "15%" }}> Commited</div>
              <div style={{ width: "10%" }}> Available</div>
              <div style={{ width: "10%" }}>On hand</div>

            </div><br />
            <div className="alignrow">
              <div style={{ width: "50%" }}>1JLT-Nook-178 One JLT Plot No: DMCC-EZ1- 1AB, Jumeirah Lakes Towers, Dubai, UAE</div>
              <div style={{ width: "15%" }}>
                {view ? viewproduct?.commitedQuantity : commited}</div>
              <div style={{ width: "10%" }}>{view ? viewproduct?.availableQuantity : available}</div>
              <div style={{ width: "10%" }}>
                <input style={{ width: "100%" }}type="number" value={onhandQuantity} onChange={handleOnhand} />
              </div>
              <span className="help-block">{validationError.ProdQuantity}</span>
            </div>
          </Card>
          <Card body><div>
            <div className="alignrow">
              <div >
                <b> FAQ </b>(Frequently ask question)
              </div>


              <div>
                 <input className="newvaraintbutton" type="button" value="+ Add Question" onClick={onhandleaddQuestion} /> 
              </div><br /><br />
            </div>
            {view ? <QuestionComponent id={viewprodid} mode={"View"} add={addQuestion} addfun={setAddQuestion} questions={questionscount} /> : edit ? <QuestionComponent id={editprodid} mode={"Edit"} add={addQuestion} addfun={setAddQuestion} /> :

              questionscount.map((count) => { return (<QuestionCreateComponent mode={"Create"} addfun={setAddQuestion} allFaq={faqs} allFaqs={setFaqs} />) })}

          </div>



          </Card>

          <Card body>
            <div>
              <b> Reviews </b> &nbsp;&nbsp; <input className="newvaraintbutton" type="button" value="+ Add Review" onClick={onhandleaddReview} />
              <br /><br />
              {view ? <ReviewComponent id={viewprodid} mode={"View"} add={addReview} addfun={setAddReview} /> : edit ? <ReviewComponent id={editprodid} mode={"Edit"} add={addReview} addfun={setAddReview} />
                : reviewcount.map((count) => <CreateReview mode={"Create"} addfun={setAddReview} allReview={reviews} allReviews={setReviews} />)}
            </div>
          </Card>

        </div>

        <div className="secondblock">


          <Card body>
            <div> <b>Product Status*</b><br />
              <div ><select onChange={handlestatusProd} value={statusprod} style={{ width: "100%" }} >
                
                <option value="Active" >Active</option>
                <option value="Inactive">Inactive</option>
              </select></div>
            </div>
            <span className="help-block">{validationError.ProdStatus}</span>
          </Card>

          <Card body>
            <div><b> Frequently bought items </b> <br /><br />
              <input className="newvaraintbutton" type="button" value="+ Add Product" onClick={onhandleProduct} /> &nbsp;&nbsp;
              <br /><br />
              {view ? <ProductList mode={"View"} id={viewprodid} addRel={prodRelFlag} addRelFun={setProdRelFlag} /> : edit ? <ProductList id={editprodid} mode={"Edit"} />
                : prodcount.map((count) => <ProductList mode={"Create"} id={count} Relprod={relatedprods} AllRelprod={setRelatedProds} />)}
            </div>
          </Card>
          <Card body>
            <div>
              <b>Product Organization</b>
            </div><br />
            {view? <CollectionViewComponent name={brand} prodid={viewprodid} FinalCollection={brandFinalRank} FinalCollectionFun ={setBrandFinalRank}/>
            :<CollectionComponent name={brand}  responseId={resProdId} brands={brandIds} resflag={resProdFlag} brandFun={setBrandIds} brandNameFun={setBrandNames} />}
            <br />
            {view? <CollectionViewComponent name={health} prodid={viewprodid} FinalCollection={healthFinalRank} FinalCollectionFun ={setHealthFinalRank}/>:
            <CollectionComponent name={health} responseId={resProdId} brands={healthIds} resflag={resProdFlag} brandFun={setHealthIds} brandNameFun={setHealthNames} />}
            <br/>
            {view? <CollectionViewComponent name={general} prodid={viewprodid} FinalCollection={generalFinalRank} FinalCollectionFun ={setGeneralFinalRank}/>:
            <CollectionComponent name={general}  responseId={resProdId} brands={generalIds}  resflag={resProdFlag} brandFun={setGeneralIds}  brandNameFun={setGeneralNames} />}
          </Card>

        </div>

      </div>
      

    </div>
  )
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProductForm);