import Select from "react-select";

const SELECT_STYLES = {
  container: (base) => ({ ...base, flex: 1 }),
};

function SelectProductType({
  productTypes = [],
  value,
  onChange = (f) => f,
  isDisabled = false,
}) {
  const findProductTypeById = (id) => productTypes.find((it) => it.id === id);

  const productOptions = productTypes.map(createOption);
  const selectedProductType = createOption(findProductTypeById(value));

  return (
    <Select
      key={`select-product-type-${value ?? ""}`}
      value={selectedProductType}
      onChange={onChange}
      options={productOptions}
      styles={SELECT_STYLES}
      isDisabled={isDisabled}
    />
  );
}

function createOption(productType) {
  if (!productType) return null;

  const { id, description } = productType;
  return { key: id, value: id, label: description?.name ?? "" };
}

export default SelectProductType;
