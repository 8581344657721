import React, { Component } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import _ from "lodash";
import { uploadFile } from "../../services/fileUploadService";

class SocialMediaLinksTable extends Component {
  constructor(props) {
    super(props);

    const { socialMediaLinks = [], onSocialMediaLinksUpdated = (f) => f } =
      this.props;

    this.onSocialMediaLinksUpdated = onSocialMediaLinksUpdated;

    this.state = {
      socialMediaLinks,
      socialMediaLink: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [
      // {
      //   name: "",
      //   cell: () => <DragIcon className="drag-handle"></DragIcon>,
      //   width: "50px",
      // },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Icon",
        selector: "image",
        maxWidth: "150px",
        cell: (row) => this.renderIcon(row),
      },
      {
        name: "Social Link",
        selector: "socialLink",
        sortable: true,
        wrap: true,
        minWidth: "150px",
      },
      // {
      //   name: "Rank",
      //   selector: "rank",
      //   maxWidth: "100px",
      //   center: true,
      //   sortable: "true",
      // },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => this.renderActionBar(row),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.socialMediaLinks !== this.state.socialMediaLinks) {
      this.setState({ socialMediaLinks: this.props.socialMediaLinks });
    }
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      kuwaUSP: {},
    });
  };

  handleImageFileSelect = async (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      if (imageFile && imageFile.size && imageFile.size > 204800) {
        const errors = { ...this.state.errros };
        errors.socialMediaIcon = "Icon should be less than 200 Kb!";
        this.setState({ errors });
        return;
      } else {
        const errors = { ...this.state.errros };
        if (errors.socialMediaIcon === "Icon should be less than 200 Kb!")
          errors.socialMediaIcon = "";
        this.setState({ errors });
      }

      try {
        this.showLoading();
        const { data: uploadDetails } = await uploadFile(imageFile);
        const socialMediaLink = {
          ...this.state.socialMediaLink,
          image: uploadDetails?.s3Url ?? "",
        };
        this.setState({ socialMediaLink });
      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );
        this.showErrorNotification(
          "Error on uploading image file! " + error.message ?? ""
        );
      } finally {
        this.hideLoading();
      }
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const socialMediaLink = { ...this.state.socialMediaLink };
    socialMediaLink[input.name] = input.value;
    this.setState({ socialMediaLink });
  };

  handleAddNew = () => {
    const socialMediaLink = {};
    this.setState({ socialMediaLink }, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
    this.clearForm();
    const socialMediaLinks = this.state.socialMediaLinks ?? [];
    const socialMediaLink = socialMediaLinks.find((it) => it.id === id);
    if (!socialMediaLink) return;

    this.setState({ socialMediaLink }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleDelete = (id) => this.deleteSocialMediaLink(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { socialMediaLink = {} } = this.state;
    if (socialMediaLink.id === null || socialMediaLink.id === undefined)
      this.createSocialMediaLink();
    else this.updateSocialMediaLink(socialMediaLink.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { image: icon, socialLink } = this.state.socialMediaLink ?? {};
    if (!socialLink || socialLink.trim() === "")
      errors.socialLink = "Social link is a required field!";
    else if (socialLink.length > 200)
      errors.socialLink = "Social link is limited to a 200 characters!";

    if (!icon) errors.socialMediaIcon = "Icon is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createSocialMediaLink = () => {
    const { socialMediaLinks = [], socialMediaLink } = this.state;
    const ids =
      socialMediaLinks.length > 0 ? socialMediaLinks.map((it) => it.id) : null;
    socialMediaLink.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      socialMediaLinks.length > 0
        ? socialMediaLinks.filter((it) => it.rank).map((it) => it.rank)
        : null;
    socialMediaLink.rank = ranks ? Math.max(...ranks) + 1 : 1;
    socialMediaLink.isActive = true;
    socialMediaLink.is_new = true;

    this.setState(
      { socialMediaLinks: [...socialMediaLinks, socialMediaLink] },
      () => {
        this.showSuccessNotification("Social Media Link Created Successfully!");
        this.onSocialMediaLinksUpdatedLocally();
      }
    );
  };

  updateSocialMediaLink = (id) => {
    const { socialMediaLinks = [], socialMediaLink } = this.state;
    const updatedSocialMediaLinks = socialMediaLinks.map((it) =>
      it.id === id ? socialMediaLink : it
    );
    this.setState({ socialMediaLinks: updatedSocialMediaLinks }, () => {
      this.showSuccessNotification("Social Media Link Updated Successfully!");
      this.onSocialMediaLinksUpdatedLocally();
    });
  };

  deleteSocialMediaLink = (id) => {
    const { socialMediaLinks = [] } = this.state;
    const socialMediaLinkToDelete =
      socialMediaLinks.find((it) => it.id === id) || {};
    if (!socialMediaLinkToDelete) {
      console.log(
        "deleteSocialMediaLink:: Social media link not found with id=",
        id
      );
      this.showErrorNotification("Social media link not found!");
      return;
    }

    socialMediaLinkToDelete.isActive = false;
    const updatedSocialMediaLinks = socialMediaLinks
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > socialMediaLinkToDelete.rank
          ? { ...it, rank: it.rank - 1 }
          : it
      );
    this.setState({ socialMediaLinks: updatedSocialMediaLinks }, () => {
      this.showSuccessNotification("Social Media Link Deleted Successfully!");
      this.onSocialMediaLinksUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { socialMediaLinks = [], sortColumn, sortDirection } = this.state;
    const sortedSocialMediaLinks = _.sortBy(
      socialMediaLinks,
      sortColumn,
      sortDirection
    );
    const fromSocialMediaLink = { ...sortedSocialMediaLinks[fromIndex] };
    const toSocialMediaLink = { ...sortedSocialMediaLinks[toIndex] };

    if (fromSocialMediaLink && toSocialMediaLink) {
      const tempRank = fromSocialMediaLink.rank;
      fromSocialMediaLink.rank = toSocialMediaLink.rank;
      toSocialMediaLink.rank = tempRank;

      const updatedSocialMediaLinks = socialMediaLinks.map((it) => {
        if (it.id === fromSocialMediaLink.id) return fromSocialMediaLink;
        else if (it.id === toSocialMediaLink.id) return toSocialMediaLink;
        else return it;
      });
      this.setState({ socialMediaLinks: updatedSocialMediaLinks }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onSocialMediaLinksUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onSocialMediaLinksUpdatedLocally = () => {
    this.clearForm();
    this.onSocialMediaLinksUpdated(
      this.state.socialMediaLinks.map((it) => {
        return { ...it };
      })
    );
  };

  renderIcon = (socialMediaLink) => (
    <img
      src={socialMediaLink.image ?? ""}
      className="form-image"
      alt="Points Icon"
      // width={100}
      height={50}
    />
  );

  renderActionBar = (socialMediaLink) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu
        popperConfig={{
          strategy: "fixed",
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
        }}
      >
        <ActionBar.Item onClick={() => this.handleEdit(socialMediaLink.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ kuwaUSPToDelete: socialMediaLink.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    return this.state.socialMediaLink.id ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-text"
      >
        <EditIcon />
      </div>
    ) : (
      ""
    );
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      socialMediaLinks = [],
      socialMediaLink = {},
      editables,
      errors,
      sortColumn,
    } = this.state;
    const isNewSocialMediaLink = !socialMediaLink.id;

    const form = (
      <>
        <div className="row mt-2">
          <div className="form-group col-6">
            <label htmlFor="socialMediaIcon">Icon*</label>
            <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={this.handleImageFileSelect}
                name="socialMediaIcon"
                id="socialMediaIcon"
                className="form-control"
                disabled={!isNewSocialMediaLink && !editables.socialMediaIcon}
              />
              {this.renderPencilIcon("socialMediaIcon")}
            </div>
            <span className="help-block">{errors.socialMediaIcon}</span>
          </div>
          {socialMediaLink?.image && (
            <div className="form-group col-6">
              <label htmlFor="selectedSocialMediaIcon">Selected Icon</label>
              <div>
                <img
                  id="selectedSocialMediaIcon"
                  name="selectedSocialMediaIcon"
                  src={socialMediaLink.image}
                  alt="Selected"
                  className="img-thumbnail mx-auto"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="form-group col">
            <label htmlFor="socialLink">Social Link*</label>
            <div className="input-group">
              <input
                type="text"
                value={socialMediaLink?.socialLink ?? ""}
                onChange={(event) => {
                  this.setState({
                    socialMediaLink: {
                      ...socialMediaLink,
                      socialLink: event.target.value,
                    },
                  });
                }}
                placeholder="Social Link"
                id="socialLink"
                name="socialLink"
                className="form-control"
                disabled={!isNewSocialMediaLink && !editables.socialLink}
              />
              {this.renderPencilIcon("socialLink")}
            </div>
            <span className="help-block">{errors.socialLink}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              {/* <ReactDragListView {...this.dragProps}> */}
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={socialMediaLinks ?? []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  // sortIcon={<ArrowDownward></ArrowDownward>}
                />
              {/* </ReactDragListView> */}
              <button
                onClick={this.handleAddNew}
                className="greenbackground"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {socialMediaLink.id === null || socialMediaLink.id === undefined
                ? "Add Social Media Link"
                : "Edit Social Media Link"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              type="button" style={{borderRadius:"20px"}}
              onClick={this.hideAddEditModal}
              className="btn btn-sm btn-outline-secondary px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.handleSubmit}
              className="greenbackground"
            >
              {socialMediaLink.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Kuwa USP</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteSocialMediaLink(this.state.kuwaUSPToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialMediaLinksTable);
