import React from "react";
import { Card } from "react-bootstrap";

function OrderNotesCard(props) {
  const { notes, onEdit } = props;

  const orderNotesView = notes ? (
    <p>{notes}</p>
  ) : (
    <span className="text-muted">No notes from customer</span>
  );

  return (
    <Card style={{ background: "#FFFFFF" }}>
      <Card.Body>
        <div className="row">
          <div className="col">
            <p className="h6">Notes</p>
          </div>
          <div className="col text-end">
            <span
              onClick={onEdit}
              style={{ cursor: "pointer", color: "#2D9BF0" }}
            >
              Edit
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">{orderNotesView}</div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default OrderNotesCard;
