import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import { connect } from "react-redux";
 function VariantPrice(props) {
    const { id, name,productId,countryid, variantid, variantdet,variantPrices, variantpriceFun, allVariants,allVarPrices } = props;

    const [variantname, setVariantname] = useState();
    const [variantList, setVariantList] = useState();
    const [variantDiscount, setVariantDiscount] = useState();
    const [variantFinal, setVariantFinal] = useState();
    const[variantPrice,setVarinatPrice]=useState({});
    const[variantDet,setVariantDet]=useState([]);
    const[finalVariantPrice,setFinalVariantPrice]=useState([]);
    const[editDetails,setEditDetails]=useState();
    const[editFlag,setEditFlag]=useState(false);
    const[validationError,setValidationError]=useState({});
    console.log("variantPrices",variantPrices);
    console.log("in VariantPrice", variantdet);
    console.log("allvariantprices",allVarPrices);

    useEffect(()=>{
        const urlstr =  `/api/v1/product/${productId}?country=1`;
        API.get(urlstr).then((res)=>res.data).then((data)=>data.newVariants).then((variant)=>setVariantDet(variant.map((vardet)=>vardet.variants).filter((det)=>det.id==variantid)));

    },[])
    useEffect(()=>{
        if(allVarPrices.length>0)
    setFinalVariantPrice(allVarPrices.filter((finalVariant)=>finalVariant.pricing
    .variantId==variantid ));
    },[allVarPrices])
    const handleVariantname = (e) => {
        setVariantname(e.target.value);
    }
    const handleVariantDisc = (e) => {
        setVariantDiscount(e.target.value);
    }
    const handleVariantFinal = (e) => {
        setVariantFinal(e.target.value);
    }

    const handleVariantList = (e) => {
        setVariantList(e.target.value);
    }
    const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
      return emptyval;
      }

    const validateAll=(name,Discount,Final,List)=>{
        const validationError={}
         if(isEmpty(name))
         validationError.name="variant name is required field";
         if(isEmpty(Discount))
         validationError.discount="Discount is required field";
         if(isEmpty(Final))
         validationError.final="Final is required field";
         if(isEmpty(List))
            validationError.list ="List is required field";        
       return validationError;
    }
   
    const handleSave = (e) => {
       const error=validateAll(variantname,variantDiscount,variantFinal,variantList);
       setValidationError(error);
       if(Object.keys(error).length==0){
       setEditDetails( {"variants":{"name": variantDet[0].name,
    "quantity": variantDet[0].quantity,
    "image": variantDet[0].image,
    "isActive":true,
    "price": 234,
    "productId": productId,
    "createdAt": "1997-03-04T00:00:00",
    "modifiedAt": "1997-09-09T00:00:00"},


"pricings":
  finalVariantPrice.map((price)=>{if(price.pricing.countryId==countryid)
    return(
    {"id":price.pricing.id,
    "countryId":price.pricing.countryId,
    "retailPrice":parseInt(variantList),
    "finalPrice":parseInt(variantFinal),
    "discount":parseInt(variantDiscount),
    "variantId":variantid})
    else {
        return(
            {"id":price.pricing.id,
            "countryId":price.pricing.countryId,
            "retailPrice":price.pricing.retailPrice,
            "finalPrice":price.pricing.finalPrice,
            "discount":price.pricing.discount,
            "variantId":variantid})
    }
}

)});

setEditFlag(true);
       }
    else{
        showErrorNotification("Some fields are Invalid ");
    }
}

useEffect(()=>{
    if(editFlag && editDetails){
        try{
        API.patch(`/module/variants/${variantid}`,editDetails).then((res)=>console.log(res));
        }
        catch(error)
        {
            console.log("error",error);
        }
      setEditFlag(false);  
    }

},[editDetails])

const showSuccessNotification = (notificationMessage) =>
showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});
    

useEffect(() => {
        if (props) {

            setVariantname(name);
            console.log("in if", name);
            setVariantList(variantdet?.retailPrice ?? 0);
            setVariantDiscount(variantdet?.discount ?? 0);
            setVariantFinal(variantdet?.finalPrice ?? 0);
        }
    }, [props])
    return (
        <div className="alignrow variantpriceblock">

      <div className="columnalignactive"><div className="group"> <label for="name">Variant name</label>    <input type="number" placeholder="variant name" value={variantname} onChange={handleVariantname} /></div> 
      <span className="help-block">{validationError.name}</span> 
      </div>
      <div className="columnalignactive">    <div className="group"> <label for="name">List</label> <input type="number" style={{ width: "7em" }} value={variantList} onChange={handleVariantList} placeholder="list price" /></div>
      <span className="help-block">{validationError.list}</span> 
</div>
<div className="columnalignactive">         <div className="group"><label for="name">Discount</label>
                <input type="number" style={{ width: "7em" }} value={variantDiscount} onChange={handleVariantDisc} placeholder="discount price" />
            </div>
            <span className="help-block">{validationError.discount}</span> 
            </div>
            <div className="columnalignactive"><div className="group"><label for="name">Final</label>
                <input type="number" style={{ width: "7em" }} value={variantFinal} onChange={handleVariantFinal} placeholder="final price" />
            </div>
            <span className="help-block">{validationError.discount}</span></div>
            <div><input className="donebtn" type="button" value="Save" onClick={handleSave} /></div>
        </div>
    )
}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(VariantPrice);
