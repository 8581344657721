import React, { useState, useEffect, useCallback } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import API from "../../utils/API";
import { Link } from "react-router-dom";
export function AllCountries(props) {
  const [countries, setCountries] = useState([]);
  const [selectRowId, setSelectRowId] = useState();

  const columns = [
    {
      name: "Country Id",
      selector: "id",
      sortable: true,
      sortField: 'id',
      wrap:true,

    },
    {
      name: "Country",
      selector: "name",
      sortable: true,
      sortField: 'country',
      wrap:true

    },
    {
      name: "Country Code",
      selector: "code",
      sortable: true,
      sortField: 'country',
      wrap:true,

    },
    {
      name: "VAT",
      selector: "vat",
      sortable: true,
      sortField: 'country',
      maxWidth:"50px",

    },
    {
      name: "Time Zone",
      selector: "timezone",
      sortable: true,
      sortField: 'country',
      wrap:true,

    },

    {
      name: "Status",
      selector: "supported",
      sortable: true,
      cell: (country) => { return (country.supported ? "Active" : "Inactive") },
      sortField: 'country',
      wrap:true,

    },
    {
      name: "Delivary Fee",
      selector: "deliveryFee",
      sortable: true,
      sortField: 'country',
      wrap:true,
    },
    {
      name: " Min Threshold",
      selector: "minThreshold",
      sortable: true,
      sortField: 'country',
      wrap:true,

    }
  ]
  useEffect(() => {
    try {
      API.get("/api/v1/country").then((res) => res.data).then((data) => setCountries(data));
      console.log("data",countries)
    }
    catch (error) {
      console.log("error is", error);
    }
  }, []);
  const handlerowselect = (row) => {
    console.log("row", row.id);
    // setShowproduct(row.id);
    props.history.push(`/dashboard/country/view/${row.id}`);
  }
  const handleChangeSelect = useCallback((state) => {

    //console.log("when click on checkbox", typeof (state.selectedRows), state.selectedRows.map((prod) => prod.productid));
    // setEditRow(true);
    const countryids = state.selectedRows.map((ctry) => ctry.id)
    //console.log(prodids);
    setSelectRowId(countryids[0]);
  }, [])
  // useEffect(() => {
  //   if (selectRowId)
  //     //props.history.push(`/dashboard/products/Edit/${selectRowId}`);
  //     props.history.push(`/dashboard/country/edit/${selectRowId}`)
  // }, [selectRowId]);



  return (
    <div>
      <div className="rightextreme">
        <div>
          <Link to={"/dashboard/country/create"}> <button className="greenbackground" >Add Country</button></Link>
        </div>
      </div>
      <br /><br />
      <Card body>
        <DataTable
          highlightOnHover
          columns={

            columns

          }
          data={countries}

          onRowClicked={handlerowselect}

          responsive
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer', 
              },
            },
          }}




        />

      </Card>
    </div>
  )




}



