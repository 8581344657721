import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import DeleteIcon from "../../../../assets/img/delete.png";
import './reviews.scss';

const updateArray = (array, index, fieldName, value) => {
    const newArray = array.map((item, i) => {
        if (i === index) {
            return { ...item, [fieldName]: value };
        }
        return item;
    });

    // Now newArray contains the original array with the updated value at the specified index.
    return newArray;
};
export default function Reviews({ reviewData = [], setReviewData, reviewError, avgRating, totalRating, setAvgRating, setTotalRating, countryData = [] }) {

    const onAddNewReview = () => {
        let newReviewData = {
            customerName: "",
            headline: "",
            rating: "",
            review: "",
            status: true,
            customerNameArabic: "",
            headlineArabic: "",
            reviewArabic: "",
            countryId:"",
        }
        setReviewData([...reviewData, newReviewData])
    }


    const onChangeReviewValue = ({ fieldName, value, index }) => {
        const updatedArray = updateArray(reviewData, index, fieldName, value);
        setReviewData(updatedArray);
    }

    const onDeleteFaq = (index) => {
        const filterItem = reviewData.filter((_, i) => i !== index);
        setReviewData(filterItem)
    }



    return (
        <div className="reviews-wrapper">
            <Card body>
                <div>
                    <div className="product-rating-section">
                        <b>Product Rating</b>
                        <div className="columnalignactive">
                            <div className="group">
                                <label for="name" className="label-title-txt">Average Rating (Out of 5)</label>
                                <input type="number" value={avgRating} onChange={(e) => setAvgRating(parseFloat(e.target.value))} />
                            </div>
                        </div>
                        <div className="columnalignactive">
                            <div className="group">
                                <label for="name" className="label-title-txt">Total Customer</label>
                                <input type="number" value={totalRating} onChange={(e) => setTotalRating(parseFloat(e.target.value))} />
                            </div>
                        </div>
                    </div>


                    <div className="reviews-container">
                        <label className="label-txt">Reviews</label>
                        <div className="action-btn" onClick={onAddNewReview}>+ Add Review</div>
                    </div>
                    {
                        reviewData.map((data, index) => {
                            return (
                                <>
                                    <div className="reviews-container-section">
                                        <div className="review-row">
                                            <div className="columnalign">
                                                <div className="alignrow">
                                                    <div className="columnalignactive">
                                                        <b>Select Rating</b>
                                                        <select value={data.rating || ""} onChange={(e) => {
                                                            onChangeReviewValue({
                                                                fieldName: "rating",
                                                                value: e.target.value,
                                                                index: index
                                                            })
                                                        }}>
                                                            <option default>Select Rating</option>
                                                            {[1, 2, 3, 4, 5].map((val) => <option value={val}>{val}</option>)}
                                                        </select>
                                                    </div>

                                                    <div className="columnalignactive">
                                                        <b>Select Country</b>
                                                        <select  value={data.countryId } onChange={(e)=>onChangeReviewValue({
                                        fieldName:"countryId",
                                        value:e.target.value,
                                        index:index
                                    })}
                                                        >
                                                         <option default>Select Country</option>
                                        {countryData.map((ctry) => <option value={ctry.id}>{ctry.name}</option>)}
                                                        </select>
                                                    </div>

                                                   

                                                    <div className="group status">
                                                        <div className="label-title-txt">Active</div>
                                                        <input type="checkbox" checked={data.status} onChange={() => {
                                                            onChangeReviewValue({
                                                                fieldName: "status",
                                                                value: !data.status,
                                                                index: index
                                                            })
                                                        }} class="checkbox-round" />
                                                    </div>

                                                    <div className="group action" onClick={() => onDeleteFaq(index)}>
                                                        <img src={DeleteIcon} alt="delete-icon" />
                                                    </div>
                                                </div>
                                            
                                            <div className="alignrow">
                                                <div>
                                                    <div className="alignrow">
                                                        <div className="columnalignactive">
                                                            <div className="group">
                                                                <label for="name" className="label-title-txt">Customer Name</label>
                                                                <input type="text" value={data.customerName || ""} onChange={(e) => {
                                                                    onChangeReviewValue({
                                                                        fieldName: "customerName",
                                                                        value: e.target.value,
                                                                        index: index
                                                                    })
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <div className="columnalignactive">
                                                            <div className="group">
                                                                <label for="name" className="label-title-txt">Headline</label>
                                                                <input type="text" value={data.headline || ""} style={{ width: "100%" }} onChange={(e) => {
                                                                    onChangeReviewValue({
                                                                        fieldName: "headline",
                                                                        value: e.target.value,
                                                                        index: index
                                                                    })
                                                                }} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="alignrow">
                                                        <div className="columnalignactive" style={{ width: '100%', marginTop: "10px" }}>
                                                            <div className="group">
                                                                <label for="name" className="label-title-txt">Review</label>
                                                                <textarea style={{ width: "100%" }} rows="10" value={data.review || ""} onChange={(e) => {
                                                                    onChangeReviewValue({
                                                                        fieldName: "review",
                                                                        value: e.target.value,
                                                                        index: index
                                                                    })
                                                                }} ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div>
                                        <div className="alignrow">
                                        <div className="columnalignactive"> 
                                            <div className="group">
                                                <label for="name" className="label-title-txt">Customer (Arabic)*</label> 
                                                <input type="text" value={data.customerNameArabic || ""} className="ar-inputfieldsize" onChange={(e)=>{
                                                onChangeReviewValue({
                                                    fieldName:"customerNameArabic",
                                                    value:e.target.value,
                                                    index:index
                                                })
                                            }} />
                                            </div>
                                        </div>
                                        <div className="columnalignactive">  
                                            <div className="group">
                                                <label for="name" className="label-title-txt">Headline (Arabic)*</label> 
                                                <input type="text" value={data.headlineArabic || ""} style={{width:"100%"}} className="ar-inputfieldsize" onChange={(e)=>{
                                                onChangeReviewValue({
                                                    fieldName:"headlineArabic",
                                                    value:e.target.value,
                                                    index:index
                                                })
                                            }} />
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div className="alignrow">
                                        <div className="columnalignactive" style={{width:'100%',marginTop:"10px"}}>
                                            <div className="group">
                                                <label for="name" className="label-title-txt">Review (Arabic)*</label> 
                                                <textarea style={{ width: "100%",height:"5rem" }} rows="10" value={data.reviewArabic|| ""} className="ar-inputfieldsize" onChange={(e)=>{
                                                onChangeReviewValue({
                                                    fieldName:"reviewArabic",
                                                    value:e.target.value,
                                                    index:index
                                                })
                                            }} ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                        </div> */}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    {reviewError && reviewError[index] && <span style={{ fontSize: '12px', color: 'red', marginTop: '10px' }} >{reviewError[index]}</span>}
                                </>
                            )
                        })
                    }

                </div>
            </Card>
        </div>
    )
}



