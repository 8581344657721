import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import SelectCountry from "../../components/select/SelectCountry";
import PhoneInput from "react-phone-input-2";

function ManageAddressViewModal(props) {
 
  const {
    onHide = (f) => f,
    onSave = (f) => f,
    isVisible = false,
  } = props;
  const numberCheck = async (value, data, event, formattedValue) => {
    if (value.slice(data.dialCode.length).length > 6) {
      const contact = await (data.dialCode + value.slice(data.dialCode.length));
      setState({ address: { ...address, mobNumber: contact } });
    } else {
      // setPhone("");
    }
  }
  const [state, setState] = useState("")
  const [address, setAddress] = useState({});
  const [billAddress,setBillAddress] = useState({})
  const [errors, setErrors] = useState({});
  const [showBillingAddress, setShowBillingAddress] = useState(false);

  function handleChange({ currentTarget: input }) {
    console.log("currentTarget",input)
    setAddress((address) => ({ ...address, [input.name]: input.value }));
    validateField(input.name, input.value)

  }

  function handleOnChange({ currentTarget: input }){
    setBillAddress((billAddress) => ({ ...billAddress, [input.name]: input.value }));
    validateField(input.name, input.value)
    console.log("biii0",input.value)
  }
  
  function handleDropdownChange(name, value) {
    setAddress((address) => ({ ...address, [name]: value }));
    validateField(name, value)
  }

 function billHandleDropdownChange(name, value) {
  setBillAddress((billAddress) => ({ ...billAddress, [name]: value }));
  validateField(name, value)
 }
  const validateField = (name, value) => {
    const errorsCopy = { ...errors };
    switch (name) {
      case "countryCode":
        errorsCopy.countryCode = value ? "" : "Country Code is required.";
        break;
      case "firstName":
        errorsCopy.firstName = value ? "" : "First Name is required.";
        break;
      case "lastName":
        errorsCopy.lastName = value ? "" : "Last Name is required.";
        break;
      case "address":
        errorsCopy.address = value ? "" : "Address is required.";
        break;
      case "apartment":
        errorsCopy.apartment = value ? "" : "Apartment is required.";
        break;
        case "city":
          errorsCopy.city = value ? "" : "City is required.";
          break;
          case "postalCode":
            errorsCopy.postalCode = value ? "" : "Postal Code is required.";
            break;
      case "stateProvince":
        errorsCopy.stateProvince = value ? "" : "State/Province is required.";
        break;
      default:
        break;
    }
    setErrors(errorsCopy);
  };

  function handleSave() {
    if (validateAllFields(address)) {
      onSave( address,billAddress);
    }
  }
  function toggleBillingAddressForm(event) {
    setShowBillingAddress(event.target.checked);
    setBillAddress({...address,sameAddressForBilling:event.target.checked})
    
  }
 
 
  function validateAllFields(event) {
    const {countryCode,firstName,lastName,address,apartment,stateProvince,city,postalCode} = event;
    const errors = {};

    if (!countryCode) {
      errors.countryCode = "Country Code is required.";
  }
  if (!firstName) {
      errors.firstName = "First Name is required.";
    }
  if (!lastName) {
      errors.lastName = "Last Name is required.";
    }
  if (!address) {
      errors.address = "Address is required.";
    }
    if (!apartment) {
      errors.apartment = "Apartment is required.";
    }
    if (!city) {
      errors.city = "City is required.";
    }
    if (!postalCode) {
      errors.postalCode = "Postal Code is required.";
    }
    if (!stateProvince) {
      errors.stateProvince = "State/Province is required.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const formAddress = (
      (
        <>
          <div className="row">
            <div>
              <h5>Shipping Address</h5>
            </div>
            <div className="form-group col">
              <label htmlFor="countryCode">Country/region*</label>
              <SelectCountry
                value={address.countryCode}
                onChange={(option) =>
                  handleDropdownChange("countryCode", option.value)

                }
                id="countryCode"
                name="countryCode"

              />
              {errors.countryCode && (

                <div style={{ color: 'red', fontSize: '12px' }} >{errors.countryCode}</div>
              )}

            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="firstName" name="firstName">First Name*</label>
              <input
                value={address.firstName ?? ""}
                onChange={handleChange}
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First name"
                className={`form-control ${errors.firstName ? "is-invalid" : ""
                  }`}
              />
              {errors.firstName && (
                <div className="invalid-feedback">{errors.firstName}</div>
              )}
            </div>
            <div className="form-group col">
              <label htmlFor="lastName">Last Name*</label>
              <input
                value={address.lastName ?? ""}
                onChange={handleChange}
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last name"
                className={`form-control ${errors.lastName ? "is-invalid" : ""
                  }`}
              />
              {errors.lastName && (
                <div className="invalid-feedback">{errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="address">Area name, Colony*</label>
              <input
                value={address.address ?? ""}
                onChange={handleChange}
                type="text"
                id="address"
                name="address"
                placeholder="Area name, Colony"
                className={`form-control ${errors.address ? "is-invalid" : ""
                  }`}
              />
              {errors.address && (
                <div className="invalid-feedback">{errors.address}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="apartment">Appartment name, Floor, Room no, City*</label>
              <input
                value={address.apartment ?? ""}
                onChange={handleChange}
                type="text"
                id="apartment"
                name="apartment"
                placeholder="Appartment name, Floor, Room no, City"
                className={`form-control ${errors.apartment ? "is-invalid" : ""
                  }`}
              />
              {errors.apartment && (
                <div className="invalid-feedback">{errors.apartment}</div>
              )}

            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="stateProvince">City*</label>

              <input
                required

                value={address.city ?? ""}

                onChange={handleChange}
                type="text"
                id="city"
                name="city"
                placeholder="City"
                className={`form-control ${errors.city ? "is-invalid" : ""}`}
              />
              {errors.city && (
                <div className="invalid-feedback">{errors.city}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="stateProvince">Postal Code*</label>

              <input
                required

                value={address.postalCode ?? ""}

                onChange={handleChange}
                type="text"
                id="postalCode"
                name="postalCode"
                placeholder="Postal Code"
                className={`form-control ${errors.postalCode ? "is-invalid" : ""}`}
              />
              {errors.postalCode && (
                <div className="invalid-feedback">{errors.postalCode}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="stateProvince">State Province*</label>

              <input
                required

                value={address.stateProvince ?? ""}

                onChange={handleChange}
                type="text"
                id="stateProvince"
                name="stateProvince"
                placeholder="State Province"
                className={`form-control ${errors.stateProvince ? "is-invalid" : ""}`}
              />
              {errors.stateProvince && (
                <div className="invalid-feedback">{errors.stateProvince}</div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="form-group col">
              <label htmlFor="phone">Phone</label>

              <PhoneInput
                inputClass="font-poppins"
                country={address?.countryCode?.toLowerCase() || "ae"}
                value={address.mobNumber || ""}
                placeholder={"Enter Phone Number"}
                onChange={(value, data, event, formattedValue) => {
                  numberCheck(value, data, event, formattedValue);
                }}
              />

            </div>
            <div className="form-group col">
              <Form.Check
                 label="Use this same address for billing"
                type="checkbox"
                id=""
                onChange={toggleBillingAddressForm}
              />
            </div>
          </div>
          {showBillingAddress?"":(
            (
              <>
              <br></br>
               <h5>Billing Address</h5>
                <div className="row">
                  <div className="form-group col">
                    <label htmlFor="countryCode">Country/region*</label>
                    <SelectCountry
                      value={billAddress.countryCode}
                      onChange={(option) =>
                        billHandleDropdownChange("countryCode", option.value)
        
                      }
                      id="countryCode"
                      name="countryCode"
        
                    />
                    {errors.countryCode && (
        
                      <div style={{ color: 'red', fontSize: '12px' }} >{errors.countryCode}</div>
                    )}
        
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col">
                    <label htmlFor="firstName" name="firstName">First Name*</label>
                    <input
                      value={billAddress.firstName ?? ""}
                      onChange={handleOnChange}
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First name"
                      className={`form-control ${errors.firstName ? "is-invalid" : ""
                        }`}
                    />
                    {errors.firstName && (
                      <div className="invalid-feedback">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="form-group col">
                    <label htmlFor="lastName">Last Name*</label>
                    <input
                      value={billAddress.lastName ?? ""}
                      onChange={handleOnChange}
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last name"
                      className={`form-control ${errors.lastName ? "is-invalid" : ""
                        }`}
                    />
                    {errors.lastName && (
                      <div className="invalid-feedback">{errors.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col">
                    <label htmlFor="address">Area name, Colony*</label>
                    <input
                      value={billAddress.address ?? ""}
                      onChange={handleOnChange}
                      type="text"
                      id="address"
                      name="address"
                      placeholder="Area name, Colony"
                      className={`form-control ${errors.address ? "is-invalid" : ""
                        }`}
                    />
                    {errors.address && (
                      <div className="invalid-feedback">{errors.address}</div>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col">
                    <label htmlFor="apartment">Appartment name, Floor, Room no, City*</label>
                    <input
                      value={billAddress.apartment ?? ""}
                      onChange={handleOnChange}
                      type="text"
                      id="apartment"
                      name="apartment"
                      placeholder="Appartment name, Floor, Room no, City"
                      className={`form-control ${errors.apartment ? "is-invalid" : ""
                        }`}
                    />
                    {errors.apartment && (
                      <div className="invalid-feedback">{errors.apartment}</div>
                    )}
        
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col">
                    <label htmlFor="stateProvince">City*</label>
        
                    <input
                      required
        
                      value={billAddress.city ?? ""}
        
                      onChange={handleOnChange}
                      type="text"
                      id="city"
                      name="city"
                      placeholder="City"
                      className={`form-control ${errors.city ? "is-invalid" : ""}`}
                    />
                    {errors.city && (
                      <div className="invalid-feedback">{errors.city}</div>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col">
                    <label htmlFor="stateProvince">Postal Code*</label>
        
                    <input
                      required
        
                      value={billAddress.postalCode ?? ""}
        
                      onChange={handleOnChange}
                      type="text"
                      id="postalCode"
                      name="postalCode"
                      placeholder="Postal Code"
                      className={`form-control ${errors.postalCode ? "is-invalid" : ""}`}
                    />
                    {errors.postalCode && (
                      <div className="invalid-feedback">{errors.postalCode}</div>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col">
                    <label htmlFor="stateProvince">State Province*</label>
        
                    <input
                      required
        
                      value={billAddress.stateProvince ?? ""}
        
                      onChange={handleOnChange}
                      type="text"
                      id="stateProvince"
                      name="stateProvince"
                      placeholder="State Province"
                      className={`form-control ${errors.stateProvince ? "is-invalid" : ""}`}
                    />
                    {errors.stateProvince && (
                      <div className="invalid-feedback">{errors.stateProvince}</div>
                    )}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="form-group col">
                    <label htmlFor="phone">Phone</label>
        
                    <PhoneInput
                      inputClass="font-poppins"
                      country={billAddress?.countryCode?.toLowerCase() || "ae"}
                      value={billAddress.mobNumber || ""}
                      placeholder={"Enter Phone Number"}
                      onChange={(value, data, event, formattedValue) => {
                        numberCheck(value, data, event, formattedValue);
                      }}
                    />
                  </div>
                </div>
              </>)
          )}
        </>
        
        
      )
      
  );

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Add Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>{formAddress}</Modal.Body>
      <Modal.Footer>
        <button style={{background:"#247A81",borderRadius:"20px"}}   onClick={handleSave} type="button" className="btn btn-sm btn-success" >
        Save Address
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ManageAddressViewModal;
