import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import API from "../../utils/API";

const DISCOUNT_TYPE_OPTIONS = [
  {
    key: "AMOUNT",
    value: "AMOUNT",
    label: "Amount",
  },
  {
    key: "PERCENTAGE",
    value: "PERCENTAGE",
    label: "Percentage",
  },
  {
    key: "COUPON",
    value: "COUPON",
    label: "Coupon",
  },
];
const SELECT_STYLES = {
  container: (base) => ({ ...base, flex: 1 }),
};

function EditDiscountViewModal(props) {
  console.log("discountProps",props)
  const {
    customer,
    isVisible = false,
    selectedCountry,
    cartDetails,
    discountDetails: details,
    onHide = (f) => f,
    onSave = (f) => f,
    onRemove = (f) => f,
  } = props;
  
  console.log("cartDetailsbdjsbjsbj",cartDetails)
  const currency = selectedCountry?.currency || ""
  const [discountDetails, setDiscountDetails] = useState(details ?? {});
  const [validationErrors, setValidationErrors] = useState({});
  const CustomerId = customer?.id
  const deviceId = customer?.newBillingAddress[0]?.deviceId  || null
console.log("deviceId",deviceId)
  useEffect(() => {
    setDiscountDetails(details ?? {});
  }, [isVisible, details]);

  function handleChange({ target: input }) {
    setDiscountDetails((value) => ({ ...value, [input.name]: input.value }));
  }

  function findDiscountTypeOption(value) {
    if (!value) return null;
    return DISCOUNT_TYPE_OPTIONS.find(
      (it) => it.value.toLowerCase() === value.toLowerCase()
    );
  }

  function validateDiscountType() {
    if (!discountDetails.discountType) {
      setValidationErrors((errors) => ({
        ...errors,
        discountType: "Please select a discount type.",
      }));
      return false;
    }
    return true;
  }

  function validateDiscountValue() {
    if (discountDetails.discountType !== "COUPON" && !discountDetails.discountValue) {
      setValidationErrors((errors) => ({
        ...errors,
        discountValue: "Discount value is required.",
      }));
      return false;
    }
    return true;
  }

  // useEffect(()=>{
  //   handleSave();
  // },[cartDetails,details,isVisible])
  async function handleSave(event) {
    setValidationErrors({});
    const isDiscountTypeValid = validateDiscountType();
    const isDiscountValueValid = validateDiscountValue();

    if (isDiscountTypeValid && isDiscountValueValid){
    if (discountDetails.discountType === "COUPON"){
      if(!customer){
        setValidationErrors({ customer: "Please select the customer." });
      }
      else{
      try {
        showLoading();
        const payload = { couponCode: discountDetails.couponCode ,userId:CustomerId,country:selectedCountry.id,};
        let products = [];
        if(cartDetails.products && cartDetails.products.length > 0){
          cartDetails?.products?.map((data)=>{
            if(data.variants && data.variants.pricings.length > 0 ){
              products.push({
                "id": data.id,
                "isVariant":true,
                "variantId": data.variants.variants.id,
                "quantity": data.quantity,
              })
            }
            else{
              products.push({
                "id": data.id,
                "isVariant":false,
                "quantity": data.quantity,
              })
            }
          })
        }
        payload.products = products;
        // payload.products = cartDetails.products.map((it) => ({
        //   if(it.variants){

        //     id: it.id,
        //     quantity: it.quantity,
        //   }
         
        // }));
        
        const {data} = await API.post("/apply-coupon-cart", payload, );
        // Currently coupons are not applying so added this to test with fake data.
        // data.discount = 10;
        // data.couponId = 8;
        const { couponApplied, discount, couponId } = data;
       
        if (discount > 0) {
          const updatedDiscountDetails = {
            ...discountDetails,
            discountValue: discount,
            couponId,
          };
          setDiscountDetails((value) => ({
            ...value,
            discountValue: discount,
          }));
          onSave(updatedDiscountDetails);
        } else {
          showErrorNotification(couponApplied);
        }
      } catch (error) {
        console.log("applyCoupon:: Error on applying coupon!", error);
        setValidationErrors({ couponError: error?.response?.data?.cashbackMsg});
      } finally {
        hideLoading();
      
      }
    }
   }
    else {
      onSave(discountDetails);
      // this.showSuccessNotification("discount added successfully")
    }
  }
  else{
 
  }
  }

  function showLoading() {
    props.toggleLoading({ isLoading: true });
  }

  function hideLoading() {
    props.toggleLoading({ isLoading: false });
  }

  function showSuccessNotification(notificationMessage) {
    showNotification(notificationMessage, true);
  }

  function showErrorNotification(notificationMessage) {
    showNotification(notificationMessage, false);
  }

  function showNotification(notificationMessage, isSuccessMessage) {
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  }

  const title = "Add discount";
  const form = (
    <>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="discountType">Discount type *</label>
          <Select
            value={findDiscountTypeOption(discountDetails?.discountType)}
            onChange={(option) =>
              setDiscountDetails((value) => ({
                ...value,
                discountType: option.value,
                couponCode: null,
              }))
            }
            options={DISCOUNT_TYPE_OPTIONS}
            styles={SELECT_STYLES}
          />
        </div>
        {discountDetails.discountType === "COUPON" ? (
          <div className="form-grup col">
            <label htmlFor="couponCode">Coupon</label>
            <input
              value={discountDetails?.couponCode ?? ""}
              onChange={handleChange}
              type="text"
              name="couponCode"
              id="couponCode"
              className="form-control py-2"
            />
          </div>
        ) : (
          <div className="form-grup col">
            <label htmlFor="discountValue">Discount value *</label>
            <div className="input-group">
              <span class="input-group-text">{currency}</span>
              <input
                value={discountDetails?.discountValue ?? ""}
                onChange={handleChange}
                type="number"
                name="discountValue"
                id="discountValue"
                className="form-control py-2"
              />
            </div>
          </div>
        )}
      </div>
      {discountDetails.discountType === "COUPON" && (
        <div className="row mt-2">
          <div className="form-grup col-6">
            <label htmlFor="discountValue">Discount value *</label>
            <div className="input-group">
              <span class="input-group-text">{currency}</span>
              <input
                value={discountDetails?.discountValue ?? ""}
                type="number"
                name="discountValue"
                id="discountValue"
                disabled={true}
                className="form-control py-2"
              />
            </div>
          </div>
        </div>
      )}
      <div className="row mt-2">
        <div className="form-group col">
          <label htmlFor="discountReason">Reason</label>
          <input
            value={discountDetails?.discountReason ?? ""}
            onChange={handleChange}
            type="text"
            name="discountReason"
            id="discountReason"
            className="form-control py-2"
          />
          <span id="discountReasonHelp" class="form-text text-muted">
            Your customer can see this reason.
          </span>
        </div>
      </div>
    </>
  );

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{form}
      {validationErrors.customer && (
          <div className="alert alert-danger mt-2">
            {validationErrors.customer}
          </div>
        )}
         {validationErrors.discountType && (
          <div className="alert alert-danger mt-2">
            {validationErrors.discountType}
          </div>
        )}
        {validationErrors.discountValue && (
          <div className="alert alert-danger mt-2">
            {validationErrors.discountValue}
          </div>
        )}
          {validationErrors.couponError && (
          <div className="alert alert-danger mt-2">
            {validationErrors.couponError}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {details && (
          <button
            onClick={onRemove}
            type="button"
            className="btn btn-sm btn-danger me-auto"
          >
            Remove discount
          </button>
        )}
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}} 
          onClick={handleSave}
          type="button"
          className="btn btn-sm btn-success ml-2"
        >
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDiscountViewModal);
