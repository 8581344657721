import React, { useState, useEffect } from 'react'
import { clearToken } from '../../services/tokenService';
import { Link } from "react-router-dom";
import API from "../../utils/API";
import { setAccessToken } from "../../services/tokenService";
import { connect } from "react-redux";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from "@mui/icons-material/Visibility";

function Login(props) {
  const [email, setEmail] = useState("");
  const [login, setLogin] = useState(false);
  const [userdetails, setUserdetails] = useState({});
  const [password, setPassword] = useState();
  const [token, setToken] = useState();
  const[adminUserId,setAdminUserId] = useState("")
  const [loginsuccess, setLoginSuccesss] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const[validationError,setValidationError]=useState({});
  const[postrequestError,setPostRequestError]=useState(false);
  const[reqErrorMsg,setReqErrorMsg]=useState();
  const handleemail = (e) => {
    console.log("email", e.target.value);
    setEmail(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,"email":"email is required field"});
    }
    else{
      setValidationError({...validationError,"email":""});
    }
  }

  const showSuccessNotification = (notificationMessage) =>
showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});

const togglePasswordVisibility = () => {
  setPasswordVisible(!passwordVisible);
};
const ErrorShow=(error)=>{
  console.log("show notification",error);
}

  useEffect(() => {
    if (login && userdetails) {
      try {
        console.log(userdetails);
        console.log("formfill", login);

        API.post("/api/v1/private/login", userdetails).then((response) => response.data).then((data) => { setToken(data.token); setAdminUserId(data.id); showSuccessNotification("success login"); });

      }
      catch (error) {
        console.log("login ", error);
         setPostRequestError(true);
         ErrorShow(error);
        showErrorNotification("Error on  create account " + error.message ?? "")
      }
    }
    setLogin(false);
  }, [userdetails])


  useEffect(() => {
    if (token != "undefined" && token != null &&adminUserId ) {
      console.log(token);
      setAccessToken(token,adminUserId);

      props.history.push("/dashboard/home");
    }
  }, [token])

  const handlelogin = (e) => {
   const validate=validateAll(email,password) ;
   setValidationError(validate);
    if(Object.keys(validate).length==0){
    setLogin(true);
    setUserdetails({
      "password": password,
      "username": email
    })
  }
  else{
    showErrorNotification("Invalid fields");
  }
  }
  const handlepassword = (e) => {
    setPassword(e.target.value);
    if(isEmpty(e.target.value))

    {
      setValidationError({...validationError,password:"password is required"});
    }
    else{
      setValidationError({...validationError,password:""});
    }
  }
  const isEmpty = (value) => {
    let emptyval=false;
   if(typeof(value) ==="undefined")
    emptyval=true;
   else if(value === "")
   emptyval=true;
   else if( value === null )
   emptyval=true;
   else if(value=="undefined")
   emptyval=true;
  return emptyval;
  }

  const validateAll=(email,password)=>{
    const error={}
    var capitalPattern = /[A-Z]/;
    var numberPattern = /[0-9]/;
    var specialCharPattern = /[!@#$%^&*()]/;
    
    // Test the password against the patterns
    var hasCapital = capitalPattern.test(password);
    var hasNumber = numberPattern.test(password);
    var hasSpecialChar = specialCharPattern.test(password);
    if( !(hasCapital && hasNumber && hasSpecialChar))
    {
        error.password="Password should be one capital,one number,one special character"
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var emailVerified=emailRegex.test(email);
    if(!emailVerified)
    {
      error.email="Email is not valid ";
    }
    if(isEmpty(email))
    {
      error.email="Email is required field";
    }
    if(isEmpty(password))
    {
      error.password="Password is required field";
    }
    return error;
  }


  return (
    <div className="shopifysignin ">

      <div className="shopifysignup">
        <div className="Namestyle"><b>Kuwa Admin</b> </div>
        <div> <div className="createsen">Log in</div>
          <div className="subhead sent">Continue to Kuwa Admin </div>

        </div>

        <div className="columnalignactive"> <div className="lablestyle">Email *</div>
          <div> <input className="inputpadding" size="30" onChange={handleemail} required="required" type="email" /></div>
          <br />
          <span className="help-block"  >{validationError.email}</span>
        </div>
        <div className="columnalignactive">
          <div>Password *</div>
          <div className="alignrowpassword borderparent"> <input className="passwordBorder  inputpadding noFocusOutline" size="30" type={passwordVisible ? 'text' : 'password'} required="required" onChange={handlepassword} />
          <div className="passwordRight"> {passwordVisible ? (
            <Visibility onClick={togglePasswordVisibility}
        />
      ) : (
        <VisibilityOff onClick={togglePasswordVisibility}/>
      )}
      </div>

          </div>
          <span className="help-block"  >{validationError.password}</span>
        </div>
        <div>
          <button className="emailcontinue Emailinput" onClick={handlelogin}>Log in</button>
        </div>

      </div>
    </div>
    
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);