import React, { Component } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import SelectProductType from "./SelectProductType";
import _ from "lodash";

class MenuTableBrands extends Component {
  constructor(props) {
    super(props);

    const { brands = [], onBrandsUpdated = (f) => f } = props;
    this.onBrandsUpdated = onBrandsUpdated;

    this.state = {
      brands,
      brand: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [
      {
        name: "",
        cell: () => <DragIcon className="drag-handle"></DragIcon>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Brand",
        selector: "healthGoal",
        sortable: true,
        wrap: true,
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => this.renderActionBar(row),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.brands !== this.state.brands) {
      this.setState({ brands: this.props.brands });
    }
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      brand: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const brand = { ...this.state.brand };
    brand[input.name] = input.value;
    this.setState({ brand });
  };

  handleAddNew = () => {
    const brand = {};
    this.setState({ brand }, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
    this.clearForm();
    const brands = this.state.brands ?? [];
    const brand = brands.find((it) => it.id === id);
    if (!brand) return;

    this.setState({ brand }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleDelete = (id) => this.deleteBrand(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { brand = {} } = this.state;
    if (brand.id === null || brand.id === undefined) this.createBrand();
    else this.updateBrand(brand.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { healthGoalId } = this.state.brand ?? {};
    if (!healthGoalId) errors.healthGoalId = "Brand is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createBrand = () => {
    const { brands = [], brand } = this.state;
    const ids = brands.length > 0 ? brands.map((it) => it.id) : null;
    brand.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      brands.length > 0
        ? brands.filter((it) => it.rank).map((it) => it.rank)
        : null;
    brand.rank = ranks ? Math.max(...ranks) + 1 : 1;
    brand.isActive = true;
    brand.is_new = true;

    this.setState({ brands: [...brands, brand] }, () => {
      this.showSuccessNotification("Brand Created Successfully!");
      this.onBrandsUpdatedLocally();
    });
  };

  updateBrand = (id) => {
    const { brands = [], brand } = this.state;
    const updatedBrands = brands.map((it) => (it.id === id ? brand : it));
    this.setState({ brands: updatedBrands }, () => {
      this.showSuccessNotification("Brand Updated Successfully!");
      this.onBrandsUpdatedLocally();
    });
  };

  deleteBrand = (id) => {
    const { brands = [] } = this.state;
    const brandToDelete = brands.find((it) => it.id === id) || {};
    if (!brandToDelete) {
      console.log("deleteBrand:: Brand not found with id=", id);
      this.showErrorNotification("Brand not found!");
      return;
    }

    brandToDelete.isActive = false;
    const updatedBrands = brands
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > brandToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ brands: updatedBrands }, () => {
      this.showSuccessNotification("Brand Deleted Successfully!");
      this.onBrandsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { brands = [], sortColumn, sortDirection } = this.state;
    const sortedBrands = _.sortBy(brands, sortColumn, sortDirection);
    const fromBrand = { ...sortedBrands[fromIndex] };
    const toBrand = { ...sortedBrands[toIndex] };

    if (fromBrand && toBrand) {
      const tempRank = fromBrand.rank;
      fromBrand.rank = toBrand.rank;
      toBrand.rank = tempRank;

      const updatedBrands = brands.map((it) => {
        if (it.id === fromBrand.id) return fromBrand;
        else if (it.id === toBrand.id) return toBrand;
        else return it;
      });
      this.setState({ brands: updatedBrands }, () => {
        this.showSuccessNotification("Brand Updated Successfully!");
        this.onBrandsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onBrandsUpdatedLocally = () => {
    this.clearForm();
    this.onBrandsUpdated(this.state.brands.map((item) => ({ ...item })));
  };

  findProductTypeById = (id) => {
    const productTypes = this.props.productTypes ?? [];
    return productTypes.find((it) => it.id === id);
  };

  renderActionBar = (brand) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu
        popperConfig={{
          strategy: "fixed",
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
        }}
      >
        <ActionBar.Item onClick={() => this.handleEdit(brand.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ brandToDelete: brand.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    return this.state.brand.id ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-text"
      >
        <EditIcon />
      </div>
    ) : (
      ""
    );
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      brands = [],
      brand = {},
      editables,
      errors,
      sortColumn,
    } = this.state;
    const isNewBrand = !brand.id;

    brands.forEach((it) => {
      it.healthGoal =
        this.findProductTypeById(it.healthGoalId)?.description?.name ?? "";
    });

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="healthGoalId">Brand*</label>
            <div className="input-group">
              <SelectProductType
                id="healthGoalId"
                productTypes={this.props.productTypes ?? []}
                value={brand.healthGoalId}
                onChange={(value) =>
                  this.setState({
                    brand: {
                      ...brand,
                      healthGoalId: value.value,
                    },
                  })
                }
                isDisabled={!isNewBrand && !editables.healthGoalId}
                className={
                  "form-control" + (errors.healthGoalId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("healthGoalId")}
            </div>
            <span className="help-block">{errors.healthGoalId}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container px-0">
        <div className="row">
          <div className="form-group col">
            <p className="m-2 h6">Brands</p>
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={brands ?? []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  // sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNew}
                className="greenbackground"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {brand.id === null || brand.id === undefined
                ? "Add Brand"
                : "Edit Brand"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              type="button" style={{borderRadius:"20px"}}
              onClick={this.hideAddEditModal}
              className="btn btn-sm btn-outline-secondary px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.handleSubmit}
              className="greenbackground"
            >
              {brand.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteBrand(this.state.brandToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuTableBrands);
