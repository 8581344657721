import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

function SearchBox({ onSearch, onKeyPress  }) {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <div className="input-group mb-3">
      <input
        type="text"
        value={searchTerm ?? ""}
        onChange={(e) => setSearchTerm(e.target.value)}
        id="search-bar"
        name="search-bar"
        className="form-control"
        placeholder="Search..."
        onKeyPress={(e)=> onKeyPress(e,searchTerm)} 
      />
      <i
        className="input-group-text"
        onClick={(e) => onSearch(searchTerm)}
        style={{ cursor: "pointer" }}
      >
        <SearchIcon />
      </i>
    </div>
  );
}

export default SearchBox;
