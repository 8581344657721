import React ,{useState}from "react";



export function RenderInputbox(props){


    const  {quantvalue,onQuantityChangeEdit,FinalProd} =props;
    const [quant,setQuant]=useState('');

    return(
        <input className="datatablenumber" type="number" size="20" value={quantvalue??0} onBlur={(event)=>FinalProd()}  onChange={(event) =>onQuantityChangeEdit(event.target.value)}/>

    )
}