import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import LinkActionButton from "../../components/LinkActionButton";
import { Link } from "react-router-dom";
import TableConfig from "../../utils/tableConfig";
import API from "../../utils/API";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { formatDateTime } from "../../utils/common";
import Search from '../../components/Search/Search';
const BASE_URL_CUSTOMERS = "/module/search/paginated/customers";


class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      sortField:"id",
      sortDirection:"desc",
      number:0,
      recordsTotal:0,
      recordsFiltered:50,
      totalPages:0,
      searchValue:"",
      clearSearch:"false",
      countries:[],
    };
    this.columns = [
      {
        name: "Customer ID",
        selector:(row)=>row.id,
        wrap: true,
        sortField: "id",
        sortable: true,
        width:"150px"
      },
      {
        name: "Customer",
        selector: "customer_name",
        wrap: true,
        center:true,
        width:"200px",
        // sortable: true,
        // sortField: "customer_name",
        format: (customer) => this.getCustomerName(customer),
      },
      {
        name:"Email",
        selector:"emailAddress",
        wrap: true,
        sortable: true,
        center:true,
        width:"150px",
        sortField: "email",
        Width:"150px"
      },
      {
        name: "Country",
        selector: "country",
        // sortable: true,
        center:true,
        sortField: "country",
        minWidth: "110px",
        cell: (row) => {
          const countryData = this.state.countries?.find((data) => data.id === row.country);
          return countryData ? countryData.name : ""; 
        },
      },

      {
        name: "Email subscription",
        selector: "email_subscription",    
        // wrap: true,
        center:true,
        width:"200px",
        // sortable: true,  
        // sortField: "email_subscription",
        format: (customer) => this.renderEmailSubscriptionStatus(customer),
      },
      {
        name: "Orders",
        selector: "noOfOrder",
        wrap: true,
        center:true,
        maxWidth:"50px"
        // sortable: true,
        // sortField: "orders",
      },
      {
        name: "Joining Date",
        selector: (row) => formatDateTime(row.joiningDate),
        wrap: true,
        center:true,
        width:"200px"
        // sortable: true,
        // sortField: "joining_date",
      },
    ];
  }

  componentDidMount() {
    this.fetchCustomers();
    API.get("api/v1/country").then((res)=>res.data).then(data =>this.setState({countries:data}))
  }

  fetchCustomers = async () => {
    try {
      this.showLoading();
      const { data } = await API.get(BASE_URL_CUSTOMERS, {
        params:{
          key:this.state.searchValue,
          search_by:"",
          page:this.state.number,
          sort_order:this.state.sortDirection,
          sort_by:this.state.sortField,
          count:this.state.recordsFiltered 

        },
      });
      console.log("data",data)
      const { customers = [],  recordsTotal,totalPages } = data;
     
      
      this.setState({ customers,recordsTotal,totalPages });
    } catch (error) {
      console.log("fetchCustomers:: Error on fetching customers!", error);
      this.showErrorNotification(
        "Error on fetching customers." + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };
  handleSort=(column,sortDirection)=>{
    this.setState(
      {
         sortField: column.sortField ,
        sortDirection: sortDirection,
      },
       () => this.fetchCustomers()
    )
  }
  handlePageChange = (number) => {
    this.setState({number:number-1},()=>{
      this.fetchCustomers();
    })
  };

  handleRowsPerPageChange = (recordsFiltered) => {
    this.setState({recordsFiltered:recordsFiltered},()=> this.fetchCustomers())
  };


  getCustomerName = (customer) => {
    if (customer) {
      const { firstName, lastName } = customer;
      return `${firstName ?? ""} ${lastName ?? ""}`.trim();
    }
    return "";
  };
  handleSearch = (val)=>{
    this.setState({ searchValue: val });
    if(!val){
      this.fetchCustomers();
    }
  }
  onSearchBtn=()=>{
    this.fetchCustomers();
  }
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.fetchCustomers();
    }
}
  clearSearch = ()=>{
    this.setState({ searchValue: "",clearSearch:true },()=>{
      this.fetchCustomers();
    })
  }


  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  renderEmailSubscriptionStatus = (customer) => {
   
    if(customer.emailSubscription){
      
      return "subscribed"
    }
    else{
      return "unsubcribed"
    }
    return "";
  };
  

  render() {
    const {recordsTotal,totalPages,recordsFiltered,number } = this.state;
    const customers = this.state.customers ?? [];

   

    return (
      <div className="manage-package">
         <div>
        <div className="rightextreme" style={{marginBottom:20}}>
          <div className="action-container">
            <div>
              <Search
                  searchExpanded={true}
                  searchTerm={this.state.searchValue}
                  handleSearch={this.handleSearch}
                  clearSearch={this.clearSearch}
                  handleSearchExit={()=>{}}
                  onKeyPress={this.handleKeyPress}
                ></Search>
            </div>
            <div className="search-btn" onClick={this.onSearchBtn}>
              Search
            </div>
            <div className="page-header">
          <div className="actions" style={{marginBottom:0}}>
            <div>
            <Link to={`/dashboard/customers/new/`}> <button className="greenbackground" >Add Customer</button></Link>
          </div>
          </div>
        </div>
          </div>
        </div>
      </div>
        <Card body>
          <DataTable
            data={customers}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownwardIcon/>}
            sortServer
            onSort={this.handleSort}
            pagination
            paginationServer
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            onRowClicked={(row, event) => {
              this.props.history.push(`/dashboard/customers/${row.id}`);
            }}
            paginationPerPage={recordsFiltered}
            paginationTotalRows={recordsTotal}
            paginationServerOptions={{
              totalPages:totalPages,
              totalRecords:recordsTotal,
              number:number
            }}
            paginationRowsPerPageOptions={TableConfig.ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            responsive
            customStyles={{
              rows: {
                style: {
                  cursor: 'pointer', 
                },
              },
            }}
          />
          
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
