import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import { Uploadfile } from "./Uploadfile";
import { connect } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
 function CreateVariant(props) {



  const [variantname, setvariantname] = useState();
  const [variantquantity, setvariantquantity] = useState();
  const [variantstatus, setvariantstatus] = useState(true);
  const [variantdetails, setvariantdetails] = useState();
  const [change, setchange] = useState(false);
  const [imageurlvar, setImageUrlvar] = useState();
  const [donestatus, setdonestatus] = useState(false);
  const [imageres, setImageres] = useState({});
  const [imageurlflag, setImageurlflag] = useState(false);
  const [imagefileupload, setImageFileUpload] = useState();
  const[validationError,setValidationError]=useState({});
  //const [labelFlag, setLabelflag] = useState(true);
  const[ allFieldsVerify,setAllFieldsVerify]=useState(false);
  const[variantEditModalPopup,setVariantEditModalPopup]=useState(false);
 const[variantImageCreate,setVariantImageCreate]=useState();
  const[temporaryVariant,setTemporaryVariantImage]=useState([]);
  const[borderImageIds,setBorderImageIds]=useState([]);
  const[variantFlag,setVariantFlag]=useState(false);

  useEffect(() => {
    

      console.log("checking in create if");
      try {
        if (change && variantdetails) {
        if (props.mode == "Create") {
          console.log("create Review", variantdetails);
          props.variants([...props.variant, variantdetails]);
        }
        if (props.mode == "EditCreate") {
          console.log("check in edit create",variantdetails);
          API.post("/module/variants", variantdetails).then((res) => console.log(res));
        }
        props.addfun(false);
      }
      }
      catch (error) {
        console.log("error", error);
      }

     
    
      
    setchange(false);
  }, [variantdetails])

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });

  useEffect(() => {
    const formData = new FormData();
    formData.append("file", imagefileupload);
    const config = {
      method: "POST",
      url: "/s3/file/upload",
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    }
    try {
      API.request(config).then(res => setImageres(res));

    }
    catch (error) {
      console.log("when handling image", error);
    }
  }, [imagefileupload])

  useEffect(() => {
    if (imageres.data) {
      const uploadDetails = imageres?.data;
      console.log("upload", uploadDetails);
      setImageUrlvar(uploadDetails?.s3Url ?? "");
      //setLabelflag(false);
      console.log(uploadDetails?.s3Url);
    }

    setImageurlflag(true);

  }, [imageres])

  const handleImage = (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      console.log("component", imageFile);
      try {
        // this.showLoading();
        setImageFileUpload(imageFile);


      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );

      }
    }
  };

  const handleEditSelect=(img,id)=>{
    console.log("ids for select",img,id,borderImageIds);
    if(temporaryVariant.includes(img)){
      const images=temporaryVariant.filter((image)=>img!==image)
    setTemporaryVariantImage(images);
    }
    else{

   setTemporaryVariantImage([...temporaryVariant,img]);
    }
   if(borderImageIds.includes(id)){
    const selectedImageIds=borderImageIds.filter((imageId)=>imageId!==id);
    setBorderImageIds(selectedImageIds);
   }
    
   else{
   setBorderImageIds([...borderImageIds,id]);
   }
  }
  
  const handleVariantEditSaveImages=()=>{
    setImageUrlvar(temporaryVariant[0]);
  }

  const variantEditForm=
  
  (<> 
    <div className="gridalign">
      { props.mode=="Create"?props.allImages.map((img,index)=><div onClick={()=>handleEditSelect(img,index)}><img className="imageshowsizeborder" style={(borderImageIds.includes(index)?{border:'1px solid blue'}:{border:'1px solid black'})} src={img}   /><Button  className="aligniconborder"  onClick={ ()=>handleDeleteVariantImage(img)}> <HighlightOffIcon  fontSize="small"    /> </Button> </div> ):<></>}
      </div>
      <div><input type="button" className="newvaraintbutton"  value="Save" onClick={handleVariantEditSaveImages}/></div>
   
    </>)
  const handleDeleteVariantImage=(img)=>{
    const temp=temporaryVariant.filter((image)=>img!==image);
    setTemporaryVariantImage(temp);
  }



  const handlevariantname = (e) => {
    setvariantname(e.target.value);
    if(isEmpty(e.target.value))
    setValidationError({...validationError,variantname:"variant name is required"});
    else{
      setValidationError({...validationError,variantname:""}); 
    }
  }
  const handlevariantquantity = (e) => {
    setvariantquantity(e.target.value);
    if(isEmpty(e.target.value))
    setValidationError({...validationError,quantity:"variant quantity is required"});
    else{
      setValidationError({...validationError,quantity:""}); 
    }
  }
  const handlevariantstatus = (e) => {
    if(e.target.checked){
    setvariantstatus(true);
    setValidationError({...validationError,status:""});
    }
    else{
      
    setvariantstatus(false);
    setValidationError({...validationError,status:"status should active"});
    }
  }
 const isEmpty = (value) => {
  let emptyval=false;
 if(typeof(value) ==="undefined")
  emptyval=true;
 else if(value === "")
 emptyval=true;
 else if( value === null )
 emptyval=true;
return emptyval;
}

  const validateFields=(variantname,quantity,status)=>{
     const validationError={};
    console.log("dddd",variantname)
    if(isEmpty(variantname)){
    validationError.variantname="VariantName is a Mandatory field";
    console.log("checking name")
    }
    if (isEmpty(quantity))
    validationError.quantity="Quantity is a required field!";
else if(Number.isNaN(Number(quantity)))
  validationError.quantity="Invalid quantity Value! (not a valid number)";
else if(quantity>10 || quantity<1)
validationError.quantity="Variant quantity between 1-10"; 
    

if(!(status))
   validationError.status="status should active";

   

   return validationError; 
   
  }

  
     
const handlevariantdetails = (e) => {
    console.log("in create edit mode");
    const validate=validateFields(variantname,variantquantity,variantstatus);
    setValidationError(validate);
  console.log("varint error",validationError.quantity);
   if(Object.keys(validate).length ===0){
    if (props.mode == "EditCreate") {
      setvariantdetails(
       { "variants" :{
          "name": variantname,
          "quantity": parseInt(variantquantity),
          "price":243.0,
          "image": imageurlvar,
           "isActive":variantstatus,
          "productId": parseInt(props.prodid),
          "createdAt": "1997-03-04T00:00:00",
          "modifiedAt": "1997-09-09T00:00:00"
        },
        "pricings":[],

      }
      );

    }
    else if (props.mode == "Create") {
      setvariantdetails(
        {
          "name": variantname,
          "quantity": parseInt(variantquantity),
           "isActive":variantstatus,
          "image": imageurlvar,
           "price":243.0,
          "productId": "",
          "createdAt": "1997-03-04T00:00:00",
          "modifiedAt": "1997-09-09T00:00:00"
        }
      );
    }
    showSuccessNotification("Variant saved locally");
    setchange(true);
    setVariantFlag(true);
  }
    // addvariant(false);
  }

const handleImageEdit=()=>{
  setVariantEditModalPopup(true);
}
  const hideVariantEditModal=()=>{
    setVariantEditModalPopup(false);
  }
  useEffect(()=>{
   if(props.mode=="Create")
   setImageUrlvar(props.imgname);
   if(props.mode=="EditCreate")
   setImageUrlvar(props.imgname);
  },[props])
  

  return (
    <div className="variantstyle alignrow">

      <div className="alignrow">

        {props.mode == "Create" ?
          <div> {props.id}</div> : <></>}
          <div className="">
        
          {/* <input type="file" name="uploadfile"  onChange={(handleImage)} id="imgd" style={{display:'none'}}  />
    <label> + Imagedss</label> */}
           
        {props.mode=="Create"?<><div><img className="imagevariantsize" src={imageurlvar}  /></div> <div className="alignrow"> <EditIcon onClick={()=>handleImageEdit()} fontSize="small"/>
        
        </div>
        <Modal
           size="lg"
          show={variantEditModalPopup}
          onHide={hideVariantEditModal}
        >
            <Modal.Header closeButton>
                <Modal.Title className="aligncenter">
                    <div>Variant Image</div> 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{variantEditForm}</Modal.Body>


        </Modal>
        
        </>
          :<img className="imagevariantsize" src={imageurlvar} />}
        
        <span className="help-block">{validationError.image}</span>
        </div>
        <div className="columnalignactive">  
        <div className="group"><label for="name">Variant Name*</label>
          <input type="number" onChange={handlevariantname} style={{ width: "20em" }} placeholder="Variant Name" required/> </div>
        <span className="help-block">{validationError.variantname}</span>
        </div>
        <div className="columnalignactive"> 
        <div className="group"><label for="name">Quantity*</label>
         <input type="number" style={{ width: "7em" }} onChange={handlevariantquantity} placeholder="Quantity"  required/>  </div> 
        <span className="help-block">{validationError.quantity}</span>
         </div>
        <div className="columnalignactive"><div> Active </div> <div><input type="checkbox" value={variantstatus} onChange={handlevariantstatus} checked={variantstatus} /></div></div>
        <div className="columnalignactive"><input className="donebtn" type="button" onClick={handlevariantdetails} value="save" disabled={variantFlag}/>
        
        </div>
      </div>

    </div>


  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVariant);