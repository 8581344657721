import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import SubscriptionForm from "../../../../components/SubscriptionForm";


export default function Subscription({subscriptionData=[] ,setSubscriptionData={}, subscriptionStatus="",setSubscriptionStatus={},subscriptionError={}})
{
  
    return (
            <div>
              <Card body>
                            <div className="subscription-wrapper">  
                            
                              <div><label className="header-txt">Subscription</label></div><br/>
                            <div className="alignrow">
                            <div className="label-txt">Enable Subscription</div>    
                          <input type="checkbox" checked={subscriptionStatus}  onChange={(e)=>setSubscriptionStatus(!subscriptionStatus)}   />
                            </div><br/>
                            <div >
                           {subscriptionStatus ? <SubscriptionForm subscriptionData={subscriptionData} setSubscriptionData={setSubscriptionData} isEditable={!subscriptionStatus}
                                       
                            />:<></>}
                            </div>
                            
                            </div>
                            {Object.keys(subscriptionError).length>0 && <span style={{fontSize:'12px',color:'red',marginTop:'10px'}} >{subscriptionError.subscriptionError}</span>}
                            </Card>
                            
                            </div>
                           
        )
}

