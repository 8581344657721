import React, { Component } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import SelectProductType from "./SelectProductType";
import _ from "lodash";
import { uploadFile } from "../../services/fileUploadService";

class MenuTableCategories extends Component {
  constructor(props) {
    super(props);

    const { categories = [], onCategoriesUpdated = (f) => f } = props;
    this.onCategoriesUpdated = onCategoriesUpdated;

    this.state = {
      categories,
      category: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [
      {
        name: "",
        cell: () => <DragIcon className="drag-handle"></DragIcon>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Icon",
        selector: "icon",
        maxWidth: "150px",
        cell: (row) => this.renderIcon(row),
      },
      {
        name: "Category",
        selector: "categoryName",
        sortable: true,
        wrap: true,
        minWidth: "120px"
      },
      {
        name: "Sub Text",
        selector: "subText",
        sortable: true,
        wrap: true,
        minWidth: "120px"
      },
      {
        name: "Sub Text (Arabic)",
        selector: "subTextArabic",
        sortable: true,
        wrap: true,
        minWidth: "150px"
      },
      {
        name: "Redirection Link",
        selector: "redirectionLink",
        sortable: true,
        wrap: true,
        minWidth: "180px"
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => this.renderActionBar(row),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.categories !== this.state.categories) {
      this.setState({ categories: this.props.categories });
    }
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      category: {},
    });
  };

  handleImageFileSelect = async (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      if (imageFile && imageFile.size && imageFile.size > 204800) {
        const errors = { ...this.state.errros };
        errors.categoryIcon = "Icon should be less than 200 Kb!";
        this.setState({ errors });
        return;
      } else {
        const errors = { ...this.state.errros };
        if (errors.categoryIcon === "Icon should be less than 200 Kb!")
          errors.categoryIcon = "";
        this.setState({ errors });
      }

      try {
        this.showLoading();
        const { data: uploadDetails } = await uploadFile(imageFile);
        const category = {
          ...this.state.category,
          icon: uploadDetails?.s3Url ?? "",
        };
        this.setState({ category });
      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );
        this.showErrorNotification(
          "Error on uploading image file! " + error.message ?? ""
        );
      } finally {
        this.hideLoading();
      }
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const category = { ...this.state.category };
    category[input.name] = input.value;
    this.setState({ category });
  };

  handleAddNew = () => {
    const category = {};
    this.setState({ category }, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
    this.clearForm();
    const categories = this.state.categories ?? [];
    const category = categories.find((it) => it.id === id);
    if (!category) return;

    this.setState({ category }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleDelete = (id) => this.deleteCategory(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { category = {} } = this.state;
    if (category.id === null || category.id === undefined)
      this.createCategory();
    else this.updateCategory(category.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { categoryId, icon, redirectionLink, subText ,subTextArabic} = this.state.category ?? {};
    if (!categoryId) errors.categoryId = "Category is a required field!";

    if (!icon)
      errors.categoryIcon = "Icon is a required field!";

    if (!redirectionLink || !redirectionLink.trim() === "")
      errors.redirectionLink = "Redirection link is a required field!";

    if (subText && subText.length > 500)
      errors.subText = "Sub text is limited to 500 characters!";
    if (subTextArabic && subTextArabic.length > 500)
      errors.subText = "Sub text Arabic is limited to 500 characters!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createCategory = () => {
    const { categories = [], category } = this.state;
    const ids = categories.length > 0 ? categories.map((it) => it.id) : null;
    category.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      categories.length > 0
        ? categories.filter((it) => it.rank).map((it) => it.rank)
        : null;
    category.rank = ranks ? Math.max(...ranks) + 1 : 1;
    category.isActive = true;
    category.is_new = true;

    this.setState({ categories: [...categories, category] }, () => {
      this.showSuccessNotification("Category Created Successfully!");
      this.onCategoriesUpdatedLocally();
    });
  };

  updateCategory = (id) => {
    const { categories = [], category } = this.state;
    const updatedCategories = categories.map((it) =>
      it.id === id ? category : it
    );
    this.setState({ categories: updatedCategories }, () => {
      this.showSuccessNotification("Category Updated Successfully!");
      this.onCategoriesUpdatedLocally();
    });
  };

  deleteCategory = (id) => {
    const { categories = [] } = this.state;
    const categoryToDelete = categories.find((it) => it.id === id) || {};
    if (!categoryToDelete) {
      console.log("deleteCategory:: Category not found with id=", id);
      this.showErrorNotification("Category not found!");
      return;
    }

    categoryToDelete.isActive = false;
    const updatedCategories = categories
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > categoryToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ categories: updatedCategories }, () => {
      this.showSuccessNotification("Category Deleted Successfully!");
      this.onCategoriesUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { categories = [], sortColumn, sortDirection } = this.state;
    const sortedCategories = _.sortBy(categories, sortColumn, sortDirection);
    const fromCategory = { ...sortedCategories[fromIndex] };
    const toCategory = { ...sortedCategories[toIndex] };

    if (fromCategory && toCategory) {
      const tempRank = fromCategory.rank;
      fromCategory.rank = toCategory.rank;
      toCategory.rank = tempRank;

      const updatedCategories = categories.map((it) => {
        if (it.id === fromCategory.id) return fromCategory;
        else if (it.id === toCategory.id) return toCategory;
        else return it;
      });
      this.setState({ categories: updatedCategories }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onCategoriesUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onCategoriesUpdatedLocally = () => {
    this.clearForm();
    this.onCategoriesUpdated(
      this.state.categories.map((item) => ({ ...item }))
    );
  };

  findProductTypeById = (id) => {
    const productTypes = this.props.productTypes ?? [];
    return productTypes.find((it) => it.id === id);
  };

  renderIcon = (row) => (
    <img
      src={row.icon}
      className="form-image"
      alt="Icon"
      height={50}
    />
  );

  renderActionBar = (category) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu
        popperConfig={{
          strategy: "fixed",
          onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
        }}
      >
        <ActionBar.Item onClick={() => this.handleEdit(category.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ categoryToDelete: category.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    return this.state.category.id ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-text"
      >
        <EditIcon />
      </div>
    ) : (
      ""
    );
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      categories = [],
      category = {},
      editables,
      errors,
      sortColumn,
    } = this.state;
    const isNewCategory = !category.id;

    categories.forEach((it) => {
      it.categoryName =
        this.findProductTypeById(it.categoryId)?.description?.name ?? "";
    });

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="categoryId">Category*</label>
            <div className="input-group">
              <SelectProductType
                id="categoryId"
                productTypes={this.props.productTypes ?? []}
                value={category.categoryId}
                onChange={(value) =>
                  this.setState({
                    category: {
                      ...category,
                      categoryId: value.value,
                    },
                  })
                }
                isDisabled={!isNewCategory && !editables.categoryId}
                className={
                  "form-control" + (errors.categoryId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("categoryId")}
            </div>
            <span className="help-block">{errors.categoryId}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="form-group col-6">
            <label htmlFor="categoryIcon">Icon*</label>
            <div className="input-group">
              <input
                type="file"
                accept="image/*"
                onChange={this.handleImageFileSelect}
                name="categoryIcon"
                id="categoryIcon"
                className="form-control"
                disabled={!isNewCategory && !editables.categoryIcon}
              />
              {this.renderPencilIcon("categoryIcon")}
            </div>
            <span className="help-block">{errors.categoryIcon}</span>
          </div>
          {category?.icon && (
            <div className="form-group col-6">
              <label htmlFor="selectedCategoryIcon">Selected Image</label>
              <div>
                <img
                  id="selectedCategoryIcon"
                  name="selectedCategoryIcon"
                  src={category.icon}
                  alt="Selected Category Icon"
                  className="img-thumbnail mx-auto"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="form-group col">
            <label htmlFor="redirectionLink">Redirection Link*</label>
            <div className="input-group">
              <input
                type="text"
                value={category?.redirectionLink ?? ""}
                onChange={(event) => {
                  this.setState({
                    category: {
                      ...category,
                      redirectionLink: event.target.value,
                    },
                  });
                }}
                placeholder="Redirection Link"
                id="redirectionLink"
                name="redirectionLink"
                className="form-control"
                disabled={!isNewCategory && !editables.redirectionLink}
              />
              {this.renderPencilIcon("redirectionLink")}
            </div>
            <span className="help-block">{errors.redirectionLink}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="form-group col">
            <label htmlFor="subText">Sub Text</label>
            <div className="input-group">
              <input
                type="text"
                value={category?.subText ?? ""}
                onChange={(event) => {
                  this.setState({
                    category: {
                      ...category,
                      subText: event.target.value,
                    },
                  });
                }}
                placeholder="Sub Text"
                id="subText"
                name="subText"
                className="form-control"
                disabled={!isNewCategory && !editables.subText}
              />
              {this.renderPencilIcon("subText")}
            </div>
            <span className="help-block">{errors.subText}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="subText">Sub Text (Arabic)</label>
            <div className="input-group">
              <input
                type="text"
                value={category?.subTextArabic ?? ""}
                onChange={(event) => {
                  this.setState({
                    category: {
                      ...category,
                      subTextArabic: event.target.value,
                    },
                  });
                }}
                placeholder="Sub Text (Arabic)"
                id="subTextArabic"
                name="subTextArabic"
                className="form-control"
                disabled={!isNewCategory && !editables.subTextArabic}
              />
              {this.renderPencilIcon("subTextArabic")}
            </div>
            <span className="help-block">{errors.subTextArabic}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container px-0">
        <div className="row">
          <div className="form-group col">
            <p className="m-2 h6">List Items</p>
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={categories ?? []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  // sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNew}
                className="greenbackground"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {category.id === null || category.id === undefined
                ? "Add Category"
                : "Edit Category"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              type="button" style={{borderRadius:"20px"}}
              onClick={this.hideAddEditModal}
              className="btn btn-sm btn-outline-secondary px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.handleSubmit}
              className="greenbackground"
            >
              {category.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteCategory(this.state.categoryToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuTableCategories);
