
export function clearToken() {
  localStorage.removeItem("tokenfalcon");

}

export function setAccessToken(token,id) {
  localStorage.setItem("tokenfalcon", token);
  localStorage.setItem("adminUserId",id)
}



export function getAccessToken() {
  const token = localStorage.getItem("tokenfalcon");
  console.log(token);
  if (token != "undefined" || token !=null) return token;

  return null;
}



export function hasToken() {
  const token = localStorage.getItem("tokenfalcon");

  if (token) return true;

  return false;
}