import React,{useEffect,useState} from "react";
import { ImageViewComponent } from "./ImageViewComponent";
import { ImageEditComponent } from "./ImageEditComponent";

export function ImageComponentLogic(props){
             const{mode, viewImages, viewImagesFun, imageAdd,imageAddFun}=props;
             const[updatedUrl,setUpdatedUrl]=useState([]);
             const[addUrl,setaddUrl]=useState();
             console.log("in logic",imageAdd);
useEffect(()=>{
    console.log("updatedUrl",updatedUrl)
    if(updatedUrl.length==2){
        const newImages=viewImages.filter((image)=>image !== updatedUrl[0])
        console.log("new images",newImages);
viewImagesFun([...newImages,updatedUrl[1]]);
    }
},[updatedUrl])

useEffect(()=>{
if(addUrl !== "" && addUrl !=="undefined")
{
    viewImagesFun([...viewImages,addUrl]);
}
},[addUrl])

    return(<div className="alignrow" >
       {
       mode=="View" && viewImages && imageAdd ? [
       viewImages.map((image,index)=> <ImageViewComponent id={index} imageurl={image} viewImages={viewImages} viewImagesFun={viewImagesFun}  updatedUrl={updatedUrl} updatedUrlFun={setUpdatedUrl}/>) ,
        <ImageEditComponent mode={"EditCreate"} updatedUrlFun={setaddUrl} imageAdd= {imageAdd} imageAddFun={imageAddFun}/>]
       :mode=="View" && viewImages ?
       viewImages.map((image,index)=> <ImageViewComponent id={index} imageurl={image} viewImages={viewImages} viewImagesFun={viewImagesFun} imageAdd= {imageAdd}  updatedUrl={updatedUrl} updatedUrlFun={setUpdatedUrl}/>)
    :<></>}
    </div> )
}