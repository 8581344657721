import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SelectCountry from "../../components/select/SelectCountry";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";

const countryList = [
  {code:"BH",name:"Bahrain"},
  {code:"SA",name:"Kingdom of Saudi Arabia"},
  {code:"KW",name:"Kuwait"},
  {code:"OM",name:"Oman"},
  {code:"QA",name:"Qatar"},
  {code:"AE",name:"United Arab Emirates"},
  
]

function EditAddressViewModal(props) {
  const {
    address: customerAddress,
    onHide = (f) => f,
    onSave = (f) => f,
    isVisible = false,
    customerData={}
  } = props;

  console.log("shippingAddressshippingAddress",customerData)
 
  const [address, setAddress] = useState(customerAddress ?? {});
  const [ allShippingAddress , setAllShippingAddress ] = useState([]);

  useEffect(
    
    () => setAddress(customerAddress ?? {}),
    [customerAddress, isVisible]
  );

 
  

  console.log("shippingPOpAddress",customerAddress)
  // useEffect(()=>{

  //   if(customerData && customerData.newShippingAddress && customerData.newShippingAddress.length > 0){

  //     const allShippingData = customerData.newShippingAddress.map((data,index)=>{
  //       return(
  //         {
  //           label: data.firstName + " " + data.lastName + " \n " +data.address + " " + data.apartment + " " + data.country,
  //           value:data.id
  //         }
  //       )
  //     })
  //     setAllShippingAddress(allShippingData)
  //   }
  // },[customerData])


  // useEffect(()=>{
  //   getAllShippingAddress()
  // },[])

  // const getAllShippingAddress = async() =>{
  //  const data =  await API.get('api/v1/private/customers');
  // }

  function handleChange({ currentTarget: input }) {
    setAddress((address) => ({ ...address, [input.name]: input.value }));
  }

  function handleDropdownChange(name, value) {
    setAddress((address) => ({ ...address, [name]: value }));
  }

  const numberCheck = async (value, data, event, formattedValue) => { 
    if (value.slice(data.dialCode.length).length > 6) {
      const contact = await (data.dialCode + value.slice(data.dialCode.length));
      console.log("contactcontact",contact)
      setAddress((address) => ({ ...address, ["mobNumber"]: value }));
    } else {
      setAddress((address) => ({ ...address, ["mobNumber"]: value }));
    }
  };
  const formAddress = (
    <>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="country">Country/region</label>
          <SelectCountry
          value={countryList.find(country => country.name === address.country)?.code || ''}
            
            onChange={(option) => handleDropdownChange("country", option.value)}
            id="country"
            name="country"
            isCountryName={true}
            isDisabled={true}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="firstName">First Name</label>
          <input
            value={address.firstName ?? ""}
            onChange={handleChange}
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First name"
            className="form-control"
            // disabled
          />
        </div>
        <div className="form-group col">
          <label htmlFor="lastName">Last Name</label>
          <input
            value={address.lastName ?? ""}
            onChange={handleChange}
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last name"
            className="form-control"
            // disabled
          />
        </div>
      </div>
      {/* <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="company">Company</label>
          <input
            value={address.company ?? ""}
            onChange={handleChange}
            type="text"
            id="company"
            name="company"
            placeholder="Company"
            className="form-control"
          />
        </div>
      </div> */}
      <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="address">Address</label>
          <input
            value={address.address ?? ""}
            onChange={handleChange}
            type="text"
            id="address"
            name="address"
            placeholder="Address"
            className="form-control"
            // disabled
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="apartment">Appartment name, Floor, Room no, City*</label>
          <input
            value={address.apartment ?? ""}
            onChange={handleChange}
            type="text"
            id="apartment"
            name="apartment"
            placeholder="Apartment, suite, etc."
            className="form-control"
            // disabled
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="apartment">City</label>
          <input
            value={address.city ?? ""}
            onChange={handleChange}
            type="text"
            id="city"
            name="city"
            placeholder="City"
            className="form-control"
            // disabled
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="postalCode">Postal Code</label>
          <input
            value={address.postalCode ?? ""}
            onChange={handleChange}
            type="text"
            id="postalCode"
            name="postalCode"
            placeholder="Postal Code"
            className="form-control"
          />
        </div>
        <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="stateProvince">State Province</label>
          <input
            value={address.stateProvince ?? ""}
            onChange={handleChange}
            type="text"
            id="stateProvince"
            name="stateProvince"
            placeholder="stateProvince"
            className="form-control"
            // disabled
          />
        </div>
        </div>
      </div>
      {/* <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="phone">Phone</label>
          <input
            value={address.phone ?? ""}
            onChange={handleChange}
            type="phone"
            id="phone"
            name="phone"
            className="form-control"
          />
        </div>
      </div> */}
        <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="phone">Phone number</label>
          {/* <input
            type="phone"
            value={contactInfo.phone ?? ""}
            onChange={handleChange}
            id="phone"
            name="phone"
            className="form-control"
          /> */}
          <PhoneInput
                    inputClass="font-poppins"
                    country={"ae"}
                    value={address.mobNumber|| ""}
                    placeholder={"Enter Phone Number"}
                    onChange={(value, data, event, formattedValue) => {
                     numberCheck(value, data, event, formattedValue);
                    }}
                    // disabled
                  />
                   {/* {errors.phone && (
            <div className="help-block">{errors.phone}</div>
          )} */}
        </div>
      </div>
    </>
  );

  const modalTitle = `Select Address`;

  const onSelectAddress = (option) =>{

    console.log("addressaddress",address)

    console.log("optionoption",option)
    console.log("allShippingAddress",allShippingAddress)
    console.log("shippingAddressshippingAddress",customerData)
    const getShippingAddress = customerData.newShippingAddress.find((data)=>data.id == option.value);
    setAddress(getShippingAddress)
    console.log("getShippingAddressgetShippingAddress",getShippingAddress)
  }

  console.log("allShippingAddressallShippingAddress",allShippingAddress)

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {/* <div className="select-address-container" style={{width:'30%',marginBottom:'20px'}}>
                    <Select
                    placeholder="Select address"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    oncloseMenuOnSelect={false}
                    value={[]}
                    onChange={onSelectAddress}
                    options={allShippingAddress}
                    />
            </div> */}
        
        {formAddress}
        
      </Modal.Body>
      <Modal.Footer>
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-md btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}}
          onClick={() => onSave(address)}
          type="button"
          className="btn btn-md btn-success ml-2"
        >
          Change
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditAddressViewModal;
