import React,{useState,useEffect}from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import API from "../../src/utils/API";
import { QuestionViewComponent } from "./QuestionViewComponent";
import  QuestionCreateComponent  from "./QuestionCreateComponent";
import QuestionEditComponent from "./QuestionEditComponent";
import { connect } from "react-redux";
function QuestionComponent(props){
    const{id,mode,add,addfun,questions}=props;

    console.log(add);
    console.log("in question comp",id,mode);
    const[faq,setfaq]=useState([]);
    const[faqcount,setfaqcount]=useState();
    const[question,setquestion]=useState();
    const[answer,setanswer]=useState();
    const[faqcreate,setfaqcreate]=useState({});
    const[faqedit,setfaqedit]=useState({});
    const[faqid,setfaqid]=useState();
    const[faqcreateid,setfaqcreateid]=useState();
    const[editFlag,setEditFlag]=useState(false);
//product faqs get


const showLoading = () => props.toggleLoading({ isLoading: true });

const hideLoading = () => props.toggleLoading({ isLoading: false });
    useEffect(()=>{
    const url=`/module/product/${id}/faqs`;
    try{
        showLoading();
        API.get(url).then((res)=>setfaq(res.data));
    }
    catch(error)
    {
        console.log("error is",error);
    }
    finally {
      hideLoading();
    }
},[add])


useEffect(()=>{
  const url=`/module/product/${id}/faqs`;
  if(editFlag){
  try{
      
      API.get(url).then((res)=>setfaq(res.data));
  }
  catch(error)
  {
      console.log("error is",error);
  }
  setEditFlag(false);
}
},[editFlag])

//product faqs  post
  useEffect(()=>{
    try{
      API.post("/module/product/faqs",faqcreate).then((res)=>console.log(res));
  }
  catch(error)
  {
      console.log("error is",error);
  }
  },[faqcreate])
   
//product  faq patch
  



   useEffect(()=>{
    console.log(faq.length);
  setfaqcreateid(faq.length+1);
     setfaqcount(faq.length);
   },[faq])
   

  
    return(
        <>
            {


        mode=="View" && faq && add ?
       
         [faq.map((ques)=>{ if(ques.isActive) return(<QuestionEditComponent question={ques.question} answer={ques.answer} id={ques.id} prodid={id} editflag={setEditFlag} />)}) ,<QuestionCreateComponent mode={"EditCreate"} prodid={id}  addfun={addfun}  />]
         
        
        :mode=="View" && faq ?
        faq.map((ques)=>{  if(ques.isActive) return(<QuestionEditComponent  question={ques.question} answer={ques.answer} id={ques.id} prodid={id} editflag={setEditFlag}/>)}):
          <></>
        }
        </>
    )
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionComponent);