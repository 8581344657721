import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import './product-status.scss';


export default function ProductStatus({setProductStatus={},productStatus}) {



  console.log("productStatusproductStatus,",productStatus)



  return (
    <div className="product-status-wrapper">
          <Card body>
            <div> <b>Product Status*</b><br />
              <div className="status-contatiner">
                <select onChange={(e)=>setProductStatus(e.target.value)} value={productStatus} style={{ width: "100%" }} >
                  <option value="Active" >Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
          </Card>
    </div>
  )
}

                           
                           
