import HomeIcon from "@mui/icons-material/Home";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonIcon from "@mui/icons-material/Person";
import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import Collections  from "./pages/products/Collections";
import CreateCollection  from "./pages/products/CreateCollection";
import Customers from "./pages/customers/Customers";
import AddCustomer from "./pages/customers/AddCustomer";
import Customer from "./pages/customers/Customer";
import Inventory  from "./pages/products/Inventory";
import UserManagement from "./pages/auth/UserManagement";
import Signup from "../src/pages/auth/SignUp";
import Login from "./pages/auth/Login";
import  Emailsignup  from "../src/pages/auth/Emailsignup";
import  Emailpassword 
 from "./pages/auth/EmailpasswordLogin";
import  CreateProductForm  from "./pages/products/CreateProductForm";
import  CreateCoupon  from "./pages/products/CreateCoupon";
import Coupons from "./pages/products/Coupons";
import CreateProduct from "./pages/ProductPage/CreateProduct/CreateProduct";
import BulkEditProduct from "./pages/products/BulkEditProduct";

import Orders from "./pages/orders/Orders";
import CreateOrder from "./pages/orders/CreateOrder";
import Order from "./pages/orders/Order";
import WebIcon from "@mui/icons-material/Web";
import WebsiteCMS from "./pages/cms/WebsiteCMS";
import { AllCountries } from "./pages/products/AllCountries";
import  CountryRegion  from "./components/CountryRegion";
import Articles from "./pages/Blog/Articles/Articles"
import ArticleCategories from "./pages/Blog/Category/ArticleCategories";
import Authors from "./pages/Blog/Author/Authors";
import AddAuthor from "./pages/Blog/Author/AddAuthor"
import CreateArticleCategory from "./pages/Blog/Category/CreateArticleCategory"
import CreateArticle from "./pages/Blog/Articles/CreateArticle";
import DealPage from "./pages/DealSection/DealPage";
import Collection from "./pages/products/Collection/Collection";
import RatingsAndReviews from "./pages/RatingsAndReviews/RatingsAndReviews";
import CartPageProducts from "./pages/CartPageProducts/CartPageProducts";



const routes = [
  {
    name: "Home",
    icon: HomeIcon,
    hasSubroute: false,
    path: "/dashboard/home",
    component: Home,
    display: true,
    role: ["admin"],
  },
  {
    name: "Orders",
    icon: PersonIcon,
    hasSubroute: false,
    path: "/dashboard/orders",
    component: Orders,
    display: true,
    role: ["admin"],
  },
  {
    name: "Create Order",
    icon: null,
    hasSubroute: false,
    path: "/dashboard/orders/new",
    component: CreateOrder,
    display: false,
    role: ["admin"],
  },
  {
    name: "Order",
    icon: null,
    hasSubroute: false,
    path: "/dashboard/orders/:id",
    component: Order,
    display: false,
    role: ["admin"],
  },
  {
    name: "Products",
    icon: LocalOfferIcon,
    hasSubroute: false,
    component: Products,
    display: true,
    role: ["admin"],
    path: "/dashboard/products",
  },
  {
    name: "EditPage",
    hasSubroute: false,
    path: "/dashboard/prods/EditNew/:Editprodid",
    component: CreateProductForm,
    display: false,
    role: ["admin"],
  },
  // {
  //   name: "create",
  //   hasSubroute: false,
  //   path: "/dashboard/prods/create",
  //   component: CreateProductForm,
  //   display: false,
  //   role: ["admin"],
  // },

  {
    name: "Add-Product",
    hasSubroute: false,
    path: "/dashboard/prods/create",
    component: CreateProduct,
    display: false,
    role: ["admin"],
  },
  {
    name: "Edit-Product",
    hasSubroute: false,
    path: "/dashboard/product/:productId",
    component: CreateProduct,
    display: false,
    role: ["admin"],
  },
  {
    name:"Bulk-Edit",
    hasSubroute:false,
    path:"/dashboard/product/bulkedit/:idList",
    component:BulkEditProduct,
    display:false,
    role:["admin"]
  },
  {
    name: "View Product",
    hasSubroute: false,
    path: "/dashboard/products/view/:Viewprodid",
    component: CreateProductForm,
    display: false,
    role: ["admin"],
  },

  {
    name: "Coupon Management",
    hasSubroute: false,
    component: Coupons,
    display: true,
    icon: LocalOfferIcon,
    role: ["admin"],
    path: "/dashboard/Coupons",
  },

  {
    name: "Create Coupon",
    hasSubroute: false,
    path: "/dashboard/Coupon/Create",
    component: CreateCoupon,

    display: false,
    role: ["admin"],
  },
  {
    name: "View Coupon",
    hasSubroute: false,
    path: "/dashboard/coupon/view/:Viewcouponid",
    component: CreateCoupon,
    display: false,

    role: ["admin"],
  },
  {
    name: "Collections",
    hasSubroute: false,
    path: "/dashboard/Collections",
    component: Collections,
    display: true,
    icon: LocalOfferIcon,
    role: ["admin"],
  },
  {
    name: "Collectionsadd",
    hasSubroute: false,
    path: "/dashboard/Collections/add",
    component: CreateCollection,
    display: false,
    role: ["admin"],
  },
  {
    name: "Customers",
    icon: PersonIcon,
    hasSubroute: false,
    path: "/dashboard/customers",
    component: Customers,
    display: true,
    role: ["admin"],
  },
  {
    name: "Add Customer",
    icon: PersonIcon,
    hasSubroute: false,
    path: "/dashboard/customers/new",
    component: AddCustomer,
    display: false,
    role: ["admin"],
  },
  {
    name: "Customers",
    icon: PersonIcon,
    hasSubroute: false,
    path: "/dashboard/customers/:id",
    component: Customer,
    display: false,
    role: ["admin"],
  },
  {
    name: "Edit Coupon",
    hasSubroute: false,
    path: "/dashboard/coupon/edit/:Editcouponid",
    component: CreateCoupon,
    display: false,
    role: ["admin"],
  },

  {
    name: "Collections",
    hasSubroute: false,
    path: "/dashboard/Collections",
    component: Collections,
    display: false,
    role: ["admin"],
  },
  {
    name: "Collectionsadd",
    hasSubroute: false,
    path: "/dashboard/Collections/add",
    component: CreateCollection,
    display: false,
    role: ["admin"],
  },

  {
    name: "CollectionsView",
    hasSubroute: false,
    path: "/dashboard/collections/view/:CollectionViewId",
    component: CreateCollection,
    display: false,
    role: ["admin"],
  },

  {
    name: "CollEditPage",
    hasSubroute: false,
    path: "/dashboard/collections/Edit/:CollectionEditId",
    component: CreateCollection,
    display: false,
    role: ["admin"],
  },

  {
    name: "Inventory Management",
    icon: LocalOfferIcon,
    hasSubroute: false,
    path: "/dashboard/Inventory",
    component: Inventory,
    
    display: true,
    role: ["admin"],
  },

  {
    name: "SignUp",
    hasSubroute: false,
    path: "/dashboard/signup",
    
    component: Signup,
    role: ["admin"],
  },

  {
    name: "User",
    icon: PersonIcon,
    hasSubroute: false,
    path: "/dashboard/Emailsignup",
    component: Emailsignup,
    role: ["admin"],
  },
  {
    name: "",
    
    icon: PersonIcon,
    hasSubroute: false,
    path: "/dashboard/Emailsignup/:id",
    component: Emailsignup,
    role: ["admin"],
  },
  {
    name: "User Management",
    
    icon: PersonIcon,
    hasSubroute: false,
    path: "/dashboard/users",
    component: UserManagement,
    display:true,
    role: ["admin"],
  },
  
  {
    name: "",
    path: "/dashboard/emailloginpassword/:mailid",
    hasSubroute: false,
    component: Emailpassword,
    role: ["admin"],
  },
  {
    name: "CMS",
    icon: WebIcon,
    hasSubroute: false,
    path: "/dashboard/cms",
    component: WebsiteCMS,
    display: true,
    role: ["admin"],
    
  },
  {
    name: "Cart Products",
    hasSubroute: false,
    path: "/dashboard/cartproducts",
    component: CartPageProducts,
    display: true,
    role: ["admin"],
  },

      {
        name: "Deal Section",
        hasSubroute: false,
        path: "/dashboard/dealsection",
        component: DealPage,
        display: true,
        role: ["admin"],
      },
      {
        name: "Ratings & Reviews",
        hasSubroute: false,
        path: "/dashboard/ratings&reviews",
        component: RatingsAndReviews,
        display: true,
        role: ["admin"],
      },
  
  {
    name: "Country Management",
    icon: LocalOfferIcon,
    hasSubroute: false,
    component: AllCountries,
    display: true,
    role: ["admin"],
    path: "/dashboard/Regions",

  },
  
  {
    name:"Blog",
    icon:LocalOfferIcon,
    hasSubroute: true,
    display: true,
    role: ["admin"],
    // path: "/dashboard/blog",
    subroutes: [
      {
        name: "Category",
        hasSubroute: false,
        path: "/dashboard/blog/category",
        component: ArticleCategories,
        display: true,
        role: ["admin"],
      },
    
      {
        name: "Author",
        hasSubroute: false,
        path: "/dashboard/blog/author",
        component: Authors,
        display: true,
        role: ["admin"],
      },
      {
        name: "Articles",
        hasSubroute: false,
        path: "/dashboard/blog/articles",
        component: Articles,
        display: true,
        role: ["admin"],
      },
    ],
   
  },
  
  {
    name: "Create",
    hasSubroute: false,
    path: "/dashboard/category/create",
    component:CreateArticleCategory,
    display: false,
    role: ["admin"],
  
   
  },
  {
    name: "Create",
    hasSubroute: false,
    path: "/dashboard/category/:id",
    component:CreateArticleCategory,
    display: false,
    role: ["admin"],

  },
  {
    name: "Create",
    hasSubroute: false,
    path: "/dashboard/author/create",
    component:AddAuthor,
    display: false,
    role: ["admin"],
  
   
  },
  {
    name: "Create",
    hasSubroute: false,
    path: "/dashboard/author/:id",
    component:AddAuthor,
    display: false,
    role: ["admin"],

  },
  {
    name: "Create",
    hasSubroute: false,
    path: "/dashboard/article/create",
    component:CreateArticle,
    display: false,
    role: ["admin"],
  
   
  },
  {
    name: "Create",
    hasSubroute: false,
    path: "/dashboard/article/:id",
    component:CreateArticle,
    display: false,
    role: ["admin"],

  },
    
  // {
  //   name: "",

  //   hasSubroute: false,
  //   component: CountryRegion,
  //   display: false,
  //   role: ["admin"],
  //   path: "/dashboard/country/edit/:editcountryId",
  // },
  {
    name: "",

    hasSubroute: false,
    component: CountryRegion,
    display: false,
    role: ["admin"],
    path: "/dashboard/country/view/:viewcountryId",
  },
  {
    name: "Create",
    hasSubroute: false,
    component: CountryRegion,
    display: false,
    role: ["admin"],

    path: "/dashboard/country/create",
  },
  {
    name: "Create",
    hasSubroute: false,
    path: "/dashboard/collection/create",
    component:Collection,
    display: false,
    role: ["admin"],
  
   
  },
  {
    name: "Create",
    hasSubroute: false,
    path: "/dashboard/collection/:id",
    component:Collection,
    display: false,
    role: ["admin"],

  },


];

export default routes;
