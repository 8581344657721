import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import DeleteIcon from "../../../../assets/img/delete.png";
import { Modal } from "react-bootstrap";
import './variant-management.scss';
import { connect } from "react-redux";


// const updateArray = (array, index,fieldName, value) => {
//     const newArray = array.map((item, i) => {
//       if (i === index) {
//         return { ...item, [fieldName]: value };
//       }
//       return item;
//     });
  
//     // Now newArray contains the original array with the updated value at the specified index.
//     return newArray;
//   };

const updateArray = (array, index, fieldName, value) => {
    const newArray = array.map((item, i) => {
        if (fieldName === "recommended" && value === true) {
            // If we're updating the recommended field and setting it to true,
            // we need to ensure all other items have recommended set to false.
            return {
                ...item,
                recommended: i === index ? true : false,
            };
        } else if (i === index) {
            return { ...item, [fieldName]: value };
        }
        return item;
    });

    return newArray;
  };
const VariantManagement=(props) =>{

    const {mediaDetails=[],variantData,setVariantData,variantDetailsError,inventoryManagement} = props
    console.log("VariantManagement",inventoryManagement)
    const {skuId="",barcode="",handQty=""} = inventoryManagement || {}
 const [showMediaData , setShowMediaData] = useState(false);
 const [ clickedVariantImageIndex , setClickedVariantImageIndex] = useState(null);
 const onAddNewVariant = () =>{
    if(Object.keys(inventoryManagement).length <= 0 || !skuId || !handQty ) {
        showErrorNotification("please Fill the Single Listing")
        return;
    }
    let newVariantData;
    if (variantData.length === 0) {
      newVariantData = {
        variantImg: "",
        vaiantName: "",
        qty: handQty,
        status: true,
        variantSku: skuId,
        variantBarcode: barcode,
        recommended:false
      };
    } else {
      newVariantData = {
        variantImg: "",
        vaiantName: "",
        qty: "",
        status: true,
        variantSku: "",
        variantBarcode: "",
        recommended:false
      };
    }
     setVariantData([...variantData,newVariantData])
 }

console.log("variantData",variantData)
 const onChangeVariantValue = ({fieldName,value,index}) => {
    const updatedArray = updateArray(variantData,index,fieldName,value);
    setVariantData(updatedArray);
 }

 const onDeleteVariant = (index) => {
    const filterItem = variantData.filter((_, i) => i !== index);
    setVariantData(filterItem)
 }

 const onSelectImage = (imageUrl,index) => {
    const updatedArray = updateArray(variantData,clickedVariantImageIndex,"variantImg",imageUrl);
    setVariantData(updatedArray);
    setShowMediaData(false)
 }

 const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);
  
  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);
  
  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });



  return (
    <div className="variant-management-wrapper">
          <Card body>
            <div>
                <div className="variant-container">
                    <label className="label-txt">Variants</label>
                    <div className="action-btn" onClick={onAddNewVariant}>+ Add Variants</div>
                </div>
                <div className="variant-div">
                {
                    variantData.map((data,index)=>{
                      const isDisabled = index === 0;

                        return(
                            <>
                          
                            <div className="variant-section">
                             <div className="variant-list">
                                <div className="group image-container">
                                   {data.variantImg ?  <img src={data.variantImg} onClick={()=>{
                                        setClickedVariantImageIndex(index)
                                        setShowMediaData(true)
                                    } } />: <div className="add-image" onClick={()=>{
                                        setClickedVariantImageIndex(index)
                                        setShowMediaData(true)
                                    } }>+ Image</div>}
                                  
                                </div>
                                <div className="group">
                                    <label for="name" className="label-title-txt">Pack Of *</label>
                                    <input type="number" onWheel={(e)=>e.target.blur()}  value={data.vaiantName} onChange={(e)=>onChangeVariantValue({
                                        fieldName:"vaiantName",
                                        value:e.target.value,
                                        index:index
                                    })}  style={{ width: "14em", color:"" }} required/> 
                                </div>
                                <div className="group">
                                    <label for="name" className="label-title-txt">Available Quantity*</label>
                                    <input type="number" disabled={isDisabled} onWheel={(e)=>e.target.blur()} value={data.qty} onChange={(e)=>onChangeVariantValue({
                                        fieldName:"qty",
                                        value:e.target.value,
                                        index:index
                                    })}  style={{ width: "14em" ,backgroundColor: isDisabled ? "#d4d4d5" : ""}}   required/> 
                                </div> 
                                <div className="group variant-status">
                                <div className="label-title-txt">Active</div>
                                <input type="checkbox" checked={data.status}  onChange={()=>{  onChangeVariantValue({
                                                    fieldName:"status",
                                                    value:!data.status,
                                                    index:index
                                                })}} class="checkbox-round"  />
                            </div> 
                            <div className="group variant-status">
                                <div className="label-title-txt">Recommended</div>
                                <input type="checkbox" checked={data.recommended}  onChange={()=>{  onChangeVariantValue({
                                                    fieldName:"recommended",
                                                    value:!data.recommended,
                                                    index:index
                                                })}} class="checkbox-round"  />
                            </div> 
                                <div className="group variant-action" onClick={()=>onDeleteVariant(index)}>
                                    <img src={DeleteIcon} alt="delete-icon"/>
                                </div> 
                              
                               
                            </div> 
                            <div className="media-fields">
                                <div className="group">
                                    <label for="variantSku" className="label-title-txt">SKU (Stock Keeping Unit)*</label>
                                    <input type="text" disabled={isDisabled} onWheel={(e)=>e.target.blur()}  value={data.variantSku} onChange={(e)=>onChangeVariantValue({
                                        fieldName:"variantSku",
                                        value:e.target.value,
                                        index:index
                                    })}  style={{ width: "14em", color:"",backgroundColor: isDisabled ? "#d4d4d5":"" }} required/> 
                                </div>
                                <div className="group">
                                    <label for="variantBarcode" className="label-title-txt">Barcode</label>
                                    <input type="text" disabled={isDisabled} onWheel={(e)=>e.target.blur()}  value={data.variantBarcode} onChange={(e)=>onChangeVariantValue({
                                        fieldName:"variantBarcode",
                                        value:e.target.value,
                                        index:index
                                    })}  style={{ width: "14em", color:"",backgroundColor:isDisabled ?"#d4d4d5" : "" }} /> 
                                </div>
                                </div>
                            </div>
                             {variantDetailsError && variantDetailsError[index] && <span style={{fontSize:'12px',color:'red'}} >{variantDetailsError[index]}</span>}
                           
                             </>
                        )
                    })
                }   
                </div>

                    <Modal size="lg" show={showMediaData} onHide={()=>setShowMediaData(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title className="aligncenter">
                                <div className="variant-media-container">Variant Image</div> 
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{display:'flex',gap:'15px',flexWrap:'wrap'}}>
                                {
                                    mediaDetails.map((data,index)=>{
                                        return(
                                            <div style={{width:'100px',height:'100px',borderRadius:"19px",cursor:'pointer', border:'1px solid #247a81'}} onClick={()=>onSelectImage(data.image,index)}>
                                                <img style={{width:'100%',height:'100%',borderRadius:"19px"}} src={data.image}></img>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
            </div>
          </Card>
    </div>
  )
}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(VariantManagement); ;
