import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { Card } from 'react-bootstrap';
import DeleteIcon from "../../../../assets/img/delete.png";
import './frequently-bought-items.scss';


export default function FrequentlyBoughtItems({listOfProduct,frequentlyBought,setFrequentlyBought}) {

    const handleChange = (selectedOption) =>{
        const isAlreadyExist = frequentlyBought.find((data) => data.value == selectedOption.value);
        if(!isAlreadyExist){
          setFrequentlyBought([...frequentlyBought,selectedOption])
        }
    }

    const onDeleteItem = (value) => {
      const filterItem = frequentlyBought.filter((data, i) => data.value
      !== value);
      setFrequentlyBought(filterItem)
    }
  return (

    <div className="product-status-wrapper">
    <Card body>
      <div> 
          <div className="header-txt" style={{fontSize:'14px',fontWeight:600}}>Frequently Bought Items</div>
          <div className="product-status-wrapper">
                <div className="select-container">
                    <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    oncloseMenuOnSelect={false}
                    value={[]}
                    onChange={handleChange}
                    options={listOfProduct}
                    />
                </div>
                
              <div className="frequently-bought-list-item">

                {
                  frequentlyBought.map((data,index)=>{
                    return(
                      <div className="list-item">
                        <div className="list-info">
                          <div>{data.label}</div>
                        </div>
                        <div className="delete-container" onClick={()=>onDeleteItem(data.value)}>
                          <div className="delete-txt">Delete</div> 
                          <img src={DeleteIcon} alt="delte-icon"/>
                        </div>
                      </div>
                    )
                  })
                }

               
              

              </div>
             
      </div>
      </div>
    </Card>
</div>
    
  )
}

                           
                           
