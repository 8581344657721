import RichTextEditor from 'react-rte';
import React, { useEffect } from 'react';
export function BodyTextEditorCreate({ value, setValue }) {

  const [editorValue, setEditorValue] =
    React.useState(RichTextEditor.createValueFromString(value, 'html'));

  const handleChange = value => {
    setEditorValue(value);
    console.log("bodytext", value);
    setValue(value.toString("html"));
  };


  useEffect(() => {
    // Check if the current editor value is different from the value prop
    const currentEditorValue = editorValue.toString("html");
    if (currentEditorValue !== value) {
      setEditorValue(RichTextEditor.createValueFromString(value, 'html'));
    }
  }, [value, editorValue]);
  console.log("editorValue",editorValue)
  console.log("valuev",value)

  return ( <RichTextEditor
      value={editorValue}
      onChange={handleChange}
      required
      id="body-text"
      name="bodyText"
      type="string"
      multiline
      variant="filled"
      style={{ minHeight: "600px"}}
    />)
  }