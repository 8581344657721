import React, { useState, useEffect } from "react";
import API from "../../utils/API";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { CollectionComponent } from "../../components/CollectionComponent";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { viewCoupondate } from "../../utils/commons";
import { NewspaperTwoTone } from "@mui/icons-material";
function CreateCoupon(props) {
  const [countries, setCountries] = useState([]);
  const [apply, setApply] = useState();
  const [specialprod, setSpecialprod] = useState(false);
  const [couponcode, setCouponCode] = useState();
  const [customerapply, setcustomerapply] = useState();
  const [prodselection, setProdselection] = useState();
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [viewcoupon, setViewCoupon] = useState({});
  const [dateformatexpiry, setdateformatexpiry] = useState();
  const [viewcouponflag, setViewcouponflag] = useState(false);
  const [coupongen, setCouponGen] = useState();
  const[viewSpecialOfferProds,setViewSpecialOfferProds]=useState([]);
  const[viewSpecialOfferCategories,setViewSpecialOfferCategories]=useState([]);
  const [checkRadioAll,setCheckRadioAll] = useState(false);
  const [checkRadioSpecial,setcheckRadioSpecial] = useState(false);
  const applyitems = [{value: "APPLY_TO_ALL",label:"Apply to all items in the cart"},
    {value:"APPLY_TO_SINGLE_MAX",label:"Apply to a single item (Max)"},
    {value:"APPLY_TO_SINGLE_MIN",label:"Apply to a single item (Min)"},
    {value:"APPLY_TO_SINGLE_WITH_MULTIPLE_QUANTITIES_MAX",label:"Apply to a single item with multiple quantities (Max)"},
    {value:"APPLY_TO_SINGLE_WITH_MULTIPLE_QUANTITIES_MIN",label:"Apply to a single item with multiple quantities (Min)"}];
  const userapply = [{value:"None",label:"None"},
    {value:"USERS_WITHOUT_ANY_ORDER",label:"Users without any order"},
    {value:"USERS_WITH_AT_LEAST_ONE_SUCCESSFULL_ORDER",label:"Users with atleast one successful order"}];
  const [editviewflag, seteditviewflag] = useState(false);
  const [couponstatus, setCouponStatus] = useState("Active");
  const [alertCoupon, setAlertCoupon] = useState(false);
  const [alphacoupon, setAlphaCoupon] = useState(false);
  const [coupontype, setCouponType] = useState();
  const [percentageflag, setPercentageflag] = useState(false);
  const [percentage, setPercentage] = useState();
  const [amount, setAmount] = useState();
  const [dateexpiry, setdateexpiry] = useState();
  const[prodType,setProdType]=useState();
  const [minamount, setMinamount] = useState();
  const [maxamount, setMaxamount] = useState();
  const [applyitem, setApplyitem] = useState();
  const [formfill, setFormfill] = useState(false);
  const [coupondetails, setCoupondetails] = useState({});
  const [countrycode, setCountrycode] = useState();
  const [userlimit, setuserlimit] = useState();
  const [couponlimit, setcouponlimit] = useState();
  const [editcoupon, setEditcoupon] = useState({});
  const [prods, setProds] = useState([]);
  const [specialOfferProds, setSpecialOfferProds] = useState([]);
  const [selectedoption, setSelectedoption] = useState([]);
  const [selectedFinalProd, setSelectedFinalProd] = useState([]);
  const [categoryprods, setCategoryProds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const[couponLimitFlag,setCouponLimitFlag]=useState(false);
  const[couponValidation,setCouponValidation]=useState(false);
  const[viewCategories,setViewCategories]=useState([]);
  const[categoryStatus,setCategoryStatus]=useState();
  const[couponFlag,setCouponFlag]=useState(false);
  const[currencycode,setCurrencyCode]=useState("AED");
  const[viewProducts,setViewProducts]=useState([]);
  const[prodCount,setProdCount]=useState([]);
  const[viewOptionCategories,setViewOptionCategories]=useState([]);
  const[buttonFlag,setButtonFlag]=useState(false);
  const[selectedoptionCategories,setSelectedoptionCategories]=useState([]);
  const[specialOfferCategories,setSpecialOfferCategories]=useState([]);
  const[viewOptionProducts,setViewOptionProducts]=useState([]);
  const[viewSpecialProducts,setViewSpecialProducts]=useState([]);
  const[validationError,setValidationError]=useState({
});

  const params = useParams();

  const viewcouponid = params.Viewcouponid;
  const editcouponid = params.Editcouponid;

  const handlecountryname = (e) => {
    //country code
    setCountrycode(e.target.value);

    console.log("country value",e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,couponCountry:"Country is required field"});
    }
    else{
      setValidationError({...validationError,couponCountry:""});
    }
  }
  const handleapply = (e) => {
    
    if(countrycode){
    
    setApply(e.target.value)
    setSpecialprod(false)
    if (e.target.value == "all") {
      setCheckRadioAll(true);
      try{
        API.get(`module/coupon/search/products?key=${""}&countryId=${parseInt(countrycode)}`)
    .then((res) => res.data)
    .then((data) => {
      const productIds = data.map((product) => product.id);
      console.log("prodsbhhjb", productIds); 
      setProds(productIds); 
    })
      }
      catch (error) {
        console.log("error is", error);
      }
    }
   
    
    if (e.target.value == "specialprod") {
      setcheckRadioSpecial(true)
       if(view){
      setCategoryStatus("Categories");}
      setSpecialprod(true);
      
    }
  }
    else{
      
      setCheckRadioAll(false)
      setcheckRadioSpecial(false)
      showErrorNotification("Please select a country");
     
      // setTimeout(()=>{
      //   window.location.reload();
      // },1000)
    }
  }
  const getdateexpire = (e) => {
    console.log("data is", e.target.value);
    setdateexpiry(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,couponExpiryDate:"expiry date is required"});
    }
    else{
      setValidationError({...validationError,couponExpiryDate:""});
    }

  }
  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  useEffect(()=>{
    
 if (prodselection == "Products") {
      setSelectedIds(selectedoption.map((prods) => prods.value));
    }

  },[selectedoption])

  useEffect(()=>{
    if (prodselection == "Categories") {
      console.log(selectedoption);
      setSelectedIds(selectedoptionCategories.map((category) => category.value));
    }

  },[selectedoptionCategories])
  




  useEffect(()=>{
    if(countrycode && countries){
      setCurrencyCode(countries.filter((ctry)=>ctry.id==countrycode)[0]?.currency);
    }

  },[countrycode])

  useEffect(() => {

    if (selectedIds.length > 0) {
      console.log("selectedIds", selectedIds);
      if (prodselection == "Categories") {
        selectedIds.map((id) => API.get(`/api/v1/private/product/type/${id}`).then((res) => res.data).then((data) => data.products).then((Prod) => setCategoryProds([...categoryprods, Prod[0].product.map((inprod) => inprod.id)])))
       // console.log(categoryprods);
      }
      if (prodselection == "Products") {
        setSelectedFinalProd(selectedIds.map((id) => id));
      }
    }


  }, [selectedIds])

  useEffect(()=>{
    if(view)
    {
      if (viewOptionCategories.length > 0) {
        console.log("selectedIds", selectedIds);
        if (categoryStatus == "Categories") {
          viewOptionCategories.map((option) => API.get(`/api/v1/private/product/type/${option.value}`).then((res) => res.data).then((data) => data.products).then((Prod) => setCategoryProds([...categoryprods, Prod[0].product.map((inprod) => inprod.id)])))
          console.log(categoryprods);
        }
      }
        if (categoryStatus== "Products") {
          setSelectedFinalProd(viewOptionProducts.map((prod) => prod.value));
        }
      
    }

  },[viewOptionCategories,viewOptionProducts])


  useEffect(() => {
    console.log(categoryprods);
    if (categoryprods.length > 0) {
      setSelectedFinalProd([...selectedFinalProd, categoryprods.flat()]);
    }

  }, [categoryprods])




  const handleprodselection = (e) => {
    setProdselection(e.target.value);

      if (e.target.value == "Categories") {
      try {
        API.get("/api/v1/product/types").then((res) => (res.data)).then((data) => data.list).then((list) => setSpecialOfferCategories(list.map((coll) => { return ({ "value": coll.id, "label": coll.code }) })));
      }
      catch (error) {
        console.log("error is", error);
      }
    }
    if (e.target.value == "Products") {
      // try { 
      //   API.get("/api/v1/products/").then((res) => res.data)
      //   .then((data) => data.products).then((products) => setSpecialOfferProds
      //   (products.map((product) => {if(product.available) 
      //     return ({ "value": product.id, "label": product?.description?.name }) }).filter((obj)=>obj)));
      // }
      try{
        API.get(`module/coupon/search/products?key=${""}&countryId=${parseInt(countrycode)}`)
    .then((res) => res.data)
    .then((data) => {
      const productIds = data.map((product) =>{return({ "value": product?.id, "label": product?.name }) });
      console.log("prodsbhhjb", productIds);
      setSpecialOfferProds(productIds); 
    })
      }
      catch (error) {
         console.log("error is",error);
      }
    }
  }

  const handleEditprodselection=(e)=>{
    setCategoryStatus(e.target.value);

    console.log("in function",e.target.value);
   //setViewCategories([]);
   //setViewProducts([]);
    
    }
  

console.log("special offer prods",specialOfferProds);




  



 const validateAllFields = (couponCode,minSpend,maxSpend,couponType,userLimit, usageLimit,apply,customerApply,date,status,country) => {
   
    const MIN_LENGTH = 3;
    const MAX_LENGTH = 20;
    const minamount=0;
    const maxamount= 1000000;
   const validationError={}; 
    console.log("validate in",apply)
   
    if (isEmpty(couponCode))
        validationError.couponCode="Coupon Code is a required field!";
    else if (!/^[a-z0-9]+$/i.test(couponCode))
        validationError.couponCode="Invalid Coupon Code! (should only contain letters and numbers).";
    else if (couponCode.length < MIN_LENGTH)
        validationError.couponCode= `Invalid Coupon Code! (should be minimum ${MIN_LENGTH} characters long).`;
    else if (couponCode.length > MAX_LENGTH)
        validationError.couponCode= `Invalid Coupon Code! (should be maximum ${MAX_LENGTH} characters long).`;
    if(isEmpty(minSpend))
    validationError.minSpend="Min spend is required field";
     if(isEmpty(maxSpend))
     validationError.maxSpend="Max spend is required field";
    if (minSpend !== null && Number(minSpend) < minamount )
        validationError.minSpend=`Invalid minspend! (should be less than ${minamount}).`;
    else if (minSpend !== null && Number(minSpend) > maxamount)
        validationError.minSpend= `Invalid minspend! (should not be greater than ${maxamount})`;

    if (Number(minSpend) > Number(maxSpend)) {
          validationError.minSpend =  "Invalid Min. Spend! (should not be greater than Max. Spend)";
         
      }
      else if (Number(maxSpend) < Number(minSpend)) {
        validationError.maxSpend =  "Invalid Max. Spend! (should not be smaller than Min. Spend)";
        
    }
    if (isEmpty(couponType))
      validationError.couponType="Coupon Type is a required field!";
  else {
     
     if(couponType=="Fixed"){
     if(isEmpty(amount))
     validationError.couponAmount="Amount is mandatory";
     }
   else if(couponType=="Percentage"){
      if(isEmpty(percentage)){
        validationError.couponPercentage= "percentage is mandatory"}
      }
      
    }


    if (apply=="Select" || apply=="" || !apply) {
      validationError.applyItem="Coupon Applicability is a required field!";
    }
    if (customerApply=="Select" || customerApply== "" || !customerApply) {
      validationError.customerApply= "Customer Applicability is a required field!";
      
  }
  if(isEmpty(status))
  validationError.couponStatus="status is a required field";
  
  else if(status=="Inactive" && !view)
  validationError.couponStatus=" Create Coupon status should active";
  if(isEmpty(country) || country=="Select Country")
  {
    validationError.couponCountry="country is a required field";

  }
  if(isEmpty(userLimit))
   validationError.userLimit="user limit is required field";
   else if(userLimit<0)
   validationError.userLimit="user limit should greaterthan zero ";
  if (isEmpty(usageLimit))
    validationError.usageLimit="coupon limit is required field";
    else if(usageLimit<0)
   validationError.usageLimit="coupon limit should greaterthan zero ";
    
  if( parseInt(userLimit) > parseInt(usageLimit))
  
  {
    validationError.couponLimit="user limit should less than coupon limit";
  }
  if (!date || date.trim() === "")
       validationError.couponExpiryDate="Coupon Expiry Date is a required field!";
    
return validationError;
    
}

 
 const handlesave = (e) => {
     const  validateAll=validateAllFields(couponcode,minamount,maxamount,coupontype,userlimit,couponlimit ,applyitem,customerapply,dateexpiry, 
        couponstatus,countrycode);
        setValidationError(validateAll);
    if(Object.keys(validateAll).length===0){
    setFormfill(true);
    }
    else{
      showErrorNotification("Some Invalid fields");

    }
  }

  const handlecouponcode = (e) => {
    setCouponCode(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,couponCode:"couponCode is required field"})
    }
    else{
      setValidationError({...validationError,couponCode:""});
    }
    
   
  }

  const handlelengthcoupon = (e) => {
    if (e.target.value.length >= 3 && e.target.length <= 20) {
      if (/^[A-Za-z0-9]*$/.test(e.target.value)) {
        setAlphaCoupon(false);
      }
      else {
        setAlphaCoupon(true);
      }
    }
    else {
      setAlertCoupon(true);
    }
  }

  const handleamount = (e) => {
    setAmount(e.target.value);
    if(coupontype == "Fixed"){
    if(isEmpty(e.target.value))
    setValidationError({...validationError,couponAmount:"Coupon Amount is required field"});
    else{
      setValidationError({...validationError,couponAmount:""}) ;
    }
    }
 
  }
  const handleminamount = (e) => {
    setMinamount(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,minSpend:"Minimum Amount is required field"});
    }
    else{
      setValidationError({...validationError,minSpend:""});
    }
   
  }
  const handlemaxamount = (e) => {
    setMaxamount(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,maxSpend:"Maximum Amount is required field"});
    }
    else{
      setValidationError({...validationError,maxSpend:""});
    }
   
  }
  const handlegeneratedcode = (e) => {
    try {
      API.get("/get-coupon-code").then((res) => res.data).then((data) => { setCouponGen(data.couponCode); setCouponCode(data.couponCode); 
        if(!isEmpty(data.couponCode))
        {
          setValidationError({...validationError,couponCode:""})
        }});
      
    }
    catch (error) {
      console.log("error gen", error);
    }

  }
  const handleCoupontype = (e) => {
    setCouponType(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,couponType:"couponType is required field"});
    }
    else{
      setValidationError({...validationError,couponType:""});
    }
    
  }


  const getdateformatexpire = (e) => {
    setdateformatexpiry(new Date(e.target.value).toISOString());
  }

  const handlepercentage = (e) => {
    if (e.target.value <= 100 && coupontype=="Percentage") {
      setPercentage(e.target.value);
      setPercentageflag(false);
      
    }
    else {
      setPercentageflag(true);
      showErrorNotification("percentage below 100");
    }
    if(coupontype=="Percentage"){
    if(isEmpty(e.target.value)){
      
      setValidationError({...validationError,couponPercentage:"percentage is required filed"});
    }
    else{
      setValidationError({...validationError,couponPercentage:""});
    }
  }
  }
  const statushandle = (e) => {
    setCouponStatus(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,couponStatus:"Status required field"});
    }
    else{
      setValidationError({...validationError,couponStatus:""});
    }
}
  const handleapplyitem = (e) => {
    console.log("value is",e.target.value);
    setApplyitem(e.target.value);

    if(isEmpty(e.target.value)){
      setValidationError({...validationError,applyItem:"apply Item required field"});

    }
    else if(e.target.value=="Select")
    {
      setValidationError({...validationError,applyItem:"select  other option field"});
    }
    else{
      setValidationError({...validationError,applyItem:""});
    }
    console.log("value is",e.target.value,applyitem);
  }
  const handlecustomerapplicable = (e) => {
    console.log("customer",e.target.value);
    setcustomerapply(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,customerApply:"customer apply  required field"});
    }
    else if(e.target.value=="Select")
    {
      setValidationError({...validationError,customerApply:"select  other option field"});
    }

    else{
      setValidationError({...validationError,customerApply:""});
    }
 
  }
  const handleuserlimit = (e) => {
      
    setuserlimit(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,userLimit:"user limit required "});
    }
    else{
      setValidationError({...validationError,userLimit:""});
    }
    
    
  }
  const handleCouponlimit = (e) => {
    console.log("coupon limit",e.target.value);
    setcouponlimit(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,couponLimit:"user limit required "});
    }
    else{
      setValidationError({...validationError,couponLimit:""});
    }
    
  }
   
  

  const getdateeditexpire = (e) => {
    setdateformatexpiry(e.target.value);
  }

  useEffect(() => {
    try {
      API.get("api/v1/active/countries").then((res) => setCountries(res.data));
      console.log("props",props);
    }
    catch (error) {
      console.log("error msg", error);
    }


  }, [])

  useEffect(() => {
    // const countrycode = 
    if (params.Viewcouponid) {
      setView(true);
      seteditviewflag(true);
      console.log("in view", viewcouponid);
    //   try{
    //     API.get(`module/coupon/search/products?key=${""}&countryId=${parseInt(countrycode)}`)
    // .then((res) => res.data)
    // .then((data) => {
    //   setProdCount(data)
    // })
    //   }
    //     catch(error)
    //     {
    //       console.log("error is",error);
    //     }
    }
  }, [viewcouponid])
 
  
  const handleEdit = (e) => {
    const  validateAll=validateAllFields(couponcode,minamount,maxamount,coupontype,userlimit,couponlimit ,applyitem,customerapply,dateformatexpiry, 
      couponstatus,countrycode);
      setValidationError(validateAll);
      if(Object.keys(validateAll).length==0){
    console.log("category status in edit",categoryStatus,viewOptionCategories.map((id)=>id.value));
    const coll=categoryStatus=="Categories"?viewOptionCategories.map((id)=>id.value):[];
    setEditcoupon({
      "couponCode": couponcode,
      "description": "dshhs",
      "discountType": coupontype,
      "couponAmount": coupontype == "Fixed" ? parseInt(amount) : parseInt(percentage),
      "couponApplicability": applyitem,
      "couponUserApplicability": customerapply,
      "couponExpiryDate": new Date(dateformatexpiry).toISOString(),
      "minSpend": minamount,
      "maxSpend": maxamount,
      "welcomeCoupon": false,
      "status": couponstatus == "Active" ? true : false,
      "couponUsageLimit": couponlimit,
      "couponUserLimit": userlimit,
      "country": parseInt(countrycode),
      "products": apply == "all" ? prods : categoryStatus == "Categories" ? [...new Set(selectedFinalProd.flat())] : [...new Set(selectedFinalProd)],
      "couponCategory": "Discount",
      "maxDiscountAllowed": 1000,
      "prodType":apply,
      "collection":apply=="specialprod"?coll:[],
    });
    setButtonFlag(true);
  }
  else{
    showErrorNotification("Some Invalid Fields ");
  }
  }

  useEffect(() => {
    if (editcoupon && buttonFlag && view && !couponFlag) {
      try {
        const urlstr = `/module/coupon/${viewcouponid}`;
        API.patch(urlstr, editcoupon).then((res) =>{ showSuccessNotification("successfully  Coupon Updated");setCouponFlag(true);props.history.goBack();});
      setButtonFlag(false);
      }
      catch (error) {
        console.log("error is", error);
        showErrorNotification(
          "Error on updating coupon " + error.message ?? ""
        );
      }

    }

  }, [editcoupon])




  //view part
  useEffect(() => {
    if (view || edit) {
      const couponid = view ? viewcouponid : editcouponid;
      const urlstr = `/module/coupon/${couponid}`;

      console.log("url", urlstr);
      try {
        API.get(urlstr).then((res) => res.data).then((data) => setViewCoupon(data)).then(setViewcouponflag(true));


      } catch (error) {
        console.log("fetchProducts:: Error on fetching products!", error);
      }
    }
  }, [editviewflag])
  useEffect(() => {
    console.log("view product count",prodCount.length);
    if (viewcouponflag && viewcoupon ) {
      console.log("coupon",viewcoupon, edit);
      setCountrycode(viewcoupon?.country);
      setCouponCode(viewcoupon?.couponCode);
      setMinamount(viewcoupon?.minSpend);
      setMaxamount(viewcoupon?.maxSpend);
      
      setCouponType(viewcoupon?.discountType);
      setdateformatexpiry((viewcoupon?.couponExpiryDate)?.split('T')[0]);
      setApplyitem(viewcoupon?.couponApplicability);
      if(viewcoupon?.discountType=="Percentage"){
        setPercentage(viewcoupon.couponAmount);
      }
      if(viewcoupon?.discountType=="Fixed"){
        setAmount(viewcoupon.couponAmount);
      }
        
     
      setcustomerapply(viewcoupon?.couponUserApplicability);
      setcouponlimit(viewcoupon?.couponUsageLimit);
      setuserlimit(viewcoupon?.couponUserLimit);
      setCouponStatus(viewcoupon?.status ? "Active" : "Inactive");
      setViewcouponflag(false);
      seteditviewflag(false);
      setViewCategories(viewcoupon?.collection.length>0?viewcoupon.collection:[]);
      setCategoryStatus(viewcoupon?.collection.length>0? "Categories":"Products");
      setApply( viewcoupon.prodType);
      setViewProducts(viewcoupon?.products);
      setViewSpecialProducts(viewcoupon.prodType=="specialprod"?viewcoupon?.products:[]);
      console.log("coupon expiredate", viewCoupondate(viewcoupon?.couponExpiryDate));

      
    }

  }, [viewcoupon])
 
  const handleEditSelection=(e,val)=>{
    if(val.action=="remove-value"){
       // viewCategories.map((category)=>console.log("category",category.value ));

     const selected=viewOptionCategories.filter((category)=>category.value !== val.removedValue.value);
       setViewOptionCategories(selected);
      
    }

    else if(val.action=="select-option"){
    setViewOptionCategories([...viewOptionCategories,val.option]);
   // setSelectedoption(value.option);
   
    }
}

const handleProdEditSelection=(e,val)=>{
  if(val.action=="remove-value"){
    // viewCategories.map((category)=>console.log("category",category.value ));

  const selected=viewOptionProducts.filter((category)=>category.value !== val.removedValue.value);
    setViewOptionProducts(selected);
   
 }

 else if(val.action=="select-option"){
 setViewOptionProducts([...viewOptionProducts,val.option]);
// setSelectedoption(value.option);

 }
}
  
  useEffect(()=>{
    console.log("apply",apply)
   if(view && apply=="all"){
    console.log("checking",)
    API.get(`module/coupon/search/products?key=${""}&countryId=${parseInt(countrycode)}`)
    .then((res) => res.data)
    .then((data) => {
      const productIds = data.map((product) => product.id);
      console.log("prodsbhhjb", productIds); 
      setProds(productIds); 
    })
   
   }


   if(view && apply=="specialprod"){
    console.log("category status",categoryStatus);
    if(categoryStatus=="Categories"){
   API.get("/api/v1/product/types").then((res) => (res.data)).then((data) => data.list).then((list) => setViewSpecialOfferCategories(list.map((coll) => { return ({ "value": coll.id, "label": coll.code }) })));
    
  }
   else if(categoryStatus=="Products")
   API.get(`module/coupon/search/products?key=${""}&countryId=${parseInt(countrycode)}`)
   .then((res) => res.data)
   .then((data) => {
     const productIds = data.map((product) =>{return({ "value": product?.id, "label": product?.name }) });
     console.log("prodsbhhjb", productIds);
     setViewSpecialOfferProds(productIds); 
   })
   }
  },[categoryStatus]) 

useEffect(()=>{
  console.log("In view",categoryStatus);
 // console.log("special prod",specialOfferProds,viewCategories,categoryStatus);
  
  if(view && categoryStatus=="Categories" && viewCategories.length>0){
   // console.log("option cat",specialOfferProds.filter((prod)=>viewCategories.filter((product)=>product==prod.value).length>0));
  setViewOptionCategories(viewSpecialOfferCategories.filter((prod)=>viewCategories.filter((product)=>product==prod.value).length>0));
  }

},[viewSpecialOfferCategories])


useEffect(()=>{
   if(view && categoryStatus=="Products"  && viewSpecialProducts.length>0){
   setViewOptionProducts(viewSpecialOfferProds.filter((prod)=>viewSpecialProducts.filter((product)=>product==prod.value).length>0))
  }
},[viewSpecialOfferProds])


useEffect(()=>{
console.log("option categories",viewOptionCategories);
},[viewOptionCategories])

  useEffect(() => {
    console.log("coupon", couponcode);
    if (formfill) {
      setCoupondetails({
        "couponCode": couponcode,
        "description": "dshhs",
        "discountType": coupontype,
        "couponAmount": coupontype == "Fixed" ? parseInt(amount) : parseInt(percentage),
        "couponApplicability": applyitem,
        "couponUserApplicability": customerapply,
        "couponExpiryDate": new Date(dateexpiry).toISOString(),
        "minSpend": minamount,
        "maxSpend": maxamount,
        "welcomeCoupon": false,
        "status": couponstatus == "Active" ? true : false,
        "couponUsageLimit": couponlimit,
        "couponUserLimit": userlimit,
        "country": parseInt(countrycode),
        "products": apply == "all" ? prods : (prodselection == "Categories" ? selectedFinalProd.flat() : selectedFinalProd),
        "couponCategory": "Discount",
        "maxDiscountAllowed": 1000,
        "prodType":apply,
        "collection": prodselection == "Categories"?selectedIds:[]
      });


    }



  }, [formfill])
  //post
  useEffect(() => {
    if (formfill &&!couponFlag) {
      try {
        API.post("/module/coupon", coupondetails).then((res) => {showSuccessNotification("successfully created Coupon");setCouponFlag(true);props.history.goBack();});
      }
      catch (error) {
        console.log("error", error);
        showErrorNotification(
          "Error on creating coupon " + error.message ?? ""
        );
      }
    }
    setFormfill(false);

  }, [coupondetails])
  
  const isEmpty = (value) => {
    let emptyval=false;
   if(typeof(value) ==="undefined")
    emptyval=true;
   else if(value === "")
   emptyval=true;
   else if( value === null )
   emptyval=true;
  return emptyval;
  }  
  const handleWheel = (e) => {

    e.preventDefault();
  };

  return (
  <div >
    <div className="d-flex justify-content-start-0">
      <div style={{ alignSelf: "flex-start", marginRight: "1rem" }}>
        <Button
        onClick={() => { setView(false); setViewcouponflag(false); setCouponLimitFlag(false);setSpecialprod(false);setCouponFlag(false); setEdit(false); props.history.goBack(); }}
        
        color="inherit"
        size="small"
      >
        <ArrowBackIcon />
      </Button></div>
      {view?(
          <span
            className="mx-2 my-2"
            style={{ fontWeight: "600", fontSize: "1.25rem" }}
          >
         Coupon
          </span>):( <span
            className="mx-2 my-2"
            style={{ fontWeight: "600", fontSize: "1.25rem" }}
          >
            Create Coupon
          </span>)}
      {view ? <div className="col text-end"><button  className={couponFlag ? 'disabled-button' : 'savebtn'} onClick={handleEdit} disabled={couponFlag}>Save</button></div>
        : <div className="col text-end"><button className={couponFlag? 'disabled-button' : 'savebtn'} onClick={handlesave} disabled={couponFlag}>Save</button></div>}</div>
    <div className="fullpage">

      <div className="firstblock">


        <Card body>
          <div>
            <div><b>Coupon details</b></div>
            <br/>

            <div className="alignrowcoupon"><b><div>Country * &nbsp;&nbsp;&nbsp; </div></b>  <div> <select  disabled={view} style={{cursor:view?"not-allowed":"pointer"}} onChange={handlecountryname} value={countrycode} > <option >Select Country</option>
              {countries.map((ctry) => <option value={ctry.id}>{ctry.name}</option>)}
            </select></div></div>

            <span className="help-block">{validationError. couponCountry}</span>
          </div> <br />
          <div className="alignrowcoupon">

            {/* to check coupon code check with api already exist or not*/}
            <b><div>Coupon Code *  &nbsp;&nbsp;  <input type="text" style={{ width: "20em", }} readOnly={view}   placeholder="Enter Coupon Code" value={couponcode} onChange={handlecouponcode} onKeyDown={handlelengthcoupon} /></div></b>

            <div>   <input className="buttonfill" type="button" value="Generate Code" disabled={view}  onClick={handlegeneratedcode} style={{cursor:view?"not-allowed":"pointer"}}/> </div>
          </div>
          {alertCoupon ? <div>Length should be minimum 3 and max 20 characters</div> : <></>}
          {alphacoupon ? <div>only contains alpha and number</div> : <></>} <br />
          <div className="columnalignactive">
            <div className="notetextcolor">Note</div>
            <div className="notetext">Coupon Code should be minimum 3 character and can be alphanumeric</div>
            <span className="help-block">{validationError.couponCode}</span>
             </div>
          <div>

          </div>


        </Card>
        <Card body>
          <div className="alignrowcoupon">
            <div><b>Coupon Value * </b></div>

            <div className="columnaligncoupon"><div><input type="radio" name="coupontype" checked={coupontype == "Percentage"} value="Percentage" style={{cursor:"pointer"}} onChange={handleCoupontype} /> &nbsp; Percentage</div>
              <div className="alignlabel"> <div><input type="number" className="rightinputradius" id="per" value={coupontype == "Percentage" ? percentage : ""} onChange={handlepercentage} onWheel={(e)=>e.target.blur()} placeholder="Enter percentage" /> </div> <div><label for="per">%</label></div>
              </div> 
              <span className="help-block">{validationError.couponType}</span>
              <span className="help-block">{validationError.couponPercentage}</span>
              </div>

            <div className="columnaligncoupon">
              <div><input type="radio" name="coupontype" value="Fixed" checked={coupontype == "Fixed"} onChange={handleCoupontype} style={{cursor:"pointer"}} /> &nbsp;Fixed amount</div>
              <div className="alignlabel"> <div><input type="number" className="rightinputradius" id="per" value={coupontype == "Fixed" ? amount : ""} onChange={handleamount}  onWheel={(e)=>e.target.blur()} placeholder="Enter Amount" /> </div>
                <div><label for="per">{currencycode}</label></div>
              </div>
              <span className="help-block">{validationError.couponType}</span>
              <span className="help-block">{validationError.couponAmount}</span>
            </div>

          </div>
        </Card>
        <Card body>
          <div className="alignrowcoupon"><div><b>Coupon Expiry * </b></div>
            { view ? <div className="columnalignactive"><input type="date" style={{ width: "15em" }} value={dateformatexpiry} onChange={getdateeditexpire} />
              <span className="help-block">{validationError.couponExpiryDate}</span>
              </div>
                : <div > <input type="date" placeholder="DD-MM-YYYY" style={{ width: "15em", }} onChange={getdateexpire} />
                  <span className="help-block">{validationError.couponExpiryDate}</span>
                 </div>}
          </div>

        </Card>
        <Card body>
          <div className="alignrowcoupon">
            <div><b>Usage restriction *</b></div>
            <div className="alignrowcoupon">
            <div className="columnaligncouponselect"><div><b>Min Spent *</b></div>
            <div className="alignlabel"><div ><input type="number" id="per" value={minamount} className="rightinputradius" placeholder="Enter Min Spent" onChange={handleminamount}   onWheel={(e)=>e.target.blur()}/></div><div><label for="per">{currencycode}</label></div></div>
            <span className="help-block">{validationError.minSpend}</span>
            </div>
            <div className="columnaligncouponselect"><div><b>Max Spent *</b></div>
            <div className="alignlabel"><div><input type="number" id="per" className="rightinputradius" placeholder="Enter Max Spent" value={maxamount} onChange={handlemaxamount}  onWheel={(e)=>e.target.blur()}/></div><div><label for="per">{currencycode}</label></div></div>
            <span className="help-block">{validationError.maxSpend}</span>
            </div>
          </div>
          </div>
          <br />
          <div className="alignrowcoupon">
            <div className="columnaligncouponselect">
              <div> <b>Coupon Applicability *</b></div>
              <div className="columnalignactive">
                <select value={applyitem} onChange={handleapplyitem}>
                  <option >Select</option>
                  {applyitems.map((item) => { return (<option value={item.value}>{item.label}</option>) })}
                </select>
                <span className="help-block">{validationError.applyItem}</span>
              </div>

            </div>
            <div className="columnaligncouponselect">
              <div> <b>Customer Applicability *</b></div>
              <div className="columnalignactive">
                <select value={customerapply} onChange={handlecustomerapplicable}>
                  <option >Select</option>
                  {userapply.map((item) => { return (<option value={item.value}>{item.label}</option>) })}

                </select>
                <span className="help-block">{validationError.customerApply}</span>
              </div>
               
            </div>
          </div>
          <br />

          <div>
            <div > <b>Applies to *</b></div> <br />
           { view?
            <div className="columnaligncouponselect">
              <div> <input type="radio" name="applyprod" value="all" onChange={handleapply} checked={apply=="all"} />&nbsp;All Products </div>
              <div> <input type="radio" name="applyprod" value="specialprod" onChange={handleapply} checked={apply=="specialprod"} />&nbsp;Special Products </div>
              <div className="notetextcolor">( Only one of the below two option can be used to select offer products )</div>
            </div>
            :
            <div className="columnaligncouponselect">
              <div> <input type="radio" name="applyprod" value="all" onChange={handleapply} checked={checkRadioAll}/>&nbsp;All Products </div>
              <div> <input type="radio" name="applyprod" value="specialprod" onChange={handleapply} checked={checkRadioSpecial} />&nbsp;Special Products </div>
              <div className="notetextcolor">( Only one of the below two option can be used to select offer products )</div>
            </div>
            }

          </div>
         {view? 
         (apply=="specialprod"?
        ( <>
         <div className="applymargin" >
         <div className="alignrowcoupon"> <div> <input type="radio" name="offer" value="Categories" onChange={handleEditprodselection} checked={categoryStatus=="Categories"} /> &nbsp;Select Categories </div>
           <div>  <input type="radio" name="offer" value="Products" onChange={handleEditprodselection} checked={categoryStatus=="Products"}/> &nbsp; Select Products   </div>
         </div>
         <div>
         { categoryStatus=="Categories"?
          <Select
             isMulti
             name={prodselection}
             options={viewSpecialOfferCategories}
             className="basic-multi-select"
             classNamePrefix="select"
             oncloseMenuOnSelect={false}
             
            value={viewOptionCategories}
             onChange={handleEditSelection}

             style={{ width: "100%" }}
           />:
           <Select
             isMulti
             name={prodselection}
             options={viewSpecialOfferProds}
             className="basic-multi-select"
             classNamePrefix="select"
             oncloseMenuOnSelect={false}
             
            value={viewOptionProducts}
             onChange={handleProdEditSelection}

             style={{ width: "100%" }}
           />

           }
           </div></div>
          </>) :<></>)

          :specialprod?<><div className="applymargin" >
            <div className="alignrowcoupon"> <div> <input type="radio" name="offer" value="Categories" onChange={handleprodselection} /> &nbsp;Select Categories </div>
              <div>  <input type="radio" name="offer" value="Products" onChange={handleprodselection} /> &nbsp; Select Products   </div>
            </div>
            <div>
           { prodselection=="Categories"?
             
             <Select
                isMulti
                name={prodselection}
                options={specialOfferCategories}
                className="basic-multi-select"
                classNamePrefix="select"
                oncloseMenuOnSelect={false}
                value={selectedoptionCategories}
                onChange={setSelectedoptionCategories}
                
                style={{ width: "100%" }}
              />
             
             
             
             : <Select
                isMulti
                name={prodselection}
                options={specialOfferProds}
                className="basic-multi-select"
                classNamePrefix="select"
                oncloseMenuOnSelect={false}
                value={selectedoption}
                onChange={setSelectedoption}

                style={{ width: "100%" }}
              />
              
           }

            </div>
          </div> </>: <></>}
          <div>

          </div>

        </Card>
        <Card body>
          <div><b>Usage *</b></div>
          <div className="alignrowcoupon">
            <div className="columnaligncoupon">
              <div>User limit per Coupon *</div>
              <div className="columnalignactive"><input type="number" style={{ width: "15em" }} value={userlimit} onChange={handleuserlimit} onWheel={(e)=>e.target.blur()} placeholder="Enter number (ex. 1, 2, 3 ...)" />
              <span className="help-block">{validationError.userLimit}</span>
              </div>

            </div>
            <div className="columnaligncoupon">
              <div>Usage limit per Coupon *</div>
              <div className="columnalignactive">  <input type="number" style={{ width: "15em" }} value={couponlimit} onWheel={(e)=>e.target.blur()} onChange={handleCouponlimit} placeholder="Enter number (ex. 1, 2, 3 ...)" />
              <span className="help-block">{validationError.usageLimit}</span>
              </div>
            </div>

          </div>

        </Card>


      </div>
      <div className="secondblock">

        <Card body>
          <div><b>Coupon Status *</b></div> <br />
          <div className="columnalignactive">  <select value={ couponstatus} onChange={statushandle}>
            
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
          <span className="help-block">{validationError.couponStatus}</span>
          </div>

        </Card>

      </div>
    </div>
  </div>)

}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCoupon);
