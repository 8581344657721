import React,{useState,useEffect} from "react";
import API from "../../src/utils/API";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";
import { connect } from "react-redux";
 function VariantViewNew(props){
     const{prodid,id,image,name,qty,status,variant,deletefalg}=props;
     
     const [variantname, setVariantName] = useState();
  const [productid, setProductid] = useState();
  const [variantid, setVariantId] = useState();
  const [quantity, setQuantity] = useState();
  const [variantstatus, setvariantstatus] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [editstatus, setEditStatus] = useState(false);
  const[countryIds,setCountryIds]=useState([]);
  const[variantsPrice,setVariantsPrice]=useState([]);
  const[variantIdPrice,setVariantIdPrice]=useState([]);
  const[editDetails,setEditDetails]=useState();
  const[deleteVariant,setDeleteVariant]=useState();
  const[deleteStatus,setDeleteStatus]=useState(false);
  const[validationError,setValidationError]=useState({});
  useEffect(() => {
    setVariantName(name);
    setProductid(prodid);
    setImageUrl(image);
    setVariantId(id);
    setQuantity(qty);
    setvariantstatus(status?"Active":"Inactive");
   
  }, [props])
  const handlevariantname = (e) => {
    setVariantName(e.target.value);
  }
  const handlevariantquantity = (e) => {
    setQuantity(e.target.value)
  }
  const handlevariantstatus = (e) => {
   if(e.target.checked)
    setvariantstatus("Active");
    else{
      setvariantstatus("Inactive");
    }

  }
  useEffect(() => {
    try {
      API.get("/api/v1/country").then((res) => res.data).then((data) => setCountryIds(data.map((ctry) => ctry.id)));
      console.log("props", props);
    }
    catch (error) {
      console.log("error msg", error);
    }


  }, [])
  useEffect(() => {
    if (countryIds.length == 6) {

      getNames();
    }

  }, [countryIds])

const handleImage=(event)=>{
  if (event.target.files && event.target.files.length) {
    const imageFile = event.target.files[0];
    console.log("component", imageFile);
    try {
      const formData = new FormData();
      formData.append("file", imageFile);
      const config = {
        method: "POST",
        url: "/s3/file/upload",
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      }
      try {
        API.request(config).then(res =>{

          const uploadDetails = res?.data;
          console.log("upload", uploadDetails);
          setImageUrl(uploadDetails?.s3Url ?? "");
          
        });
  
      }
      catch (error) {
        console.log("when handling image", error);
      }
     


    } catch (error) {
      console.log(
        "handleImageFileSelect:: Error on uploading image file!",
        error
      );

    }
  }
}


  const getNames = () => {
    const promises = [];

    countryIds.forEach((countryId) => {
      promises.push(API.get(`api/v1/product/${prodid}?country=${countryId}`));
    })

    // Once all promises are resolved, update the state
    Promise.all(promises).then((responses) => {
      
      const variants = responses.map((response) => response.data.newVariants.map((variant) => { return ({ "name": variant.variants.name, "varid": variant.variants.id, "pricing": variant.pricings[0] }) }));
      
     
      setVariantsPrice(variants);
      
      console.log("variantprice", variants);
    })

  }
   useEffect(()=>{
    setVariantIdPrice(variantsPrice.filter((variant)=>variant.length>0).flat().filter((price)=>id==price.varid).filter((variant)=>variant.pricing));
   },[variantsPrice])

   useEffect(()=>{
   console.log("variants det",variantIdPrice)
   },[variantIdPrice])
  
   const handleDeleteVariant=(id)=>{
     setDeleteVariant({"variants":{"name": variantname,
     "quantity":quantity ,
     "image":image ,
     "price": 234,
     "id":id,
     "isActive":false,
     "productId": prodid,
     "createdAt": "1997-03-04T00:00:00",
     "modifiedAt": "1997-09-09T00:00:00"},
 
 
 "pricings":
   variantIdPrice.map((price)=>{return{"id":price.pricing.id,
     "countryId":price.pricing.countryId,
     "retailPrice":price.pricing.retailPrice,
     "finalPrice":price.pricing.finalPrice,
     "discount":price.pricing.discount,
     "variantId":id}}
 
 )})
 setDeleteStatus(true);
}

useEffect(()=>{
  if(deleteStatus && deleteVariant){
  API.patch(`/module/variants/${id}`,deleteVariant).then((res)=>console.log(res));
  setDeleteStatus(false);
  deletefalg(true);
  }
 },[deleteStatus])

 const isEmpty = (value) => {
  let emptyval=false;
 if(typeof(value) ==="undefined")
  emptyval=true;
 else if(value === "")
 emptyval=true;
 else if( value === null )
 emptyval=true;
return emptyval;
}
const showSuccessNotification = (notificationMessage) =>
showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});

 const validateFields=(variantname,quantity,image)=>{

  const validationError={};
  console.log("dddd",variantname)
  if(isEmpty(variantname)){
  validationError.variantname="VariantName is a Mandatory field";
  console.log("checking name")
  }
  if (isEmpty(quantity))
  validationError.quantity="Quantity is a required field!";

else if(quantity>10 || quantity<1)
validationError.quantity="Variant quantity between 1-10"; 
  
if (isEmpty(image))
validationError.image="Image is a required field!";
 return validationError; 

 }
      
     const handleEditVariantDetails=(e)=>{
      const valid=validateFields(variantname,quantity,image);
      setValidationError(valid);
      if(Object.keys(valid).length==0){
         setEditDetails({"variants":{"name": variantname,
         "quantity":quantity ,
         "image":image ,
         "price": 234,
         "id":id,
         "isActive":variantstatus=="Active"?true:false,
         "productId": prodid,
         "createdAt": "1997-03-04T00:00:00",
         "modifiedAt": "1997-09-09T00:00:00"},
     
     
     "pricings":
       variantIdPrice.map((price)=>{return{"id":price.pricing.id,
         "countryId":price.pricing.countryId,
         "retailPrice":price.pricing.retailPrice,
         "finalPrice":price.pricing.finalPrice,
         "discount":price.pricing.discount,
         "variantId":id}}
     
     )})
     setEditStatus(true);
      }
     }
     useEffect(()=>{
      if(editstatus && editDetails){
        try{

        
      API.patch(`/module/variants/${id}`,editDetails).then((res)=>showSuccessNotification("variant edit success"));
        }
        catch(error){
          console.log("error is",error);
          showErrorNotification("error in saving");
        }
      
      setEditStatus(false);
      }
     },[editstatus])

     return(
        <div className="variantstyle alignrow">
     <div className="alignrow">


     <div> {id}</div>
     <div className="columnalignactive ">
    <div className="imageblock"> 
   <label className="label"> 
   <input  id="img" type="file" name="uploadfile" onChange={handleImage} required style={{display:"none"}}/>
   <span>+ Image</span>
   </label>
   <img className="imagevariantsize" src={imageUrl} />
   </div> 
   <span className="help-block">{validationError.image}</span>
   </div>
   <div className="columnalignactive "><div className="group"><label for="name">Variant Name</label>    <input type="number"   id="name" style={{width:"20em"}} value={variantname} onChange={handlevariantname}/> </div>
   <span className="help-block">{validationError.variantname}</span>
   </div>
   <div className="columnalignactive "><div className="group">  <label for="qty">Quantity</label><input type="number"  id="qty" style={{width: "7em"}} value={quantity }   onChange={handlevariantquantity}/>    </div>
   <span className="help-block">{validationError.quantity}</span>
   </div>
   <div className="columnalignactive "><div> Active </div> <div><input type="checkbox" value="Active" checked={variantstatus=="Active"}   onChange={handlevariantstatus}/></div></div>
   <div><input className="donebtn" type="button" onClick={handleEditVariantDetails} value="Edit" /></div>
   <div><Button onClick={ ()=>handleDeleteVariant(id)}> <DeleteIcon  fontSize="small"    /> </Button></div>
     </div>
     </div>
     )
       
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VariantViewNew);
