import React,{useState} from 'react'
import EmailIcon from '@mui/icons-material/Email';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import Login from './Login';
import { Link } from 'react-router-dom';
function Signup(props) {
  const[username,setUsername]=useState("");
  const[password,setPassword]=useState("");
  

  return (
    <div className="signup">

      <div className="shopifysignup">
       <div className="Namestyle"><b>Shopify</b> </div>
       <div> <div className="createsen">Create a Shopify ID </div>
       <div className="subhead">One last step before starting your free trial </div>
       </div>
    <Link to="/dashboard/Emailsignup"  > <div className="emailblock">
       <EmailIcon  /> &nbsp;Continue with Email
       </div></Link>
       
       <div>By proceeding, you agree to the Terms and Conditions and Privacy Policy
        <br/><br/>
       Already have a Shopify ID?<Link to="/dashboard/Login" >Log in</Link> </div>
       
      </div>
    </div>
  )
}

export default Signup;