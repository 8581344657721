import React, { useState, useEffect } from 'react';
import { Card, Modal, } from 'react-bootstrap';
import "react-toggle/style.css"
import { connect } from "react-redux";
import Toggle from 'react-toggle';
import './dealSection.scss';
import Select from 'react-select';
import API from "../../utils/API"
import ProductSection from './ProductSection';


const DealSection = (props ) => {
  const { data, selectCountry, index, handlefetch } = props;
  const { deal={}, dealProductVariantDtoList = [] } = data || {};


  const [deals, setDeals] = useState({});
  const [dealProducts, setDealProducts] = useState([]);
  // const [image, setImage] = useState(null);
  // const [imageUrl, setImageUrl] = useState("");
  // const [icon, setIcon] = useState(null);
  const [iconUrl, setIconUrl] = useState();
  const [isEditable, setIsEditable] = useState()
  const [count, setCount] = useState(1)
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if(deal.id){
      setDeals(deal);
      setDealProducts(dealProductVariantDtoList);
    }
  }, [])

  const handleDealToggle = (e) => {
    setDeals({ ...deals, isDealActive: e.target.checked })
  }
  const handleTimeToggle = (e) => {
    setDeals({ ...deals, isTimerActive: e.target.checked })
  }

  const handleInputChange = (fieldName, value) => {
    setDeals({ ...deals, [fieldName]: value })
  };

  const uploadImage = async (imageFile,index) => {
    try {
      const formData = new FormData();
      formData.append('file', imageFile);

      const response = await API.post('/s3/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setDeals({ ...deals, backgroundImageUrl: response.data.s3Url });
      // setImageUrl(response.data.s3Url)
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  };

  const uploadIcon = async (index, iconFile) => {

    try {
      const formData = new FormData();
      formData.append('file', iconFile);

      const response = await API.post('/s3/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    
      setDeals({ ...deals, tagIconUrl: response.data.s3Url })
      setIconUrl(response.data.s3Url)
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  }

  const handleImageChange = (e,index) => {
    const file = e.target.files[0];
    if (file) {
      // setImage(file);
      uploadImage(file,index);
    }
  };
  const handleIconChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      // setIcon(file);
      uploadIcon(index, file);
    }
  }
  const handleValidationError = () => {
    console.log("dealProductsValidation",dealProducts)
    let newError = {};
    if (!deals.heading)
    newError.heading = 'Heading is required'
    if (!deals.headingArabic)
      newError.headingArabic = 'Heading Arabic is required'
    if (!deals.backgroundImageUrl){
      newError.backgroundImageUrl = 'Background Image is required';
    }
    if (!deals.countDownStartsAt)
      newError.countDownStartsAt = 'CountDown Starts At is required';
    if (!deals.countDownEndsAt)
      newError.countDownEndsAt = 'CountDown Ends At is required';
    if (!deals.seoUrl)
      newError.seoUrl = "Seo URL is required"
      dealProducts.map(data => {
        if (data.dealDiscountPrice < 0 || !data.dealFinalPrice || !data.dealListPrice) {
          showErrorNotification("All price fields are required");
          newError = "All prices fields are required"
          return; 
        }
    
      });
    setErrors(newError)
    return Object.keys(newError).length === 0;
  }

  const handelSaveDeal = async () => {
    const validate = handleValidationError();
    if (validate) {
      if (deals.id) {
        const payload = {
          "deal": {
            ...deals,
          },
          "dealProductVariantDtoList": dealProducts,
        }
    
        
       
        const res = await API.put(`api/v1/deals/update`, payload);
        if(res.status == 200){
          showSuccessNotification("Deal Updated Succefully")
          setIsEditable(prev=>false)
          handlefetch();
        }
        else{

        }
      }
      else {
        if(deals.isDealActive==null){
          deals.isDealActive=false;
        }
        if(deals.isTimerActive==null){
          deals.isTimerActive=false;
        }
        const payload = {
          "deal": {
            ...deals,
            countryId: selectCountry.value
          },
          "dealProductVariantDtoList": dealProducts,
        }
        const res = await API.post(`api/v1/deals/save`, payload);
        if(res.status == 200){
          showSuccessNotification("Deal Created succefully")
          setIsEditable(prev=>false)
          handlefetch();
        }
        else{

        }
       
      }
    }
    else{

    }

  }

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });

  return (
    <>
      <Card key={index + "card"} Body>
        <div key={index} className='dealContainer'>
          <div className='mainHeader'>
            <div className='dealNumber'>Deal {index + 1}</div>
            <Toggle
              checked={deals?.isDealActive || false}
              onChange={(e) => handleDealToggle(e)}
            />

            <div className='saveBtn' onClick={() => handelSaveDeal()}>{deals.id ? "Update" : "Save"}</div>
            <div className='editBtn' onClick={() => setIsEditable(true)}>Edit</div>
          </div>
          <div className='dealDescription'>
            <div className='columnalignactive'>
              <div className='group'>
                <label className='labelTitle' for="heading">Heading*</label>
                <input name="heading" type="text" placeholder="Heading" value={deals?.heading} disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
                {errors.heading && <span style={{ fontSize: '12px', color: 'red' }}>{errors.heading}</span>}
              </div>
            </div>
            <div className='columnalignactive'>
              <div className='group'>
                <label className='labelTitle' for="headingArabic">Heading (Arabic)*</label>
                <input name="headingArabic" type="text" placeholder="HeadingArabic" value={deals?.headingArabic} disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
                {errors.headingArabic && <span style={{ fontSize: '12px', color: 'red' }}>{errors.headingArabic}</span>}
              </div>
            </div>

            <div className='columnalignactive'>
              <div className='group'>
                <label className='labelTitle' for="tag">Tag</label>
                <input name="tag" type="text" placeholder="Tag" value={deals?.tag} disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'}
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
            <div className='columnalignactive'>
              <div className='group'>
                <label className='labelTitle' for="tagArabic">Tag (Arabic)</label>
                <input name="tagArabic" type="text" placeholder="Tag Arabic" value={deals?.tagArabic} disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'}
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                />
              </div>
            </div>
            <div className='columnalignactive iconImage'>
              <label className='icon' for="backGroundIcon">Icon</label>
              <div className='iconDiv'>
                <div>
                <label className='iconTxt' htmlFor={`backGroundIcon-${index}`} style={{position:"relative"}}>
                {deals.tagIconUrl ? <img src="https://d25uasl7utydze.cloudfront.net/assets/edit.svg" alt="Edit" /> : "+Icon"}
                <input
                  name="backGroundIcon"
                  style={{ height: "80px", display: "none" }}
                  type="file"
                  id={`backGroundIcon-${index}`} 
                  accept="image/*"
                  onChange={(e) => handleIconChange(index, e)}
                  disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'}
                />
              </label>
              </div>

                {(deals?.tagIconUrl) && <img
                  style={{ position: "relative", width: "32px", top: "-10px" }}
                  src={iconUrl || deals?.tagIconUrl} alt="Uploaded" />}
              </div>
            </div>



            <div className='columnalignactive bgImage'>
              <label className='backGroundImage' htmlFor="backGroundImage">Background image*</label>
              <div className='imageDiv'>
                <div>
                <label className='imageTxt' htmlFor={`backGroundImage=${index}`} style={{position:"relative"}}>
              {deals.backgroundImageUrl ? <img src="https://d25uasl7utydze.cloudfront.net/assets/edit.svg" alt="Edit" /> : "+Image"}

                <input
                  style={{ height: "80px", display: "none" }}
                  type="file"
                  id={`backGroundImage=${index}`}
                  accept="image/*"
                  onChange={(e) => handleImageChange(e,index)}
                  disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'}
                />
                </label>
          
              </div>
                {(deals?.backgroundImageUrl) &&
                  <img
                    style={{
                      position: "relative", bottom: "16px",
                      left: "30px",
                      width: "150px",
                      height: "68px",
                    }}
                    src={deals?.backgroundImageUrl} alt="Uploaded" />
                }
                
              </div>
              {errors.backgroundImageUrl && <span style={{ fontSize: '12px', color: 'red' }}>{errors.backgroundImageUrl}</span>}
            </div>

          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "11px", marginTop: "10px" }}>
            <b>Timer</b>
            <Toggle
              checked={deals?.isTimerActive}
              onChange={(e) => handleTimeToggle(e)} />
            {/* disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'} */}
          </div>


          <div className='dealTimer'>

            <div className='columnalignactive'>
              <div className='group'>
                <label className='labelTitle' for="countDownStartsAt">Countdown Starts at*</label>
                <input name="countDownStartsAt" type="datetime-local" value={deals?.countDownStartsAt}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                  disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'} />
                  {errors.countDownStartsAt && <span style={{ fontSize: '12px', color: 'red' }}>{errors.countDownStartsAt}</span>}
              </div>
            </div>
            <div className='columnalignactive'>
              <div className='group'>
                <label className='labelTitle' for="countDownEndsAt">Countdown Ends at*</label>
                <input name="countDownEndsAt" type="datetime-local" value={deals?.countDownEndsAt}
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                  disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'} />
                  {errors.countDownEndsAt && <span style={{ fontSize: '12px', color: 'red' }}>{errors.countDownEndsAt}</span>}
              </div>
            </div>

          </div>

          <div className='columnalignactive'>
            <div className='group'>
              <label className='labelTitle' for="seoUrl">SEO url*</label>
              <input name="seoUrl" type="text" placeholder="seoUrl" value={deals?.seoUrl}
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                disabled={!isEditable} className={isEditable ? 'enable-input' : 'disabled-input'}
              />
              {errors.seoUrl && <span style={{ fontSize: '12px', color: 'red' }}>{errors.seoUrl}</span>}
            </div>
          </div>

        </div>
        <ProductSection dealProducts={dealProducts} setDealProducts={setDealProducts} key={index} selectCountry={selectCountry} />
      </Card>
    </>
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealSection);