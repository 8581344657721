import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";

function EditTaxViewModal(props) {
  const { isVisible = false, onHide = (f) => f, onSave = (f) => f } = props;

  const [chargeTaxes, setChargeTaxes] = useState(props.chargeTaxes ?? false);

  useEffect(() => {
    setChargeTaxes(props.chargeTaxes ?? false);
  }, [props.chargeTaxes, isVisible]);

  const form = (
    <>
      <div className="row">
        <span>Taxes are automatically calculated.</span>
      </div>
      <div className="row mt-2">
        <div className="form-group col">
          <Form.Check
            type="checkbox"
            checked={chargeTaxes}
            onChange={(event) => {
              setChargeTaxes(event.target.checked);
            }}
            id="chargeTaxes"
            name="chargeTaxes"
            label="Charge taxes"
          />
        </div>
      </div>
    </>
  );

  return (
    <Modal show={isVisible} onHide={onHide} size="sm" centered>
      <Modal.Body>{form}</Modal.Body>
      <Modal.Footer>
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-sm btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}} 
          onClick={(event) => onSave(chargeTaxes)}
          type="button"
          className="btn btn-sm btn-success ml-2"
        >
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditTaxViewModal;
