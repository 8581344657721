import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Divider, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import API from "../../../utils/API"
import Spinner from "../../../components/Spinner/Spinner"
import { useParams, useHistory } from "react-router-dom";

const AddAuthor = (props) => {
  const [imageFile, setImageFile] = useState(null);
  const [imageurl, setImageUrl] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState(false)
  const params = useParams();

  const authorId = params.id
  const [formState, setFormState] = useState({
    id: view ? authorId : null,
    email: '',
    isActive: "false",
    image: '',
    specialization: '',
    userName: '',
    description: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    userName: '',
    specialization: '',
    description: '',
    isActive:''
  });

 
  

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      setImageFile(file)
      uploadFileAll(file)

    }
  };


  const uploadFileAll = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await API.post('/s3/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const url = response?.data;
      setFormState((prevState) => ({
        ...prevState,
        image: url?.s3Url || '',
      }));
    } catch (error) {
      console.log('Error handling image', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };


  const validateForm = () => {
    const newErrors = {};
    if (!formState.email.trim()) {
      newErrors.email = 'Email is required';
    }
    else if (!/\S+@\S+\.\S+/.test(formState.email.trim())) {
      newErrors.email = 'Invalid email format';
    }

    if (!formState.userName.trim()) {
      newErrors.userName = 'UserName is required';
    }

    if (!formState.specialization.trim()) {
      newErrors.specialization = 'Specialization is required';
    }

    if (!formState.description.trim()) {
      newErrors.description = 'Description is required';
    }
    if (!formState.isActive) {
      newErrors.isActive = 'Status is required';
    }

    // Set errors and return true if there are any errors
    setErrors(newErrors);
    return Object.keys(newErrors).length > 0;
  };

  const saveAuthor = async () => {
    try {
      setIsLoading(true);
      if (validateForm()) {
       return true; 
      }
      

      const payload = {
        ...formState,
        password: "Dummy@123",
        firstName: "",
        lastName: "",
        dateJoined: "",
        dateModified: "",
        };
        const apiEndpoint = view ? "authors/update" : "authors/create"
        const method = view ? "PATCH" : "POST";
      const response = await (method === "PATCH" ? API.patch : API.post)(apiEndpoint, payload);
      props.history.goBack()
      view ? showSuccessNotification("Author is updated successfully") : showSuccessNotification("Author is created successfully")
      console.log('Author created successfully', response);
    } catch (error) {
      console.log('Error creating author', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {

    if (authorId) {
      viewAuthor();
      setView(true)
    }
  }, [])


  const viewAuthor = async () => {
    showLoading();
    try {
      const data = await API.get(`authors/${authorId}`)
      hideLoading()
      const res = data.data;

      const newObj = {
        ...res , "isActive" : res["active"]
      }
      delete newObj["active"]
      setFormState(newObj)
     
      setImageFile(res.image)
     }
    catch {

    }
  }
  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });
  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  return (
    <>
      <div style={{ alignSelf: "flex-start", display: 'flex', gap: '10px', }}>
        <Button
          onClick={() => props.history.goBack()}
          variant="outlined"
          color="inherit"
          size="large"
        >
          <ArrowBackIcon />
        </Button>
        <h5> {view ? formState.userName : "Add Author User"}</h5>

        <div className="col text-end">
          <Button

            variant="contained"
            size="large"
            color="success"
            onClick={saveAuthor}
            sx={{
              bgcolor: "#247A81",
              color: "white",
              borderRadius: "20px",
              ":hover": {
                bgcolor: "#125443",
               
              },
              textTransform: "capitalize",
            }}
          >
            {view ? "Update" : "Save"}
            
          </Button>
        </div>
      </div>
      <br /><br />
      <Card body>



        <div style={{ display: 'flex', }}>

          <div style={{ display: "flex", flexDirection: "column", gap: "20px", width:"50%" }}>
            <div className="columnalignactive">
              <div className="group"  style={{width:"70%"}}> <label for="name">UserName *</label> <input className=""
                type="text"
                name="userName"
                value={formState.userName}
                onChange={handleChange}
                placeholder="UserName" />
                {errors.userName && (
                  <span className="help-block" style={{ display: 'block' }}>
                    {errors.userName}
                  </span>
                )}
              </div>
            </div>



            <div>
              <b>Status*</b>
              <div className='status'style={{width:"70%"}} >
                <select
                style={{width:"100%"}}
                  name="isActive"
                  value={formState.isActive}
                  onChange={handleChange}>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                  
                </select>
                {/* {errors.isActive && (
                  <span className="help-block" style={{ display: 'block' }}>
                    {errors.isActive}
                  </span>
                )} */}
              </div>
            </div>

            <div className="headingcolor"> <div>Specialization *</div>

              <input
             style={{width:"70%", height:"45px"}}
                name='specialization'
                value={formState.specialization}
                onChange={handleChange}
                type="text"
                placeholder='Specialization' />
              {errors.specialization && (
                <span className="help-block" style={{ display: 'block' }}>
                  {errors.specialization}
                </span>
              )}
            </div>

            <div className="headingcolor"> <div>Description *</div>

              <input
              style={{width:"70%", height:"89px"}}
                name="description"
                value={formState.description}
                onChange={handleChange}
                type="text"
                placeholder='Description' />
              {errors.description && (
                <span className="help-block" style={{ display: 'block' }}>
                  {errors.description}
                </span>
              )}
            </div>
          </div>

          <div style={{width:"50%"}}>
            <div className="group" style={{width:"70%"}}> <label for="name">Email*</label> <input className=""
              type="email"
              name='email'
              placeholder="email"
              value={formState.email}
              onChange={handleChange}
            /> {errors.email && (
              <span className="help-block" style={{ display: 'block' }}>
                {errors.email}
              </span>
            )}
            </div>

            <div style={{marginTop:"20px"}}> <b>Image</b></div><br />
            <div className="imagecountryblock" style={{width:"70%", height:"240px"}}>
              <div style={{ backgroundColor: "#247A81", color: "#FFFFFF", fontSize: "12px" }}>
                <label style={{cursor:"pointer"}}> {imageFile ? <img src="https://d25uasl7utydze.cloudfront.net/assets/edit.svg" /> : "+Image"}
                  <input style={{ display: "none",width:"100%",  }}
                    type="file"
                    className="variantbutton"
                    accept="image/*"
                    onChange={handleImageUpload} ></input>
                </label>
              </div>
              {imageFile && (
                <img
                  src={formState.image}
                  alt="Selected Image"
                  style={{ width: '64%', height: 'auto', }}
                />
              )}
            </div>

          </div>
        </div>




      </Card>
      <Spinner isShow={isLoading} />
    </>
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAuthor);