import React,{useState,useEffect} from 'react';
import DealSection from './DealSection';
import API from '../../utils/API';
import Select from 'react-select';
import { connect } from "react-redux";
const DealPage = (props) => {
  const [dealPage,setDealPage] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectCountry, setSelectCountry] = useState({ label: 'Bahrain', value: 8,id:8 });

  const fetchAllDeals = async()=>{
    try{
      showLoading()
      setDealPage()
      const res =await API.get(`api/v1/deals/all?countryId=${selectCountry.value}`);
      if(res.status== 200){
        hideLoading();
        const data = res.data;
        setDealPage(data);
      }
      else{
        hideLoading();
        console.log("Error in fetching the data")
      }
     
    }
    catch(error){
      hideLoading()
      console.log("Error in fetching the data",error);
    }
  }
  useEffect(()=>{
    fetchAllDeals();
  },[selectCountry,])

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await API.get("api/v1/active/countries");
        setCountries(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchCountries();
  }, []);

  const handlefetch=()=>{
    fetchAllDeals()
  }

  const addDeal = ()=>{
    const newDeal = {};
      setDealPage([ ...dealPage,newDeal]);
  }


  const countryOptions = countries.map(country => ({
    label: country.name,
    value: country.id,
    id: country.id
  }));
  
  const showLoading = () => props.toggleLoading({ isLoading: true });
  
  const hideLoading = () => props.toggleLoading({ isLoading: false });

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });
  return (
    <>
      <div className='dealHeader'>
        <div className='title'>Deal Section</div>
        <div className='addTxt' onClick={addDeal}>+ Add Deals</div>
        <div className='selectCountry'>
          <label>Select Country*</label>
          <Select
            onChange={(countryOptions) => {
              setSelectCountry(countryOptions);
            }}
            value={selectCountry}
            options={countryOptions}
          />
        </div>
      </div>
  
      {dealPage?.map((data, index) => {
        console.log("daat",data)
        return (
          <div key={index}>
            <DealSection data={data} selectCountry={selectCountry} index={index} handlefetch={handlefetch} />
          </div>
        );
      })}
    </>
  );
   }

    function mapStateToProps(state) {
      return {};
    }
    
    function mapDispatchToProps(dispatch) {
      return {
        showNotificationMessage: (value) =>
          dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
      };
    }
    
    export default connect(mapStateToProps, mapDispatchToProps)(DealPage);