import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Navigation from "./Navigation";
import logo_img from "../assets/img/kuwa_logo.png";

class Sidebar extends Component {
  render() {
    const {
      expandMenu = false,
      menuExpanded = false,
      setRouterName = (f) => f,
    } = this.props;
// console.log("aaaaaa",menuExpanded)
    return (
      <div className={"sidebar " + (menuExpanded ? "" : "collapsed")}>
        <div className="logo-container">
          {/* <MenuIcon className="menu-icon" onClick={this.props.toggleMenu} /> */}
          <NavLink className="logo" activeClassName="active" to="/dashboard">
            <img src={logo_img} alt="logo" className="valeo-logo" />
          </NavLink>
        </div>
        <Navigation
          expandMenu={this.props.expandMenu }
          setRouterName={this.props.setRouterName}
          menuExpanded={menuExpanded}
        />
      </div>
    );
  }
}

export default Sidebar;
