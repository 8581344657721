import React, { useCallback, useEffect, useState } from "react";
import API from "../../utils/API";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Search from '../../components/Search/Search';
import './product-module.scss';
import TableConfig from "../../utils/tableConfig";
import Select from "react-select";
import CrossIcon from "../../assets/img/cross_icon.png"
import ExportPopUp from "./ExportPopUp";
import ImportPopUp from "./ImportPopUp";

const collection_base_url_brand = "module/collections/12";
const collection_base_url_health = "module/collections/18";

const status_option = [{
  label:"Active",
  value:"Active",
  id:1
},
{
  label:"Inactive",
  value:"Inactive",
  id:0
},
]

const ShowFilterPopUp = ({
  isVisible ,onHide,applyFilter,collectionOfBrands,collectionOfHealth,selectedOptionOFBrand,
  setSelectedOptionOFBrand,setSelctOptionOfHealth,selectOptionOfHealth,setSelectStatus,selectStatus})=>{
  
 
 
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [isHealthSelected, setIsHealthSelected] = useState(false);
 
  console.log("selectedOptionOFBrand",selectedOptionOFBrand)
  const collectionDataOfBrand = collectionOfBrands?.map((data) => ({
    name: data.name,
    label: data.name,
    value: data.id,
  }));

  const collectionDataOfHealth = collectionOfHealth?.map((data) => ({
    name: data.name,
    label: data.name,
    value: data.id,
  }));

  const handleChange  = (option)=>{
    setSelectStatus([option]);
    setIsBrandSelected(false);
    setIsHealthSelected(false);
  }

  const handleChangeBrand = (option) =>{
    setSelectedOptionOFBrand([option]);
    setIsBrandSelected(true);
    setIsHealthSelected(false);
  }
  const handleChangeHealth = (option) =>{
    setSelctOptionOfHealth([option]);
    setIsBrandSelected(false);
    setIsHealthSelected(true);
  }

  console.log("selectOption",selectStatus)
  const onDeleteItemOfHealth = (value) => {
    const filterItem = selectOptionOfHealth.filter((data, i) => data.value
    !== value);
    setSelctOptionOfHealth(filterItem);
    setIsHealthSelected(filterItem.length > 0);
  }

  const onDeleteItemOfBrand = (value) => {
    const filterItem = selectedOptionOFBrand.filter((data, i) => data.value
    !== value);
    setSelectedOptionOFBrand(filterItem)
    setIsBrandSelected(filterItem.length > 0)
  }
  const onDeleteStatus = (value) => {
    const filterItem = selectStatus.filter((data, i) => data.value
    !== value);
    setSelectStatus(filterItem)
  }
  const clearFilter = ()=>{
    setSelectStatus([]);
    setSelectedOptionOFBrand([]);
    setSelctOptionOfHealth([]);
    setIsBrandSelected(false);
    setIsHealthSelected(false);
  }

 



 
  return(
    <Modal show={isVisible} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
        <div className="row">
        <div className="form-group col">
              <label htmlFor="">Status</label>
              <div className="select-container">
              <Select
                
                classNamePrefix="select"
                options={status_option}
                onChange={handleChange}
                oncloseMenuOnSelect={false}
                value={selectStatus}
                />
              </div>
            </div>
            <div>
      </div>
      <div className="list-item-container" style={{display:"flex"}}>

{
  selectStatus && selectStatus.map((data,index)=>{
    return(
      <div className="list-item" style={{width:"100px"}} >
        <div className="list-item-txt">{data.label}</div>
        <img src={CrossIcon} onClick={()=>onDeleteStatus(data.value)} className="cross-icon" alt="cross-icon"/>
      </div>
    )
    
  })
}

</div>
        </div>


          <div className="row">
            <div className="form-group col">
              <label htmlFor="">Brands</label>
              <div className="select-container">
                <Select
                 isDisabled={isHealthSelected}
                classNamePrefix="select"
                options={collectionDataOfBrand}
                onChange={handleChangeBrand}
                oncloseMenuOnSelect={false}
                value={selectedOptionOFBrand}
                />
           
              </div>
            </div>
            <div>
 <div className="list-item-container" style={{display:"flex"}}>

{
  selectedOptionOFBrand && selectedOptionOFBrand.map((data,index)=>{
    return(
      <div className="list-item" style={{width:"100px"}} >
        <div className="list-item-txt">{data.label}</div>
        <img src={CrossIcon} onClick={()=>onDeleteItemOfBrand(data.value)} className="cross-icon" alt="cross-icon"/>
      </div>
    )
    
  })
}

</div>
            </div>
          </div>
          {/* <div className="row">
            <div className="form-group col">
              <label htmlFor="">Health</label>
              <div className="select-container">
                <Select
                classNamePrefix="select"
                isDisabled={isBrandSelected}
                options={collectionDataOfHealth}
                onChange={handleChangeHealth}
                oncloseMenuOnSelect={false}
                value={selectOptionOfHealth}
                />
           
              </div>
            </div>
            <div className="list-item-container" style={{display:"flex"}}>

{
  selectOptionOfHealth && selectOptionOfHealth.map((data,index)=>{
    return(
      <div className="list-item" style={{width:"100px"}} >
        <div className="list-item-txt">{data.label}</div>
        <img src={CrossIcon} onClick={()=>onDeleteItemOfHealth(data.value)} className="cross-icon" alt="cross-icon"/>
      </div>
    )
    
  })
}

        </div>
          </div> */}
        </>
      </Modal.Body>
      <Modal.Footer>
        <button
          style={{ borderRadius: '20px' }}
         
          type="button"
          className="btn btn-md btn-outline-secondary"
          onClick={()=>clearFilter()}
        >
          Clear Filter
        </button>
        <button
          style={{ background: '#FF0000', borderRadius: '20px' }}
          onClick={applyFilter}
          type="button"
          className="btn btn-md btn-success ml-2"
        >
         Apply Filter
        </button>
      </Modal.Footer>
    </Modal>

  )
}


function Products(props) {

  const [products, setProducts] = useState([])
  const [productdetails, setProductdetails] = useState([]);
  const [showproduct, setShowproduct] = useState();
  const [editshow, setEditshow] = useState(false);
  const [editrow, setEditRow] = useState(false);
  const [selectRowId, setSelectRowId] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [countries,setCountries] = useState([])

  const [ currentPage , setCurrentPage] = useState(0);
  const [ recordsTotal , setRecordsTotal]  = useState(0);
  const [ rowsPerPage, setRowPerPage] = useState(50);
  const [ totalPages , setTotalPages ] = useState(0);
  const [sortDirection , setSortDirection] = useState('desc');
  const [sortField , setSortField] = useState('ID');
  const [clearSearch, setClearSearch] = useState(false)
  const [isFilterPopUpVisible,setIsFilterPopUpVisible] = useState(false)
  const [collectionOfBrands,setCollectionOfBrands] = useState([]);
  const [collectionOfHealth,setCollectionOfHealth] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [ selectedOptionOFBrand, setSelectedOptionOFBrand ] = useState([]);
  const [selectOptionOfHealth, setSelctOptionOfHealth] = useState([]);
  const [selectStatus,setSelectStatus] = useState([]);
  const [selectedHealthId,setSelectedHealthId] = useState("");
  const [selectedBrandId,setSelectedBrandId] = useState("");
  const [statusId,setStatusId] = useState("");


  const [isShowExportPopUp,setIsShowExportPopUp] = useState(false);
  const [isShowImportPopUp,setIsShowImportPopUp] = useState(false)

  // let selectedHealthId;
  // let selectedBrandId;
  // let statusId;

  const handleCheckboxChange = (e,row) => {
    const updatedSelectedRows = [...selectedRows];
    if (e.target.checked) {
      // console.log("sbhbhsa",row.selected)
      console.log("santoshsbjs")
      // If checkbox is selected, add to the selected rows
      updatedSelectedRows.push(row.id);
    } else {
      // If checkbox is unselected, remove from the selected rows
      const index = updatedSelectedRows.indexOf(row.id);
      if (index !== -1) {
        updatedSelectedRows.splice(index, 1);
      }
    }
    console.log("updatedSelectedRows",updatedSelectedRows)
    setSelectedRows(updatedSelectedRows);
  };

  const putCheckBox = (row) => {
    // console.log("bdshb", row);
    return (
      <input
        type="checkbox"
        checked={selectedRows?.includes(row.id) || false}
        onChange={(e) => handleCheckboxChange(e, row)}
      />
    );
  };

  const handleHeaderCheckboxChange = (e) => {
    const allProductIds = products.map((row) => row.id);
    setSelectedRows(e.target.checked ? allProductIds : []);
  };
  

  const columns = [
 {  name: (
  <input
    type="checkbox"
    onChange={handleHeaderCheckboxChange}
    checked={
      selectedRows.length === products.length &&
      products.length > 0
    }
  />
),
  selector:"checkbox",

    cell:(row) => putCheckBox(row),
    
    allowOverflow: true,
    // button: true,
    // maxWidth: "150px",
    // center:true
    width:"45px"
  },
  {
    name: "ID",
  //  selector:(product)=>product.id,
   sortField: "ID",
   sortable: true,
   cell:(row)=>row.id,
   width:"130px",
  //  minWidth:"120px"
  //  center:true,
  //  maxWidth:"100px",
  
  },
    {
      name: "Products",
      selector:"name",
      sortable: true,
      // center:true,
      sortField: 'NAME',
      // minWidth:"500px"
      width:"600px",
      cell:(row)=> renderProductName(row)

    },
    {
      name: "Inventory",
      selector:(product)=> product.quantity ,
      sortable: true,
      center: true,
      sortField: 'QUANTITY',
      // maxWidth: "150px",
   width:"120px"
    },
    {
      name: "Country",
      selector: "countries",
      // sortable: true,
      sortField: "country",
      // maxWidth: "300px",
      width:"150px",
      format:(product)=>product.countries?.split(",").map((data,index)=>{
        return( <div key={index}>{data}</div>)
      })
    },

    {
      name: "Brands",
      selector:"brands",
      sortable: true,
      // center:true,
      sortField: 'NAME',
      // minWidth:"500px"
      width:"200px"

    },
    {
      name: "Status",
      // selector:
      sortable: true,
      sortField: 'STATUS',
      width:"100px",
      cell:(product)=> product.status,
    },
  ]
 
  const renderProductName = (row) =>{
    return (
      <div onClick={()=> window.location.href = `/dashboard/product/${row.id}`} style={{
        maxHeight: "60px",
        overflowY: "auto",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
      }}>
        {row.name}
      </div>
    )
  }
  
  const handleBulkEdit = () => {
    // Implement your logic for bulk edit
    props.history.push(`/dashboard/product/bulkedit/${selectedRows}`)
  };
  
  const handleSetActive = () => {
    // Implement your logic for setting active
    console.log("svhavh", selectedRows);
    
    // Assuming you have an API endpoint for updating the status, you can use the following code
    API.patch(`api/v1/bulk/products/update/status?productIds=${selectedRows}&status=1`)
      .then((response) => {
        console.log("Status updated successfully", response.data);
  
        // After updating the status, unselect checkboxes and fetch updated data
        setSelectedRows([]);
        getSearchProductData(selectedBrandId, selectedHealthId, statusId);
      })
      .catch((error) => {
        console.error("Error updating status", error);
      });
  };
  


  const handleSetInactive = () => {
    // Implement your logic for setting active
    console.log("svhavh", selectedRows);
    
    // Assuming you have an API endpoint for updating the status, you can use the following code
    API.patch(`api/v1/bulk/products/update/status?productIds=${selectedRows}&status=0`)
      .then((response) => {
        console.log("Status updated successfully", response.data);
  
        // After updating the status, unselect checkboxes and fetch updated data
        setSelectedRows([]);
        getSearchProductData(selectedBrandId, selectedHealthId, statusId);
      })
      .catch((error) => {
        console.error("Error updating status", error);
      });
  };
  

  const buttonContainer = selectedRows.length > 0 && (
    
    <div className="btn-container" style={{display:"flex",position:"fixed",top:"90%",left:"50%"
    ,zIndex:"100"}} >
    <button onClick={handleBulkEdit}>Bulk Edit</button>
    <button onClick={handleSetActive}>Set Active</button>
    <button onClick={handleSetInactive}>Set Inactive</button>
    </div>
  )

 


  const getSearchProductData = (selectedBrandId,selectedHealthId,statusId) =>{
    showLoading()
    try {
      const requestData = {
        message: searchValue
      };
    
      const queryParams = new URLSearchParams({
        page: currentPage,
        sort_order: sortDirection,
        sort_by: sortField,
        count: rowsPerPage,
        brands: selectedBrandId ? selectedBrandId : "",
        status: statusId ? statusId : "",
        healthGoals: selectedHealthId ? selectedHealthId : ""
      });
        API.post(`/api/v1/search/paginated/product/?${queryParams.toString()}`,requestData)
        .then((res) => {
          if (res.status === 200) {
            return res.data;
          } else {
            hideLoading(); 
            throw new Error("Request failed with status code: " + res.status);
          }
        })

          .then((data) =>{
          hideLoading()
          setProducts([...data.products])
          setRecordsTotal(data.recordsTotal)
          setTotalPages( data.totalPages )
       
        } ).catch(()=>{
          hideLoading()
        });

    } catch (error) {
      hideLoading()
      
      console.log("fetchProducts:: Error on fetching products!", error);
    }
    finally{
      setSelectedRows([]);
    }
  }

  const getCollectionOfBrand = async() =>{
    const res = await API.get(collection_base_url_brand)
    const collectionListOfBrand =res?.data
    setCollectionOfBrands(collectionListOfBrand)
  
  }

  const getCollectionOfHealth = async()=>{
    const res = await API.get(collection_base_url_health);
    const collectionListOfHealth = res?.data;
    setCollectionOfHealth(collectionListOfHealth);
    }


  useEffect(() => {
    
    API.get("api/v1/active/countries").then((res)=>res.data).then(data  =>  setCountries(data))
   
     
      }, [])


  useEffect(()=>{
    getCollectionOfBrand();
    getCollectionOfHealth();
  },[])

  useEffect(() => {
    getSearchProductData(selectedBrandId,selectedHealthId,statusId)
  }, [currentPage,rowsPerPage,sortDirection]);

  useEffect(()=>{
    if(clearSearch){
      getSearchProductData(selectedBrandId,selectedHealthId,statusId)
    }
  },[clearSearch])

  useEffect(() => {
    if (selectRowId)
      //props.history.push(`/dashboard/products/Edit/${selectRowId}`);
      props.history.push(`/dashboard/prods/EditNew/${selectRowId}`)
  }, [selectRowId]);

  const handlerowselect = (row) => {
    console.log("row", row.productid);
    setShowproduct(row.id);
    // props.history.push(`/dashboard/product/${row.productid}`);
    window.location.href = `/dashboard/product/${row.id}`
  }


  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });

  const onChangeRowsPerPage = (currentRowsPerPage,currentPage) =>{
    setRowPerPage(currentRowsPerPage)
  }

  const onChangePageClick = (page)=>{
    setCurrentPage(page-1)
  }

  const handleSearch = (val) =>{
    if (val) {
      setSearchValue(val);
      setClearSearch(false);
    } else {
      setSearchValue("");
      setClearSearch(true);
    }
  }

  const onSearchBtn = () =>{
      getSearchProductData(selectedBrandId,selectedHealthId,statusId)
  }

 const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      getSearchProductData(selectedBrandId,selectedHealthId,statusId)
    }
}


  const handleSort = (column, sortDirection) =>{
    console.log("column",column)
    console.log("columnsortDirectionsortDirection",sortDirection)
     setCurrentPage(0)
     setSortField(column.sortField)
     setSortDirection(sortDirection)

  }

  const handleShowFilterPopUpVisible =()=>{
    setIsFilterPopUpVisible(true)
  }
  const handleHideFilterPopUpVisible = ()=>{
    setIsFilterPopUpVisible(false)
  }
  const handleApplyFilter = ()=>{
    console.log("dmbs",selectOptionOfHealth)
    const healthId = selectOptionOfHealth && selectOptionOfHealth[0]?.value
    setSelectedHealthId(healthId);
     setSelectedBrandId(selectedOptionOFBrand[0]?.value)
    handleHideFilterPopUpVisible();
    console.log("selectStatus",selectStatus);

    setStatusId(selectStatus?.map((data)=>
     {
      if(data.value=="Active"){
        return 1;
      }
      else{
        return 0;
      }
   }))

    console.log("bhabha",selectedBrandId,statusId)
    // getSearchProductData(selectedBrandId,selectedHealthId,statusId); 
    
  }
  useEffect(() => {
    // Call the function when selectedBrandId, selectedHealthId, or statusId change
    getSearchProductData(selectedBrandId, selectedHealthId, statusId);
  }, [selectedBrandId, selectedHealthId, statusId]);


  const handleRemoveFilter = (id)=>{
    const updatedFiltersOfBrands = selectedOptionOFBrand.filter((data)=> data.value !== id)
    const updatedFiltersOfHealth = selectOptionOfHealth.filter((data)=> data.value !==id)
    const updatedStatus = selectStatus.filter((data)=>data.id !== id)
    setSelectedOptionOFBrand(updatedFiltersOfBrands);
    setSelctOptionOfHealth(updatedFiltersOfHealth);
    setSelectStatus(updatedStatus);

    const updatedBrandId = updatedFiltersOfBrands[0]?.value || "";
    setSelectedBrandId(updatedBrandId);
    const updatedHealthId = updatedFiltersOfHealth[0]?.value || "";
    setSelectedHealthId(updatedHealthId);
    const updatedStatusId = updatedStatus?.map((data) => (data.value === "Active" ? 1 : 0)) || "";
    setStatusId(updatedStatusId)
    // getSearchProductData(updatedBrandId,updatedHealthId,updatedStatusId);
  }

  const handleExport = ()=>{
    if(selectedRows.length > 0){
      setIsShowExportPopUp(true)
    }
    else{
      showErrorNotification("Please Select the Product")
    }
 
  }

  const handleImport = () =>{
    setIsShowImportPopUp(true)
  }

  

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });


console.log("selected")
  return (
<>
    <div className="productpage">
      <div className="header-div" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"5px"}}>
      <div className="select-filter"  onClick={handleShowFilterPopUpVisible} style={{marginLeft:"10px"}}>
      <h6>Filter</h6>
      <div className="filter">
         Select Filter
         <img style={{marginLeft:"10px"}} src="https://dzoweysldjhvw.cloudfront.net/79b9254cb9d2cc4c405a.svg" ></img>
         </div>
      
        </div>
        <div className="export-btn" onClick={handleExport}>Export</div>
        <div className="import-btn" onClick={handleImport}>Import</div>
        </div>
        <div className="rightextreme">
          <div className="action-container">
            <div>
              <Search
                  searchExpanded={true}
                  searchTerm={searchValue}
                  handleSearch={(val)=>handleSearch(val)}
                  clearSearch={()=>{setSearchValue("");setClearSearch(true)}}
                  handleSearchExit={()=>{}}
                  onKeyPress={handleKeyPress}
                ></Search>
            </div>
            <div className="search-btn" onClick={onSearchBtn}>
              Search
            </div>
            <div>
              <Link to={"/dashboard/prods/create"}> <button className="greenbackground" >+ Add Product</button></Link>
            </div>
          </div>
         
         
        
        </div>
      
        
      </div>

<div className="filter-result-section" style={{marginTop:"10px"}}>
{selectedOptionOFBrand &&
 <div className="filter-result">
  {selectedOptionOFBrand.map((data)=>
  <p>
    <div className="filter-list-item">
            <span >
              {data.label}
              <img
                src={CrossIcon}
                alt="Remove filter"
                onClick={() => handleRemoveFilter(data.value)}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              />
            </span>
            </div>
            </p>
            )}

            {selectOptionOfHealth.map((data)=>
  <p>
    <div className="filter-list-item">
            <span >
              {data.label}
              <img
                src={CrossIcon}
                alt="Remove filter"
                onClick={() => handleRemoveFilter(data.value)}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              />
            </span>
            </div>
            </p>
            )}
            {selectStatus.map((data)=>
  <p>
    <div className="filter-list-item">
            <span >
              {data.label}
              <img
                src={CrossIcon}
                alt="Remove filter"
                onClick={() => handleRemoveFilter(data.id)}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
              />
            </span>
            </div>
            </p>
            )}

</div>

}
</div>
      <div style={{marginTop:'10px'}}>
      <Card  >
        <DataTable
          highlightOnHover
          columns={
            columns
          }
          sortServer
          onSort={handleSort}
          data={products}
          defaultSortField="id"
          defaultSortAsc={false}
          onRowClicked={handlerowselect}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={recordsTotal}
          onChangePage={onChangePageClick}
          paginationServerOptions={{
            totalPages:totalPages,
            totalRecords: recordsTotal,
            currentPage:currentPage
          }}
          paginationRowsPerPageOptions={TableConfig.ROWS_PER_PAGE_OPTIONS}
         
          pagination
          paginationServer
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer', 
              },
            },
          }}
          title={

            <p className="table-header" style={{marginLeft:"-10px"}}>
              {"Found " + recordsTotal + " results"}
            </p>

        }
          responsive
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Card>
      <div>{buttonContainer}</div>

    <ShowFilterPopUp
      
    isVisible={isFilterPopUpVisible} 
    onHide={handleHideFilterPopUpVisible}
    collectionOfBrands={collectionOfBrands}
    collectionOfHealth={collectionOfHealth}
    setSelectedOptionOFBrand={setSelectedOptionOFBrand}
    selectedOptionOFBrand={selectedOptionOFBrand}
    applyFilter={handleApplyFilter}
    selectOptionOfHealth={selectOptionOfHealth}
    setSelctOptionOfHealth={setSelctOptionOfHealth}
    setSelectStatus={setSelectStatus}
    selectStatus={selectStatus} />
        {isShowExportPopUp && <div className="exportPopUp">
        <ExportPopUp
        setIsShowExportPopUp={setIsShowExportPopUp}
        selectedRows={selectedRows}
    />
    </div>}
    {isShowImportPopUp && <div className="importPopUp">
    <ImportPopUp
    setIsShowImportPopUp={setIsShowImportPopUp}
    countries={countries}
    />
    </div>}
    </div>
      </div>
      
    </>
  )
}


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);

