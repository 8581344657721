import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import DeleteIcon from '@mui/icons-material/Delete';
import VariantPriceEditCreate from "./VariantPriceEditCreate";
import { connect } from "react-redux";
 function VariantCountryEdit(props) {
const{mode,productId,variantPriceDetails}=props;
    const [countryid, setCountryId] = useState([]);
    
    const[countries,setCountries]=useState([]);
    const [listPrice, setListPrice] = useState();
    const [discountPrice, setDiscountPrice] = useState();
    const [finalPrice, setFinalPrice] = useState();
    const [variantpricecount, setVariantpricecount] = useState([1]);
    const[countrySeo,setCountrySeo]=useState();
    const[countryPageTitle,setCountryPageTitle]=useState();
    const[countryMetaDescription,setCountryMetaDescription]=useState();
    const[editFlag,setEditFlag]=useState(false);   
    const[countryEditCreate,setCountryEditCreate]=useState({});
    const[priceCheckbox,setPricecheckbox]=useState();
    const[deletecountry,setDeletecountry]=useState();
    const[pricesAll,setPricesAll]=useState([]);
    const[validationError,setValidationError]=useState({});
    useEffect(() => {
        try {
            API.get("/api/v1/country").then((res) => setCountries(res.data));
            setPricesAll(variantPriceDetails.filter((variant)=>variant.length>0).flat());
            

        }
        catch (error) {
            console.log("error msg", error);
        }


    }, [])
     
       useEffect(()=>{
        if(pricesAll.length>0)
        console.log("all prices",pricesAll);
       },[pricesAll])
    

    const handlePageTitle=(e)=>{
       
        setCountryPageTitle(e.target.value);
    }
    const onhandleMetaDescription=(e)=>{
       
        setCountryMetaDescription(e.target.value);
    }
    const handlecountryname = (e) => {
        setCountryId(e.target.value);
        console.log(e.target.value);
       
    }
    const handleListPrice = (e) => {
        setListPrice(e.target.value);
    }
    const handleDiscountPrice = (e) => {
        setDiscountPrice(e.target.value);
    }
    const handleFinalPrice = (e) => {
        setFinalPrice(e.target.value);
    }
    const handleSeoURL = (e) => {
        setCountrySeo(e.target.value);
      
    }
    const handleCheckBox=(e)=>{
        setPricecheckbox(e.target.value);
    }
   
    const onhandleaddVariant = (e) => {
        setVariantpricecount([...variantpricecount, variantpricecount.length + 1]);
    }
    const handledelete = (e) => {
        setDeletecountry(e.target.id);

    }
    const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
       else if(value=="undefined")
       emptyval=true;
      return emptyval;
      }


    const validateAllFields=(country,list,discount,final,pageTitle,countrySeo,metaDescription)=>{
        const validationError={};
        console.log("country,discount,final",country,discount,final);
         if(isEmpty(country))
         validationError.country="Country Field is required";
         if(isEmpty(list))
             validationError.list="Retail price is required";
             if(isEmpty(discount))
             validationError.discount="Discount is required";
             if(isEmpty(final))
             validationError.final="Final price  is required";
             if(isEmpty(pageTitle))
             validationError.pageTitle="pageTitle is required";
             if(isEmpty(countrySeo))
             validationError.Seo="Seo is required";
             if(isEmpty(metaDescription))
            validationError.Description="Description is required";
            
            return validationError;
    }

    const handleSave = (e) => {
        console.log("countryid", countryid);
      const validate  =validateAllFields(countryid,listPrice,discountPrice,finalPrice,countryPageTitle,countrySeo,countryMetaDescription);
      setValidationError(validate);
      if(Object.keys(validate).length==0)
      {  
      setCountryEditCreate({
            "country":parseInt( countryid),
            "seo":{
                "seoUrl": countrySeo,
                    "country": countryid,
                    "productId": productId,
                    "metaTitle": countryPageTitle,
                    "metaDescription": countryMetaDescription,
            },

            "isActive":true,
             "retailPrice": parseInt(listPrice),
            "discount": parseInt(discountPrice),
            "finalPrice": parseInt(finalPrice),
            "productId": parseInt(productId),

        });
        setEditFlag(true);
    }
    }

  useEffect(()=>{
    if(editFlag){
    try{
        API.post("api/v1/product/price-seo",countryEditCreate).then((res)=>showSuccessNotification("Created Country Prices"));
    }
    catch(error){

    }
    setEditFlag(false);
} 
  },[editFlag])
  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);
  
  const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);
  
  const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });
  


    return (

        <div className="countryblock">
            <div className="alignrow">
                <div> <select onChange={handlecountryname} value={countryid}>  <option default>Select Country</option>
                    {countries.map((ctry) => <option value={ctry.id}>{ctry.name}</option>)}
                </select></div>
                <div className="columnalignactive">
                <div>
                    <input type="number" style={{ width: "7em" }} value={listPrice} onChange={handleListPrice} placeholder="List Price" />
                </div>
                <span className="help-block">{validationError.list}</span>
                </div>
                <div className="columnalignactive">
                <div>
                    <input type="number" style={{ width: "7em" }} value={discountPrice} onChange={handleDiscountPrice} placeholder="Discount Price" />
                </div>
                <span className="help-block">{validationError.discount}</span>
                </div>
                <div className="columnalignactive">
                <div>
                    <input type="number" style={{ width: "7em" }} value={finalPrice} onChange={handleFinalPrice} placeholder="Final Price" />
                </div>
                <span className="help-block">{validationError.final}</span>
                </div>

                <div className="columnalignactive"><div>Active</div>
                    <div><input type="checkbox" 
                    onChange={handleCheckBox} /></div>
                </div>
                <div><input className="donebtn" type="button" value="save" onClick={handleSave} /></div>
                <DeleteIcon onClick={handledelete} fontSize="small" />
            </div>

            <div>SEO at Country Level<br />
                <div className="alignrow">
                    <div className="columnalign">
                        <div ><input type="text" placeholder=" Page Title"  onChange={handlePageTitle}/></div>
                        <div ><input type="text" placeholder="URL Handle" onChange={handleSeoURL} /></div>
                    </div>


                    <div><textarea rows={10} placeholder="Meta Description"
                        cols={50} onChange={onhandleMetaDescription} /></div>

                </div>

            </div>

            <div>
                <input className="newvaraintbutton" type="button" value="+ Variants" onClick={onhandleaddVariant} /><br /><br />
            </div>
            <div>
               
                    
                  {mode=="EditCreate" ? variantpricecount.map((variant) => <VariantPriceEditCreate CountryId={countryid}   />)
                  :
                  <></>
                }

            </div>


        </div>

    )

}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(VariantCountryEdit);