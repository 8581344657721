import React,{useState,useEffect} from 'react'
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useHistory} from "react-router-dom";
import {connect} from "react-redux"
import API from "../../../utils/API"
import Search from '../../../components/Search/Search';

const Authors = (props) => {
  const [searchTerm,setSearchTerm] = useState("")
  const [authors,setAuthors] = useState([])
  const [clearSearch, setClearSearch] = useState(false)
  const history=useHistory();
  
  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      sortField: 'id',
      wrap:true,
      "maxWidth":"10px"
     

    },
    {
      name: "Name",
      selector: "userName",
      // sortable: true,
      sortField: 'id',
      width:"200px",
      wrap:true,

    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      sortField: 'id',
      wrap:true,
      width:"400px",
      // "maxWidth":"10px"

    },
    {
      name: "Status",
      selector: "active",
      sortable: true,
      sortField: 'id',
      wrap:true,
      cell:(author)=>author.active === true ? "Active" : "Inactive",
      // width:"100px"
      // "maxWidth":"50px"

    },
    {
      name: "View",
      selector: "view",
      // sortable: true,
      sortField: 'id',
      wrap:true,
      width:'200px',
      cell:(author) => renderViewButton(author.id)

    },
  ]
  const  renderViewButton = (id) =>(
    <div>
        <button
          className="btn px-5"
          style={{ backgroundColor: "#247A81",color:"#FFF" }}
          onClick={() => {
            handleView(id);
          }}
        >
          View
        </button>
      </div>
   )
   const handleView = (id) =>{

    if(id){
   history.push(`/dashboard/author/${id}`)
 
  }
 }
  
   useEffect(()=>{
    getAuthors();
   },[])

   const getAuthors = async()=>{
    showLoading();
    try{
     const data = await API.get(`/authors`)
     showLoading();
     if(data.status === 200){
      hideLoading()
      const res = data.data
      setAuthors(res)
     }
     else{
      hideLoading();
      console.log("Error in fetching the data")
     }
    
  
    }
    catch{
      hideLoading()
    }
   }

   const handleSearch = (val)=>{
    if (val) {
      setSearchTerm(val);
      setClearSearch(false);
    } else {
      setSearchTerm("");
      setClearSearch(true);
      getAuthors();
    }
  }


  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });
   const onSearchBtn = () =>{

    const filteredAuthors = filterAuthors(authors,searchTerm);
    setAuthors(filteredAuthors);
   }
   const filterAuthors = (authors ,searchTerm) =>{
    if(!searchTerm) return authors;

    const newValue = searchTerm.trim().toLowerCase();
    return authors.filter((author)=>{
      const {id , email ,userName} = author
      return (
        (userName &&
          userName.toLowerCase().includes(newValue)) ||
        (id && id.toString().includes(newValue)) ||
        (email && email.includes(newValue))
      )
    });
   }
   const handleKeyPress = (event) =>{
    if (event.key === 'Enter') {
      onSearchBtn();
    }
   }
  return (
    <div>
      <div className="rightextreme">
        <div className="action-container">
      
      <div>
              <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={(val)=>handleSearch(val)}
                  clearSearch={()=>{setSearchTerm("");setClearSearch(true);getAuthors()}}
                  handleSearchExit={()=>{}}
                  onKeyPress={handleKeyPress}
                
                ></Search>
            </div>
             <div className="search-btn" onClick={onSearchBtn}>
              Search
            </div>
        <div>
          <Link to={"/dashboard/author/create"}> <button className="greenbackground" >+ Add Author</button></Link>
        </div>
      
      </div>
      </div>
      
      <br/><br/>
      <Card body>
        <DataTable
          highlightOnHover
          columns={

            columns

          }
          data={authors}
          pagination
          // onRowClicked={handlerowselect}
          title={
            searchTerm ? (
              <p className="table-header">
                {"Found " + authors.length + " results"}
              </p>
            ) : (
              ""
            )
          }
          
          // clearSelectedRows={this.state.toggledClearRows}

          responsive




        />

      </Card>


    </div>
  )
}





function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Authors)