import moment from "moment";

const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";
const TIME_FORMAT_12_HOUR = "hh:mm A";
const TIME_FORMAT_24_HOUR = "HH:mm";
const DEFAULT_TIME_FORMAT = TIME_FORMAT_12_HOUR;
const DEFAULT_DATE_TIME_FORMAT = "DD MMM, YYYY, hh:mm a";

export function formatDate(date, format = DEFAULT_DATE_FORMAT) {
  try {
    return moment(date).format(format);
  } catch (error) {
    console.log("formatDate:: Error on formating date!", error);
    return "";
  }
}

export function formatTime(time, format = DEFAULT_TIME_FORMAT) {
  try {
    return moment(time).format(format);
  } catch (error) {
    console.log("formatTime:: Error on formating time!", error);
    return "";
  }
}

export function formatDateTime(dateTime, format = DEFAULT_DATE_TIME_FORMAT) {
  try {
    if(!dateTime)
     return "-"
    const date = moment(dateTime).format("DD MMM YYYY");
    const time = moment(dateTime).format("hh:mm a");
    return `${date} at ${time}`;
    // return moment(dateTime).format(format);
  } catch (error) {
    console.log("formatDateTime:: Error on formating datetime!", error);
    return "";
  }
}

export function titleCase(word) {
  if (!word || word.trim() === "") return word;
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function calculatePercentage(baseValue, percentageValue) {
  if (
    typeof baseValue !== "number" ||
    typeof percentageValue !== "number" ||
    isNaN(baseValue) ||
    isNaN(percentageValue)
  ) {
    throw new Error("Invalid input: both arguments must be numbers");
  }

  const percentage = percentageValue / 100;
  return baseValue * percentage;
}
