import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import './exportPopUp.scss';
import { connect } from "react-redux";
import API from "../../utils/API";

const ExportPopUp = (props) => {
  const setIsShowExportPopUp = props.setIsShowExportPopUp;
  const selectedRows = props.selectedRows;

  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState("");

  const handleExportProduct = async () => {
    if (!selectedOption) {
      setError("Please select a option");
      return;
    }
    setError("");

    if (selectedOption === "allProducts") {
      setIsShowExportPopUp(false);
      showLoading()
      let timeoutConfig = { timeout: 60000 };
      const res = await API.get(`api/v2/export?isAllProducts=true`,timeoutConfig);
      if(res.status == 200){
        hideLoading();
       
      const data = res.data
      exportToExcel(data.products);
      }
    } else {
      setIsShowExportPopUp(false)
      const payload = selectedRows;
      showLoading()
      const res = await API.get(`api/v2/export?productIdList=${payload}`);
    
      if(res.status == 200){
        hideLoading()
      
      }
      const data = res.data
      exportToExcel(data.products);
    }
  };

  const exportToExcel = (data) => {

    const worksheet = XLSX.utils.book_new();
    const keyData = [
      'SKU *','Available Quantity','SEO URL', 'Title*', "Title Arabic*",'Description*','Description Arabic*', 'Short Description*','Short Description Arabic*', 'Ingredients & Dosage','ingredients & Dosage Arabic', 'Benefits','Benefits Arabic', 'Brand*', 'Health goal 1', 'Health goals 2','status', 'Barcode','Image Link 1','Image Link 2','Image Link 3','Image Link 4','Image Link 5', 'Bahrain', 'List Price-1', 'Discount-1', 'Final Price-1', 'Meta Title-1',  'Meta Title Arabic-1',  'Meta Description-1', 'Meta Description Arabic-1', 'SEO Keywords-1', 'SEO Keywords Arabic-1', 'Status-1', 'Oman', 'List Price-2', 'Discount-2', 'Final Price-2', 'Meta Title-2', 'Meta Title Arabic-2', 'Meta Description-2', 'Meta Description Arabic-2', 'SEO Keywords-2', 'SEO Keywords Arabic-2', 'Status-2', 'Qatar', 'List Price-3', 'Discount-3', 'Final Price-3', 'Meta Title-3','Meta Title Arabic-3', 'Meta Description-3', 'Meta Description Arabic-3', 'SEO Keywords-3', 'SEO Keywords Arabic-3', 'Status-3', 'Kuwait', 'List Price-4', 'Discount-4', 'Final Price-4', 'Meta Title-4', 'Meta Title Arabic-4', 'Meta Description-4', 'Meta Description Arabic-4', 'SEO Keywords-4', 'SEO Keywords Arabic-4', 'Status-4', 'Saudi', 'List Price-5', 'Discount-5', 'Final Price-5', 'Meta Title-5', 'Meta Title Arabic-5', 'Meta Description-5', 'Meta Description Arabic-5', 'SEO Keywords-5', 'SEO Keywords Arabic-5', 'Status-5', 'UAE', 'List Price-6', 'Discount-6', 'Final Price-6', 'Meta Title-6', 'Meta Title Arabic-6', 'Meta Description-6', 'Meta Description Arabic-6', 'SEO Keywords-6', 'SEO Keywords Arabic-6', 'Status-6'
  ];
    const keys = Object.keys(data[0]);

    // console.log("Keys: ",keys)

    // const keysArray = [keys];

    // console.log("keysArray", keysArray);
    const keysArray = [keyData];
    const flattenedData =  data.map(row => Object.values(row));
    
    console.log("flattenedData",flattenedData)
    
    const dataArray = [...keysArray, ...flattenedData];
    
    console.log("dataArray",dataArray)
    const worksheetData = XLSX.utils.aoa_to_sheet(dataArray);
    XLSX.utils.book_append_sheet(worksheet, worksheetData, "Sheet1");
    XLSX.writeFile(worksheet, "KuwaProducts.xlsx");
    showSuccessNotification("Products Exported Successfully")
    window.location.reload();
  };

 
  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });
  return (
    <>
      <div className='exportPopUpContainer'>
        <div className='popUp'>
          <div className='exportHeading'>Export Products</div>
          <div className='optionContent'>
            <div className='option'>
              <input type="radio" name="pageOption" id="allProducts" value="allProducts" onChange={(e) => setSelectedOption(e.target.value)} />
              <label htmlFor="allProducts">All Products</label>
            </div>
            <div className='option'>
              <input type="radio" name="pageOption" id="selectedProducts" value="selectedProducts" onChange={(e) => setSelectedOption(e.target.value)} />
              <label htmlFor="selectedProducts">Selected {selectedRows.length} Product</label>
            </div>
          </div>
          {error && <div className="error-msg">{error}</div>}
          <div className='footer-div'>
            <div className='export-btn' onClick={handleExportProduct}>Export Products</div>
            <div className='cancel-btn' onClick={() => setIsShowExportPopUp(false)}>Cancel</div>
          </div>
         
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportPopUp);
