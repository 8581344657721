import React, { useState, useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { Card } from 'react-bootstrap';
import { Button, selectClasses } from "@mui/material";
import  VariantPrice from "../components/VariantPrice";
import API from "../../src/utils/API";
import  VariantPriceCreate  from "./VariantPriceCreate";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

 function VariantCountry(props) {
    const { mode, prodid, Price, Countryid, SEOURL, priceallChange, priceDetail, variantprice, variantpricefun,variantdet,seoPageTitle,seoMetaDescription,countriesSelected, countriesSelectedFun } = props;


    const [selectcountry, setselectCountry] = useState();
    const [pricecheckbox, setPricecheckbox] = useState(true);
    const [variantname, setVariantname] = useState();
    const [metadescription, setMetadescription] = useState();
    const [deletecountry, setDeletecountry] = useState();
    const [variantpricecount, setVariantpricecount] = useState([1]);
    const [countries, setCountries] = useState([]);
    const [products, setProducts] = useState([]);
    const [countryid, setCountryId] = useState([]);
    const [productvariant, setProductvariant] = useState([]);
    const [prodprice, setProdPrice] = useState();
    const [listPrice, setListPrice] = useState();
    const [discountPrice, setDiscountPrice] = useState();
    const [finalPrice, setFinalPrice] = useState();
    const [variantsCount, setVariantsCount] = useState();
    const[countrySeo,setCountrySeo]=useState();
    const[countryPageTitle,setCountryPageTitle]=useState();
    const[countryMetaDescription,setCountryMetaDescription]=useState();
    const[CountryEditCreate,setCountryEditCreate]=useState();
    const[validationError,setValidationError]=useState({});
    const[isModalVisible,setIsModalVisible]=useState(false);
    const[variantSelected,setVariantSelected]=useState([]);
    const[countryFlag,setCountryFlag]=useState(false);
    //const[allVariantPrice,setAllVariantPrice]=useState([]);

    useEffect(() => {
        try {
            API.get("/api/v1/country").then((res) => setCountries(res.data));

        }
        catch (error) {
            console.log("error msg", error);
        }


    }, [])


    //country api
    const handlePageTitle=(e)=>{
        seoPageTitle(e.target.value);
        setCountryPageTitle(e.target.value);
        if(isEmpty(e.target.value))
        {
            setValidationError({...validationError,pageTitle:"pageTitle is required field"});
        }
        else{
            setValidationError({...validationError,pageTitle:""});
        }
    }
    const onhandleMetaDescription=(e)=>{
        seoMetaDescription(e.target.value);
        setCountryMetaDescription(e.target.value);
        if(isEmpty(e.target.value))
        {
            setValidationError({...validationError,metaDescription:"metaDescription is required field"});

        }
        else{
            setValidationError({...validationError,metaDescription:""});
        }
    }
    const handlecountryname = (e) => {
        setCountryId(e.target.value);
        if(countriesSelected.includes(e.target.value))
        showErrorNotification("Country already selected ");


       if(isEmpty(e.target.value))
       {
        setValidationError({...validationError,country:"country is required"})
       }
      else{
        setValidationError({...validationError,"country":""});
      }
       
    }
    const handleListPrice = (e) => {
        setListPrice(e.target.value);
        if(isEmpty(e.target.value)){
            setValidationError({...validationError,list:"list is required"})
        }
        else{
            setValidationError({...validationError,list:""}) 
        }
    }
    const handleDiscountPrice = (e) => {
        setDiscountPrice(e.target.value);
        if(isEmpty(e.target.value))
        {
            setValidationError({...validationError,discount:"discount is required"})
        }
        
        else{
            setValidationError({...validationError,discount:""}) ;
        }
    }
    const handleFinalPrice = (e) => {
        setFinalPrice(e.target.value);
        if(isEmpty(e.target.value))
        setValidationError({...validationError,final:"Final Price is required"});
       
        else{
            setValidationError({...validationError,final:""});
        }

    }
    const handleSeoURL = (e) => {
        setCountrySeo(e.target.value);
        SEOURL(e.target.value);
        if(isEmpty(e.target.value))
        setValidationError({...validationError,countrySeo:"Seo url required field"});
        else{
            setValidationError({...validationError,countrySeo:""});
        }

    }
    const handleCheckBox=(e)=>{
        if(e.target.checked)
        setPricecheckbox(true);
        else
        setPricecheckbox(false);
    }
    const clearForm=()=>{

    }
    const hideVariantModal =()=>{
        clearForm();
        setIsModalVisible(false);
    }
    const onhandleaddVariant = (e) => {
       // setVariantpricecount([...variantpricecount, variantpricecount.length + 1]);
        setIsModalVisible(true);
    }
    const handledelete = (e) => {
        setDeletecountry(e.target.id);

    }
    
    useEffect(() => {
        if(!isEmpty(prodid) && !isEmpty(Countryid))
        try {
            const url = `/api/v1/product/${prodid}?country=${Countryid}`;
            API.get(url).then((res) => res.data).then((data) => setProductvariant(data.newVariants));
        }
        catch (error) {
            console.log(error)
        }

    }, [prodid])

    const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
       else if(value=="undefined")
       emptyval=true;
      return emptyval;
      }

    const validateAllFields=(country,list,discount,final,pageTitle,countrySeo,metaDescription,status)=>{
        const validationError={};
        console.log("country,discount,final",country,discount,final);
         if(isEmpty(country))
         validationError.country="Country Field is required";
         if(isEmpty(list))
             validationError.list="Retail price is required";
             if(isEmpty(discount))
             validationError.discount="Discount is required";
             if(discount>list)
             validationError.discount="Discount less than list";
             if(isEmpty(final))
             validationError.final="Final price  is required";
             if(parseInt(final) !== parseInt(list)-parseInt(discount))
              validationError.final="Final price is wrong";
             if(isEmpty(pageTitle))
             validationError.pageTitle="pageTitle is required";
             if(isEmpty(countrySeo))
             validationError.Seo="Seo is required";
             if(isEmpty(metaDescription))
            validationError.Description="Description is required";
           
             if(!status)
            validationError.status="status Active";
            return validationError;
       
    }
  
 

   
   
   



    const handleSave = (e) => {
        console.log("countryid", countryid);
      const  validateAll= validateAllFields(countryid,listPrice,discountPrice,finalPrice,countryPageTitle,countrySeo,countryMetaDescription,pricecheckbox);
      setValidationError(validateAll); 
      if(Object.keys(validateAll).length===0){
       if(props.mode=="Create" ){
         countriesSelectedFun([...countriesSelected,countryid])
        priceallChange(price=>[...price, {
            "country": countryid,
            "seo":{
                "seoUrl": countrySeo,
                    "country": countryid,
                    "productId": "",
                    "metaTitle": countryPageTitle,
                    "metaDescription": countryMetaDescription,
            },

            "isActive":pricecheckbox,
            "retailPrice": listPrice,
            "discount": discountPrice,
            "finalPrice": finalPrice,
            "productId": "",

        }])
    }
showSuccessNotification("country saved locally");
setCountryFlag(true);
}    
    }

    const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });

    const handleVariantCheckbox=(e)=>{
          if(e.target.checked)
          setVariantSelected([...variantSelected,e.target.name]);
          else {
            const newSelected=variantSelected.filter((variant)=>variant!==e.target.name);
            setVariantSelected([...newSelected]);
          }
    }

const form=(<>
<div>
  {  variantdet.map((variant)=> { return(<div className="columnalign">
    <div className="alignrowpopup">  <div className="variantimage"> <img className="variantimage" src={variant.image} /></div><div className="namesize">{variant.name} </div>
     <div >  select  <input name={variant.name} type="checkbox" onChange={handleVariantCheckbox}/> </div></div>
    </div>)})}
</div>
</>)
    


    useEffect(() => {
        if (productvariant) {
            setCountryId(Countryid);
           // setListPrice(Price);
            //setDiscountPrice(0);
            //setFinalPrice(Price);
            setVariantsCount(productvariant.length);
        }
    }, [productvariant])

    return (

        <div className="countryblock">
            <div className="alignrow">
                <div className="columnalignactive"> 
                <select onChange={handlecountryname} value={countryid}> {props.mode == "Create" ? <option default>Select Country</option>:<></>}
                    {countries.map((ctry) => <option value={ctry.id}>{ctry.name}</option>)}
                </select>
                <span className="help-block">{validationError.country}</span>
                </div>

                <div className="columnalignactive">
                <div className="group"><label for="name">List Price*</label>
                    <input type="number" style={{ width: "7em" }} value={listPrice} onChange={handleListPrice} placeholder="List Price" />
                   </div>
                    <span className="help-block">{validationError.list}</span>
                </div>
                <div  className="columnalignactive">
                <div className="group"><label for="name">Discount*</label>
                    <input type="number" style={{ width: "7em" }} value={discountPrice} onChange={handleDiscountPrice} placeholder="Discount Price" />
                    </div>
                    <span className="help-block">{validationError.discount}</span>
                </div>
                <div  className="columnalignactive">
                <div className="group"><label for="name">Final Price*</label>
                    <input type="number" style={{ width: "7em" }} value={finalPrice} onChange={handleFinalPrice} placeholder="Final Price" />
                    </div>
                    <span className="help-block">{validationError.final}</span>
                </div>

                <div className="columnalignactive"><div>Active</div>
                    <div><input type="checkbox" value={pricecheckbox}
                    onChange={handleCheckBox}  checked={pricecheckbox}/></div>
                     <span className="help-block">{validationError.status}</span>
                </div>
                
                
            </div>

            <div>SEO at Country Level<br /><br/>
                <div className="alignrow">
                    <div className="columnalign">
                        <div className="columnalignactive">
                        <div className="group"><label for="name">Page Title*</label> 
                            <input type="text" placeholder=" Page Title"  onChange={handlePageTitle}/></div>
                        <span className="help-block">{validationError.pageTitle}</span>
                        </div>
                        <div className="columnalignactive">
                        <div className="group"><label for="name">URL Handle*</label>  
                            <input type="text" placeholder="URL Handle" onChange={handleSeoURL} /></div>
                        <span className="help-block">{validationError.Seo}</span>
                        </div>
                    </div>


                    <div className="columnalignactive">
                    <div className="group"><label for="name">Meta Description*</label> 
                        <textarea rows={10} placeholder="Meta Description"
                        cols={50} onChange={onhandleMetaDescription} /></div>
                        <span className="help-block">{validationError.Description}</span>
                        </div>
                       <div ><input className="donebtn" type="button" value="Save" onClick={handleSave} disabled={countryFlag} /></div>
                </div>

            </div>
              <br/>
            <div>
    
                <input className="newvaraintbutton" type="button" value="+ Variants" onClick={onhandleaddVariant} /><br /><br />

            </div>
            <Modal
          
          show={isModalVisible}
          onHide={hideVariantModal}
        >
            <Modal.Header closeButton>
                <Modal.Title className="aligncenter">
                    <div className="aligncenter">Select Variant</div> 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{form}</Modal.Body>


        </Modal>

            <div>
                {mode == "View" ? productvariant.map((variant) => <VariantPrice id={variant.id} name={variant.name} countryid={Countryid} variantdet={variant?.price} />)
                    
                    :mode=="Create" && variantSelected.length>0? variantSelected.map((variant) => <VariantPriceCreate CountryId={countryid} Allvariantprice={variantprice} FunAllvariantprice={variantpricefun} variantnames={variantdet} SelectedVariantname={variant} />)
                :<></>
                }

            </div>


        </div>

    )
}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(VariantCountry);