import React, { useState, useEffect } from "react";


export function EditVariant(props) {
  const { prodid, image, id, name, qty } = props;
  const [variantname, setVariantName] = useState();
  const [productid, setProductid] = useState();
  const [variantid, setVariantId] = useState();
  const [quantity, setQuantity] = useState();
  const [variantstatus, setvariantstatus] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [editstatus, setEditStatus] = useState(false);
  useEffect(() => {
    setVariantName(name);
    setProductid(prodid);
    setImageUrl(image);
    setVariantId(id);
    setQuantity(qty);
  }, [props])
  const handlevariantname = (e) => {
    setVariantName(e.target.value);
  }
  const handlevariantquantity = (e) => {
    setQuantity(e.target.value)
  }
  const handlevariantstatus = (e) => {
    setvariantstatus(e.target.checked)
  }
  const handlevariantdetails = (e) => {
    setEditStatus(true);
  }
  useEffect(() => {
    if (editstatus) {

      
    }



  }, [editstatus])



  return (
    <div className="variantstyle alignrow">

      <div className="alignrow">


        <div> {id}</div>
        <div className="imageblock"> <img className="imagevariantsize" src={imageUrl} /></div>
        <div>    <input type="text" value={variantname} onChange={handlevariantname} style={{ width: "20em" }} placeholder="Variant Name" /> </div>
        <div>  <input type="number" value={quantity} style={{ width: "7em" }} onChange={handlevariantquantity} placeholder="Quantity" />    </div>
        <div className="columnalignactive"><div> Active </div> <div><input type="checkbox" onChange={handlevariantstatus} checked={quantity > 0} /></div></div>
        <div><input className="donebtn" type="button" onClick={handlevariantdetails} value="Edit" /></div>
      </div>

    </div>
  )




}