import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import Select from "react-select";


export function RelatedProdsCreate(props){
    const [create, setCreate] = useState(false);
    const [prodsrelated, setProdsrelated] = useState([]);
    const[selectprod,setSelectprod]=useState();
    const[specialOfferProds,setSpecialOfferProds]=useState([]);
    const[allSelectedIds,setAllSelectedIds]=useState([]);
    const[finalIds,setFinalIds]=useState([]);
    const prodselection="products";

    useEffect(() => {

        if (props.mode == "View") {
            const url = `/module/related-product/${props.id}`;
            try {
                API.get(url).then((res) => res.data).then((data) => setProdsrelated(data));
                API.get(url).then((res) => res.data).then((data) => setAllSelectedIds((data)=>{if(data!==null)data.map((prod)=>prod.id)}));
            }
            catch (error) {
                console.log("error", error);
            }
        }
        
    }, [props])
    const onhandleCreateRelate = (e) => {
        setCreate(true);
        setFinalIds([...allSelectedIds,selectprod.value]);

    }
    
    
    useEffect(() => {
        if (finalIds.length>0 ) {
            console.log("select prod", selectprod);
            console.log("finalids",finalIds);
            API.post(`module/related-product/${props.prodid}`,finalIds).then((res)=>console.log(res));
            if(props.componentflag)
            props.componentflagFun(false);
            else{
                props.componentflagFun(true); 
            }

            setCreate(false);
            props.relfun(false);
        }
    }, [finalIds])

    useEffect(() => {

        try {
            API.get("/api/v1/products/").then((res) => res.data).then((data) => data.products).then((products) => setSpecialOfferProds(products.map((product) => { return ({ "value": product.id, "label": product?.description?.name }) })));
        }
        catch (error) {
            console.log("error", error);
        }
    
    }, [])

    return(
        <div className="prodselect">
                <Select

                    name={prodselection}
                    options={specialOfferProds}
                    className="basic-single"
                    classNamePrefix="select"
                    oncloseMenuOnSelect={false}


                    onChange={setSelectprod}

                    style={{ width: "100%" }}
                />
                <div><input type="button" onClick={onhandleCreateRelate} value="Save" /></div>
            </div>
    )
}