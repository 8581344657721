import Select from "react-select";

const SELECT_STYLES = {
  container: (base) => ({ ...base, flex: 1 }),
};

function SelectProduct({
  products,
  value,
  isDisabled = false,
  onChange = (f) => f,
}) {
  const findProductById = (id) => products.find((product) => product.id === id);

  const productOptions = products.map(createOption);
  const selectedProduct = createOption(findProductById(value));

  return (
    <Select
      key={`select-product-${value ?? ""}`}
      value={selectedProduct}
      onChange={onChange}
      options={productOptions}
      styles={SELECT_STYLES}
      isDisabled={isDisabled}
    />
  );
}

function createOption(product) {
  if (!product) return null;

  const { id, productName } = product;
  return { key: id, value: id, label: productName };
}

export default SelectProduct;
