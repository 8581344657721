import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { getUserType } from "../services/userService";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Notification from "../components/notification/Notification";
import Sidebar from "../components/Sidebar";
import routes from "../routes";
import history from "../utils/history";

class ValeoEnglish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuExpanded: true,
      clients: [],
    };
    this.routerName = "";
  }

  componentDidMount() {
    this.getRouterName();
    this.forceUpdate();
  }

  getRouterName = () => {
    const role = getUserType();
    for (let route of routes) {
      if (!route.hasSubroute) {
        if (route.role && route.role.includes(role)) {
          if (history.location.pathname.indexOf(route.path) > -1) {
            this.setRouterName(route.name);
          }
        }
      } else {
        for (let subroute of route.subroutes) {
          if (subroute.role && subroute.role.includes(role)) {
            if (history.location.pathname.indexOf(subroute.path) > -1) {
              this.setRouterName(subroute.name);
            }
          }
        }
      }
    }
  };

  setRouterName = (route) => {
    this.setState({ routerName: route });
  };

  toggleMenu = () => {
    this.setState({ menuExpanded: !this.state.menuExpanded });
  };

  expandMenu = () => {
    this.setState({ menuExpanded: true });
  };

  collapseMenu = () => {
    this.setState({ menuExpanded: false });
  };

  getRoutes() {
    const role = getUserType();
    const allowedRoutes = [];
    for (let route of routes) {
      if (!route.hasSubroute) {
        if (route.role && route.role.includes(role)) {
          allowedRoutes.push(
            <Route
              exact
              path={route.path}
              render={(props) => (
                <route.component {...props} click={this.getRoutesName} />
              )}
              key={route.path}
            ></Route>
          );
        }
      } else {
        for (let subroute of route.subroutes) {
          if (subroute.role && subroute.role.includes(role)) {
            allowedRoutes.push(
              <Route
                exact
                path={subroute.path}
                render={(props) => (
                  <subroute.component {...props} click={this.getRoutesName} />
                )}
                key={subroute.path}
              ></Route>
            );
          }
        }
      }
    }
    return allowedRoutes;
  }

  render() {
    const { menuExpanded, routerName } = this.state;

    return (
      <div className="main-container">
        <Header menuExpanded={menuExpanded} routerName={routerName} />
        <Sidebar
          expandMenu={this.expandMenu}
          toggleMenu={this.toggleMenu}
          setRouterName={this.setRouterName}
          menuExpanded={menuExpanded}
        />
        <Notification menuExpanded={menuExpanded} />
        <div className={"page-view " + (menuExpanded ? "" : "wide")}>
          <Loader menuExpanded={menuExpanded} />
          <Switch history={history}>
            {this.getRoutes()}
            <Redirect from="/dashboard" to="/dashboard/home" />
            <Redirect from="*" to="/dashboard/home" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default ValeoEnglish;
