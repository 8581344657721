import React,{ useEffect, useState} from 'react'
import { Modal } from "react-bootstrap";

function DeliveryStatusPopUp(props) {
    const {
        selectedRows=[],
        title = "",
        notes = "",
        onHide = (f) => f,
        onSave = (f) => f,
        isVisible = false,
      } = props;
      console.log("bjabhba",selectedRows)
  return (
    <Modal show={isVisible} onHide={onHide} size="sm" centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <>
       {selectedRows.length>1 ?
       <div>Are you Sure to confirm Mark as delivered {selectedRows.length} orders</div>
    :<div>Order Id: {selectedRows[0]} to confirm Mark as delivered order </div>
    }
      </>
    </Modal.Body>
    <Modal.Footer>
      <button style={{borderRadius:"20px"}}
        onClick={onHide}
        type="button"
        className="btn btn-md btn-outline-secondary"
      >
        Cancel
      </button>
      <button style={{background:"#247A81",borderRadius:"20px"}}
        onClick={(event) => onSave()}
        type="button"
        className="btn btn-md btn-success ml-2"
      >
        Okay
      </button>
    </Modal.Footer>
  </Modal>
  )
}

export default DeliveryStatusPopUp