import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import MultiSelectWithRanking from "./MultiSelectWithRanking/MultiSelectWithRanking";
import './product-organization.scss';

export default function ProductOrganization({productOrganizationList,collectionData,setProductOrganization,productOrganization}) {

   


    

  return (
    <div className="product-status-wrapper">
          <Card body>
            <div> 
                <div style={{fontSize:'14px',fontWeight:600}}>Product Organization</div>
                <div className="prodselect">
                    {
                        collectionData.map((data,index)=>{
                            const optionsData = productOrganizationList[data.name]
                            return(
                                <div style={(index!=0)?{marginTop:"40px"}:{marginTop:"10px"}}>
                                    <div className="collection-name" >+ {data.name}</div>
                                    <MultiSelectWithRanking selectedData={productOrganization[data.name]} productOrganization={productOrganization} optionData={optionsData} fieldName={data.name} setProductOrganization={setProductOrganization} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
          </Card>
    </div>
  )
}

                           
                           
