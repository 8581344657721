import React,{useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table';
import { Modal } from "react-bootstrap";
import API from "../../utils/API"


const ListOfProduct = ({ selectedProducts = [], selectedVariants = [], product = {}, index = 0, toggleProductSelection, toggleVariantSelection }) => {
    console.log("ListOfProduct", product);
    const { productDto, variantList } = product || {};
    const { productId, productName, productImage, productPrice, discount, productSpecialPrice, inventory } = productDto;
    return (
        <Table hover size="lg">
            <tbody>
                <tr>
                    <td colSpan={1} style={{ width: "1%" }} >
                        <input type='checkbox' checked={selectedProducts.some(p => p.productId === productId)} onChange={() => toggleProductSelection(productDto)} />
                    </td>
                    <td colSpan={1} style={{ width: "15%", }}>
                        {productName}
                    </td>
                </tr>
                {
                    variantList?.map((data, index) => {
                        const { variantId, variantName, variantListPrice, variantDiscount, variantFinalPrice } = data || {};
                        return (
                            <tr key={variantId}>
                                <td colSpan={1} style={{ width: '1%', textAlign: 'right' }}>
                                    <input type="checkbox" checked={selectedVariants.some(v => v.variantId === variantId)} onChange={() => toggleVariantSelection(data)} />
                                </td>
                                <td colSpan={1} style={{ width: '2%', textAlign: 'left' }}>{"pack of" + " " + variantName}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}

const CartPageProductModal = ({ isShowProductModal, setIsShowProductModal, handleProductSelect, selectCountry, allActiveProducts }) => {

    console.log("allActiveProducts", allActiveProducts)
    const [searchTerm, setSearchTerm] = useState();
    const [isSearching, setIsSearching] = useState(false);
    const [listOfProducts, setListOfProducts] = useState(allActiveProducts);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState([]);

    useEffect(() => {
        if (isShowProductModal) {
            setListOfProducts(allActiveProducts);
        }
    }, [isShowProductModal, allActiveProducts]);

    const getProduct = async () => {
        try {
            setIsSearching(true);
            const res = await API.get(`/api/v1/deals/search?searchKey=${searchTerm}&countryId=${selectCountry.id}`);
            if (res.status == 200) {
                const data = res?.data || {}
                setListOfProducts(data);
                setIsSearching(false)
            } else {
                setIsSearching(false)
                console.log("Error in fetching the data")
            }
        }
        catch (error) {
            setIsSearching(false)
            console.log("Error in fetching the data", error)
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            getProduct(searchTerm);
        }
    };

    const toggleProductSelection = (product) => {
        const isSelected = selectedProducts.some(p => p.productId === product.productId);
        if (isSelected) {
            setSelectedProducts(selectedProducts.filter(p => p.productId !== product.productId));
        } else {
            setSelectedProducts([...selectedProducts, product]);
            // Deselect all variants of the selected product
            setSelectedVariants(selectedVariants.filter(v => v.productId !== product.productId));
        }
    };

    const toggleVariantSelection = (variant) => {
        const isSelected = selectedVariants.some(v => v.variantId === variant.variantId);
        if (isSelected) {
            setSelectedVariants(selectedVariants.filter(v => v.variantId !== variant.variantId));
        } else {
            setSelectedVariants([...selectedVariants, variant]);
            // Deselect the product if its variant is selected
            setSelectedProducts(selectedProducts.filter(p => p.productId !== variant.productId));
        }
    };

    return (
        <Modal show={isShowProductModal} onHide={() => setIsShowProductModal(!isShowProductModal)} size="lg" centered>
            <Modal.Header>
                <div>All Products</div>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="row">
                        <div className="form-group col">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control"
                                    id="search-bar"
                                    name="search-bar"
                                    placeholder="Search Products"
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e.target.value) }}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>

                        </div>
                    </div>
                    <Table>
                        <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
                            <th style={{ width: "5%" }}> </th>
                            <th style={{ width: "16%" }}>Product Name</th>
                        </thead>
                    </Table>
                    {
                        isSearching ?
                            <div style={{ height: '50vh', overflow: "scroll" }}>
                                <div className="search-spinner" ></div>
                            </div> :
                            (!isSearching && listOfProducts && listOfProducts.length == 0) ?
                                <div>No results found for "{searchTerm}"</div> :
                                <div style={{ height: '50vh', overflow: "scroll" }}>
                                    {
                                        listOfProducts && listOfProducts.map((data, index) => {
                                            return <ListOfProduct
                                                key={index}
                                                selectedProducts={selectedProducts}
                                                selectedVariants={selectedVariants}
                                                product={data} index={index}
                                                toggleProductSelection={toggleProductSelection}
                                                toggleVariantSelection={toggleVariantSelection} />
                                        })
                                    }
                                </div>
                    }
                </>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end", gap: "10px", width: '100%' }}>
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-success ml-2"
                            onClick={() => (handleProductSelect([...selectedProducts, ...selectedVariants]), setSearchTerm(""), setListOfProducts([]), setSelectedProducts([]), setSelectedVariants([]))}
                            style={(selectedProducts.length > 0 || selectedVariants.length > 0) ? {} : { opacity: 0.2, backgroundColor: '#247A81' }}
                        >
                            Add
                        </button>
                    </div>
                    <div>
                        <button style={{ borderRadius: "20px" }}
                            type="button"
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => setIsShowProductModal(!isShowProductModal)}>
                            Cancel
                        </button>
                    </div>

                </div>
            </Modal.Footer>
        </Modal>
    )
}


export default CartPageProductModal