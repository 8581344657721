import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";
import { connect } from "react-redux";

 function QuestionEditComponent(props) {
    const [editquestion, setEditQuestion] = useState();
    const [editanswer, setEditanswer] = useState();
    const [faqid, setfaqid] = useState();
    const [faqedit, setfaqedit] = useState({});
    const[faqStatus,setFaqStatus]=useState(true);
    const[faqDelete,setFaqDelete]=useState({});
    const[validationError,setValidationError]=useState({});
    const { question, answer, id, prodid,editflag } = props;

    useEffect(() => {
        setEditQuestion(question);
        setEditanswer(answer);

    }, [props])
    const onhandlequestion = (e) => {
        setEditQuestion(e.target.value);
    }
    const onhandleanswer = (e) => {
        setEditanswer(e.target.value);
    }
    const isEmpty = (value) => {
      let emptyval=false;
     if(typeof(value) ==="undefined")
      emptyval=true;
     else if(value === "")
     emptyval=true;
     else if( value === null )
     emptyval=true;
    return emptyval;
    }
    const validateAll=(question,answer)=>{
      const validationError={};
       if(isEmpty(question))
       {
         validationError.question="Question Field is required";
       }
       if(isEmpty(answer))
       {
         validationError.answer="answer Field is required";
   
       }
      
       return validationError;
     }

    const onhandlefaqEdit = (e) => {
      const validate=validateAll(editquestion,editanswer);
      setValidationError(validate);

        if(id !== "undefined" && id !=="" && Object.keys(validate).length==0){
        try{  
            API.patch( `/module/product/faqs/${id}`, {
            "question": editquestion,
            "answer": editanswer,
            "isActive": faqStatus,
            "modifiedAt": "1997-09-09T00:00:00",
            "createdAt": "1997-09-09T00:00:00",
            "productId": prodid

        }).then((res)=>console.log(res));
        }
        catch (error) {
            console.log("error is", error);
        }
      }
        
    }

    const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);
  
  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);
  
  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

   const  handleDeleteFaq=(id)=>{
   console.log("delete id", id);
   if(id !== "undefined" && id !==""){
   console.log("check faq",id);
   try{
   API.patch( `/module/product/faqs/${id}`,{
        "question": editquestion,
        "answer": editanswer,
        "isActive": false,
        "modifiedAt": "1997-09-09T00:00:00",
        "createdAt": "1997-09-09T00:00:00",
        "productId": prodid

    }).then((res)=>showSuccessNotification("Edit Success"));
    editflag(true);
}
catch (error) {
    console.log("error is", error);
    showErrorNotification("error in Edit");
}
}  
   }
    
  
   
   
   
    const handlefaqstatus=(e)=>{
        if(e.target.checked)
  setFaqStatus(true);
  else 
  setFaqStatus(false);
    }


    return (<div className="faqblock">
      <div className="alignrow" >
        <div className="columnalignactive">
        <div className="group"><label for="name">Question</label><input type="text" size="90" placeholder="Question" value={editquestion} onChange={onhandlequestion} /></div>
        <span className="help-block">{validationError.question}</span></div>
       <div className="columnalignactive"> <div > Active </div> <div><input type="checkbox" onChange={handlefaqstatus} checked={faqStatus} /></div>
         
         </div>
         <Button onClick={ ()=>handleDeleteFaq(id)}> <DeleteIcon  fontSize="small"    /> </Button>
        </div>
        <br />
        <div className="columnalignactive">
        <div className="group"> <label for="name">Answer</label><textarea rows="10" style={{ width: "90%" }} placeholder="Answer" value={editanswer} onChange={onhandleanswer} /></div>
        <span className="help-block">{validationError.answer}</span>
        </div>
        <div> <input className="donebtn" type="button" onClick={onhandlefaqEdit} id={id} value="Edit" /></div>
    </div>)

}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuestionEditComponent);