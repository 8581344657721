import React, { useEffect, useState, useCallback } from "react";
import API from "../../utils/API";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import TableConfig from "../../utils/tableConfig";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Search from '../../components/Search/Search'
import { connect } from "react-redux";
function Collections(props) {


  const [selectRowId, setSelectRowId] = useState();
  const [collections, setCollections] = useState([]);
  const [collectiondetails, setCollectiondetails] = useState([]);
  const [collectionid, setCollectionId] = useState();

  const [ currentPage , setCurrentPage] = useState(0);
  const [ recordsTotal , setRecordsTotal]  = useState(0);
  const [ rowsPerPage, setRowPerPage] = useState(50);
  const [ totalPages , setTotalPages ] = useState(0);
  const [sortDirection , setSortDirection] = useState('desc');
  const [sortField , setSortField] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [clearSearch, setClearSearch] = useState(false)

  const columns = [
    {
      name: "CollectionId",
      selector: "collectionId",
      sortable: true,
      sortField: 'id',
      width:"150px"
    

    },
    {
      name: "Title",
      selector: "collectionTitle",
      sortable: true,
      sortField: 'code',
      width:"250px"

    },
    // {
    //   name: "Title (Arabic)",
    //   selector: "collectionTitleArabic",
    //   sortable: true,
    //   sortField: 'code',
    //   width:"250px"

    // },
    {
      name: "Products",
      selector: "collectionProductsCount",
      width:"150px",
      center:true,
      sortable: true,
      sortField: 'products',
     

    },
    {
      name:"SuperCollection",
      selector: "superCollection",
      center:true

    },
    {
      name: "Status",
      sortable: true,
      sortField: 'visible',
     cell:(row)=>row.status 

    },
  ]
  const handleChangeSelect = useCallback((state) => {

    console.log("when click on checkbox", typeof (state.selectedRows), state.selectedRows.map((coll) => coll.id));

    const collids = state.selectedRows.map((coll) => coll.id)

    setSelectRowId(collids[0]);
}, []);

  // useEffect(() => {
  //   if (selectRowId) {
  //     console.log("edit id", selectRowId);
  //     //props.history.push(`/dashboard/products/Edit/${selectRowId}`);
  //     props.history.push(`/dashboard/collections/Edit/${selectRowId}`)
  //   }
  // }, [selectRowId]);

  const handlerowselect = (row) => {
    console.log(row.id);
    setCollectionId(row.id);
    // props.history.push(`/dashboard/collections/view/${row.categoryid}`);
    // window.location.href  = `/dashboard/collections/view/${row.collectionId}`
    window.location.href  = `/dashboard/collection/${row.collectionId}`

  }

  const getSearchCollectionData = async() =>{
    showLoading()
    try{
      const res = await API.get(`/api/v1/collection/search?sort_by=${sortField||""}&sort_order=${sortDirection}&count=${rowsPerPage}&page=${currentPage}&search_key=${searchValue}`)
      if(res.status == 200){
        hideLoading();
        const collectionData = res.data;
        setCollections(collectionData.collection);
        setRecordsTotal(collectionData.recordsTotal)
        setTotalPages(collectionData.totalPages )
    }
    else{
      hideLoading();
      console.log("fetching the collection Data")
    }
  }
    catch(error){  
      hideLoading();
     console.log("error",error)      
    }
  
 
  }

useEffect(()=>{
  getSearchCollectionData();
},[currentPage,rowsPerPage,sortDirection])

useEffect(()=>{
  if(clearSearch){
    getSearchCollectionData();
  }
},[clearSearch])

  const handleSort = (column, sortDirection) =>{
   
    console.log("column",column)
    console.log("columnsortDirectionsortDirection",sortDirection)
     setCurrentPage(0)
     setSortField(column.sortField)
     setSortDirection(sortDirection)
  
  }

  const onChangePageClick = (page)=>{
    setCurrentPage(page-1)
  }
  const onChangeRowsPerPage = (currentRowsPerPage,currentPage) =>{
    setRowPerPage(currentRowsPerPage)
  }
  const handleSearch = (val) =>{
    if (val) {
      setSearchValue(val);
      setClearSearch(false);
    } else {
      setSearchValue("");
      setClearSearch(true);
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      getSearchCollectionData()
    }
}

const onSearchBtn = () =>{
  getSearchCollectionData()
}
const showLoading = () => props.toggleLoading({ isLoading: true });

const hideLoading = () => props.toggleLoading({ isLoading: false });
  return (

    <div className="productpage">
        <div className="rightextreme">
        <div className="action-container">
        <div>
              <Search
                  searchExpanded={true}
                  searchTerm={searchValue}
                  handleSearch={(val)=>handleSearch(val)}
                  clearSearch={()=>{setSearchValue("");setClearSearch(true)}}
                  handleSearchExit={()=>{}}
                  onKeyPress={handleKeyPress}
                ></Search>
            </div>
            <div className="search-btn" onClick={onSearchBtn}>
              Search
            </div>
          <div>
            <Link to={`/dashboard/collection/create`}> <button className="greenbackground" >Create Collection</button></Link>
          </div>
        </div>
      </div>
      <br></br>
      <Card >
        <DataTable
          highlightOnHover
          columns={

            columns

          }
          sortServer
          // sortIcon={<ArrowDownwardIcon/>}
          data={collections}
          onSort={handleSort}
          defaultSortField="id"
          defaultSortAsc={false}
          onRowClicked={handlerowselect}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={recordsTotal}
          onChangePage={onChangePageClick}
          paginationRowsPerPageOptions={TableConfig.ROWS_PER_PAGE_OPTIONS}
          paginationServerOptions={{
            totalPages:totalPages,
            totalRecords: recordsTotal,
            currentPage:currentPage
          }}
          paginationServer
          pagination
          responsive
          onChangeRowsPerPage={onChangeRowsPerPage}
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer', 
              },
            },
          }}


        />
      </Card>

    </div>
  )
}


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Collections);
