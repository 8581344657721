import React, { useState, useEffect } from 'react'
import API from "../../utils/API"
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Search from '../../components/Search/Search';
import TableConfig from '../../utils/tableConfig';



const UserManagement = (props) => {

  const [users,setUsers] = useState([])
  const [searchValue, setSearchValue] = useState("");

  const [ currentPage , setCurrentPage] = useState(0);
  const [ recordsTotal , setRecordsTotal]  = useState(0);
  const [ rowsPerPage, setRowPerPage] = useState(50);
  const [ totalPages , setTotalPages ] = useState(0);
  const [sortDirection , setSortDirection] = useState('desc');
  const [sortField , setSortField] = useState('id');
  const [clearSearch, setClearSearch] = useState(false)

  const columns = [
    {
      name: "Id",
      selector: "id",
      sortable: true,
      sortField:"id",
      width:"100px"

    },
    {
      name: "Name",
      selector: "name",
      // sortable: true,
      format: (user) => getUserName(user)

    },
    {
      name: "Email",
      selector: "adminEmail",
      sortable: true,
      sortField:"email"

    },
    {
      name: "Status",
      selector: (row) => row.active ? "Active" : "inActive",
    
      // sortable: true,

    },
  ]

  useEffect(()=>{
  
    fetchUser()

  },[currentPage,rowsPerPage,sortDirection])

  const fetchUser = async()=>{
    showLoading()
    try{
      const payload = {
        "key": searchValue
      }
      const res = await API.post(`api/v1/search/admin/users?count=${rowsPerPage}&page=${currentPage}&sort_by=${sortField}&sort_order=${sortDirection}`,payload)
      showLoading()
      if(res.status=== 200){
        hideLoading();
        const data = res.data
      const users = res.data.users
      setUsers(users)
      setRecordsTotal(data.recordsTotal)
      setTotalPages( data.totalPages )

    }
  
  else{
    console.log("api is not fetching")
  }
}
    catch{
      hideLoading()
    }
    
  }
  const getUserName = (user) =>{
    const {firstName ,lastName} = user
    return `${firstName ?? ""} ${lastName ?? ""}`.trim();
  }

  const handleSort = (column,sortDirection) =>{
    setCurrentPage(0)
    setSortField(column.sortField)
    setSortDirection(sortDirection)
  }

  const onChangePageClick = (page)=>{
    setCurrentPage(page-1)
  }
  const onChangeRowsPerPage = (currentRowsPerPage,currentPage) =>{
    setRowPerPage(currentRowsPerPage)
  }

  const handleSearch = (val) =>{
    if (val) {
      setSearchValue(val);
      setClearSearch(false);
    } else {
      setSearchValue("");
      setClearSearch(true);
    }
  }

  const onSearchBtn = () =>{
    fetchUser()
}

const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    fetchUser()
  }
}

useEffect(()=>{
  if(clearSearch){
    fetchUser()
  }
},[clearSearch])
const handlerowselect = (row) =>{
  window.location.href = `/dashboard/Emailsignup/${row.id}`
}
  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });
  return (
    <div>
      <>
        <div className="rightextreme">
        <div className="action-container">
        <div>
              <Search
                  searchExpanded={true}
                  searchTerm={searchValue}
                  handleSearch={(val)=>handleSearch(val)}
                  clearSearch={()=>{setSearchValue("");setClearSearch(true)}}
                  handleSearchExit={()=>{}}
                  onKeyPress={handleKeyPress}
                ></Search>
            </div>
            <div className="search-btn" onClick={onSearchBtn}>
              Search
            </div>
          <div>
            <Link to={"/dashboard/Emailsignup"}>
              <button className="greenbackground">Create User</button>
            </Link>
          </div>
          </div>
        </div>
        <br /><br />
        <div>
          <Card>
            <DataTable
              highlightOnHover
              sortServer
              columns={columns}
              data={users}
              defaultSortField="id"
              defaultSortAsc={false}
              onRowClicked={handlerowselect}
            
              onSort={handleSort}
              onChangePage={onChangePageClick}
              onChangeRowsPerPage={onChangeRowsPerPage}
              paginationTotalRows={recordsTotal}
              paginationPerPage={rowsPerPage}
              paginationServerOptions={{
                totalPages:totalPages,
                totalRecords: recordsTotal,
                currentPage:currentPage
              }}
              paginationRowsPerPageOptions={TableConfig.ROWS_PER_PAGE_OPTIONS}
              pagination
              paginationServer
              responsive
              customStyles={{
                rows: {
                  style: {
                    cursor: 'pointer', 
                  },
                },
              }}
            />
          </Card>
        </div>
      </>
    </div>
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

