import React, { useEffect, useState } from "react";
import Select from "react-select";
import API from "../../utils/API";

const BASE_URL_COUNTRIES = "/api/v1/active/countries";
const SELECT_STYLES = {
  container: (base) => ({ ...base, flex: 1 }),
};

function SelectCountry({ value, onChange = (f) => f , isCountryName=false, isDisabled=false}) {
  console.log("countryName",value)
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
        setCountries(countries);
      } catch (error) {
        console.log("fetchCountries:: Error on fetching countries!", error);
      }
    };
    fetchCountries();
  }, []);

  console.log("countriescountries",countries)

  const findCountryByCode = (code) =>
    countries.find((country) => country.code === code);

  const countryOptions = countries.map(createOption);
  const selectedCountry = createOption(findCountryByCode(value));


  const findCountryByName = (code) =>
  countries.find((country) => country.code === code);

const countryOptionsWithName = countries.map(createOptionByName);
const selectedCountryName = createOptionByName(findCountryByName(value));
if(isCountryName){
  console.log("countryOptionsWithNamecountryOptionsWithName",countryOptionsWithName)
  return(
      <Select
      key={`select-country-${value ?? ""}`}
      value={selectedCountryName}
      onChange={onChange}
      options={countryOptionsWithName}
      styles={SELECT_STYLES}
      isDisabled={isDisabled}
    />
  )
}else{
  return (
    <Select
      key={`select-country-${value ?? ""}`}
      value={selectedCountry}
      onChange={onChange}
      options={countryOptions}
      styles={SELECT_STYLES}
      isDisabled={isDisabled}
    />
  );
}
 
}

function createOption(country) {
  if (!country) return null;

  const { code, name,id } = country;
  return { key: code, value: code, label: name ,id:id};
}
function createOptionByName(country) {
  if (!country) return null;

  const { code, name,id } = country;
  return { key: name, value: name, label: name ,id:id};
}

export default SelectCountry;
