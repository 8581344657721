import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import CrossIcon from "../../../../../assets/img/cross_icon.png";
import './multi-select.scss'


export default function MultiSelectWithRanking({selectedData,optionData,fieldName,setProductOrganization}) {
    
    const [ selectedOption, setSelectedOption ] = useState([]);
    const [isBrandDropdownDisabled, setIsBrandDropdownDisabled] = useState(false);
    const handleChange = (option) =>{

      if (fieldName === "BRAND") {
        setSelectedOption([option]);
        setIsBrandDropdownDisabled(true);
    }else {
        const isAlreadyExist = selectedOption.find((data) => data?.value == option.value)
        if(!isAlreadyExist){
          setSelectedOption([...selectedOption,option])
        }
      }
        
      //   setProductOrganization(prevState => ({
      //     ...prevState,
      //     [fieldName]: selectedOption
      // }));
    }

    useEffect(()=>{
      console.log("abjabj",selectedData)
      if(selectedData && selectedData?.length > 0){
        setSelectedOption(selectedData)
        if (fieldName === "BRAND") {
          setIsBrandDropdownDisabled(true);
      }
      }

    },[selectedData,fieldName])
      useEffect(()=>{
        setProductOrganization(prevState => ({
            ...prevState,
            [fieldName]: selectedOption
        }));
      },[selectedOption, fieldName, setProductOrganization])


      const onDeleteItem = (value) => {
        const filterItem = selectedOption.filter((data, i) => data.value
        !== value);
        setSelectedOption(filterItem)
        if (fieldName === "BRAND") {
          setIsBrandDropdownDisabled(false);
      }
      }

      
      console.log("selectedDataselectedData",selectedData)

  return (
    <div className="multi-select-wrapper">
                <div className="select-container">
                    <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    oncloseMenuOnSelect={false}
                    value={[]}
                    onChange={handleChange}
                    options={optionData}
                    isDisabled={isBrandDropdownDisabled}
                    />
                </div>

                <div className="list-item-container">

                  {
                    selectedData && selectedData?.map((data,index)=>{
                      return(
                        <div className="list-item" >
                          <div className="list-item-txt">{data?.label}</div>
                          <img src={CrossIcon} onClick={()=>onDeleteItem(data.value)} className="cross-icon" alt="cross-icon"/>
                        </div>
                      )
                      
                    })
                  }
                  
                </div>
    </div>
  )
}
