import React, { useState, useEffect } from "react";
import API from "../../utils/API";
import { Editor } from '@tinymce/tinymce-react';
import { Card } from 'react-bootstrap';
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { SelectMenu } from "../../components/SelectMenu";
import { BodyTextEditor } from "../../components/RichEdittorComp";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CollectionProdRank from "../../components/CollectionProdRank";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BodyTextEditorCreate } from "../../components/RichEditorCreateComp";
function CreateCollection(props) {
  const [imageres, setImageres] = useState({});
  const [imageurlflag, setImageurlflag] = useState(false);
  const [imagefileupload, setImageFileUpload] = useState();
  const [supercoll, setSuperColl] = useState([]);
  const [collectiondetails, setCollectiondetails] = useState({});
  const [categoryviewdetails, setCategoryViewdetails] = useState();
  const [categoryeditdetails, setCategoryEditdetails] = useState();
  const [selectval, setSelectval] = useState("");
  const [brand, setBrand] = useState();
  const[editDescription,setEditDescription]=useState();
  const [superCollectionDetails, setSuperCollectionDetails] = useState([]);
  const [healthgoal, setHealthgoal] = useState();
  const [general, setGeneral] = useState();
  const [formfill, setFormfill] = useState(false);
  const [collectionStatus, setCollectionStatus] = useState("Active");
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [descriptions, setDescriptions] = useState([]);
  const [imageurl, setImageUrl] = useState();
  const [imageurlFlag, setImageUrlFlag] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editViewFlag, setEditViewFlag] = useState(false);
  const [prods, setProds] = useState([]);
  const [sortColumn, setSortColumn] = useState("rank");
  const [sortDirection, setSortDirection] = useState("asc");
  const [updatedProducts, setUpdatedProducts] = useState();
  const [brandId, setBrandId] = useState({});
  const [healthId, setHealthId] = useState({});
  const [generalId, setGeneralId] = useState({});
  const [healthType, setHealthType] = useState("");
  const [brandType, setBrandType] = useState("");
  const [generalType, setGeneralType] = useState("");
  const [updateProdRank, setUpdateProdRank] = useState(false);
  const [updateAllProds, setUpdateAllProds] = useState(false);
  const [editDetails, setEditdetails] = useState(false);
  const [finalProds, setFinalProds] = useState([]);
  const[validationError,setValidationError]=useState({});
  const[collectionFlag,setCollectionFlag]=useState(false);
  const params = useParams();
  const collectionviewid = params.CollectionViewId;
  const collectioneditid = params.CollectionEditId;





  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });
  useEffect(() => {
    if (params.CollectionViewId) {
      setView(true);
      setEditViewFlag(true);
      console.log("in create", collectionviewid);
    }
  }, [collectionviewid])

  useEffect(() => {
    if (params.CollectionEditId) {
      setEdit(true);
      setEditViewFlag(true);
      console.log("in COLLECTION", collectioneditid);
    }
  }, [collectioneditid])

  const uploadImage = (imagefileupload) =>{
    const formData = new FormData();
      formData.append("file", imagefileupload);
      const config = {
        method: "POST",
        url: "/s3/file/upload",
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      }
      try {
       API.request(config).then(res => setImageres(res));
  
      }
      catch (error) {
        console.log("when handling image", error);
      }
  }

  useEffect(() => {
    try {
      API.get("module/super/collection").then((res) => (res.data)).then((data) => setSuperCollectionDetails(data.map((coll) => { return ({ "id": coll.id, "name": coll.name }) })));
    }
    catch (error) {
      console.log("error is", error)
    }
  }, [])

  useEffect(() => {
    if (superCollectionDetails.length > 0) {
      console.log("supercoll", superCollectionDetails);
      setBrandId(superCollectionDetails.filter((supercollection) => supercollection.name == "BRAND")[0]);
      //console.log(brandiddetails);
      //setBrandId(brandiddetails);
      setHealthId(superCollectionDetails.filter((supercollection) => supercollection.name == "HEALTH GOAL")[0]);
      setGeneralId(superCollectionDetails.filter((supercollection) => supercollection.name == "GENERAL")[0]);

    }

  }, [superCollectionDetails])


  useEffect(() => {
    if (brandId)
      console.log("brand", brandId.id);

  }, [brandId])


  useEffect(() => {
    if (imageres.data) {
      const uploadDetails = imageres?.data;
      console.log("upload", uploadDetails);
      setImageUrl(uploadDetails?.s3Url ?? "");
      setImageUrlFlag(true);
      // setLabelflag(false);
      console.log(uploadDetails?.s3Url);
    }

    setImageurlflag(true);

  }, [imageres])



useEffect(() => {
  if (view || edit) {
    try {
      showLoading(); 
      
      if (view) {
        API.get(`/api/v1/private/product/type/${collectionviewid}`)
          .then((res) => res.data)
          .then((data) => {
            setCategoryViewdetails(data);
            setProds(data.products[0].product);
            setUpdateProdRank(true);
            hideLoading();
          })
          .catch((error) => {
            console.log("error is", error);
            hideLoading();
          });
      }
      

      if (edit) {
        API.get(`/api/v1/private/product/type/${collectioneditid}`)
          .then((res) => res.data)
          .then((data) => {
            setCategoryViewdetails(data);
            const products = data.products[0].product;
            setProds(products);
            setUpdateProdRank(true);
            hideLoading();
          })
          .catch((error) => {
            console.log("error is", error);
            hideLoading();
          });
      }
      
    } catch (error) {
      console.log("error is", error);
      hideLoading(); 
    }
  }
}, [editViewFlag]);



  useEffect(() => {
    if (prods.length > 0 && updateProdRank) {
      if (prods.length == 1) {
        setFinalProds(prods.map((prod, index) => { return (prod.rank == null ? { ...prod, "rank": 1 } : prod) }));
      }
      else {

        setFinalProds(prods.map((prod, index) => { return (prod.rank == null ? { ...prod, "rank": prods.length + 1 + index } : prod) }));

        console.log("checking after prod", prods);
      }
    }
    setUpdateAllProds(true);
  }, [prods])


  useEffect(() => {
    if (categoryviewdetails != undefined) {

      console.log("categoryviewdetails", categoryviewdetails.superCollection.name);
      setTitle(categoryviewdetails?.description?.name);
      setDescription(categoryviewdetails?.description?.description==null?"":categoryviewdetails?.description?.description);
      setBrandType(categoryviewdetails?.superCollection[0]?.name);

      setSuperColl(categoryviewdetails?.superCollection);

      setCollectionStatus(categoryviewdetails?.visible ? "Active" : "Inactive");
      setImageUrl(categoryviewdetails?.description?.image);
    }

  }, [categoryviewdetails])

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });



  useEffect(() => {
    if (editDetails && categoryeditdetails) {
      try {
        showLoading()
        const urlstr = `api/v1/private/product/type/${collectionviewid}`;
        API.put(urlstr, categoryeditdetails).then((res) =>
         {showSuccessNotification("Updated Collection Successfully");
        setCollectionFlag(true);
       
        setTimeout(()=>{
          // props.history.goBack();
          window.location.href ="/admin/dashboard/Collections"
          
        },1000)
       })
       .catch(error=>{
        showErrorNotification(error.response.data)
       });
      
      }
      catch (error) {
        console.log("error is", error);
        showErrorNotification(
          "Error on updating collection " + error.message ?? ""
        );
      }
      finally{
        hideLoading()
      }
    }

  }, [categoryeditdetails])


const onTitleChange = (e) => {
    setTitle(e.target.value);
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,title:"Title is required"});
    }
    else{
      setValidationError({...validationError,title:""});
    }
  }
  const isEmpty = (value) => {
    let emptyval=false;
   if(typeof(value) ==="undefined")
    emptyval=true;
   else if(value === "")
   emptyval=true;
   else if( value === null )
   emptyval=true;
   else if(value=="undefined")
   emptyval=true;
  return emptyval;
  }

const validateAll=(title,coll,status)=>{
  const validationError={};
  if(isEmpty(title))
  validationError.title="Title is required field";
 
  if(coll.length==0)
   validationError.coll="Type is required field";

   if(isEmpty(status))
     validationError.status="Status required field"
     
     else if(status=="Inactive" && !view)
     validationError.status="status should active";

     return validationError;
}


  const onhandleselect = () => {
     const valid= validateAll(title,supercoll,collectionStatus);
        setValidationError(valid);
    if(Object.keys(valid).length==0){
    setCollectiondetails({
      "code": title,
      "visible": collectionStatus == "Active" ? true : false,
      "allowAddToCart": true,
      "descriptions": [
        {
          "language": "en",
          "name": title,
          "description": description.bodyText,
          "friendlyUrl": null,
          "keyWords": null,
          "highlights": null,
          "metaDescription": description.bodyText,
          "title": title,
          "image": imageurl,
        }
      ],
      "superCollections": supercoll,
    });
    setFormfill(true);
  }
  else{
    showErrorNotification("some invalid fields");
  }
  }
  const statushandle = (e) => {
    
    setCollectionStatus(e.target.value);
     if(isEmpty(e.target.value)){
      setValidationError({...validationError,status:"status required"});
     }
     else{
      setValidationError({...validationError,status:""});
     }

  }

  const handleImage = (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      console.log("component", imageFile);
      try {
        // this.showLoading();
        uploadImage(imageFile)
        setImageFileUpload(imageFile);


      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );

      }
    }
  };



  const handleSuperColl = (e) => {
    if (e.target.checked) {
      console.log("value", e.target.value);
      if (e.target.value == "BRAND") {
        setSuperColl([{
          "id": brandId.id,
        }]);
        setBrand(e.target.value);
        setBrandType(e.target.value);


      }
      else if (e.target.value == "HEALTH GOAL") {
        setSuperColl([{
          "id": healthId.id
        }]);
        setHealthgoal(e.target.value);
        setBrandType(e.target.value);


      }
      else if (e.target.value == "GENERAL") {
        setSuperColl([{
          "id": generalId.id
        }]);
        setGeneral(e.target.value);
        setBrandType(e.target.value);
      }
    }
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,coll:"Type is required field"});
    }
    else{
      setValidationError({...validationError,coll:""});
    }
  }



  //post
  useEffect(() => {
    if (formfill && collectiondetails) {
      console.log("collectiondet", collectiondetails);
      try {
        API.post("/api/v1/private/product/type", collectiondetails)
          .then((res) => {
            showSuccessNotification("Collection created Successfully");
            setCollectionFlag(true);
            props.history.goBack();
          })
          .catch((error) => {
            showErrorNotification(error.response.data)
          });
      } catch (error) {
        console.log("fetchProducts:: Error on sending products details!", error);
        showErrorNotification(
          "Error on updating coupon " + error.message ?? ""
        );
      }
      setFormfill(false);
    }
  }, [collectiondetails]);




  const onhandlEditcoll = (e) => {
   console.log("image",imageurl);
  const error= validateAll(title,supercoll,collectionStatus);
  setValidationError(error);
  if(Object.keys(error).length==0){
    setCategoryEditdetails({
      "code": title,
      "visible": collectionStatus == "Active" ? true : false,
      "allowAddToCart": true,
      "descriptions": [
        {
          "language": "en",
          "name": title,
          "description": editDescription?editDescription:description,
          "friendlyUrl": null,
          "keyWords": null,
          "highlights": null,
          "metaDescription": null,
          "title": title,
          "image": imageurl,
        }
      ],
      "superCollections": supercoll,
      "ranks": finalProds.map((
        prod) => {
        return ({
          "productId": prod.id,
          "rank": prod.rank
        })
      }),

    })

    setEditdetails(true);}
    else{
      showErrorNotification("some invalid fields");
    }
  }




  const ProductsUpdated = (updatedProducts) => {
    setFinalProds(updatedProducts);
    console.log("updated products", updatedProducts);

  }




  return (
    <div >
      <div className="d-flex justify-content-start-0">
        <div style={{ alignSelf: "flex-start", marginRight: "1rem" }}>
          <Button
          onClick={() => { setView(false); setEdit(false); setImageUrlFlag(false); setUpdateAllProds(false); setUpdateProdRank(false); setEditdetails(false); props.history.goBack(); }}
          color="inherit"
          size="large"
        >
          <ArrowBackIcon />
          
        </Button>
        </div>
        {view?(
          <span
            className="mx-2 my-2"
            style={{ fontWeight: "600", fontSize: "1.25rem" }}
          >
             {categoryviewdetails?.code}
          </span>):( <span
            className="mx-2 my-2"
            style={{ fontWeight: "600", fontSize: "1.25rem" }}
          >
            New Collection
          </span>)}

        {view ? <div className="col text-end"><button className={collectionFlag ? 'disabled-button' : 'savebtn'} onClick={onhandlEditcoll} disabled={collectionFlag}>Save</button></div> : <></>}
        {!view && !edit ? <div className="col text-end" ><button className={collectionFlag ? 'disabled-button' : 'savebtn'} onClick={onhandleselect} disabled={collectionFlag}>Save</button></div> : <></>}</div>

      <div className="fullpage">

        <div className="firstblockcollection">

          <Card body>
            <div className="columnalignactive">
            <div>
              <label for="title"> <b>Title* </b></label><br />
              <div> <input id="title" type="text" className="inputfieldsize" value={title} onChange={onTitleChange} /></div>
            </div>
            <span className="help-block">{validationError.title}</span>
            </div>
            <br />
            <div className="columnalignactive">
            {view ? <BodyTextEditor 
                  value={description} setValue={setEditDescription}/> :
                
                <BodyTextEditor 
                  value={description}
                  setValue={bodyText => setDescription({ ...description, bodyText })}
                />}
                
                 </div>
          </Card>

          <Card body>

            <b> Collection Type*</b><br /><br />
            <div className="columnalignactive">
            <div className="radiorow">
              {
                 view ? <>

                  <input type="radio" value="BRAND" name="collection" checked={brandType == "BRAND"} /> Brand



                  <input type="radio" value="HEALTH GOAL" name="collection" checked={brandType == "HEALTH GOAL"}   />Health Goal


                  <input type="radio" value="GENERAL" name="collection" checked={brandType == "GENERAL"}   />General

                </>

                  : <><input type="radio" value="BRAND" name="collection" onChange={handleSuperColl} /> Brand
                    <input type="radio" value="HEALTH GOAL" name="collection" onChange={handleSuperColl} />Health Goals
                    <input type="radio" value="GENERAL" name="collection" onChange={handleSuperColl} />General
                  </>}
                  </div>
                  <span className="help-block">{validationError.coll}</span> 
            </div>

          </Card>
          {updateAllProds && finalProds.length > 0 ?
            <CollectionProdRank Products={finalProds ?? []}
              onProductsUpdated={ProductsUpdated} /> : <></>}





        </div>

        <div className="secondblock">
          <Card body>
            <div><b>Collection Status*</b></div> <br />
            <div className="columnalignactive">
            <div>  <select value={collectionStatus} onChange={statushandle}>
            
              <option value="Active" >Active</option>
              <option value="Inactive">Inactive</option>
            </select>
            </div>
            <span className="help-block">{validationError.status}</span> 
            </div>

          </Card>

          <Card body>
            <div> <b>Image</b></div><br />
            <div className="columnalignactive">
            <div className="imagecountryblock">
              {view? imageurl?<div style={{alignItems:"center"}}><label for="img"> <div className="newvaraintbutton">Add Image</div></label><input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} /><br/> <img src={imageurl} className="imageshowsize" /></div>:<div style={{alignItems:"center"}}> <label for="img"> <div className="newvaraintbutton">Add Image</div></label>
                <input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} /></div> : 
              imageurlFlag ?<div style={{alignItems:"center"}}><label for="img"> <div className="newvaraintbutton">Add Image</div></label><input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} />  <img src={imageurl} className="imageshowsize" /></div>:
              !imageurlFlag ? <div style={{alignItems:"center"}}> <label for="img"> <div className="newvaraintbutton">Add Image</div></label>
                <input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} /></div> : 
              imageurlFlag ?  <img src={imageurl} className="imageshowsize" /> : <></>}
              
            </div>
           
            </div>
          </Card>

        </div>
      </div>



    </div>)
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCollection);
