import React,{useState,useEffect} from "react";
import Select from "react-select";
import API from "../../src/utils/API";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";

export function ViewProdRelated(props){
    const{prodid,selectprod,componentflag,componentflagFun}=props;
     //console.log("componentFlag",compflag);
     const prodselection="products";
     const[prodEditSelect,setProdEditSelect]=useState();
     const[selectedAllEdit,setSelectedAllEdit]=useState([]);
     const[removeExist,setRemoveExist]=useState([]);
     const[prodsrelated,setProdsrelated]=useState([]);
     const[specialOfferProds,setSpecialOfferProds]=useState([]);
     
  useEffect(()=>{
    const url = `/module/related-product/${prodid}`;
            try {
                API.get(url).then((res) => res.data).then((data) => setProdsrelated(data));
              
            }
            catch (error) {
                console.log("error", error);
            }
    
  },[componentflag])

  useEffect(() => {
         
    try {
        API.get("/api/v1/products/").then((res) => res.data).then((data) => data.products).then((products) => setSpecialOfferProds(products.map((product) => { return ({ "value": product.id, "label": product?.description?.name }) })));
    }
    catch (error) {
        console.log("error", error);
    }

}, [])

 const onhandleEditRelate=(e)=>{
     if(prodsrelated[0].id==null)
     {
      setSelectedAllEdit([prodEditSelect.value]);

     }
     else{ 
        if(selectprod.id!==null){
            console.log("selectprod in viewprod",selectprod,prodEditSelect.value);
            setSelectedAllEdit([...prodsrelated.filter((prod)=>prod.id !== selectprod.id).map((prod)=>prod.id),prodEditSelect.value]);
     }
    else{
    setSelectedAllEdit([...prodsrelated.map((prod)=>prod.id),prodEditSelect.value]);
    }
     }
 }

const handleDeleteRelated=(prodid)=>{
    console.log("delete prod id",prodid);
    setSelectedAllEdit([...prodsrelated.filter((prod)=>prod.id !== prodid ).map((prod)=>prod.id)]);

}

 useEffect(()=>{
  if(selectedAllEdit.length>0){
  const url = `/module/related-product/${prodid}`;
  console.log("select all edit",selectedAllEdit);
  try {
    API.patch(url,selectedAllEdit).then((res)=>console.log(res));
    if(componentflag)
            componentflagFun(false);
            else{
                componentflagFun(true); 
            }
     
}
catch (error) {
    console.log("error", error);
}
  }
},[selectedAllEdit])


return(
    <div className="prodselect">
        
        <Select

                    name={prodselection}
                    options={specialOfferProds}
                    className="basic-single"
                    classNamePrefix="select"
                    oncloseMenuOnSelect={false}
                    defaultValue={{"value":selectprod.id,"label":selectprod.name}}

                    onChange={setProdEditSelect}

                    style={{ width: "100%" }}
                />

<br/>
<div className="alignrow "><input className="donebtn" type="button" onClick={onhandleEditRelate} value="Edit" /> 

<Button onClick={ ()=>handleDeleteRelated(prodEditSelect?.value?prodEditSelect.value:selectprod.id )}> <DeleteIcon /> </Button>
</div>

    </div>
)
}
