import { NavLink } from "react-router-dom";
import { getUserType } from "../services/userService";
import ArrowImage from "../assets/img/arrow.svg";
import routes from "../routes";

function Navigation(props) {
  console.log("njjj",props)
  return (
    <div id="accordion" className="nav-links">
      {createNavLinks(props)}
    </div>
  );
}


function createNavLinks(props) {
  // console.log("props",props)
  const {expandMenu,menuExpanded,setRouterName} = props||{}
  return routes
    .filter((route) => route.display ?? false)
    .filter((route) => hasRouteAccess(route))
    .map((route, index) => {
      if (route.hasSubroute) {
        // console.log("subRoute")
        return (
          <div
            className="nav-content"
            key={index}
            onClick={ () =>  expandMenu() }
          >
            <div
              className="nav-header collapsed nav-link accordion-header"
              data-toggle="collapse"
              data-target={"#collapse" + index}
              aria-expanded="false"
              aria-controls={"#collapse" + index}
            >
              <span>
                {/* <route.icon className="nav-icon" /> */}
                <span className="nav-text">
                  <span className="text">{route.name}</span>
                </span>
              </span>
              {/* <img className="arrow-down" alt="arrow" src={ArrowImage}></img> */}
            </div>
            <div
              // className="collapse"
              id={"collapse" + index}
              aria-labelledby={"heading1" + index}
              data-parent="#accordion"
            >
              <div className="sub-links">{renderSubroutes(route, props)}</div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="nav-content" key={index}>
            <NavLink
              to={route.path}
              className="nav-link nav-header"
              activeClassName="active"
              onClick={() => {
                // hideNotification(route.notificationId);
                props.setRouterName(route.name);
              }}
            >
              {/* <route.icon className="nav-icon" /> */}
              <span className="nav-text">
                <span className="text">{route.name}</span>
                {route.hasNotification ? (
                  <div className="notification"></div>
                ) : (
                  ""
                )}
              </span>
            </NavLink>
          </div>
        );
      }
    });
}

function hasRouteAccess(route) {
  const userRole = getUserType();
  // console.log("userRole",userRole)
  return route.role && route.role.includes(userRole);
}

function renderSubroutes(route, props) {
  // console.log("rouute",route,props)
  const subroutes = route?.subroutes ?? [];
  // console.log("subroutes",subroutes)
  return subroutes
    .filter((route) => route.display ?? false)
    .filter((route) => hasRouteAccess(route))
    .map((subroute, key) => {
      // console.log("subroute",subroute)
      return (
        <NavLink
          key={key}
          activeClassName="active"
          to={subroute.path}
          onClick={() => {
            // hideNotification(route.notificationId);
            props.setRouterName(subroute.name);
          }}
        >
          <span className="text">{subroute.name}</span>
          {subroute.hasNotification ? <div className="notification"></div> : ""}
        </NavLink>
      );
    });
}

export default Navigation;
