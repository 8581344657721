import React, { useState, useEffect } from "react";
import { Uploadfile } from "./Uploadfile";
import { Uploadfilefun } from "./UploadFilefun";
import API from "../../src/utils/API";
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from "react-bootstrap";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button } from "@mui/material";
import { connect } from "react-redux";
 function ImageComponent(props) {

  const [imageurlcomp, setImageUrlcomp] = useState();
  const [checkimage, setCheckimage] = useState();
  const[responseImage,setResponseImage]=useState([]);
  const { id, mode, imageprod,imageprodfun,imageurl,primaryCheckbox,primaryCheckboxFun } = props;

  const [imagerescomp, setImagerescomp] = useState({});
  const [imagefileuploadcomp, setImageFileUploadcomp] = useState();
  const[imagesPopup,setImagesPopup]=useState(false);
  const[temporaryVariant,setTemporaryVariantImage]=useState([]);
  const[borderImageIds,setBorderImageIds]=useState([]);
   useEffect(()=>{
      setImageUrlcomp(imageurl);
   },[props])


  useEffect(() => {
    const formData = new FormData();
    formData.append("file", imagefileuploadcomp);
    const config = {
      method: "POST",
      url: "/s3/file/upload",
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    }
    try {
      API.request(config).then(res => setImagerescomp(res));

    }
    catch (error) {
      console.log("when handling image", error);
    }
  }, [imagefileuploadcomp])

  const  uploadFileAll =(images)=>
  {
  const promises = [];
  console.log("length" ,images.length);
  for(let i=0;i<images.length;i++)
   {
    const formData = new FormData();
      formData.append("file", images[i]);
      
      const config = {
        method: "POST",
        url: "/s3/file/upload",
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
    promises.push(API.request(config));
  }
  
  // Once all promises are resolved, update the state
  Promise.all(promises).then((responses) =>  { const images = responses.map((response) => response.data.s3Url);
    setResponseImage(images);
    
  
  });
  
  
  }

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });
  const showLoading = () => props.toggleLoading({ isLoading: true });

const hideLoading = () => props.toggleLoading({ isLoading: false });

  useEffect(()=>{
    if(responseImage.length>0){
    console.log("resin useEffect",responseImage);
    imageprodfun([...imageprod,...responseImage]);
    }
  },[responseImage])

  const handleSelect=(img,id)=>{
    console.log("ids for select",img,id,borderImageIds);
    if(temporaryVariant.includes(img)){
      const images=temporaryVariant.filter((image)=>img!==image)
    setTemporaryVariantImage(images);
    }
    else{

   setTemporaryVariantImage([...temporaryVariant,img]);
    }
   if(borderImageIds.includes(id)){
    const selectedImageIds=borderImageIds.filter((imageId)=>imageId!==id);
    setBorderImageIds(selectedImageIds);
   }
    
   else{
   setBorderImageIds([...borderImageIds,id]);
   }
    console.log("select",temporaryVariant);
  }
  const handleDeleteImage=(image)=>{
    const allImages=imageprod.filter((img)=>img !== image);
    imageprodfun(allImages);
        
  }

  const handleImages =  (event) => {
    if (event.target.files && event.target.files.length) {
      console.log("all images",event.target.files);
      const imageFile = event.target.files;
      

      try {
        //showLoading();
        uploadFileAll(imageFile);
        
       // console.log("upload details",uploadDetails);
       // setProdImage([...prodimage, uploadDetails?.s3Url]);
        
        }
       
       catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );
        showErrorNotification(
          "Error on uploading image file! " + error.message ?? ""
        );
      } finally {
        hideLoading();
      }
    }
  };

const handleSelectedSaveImages =()=>{
   if(temporaryVariant.length>0) 
   setImageUrlcomp(temporaryVariant[0]);
}

  const form=(<>
    <div className="alignrowcenter"> <label className="label"><div className="newvaraintbutton">+Upload Image</div> <input type="file" className="variantbutton" accept="image/*" multiple onChange={handleImages}/></label>
    <div><input type="button" className="newvaraintbutton"  value="Save" onClick={handleSelectedSaveImages}/></div>
    </div>
    <div className="gridalign">
     { imageprod.map((img,index)=><div><img className="imageshowsizeborder" style={(borderImageIds.includes(index)?{border:'1px solid blue'}:{border:'1px solid black'})} onClick={()=>handleSelect(img,index)}src={img}  /><Button  className="aligniconborder"  onClick={ ()=>handleDeleteImage(img)}> <HighlightOffIcon  fontSize="small"    /> </Button> </div> )}
     </div>
   </>)

  useEffect(() => {
    if (imagerescomp.data) {
      const uploadDetails = imagerescomp?.data;
      console.log("upload", uploadDetails);
      setImageUrlcomp(uploadDetails?.s3Url ?? "");
      console.log(uploadDetails?.s3Url);
      imageprodfun([...imageprod,uploadDetails?.s3Url]);
    }



  }, [imagerescomp])



  const handleImageFileSelect = (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      console.log("component", imageFile);
      try {
        // this.showLoading();
        setImageFileUploadcomp(imageFile);


      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );

      }
    }
  };
  const handlecheckbox = (e) => {
    if(e.target.checked){
    setCheckimage("Primary");
      primaryCheckboxFun(imageurlcomp);
    }
    else{
    setCheckimage("");
    primaryCheckboxFun();
    }
  }

  const handleEdit=(imagename)=>{
    setImagesPopup(true);
  }
  
  const hideImageEditModal=()=>{
    setImagesPopup(false);
  }

  return (<div className="columnalign">
    <div className="alignrow"><div style={{ color: "#247A81" }}>Primary</div>
      <div> <input type="checkbox" value={id} onChange={handlecheckbox} checked={imageurlcomp==primaryCheckbox} /></div></div>

    <div className="imageStyle" >
      

    <img className="imageshowsize" src={imageurlcomp} />
    </div>
    <div className="alignrow"> <EditIcon onClick={()=>handleEdit(imageurlcomp)}/></div>
    <Modal
           size="lg"
          show={imagesPopup}
          onHide={hideImageEditModal}
        >
            <Modal.Header closeButton>
                <Modal.Title className="aligncenter">
                    <div>product image</div> 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{form}</Modal.Body>


        </Modal>

    </div>
      )
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageComponent);