import React,{useState,useEffect} from "react";
import API from "../../src/utils/API";


export function ImageEditComponent(props){
    const{mode, updatedUrlFun,imageAdd,imageAddFun}=props;
    const [imageurlcomp, setImageUrlcomp] = useState();
    const [imagerescomp, setImagerescomp] = useState({});
  const [imagefileuploadcomp, setImageFileUploadcomp] = useState();
  const [checkimage, setCheckimage] = useState();
  
    



const handleImageFileSelect = (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      console.log("component", imageFile);
      try {
        const formData = new FormData();
        formData.append("file", imageFile);
        const config = {
          method: "POST",
          url: "/s3/file/upload",
          data: formData,
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
        try {
          API.request(config).then(res =>{

            const uploadDetails = res?.data;
            console.log("upload", uploadDetails);
            setImageUrlcomp(uploadDetails?.s3Url ?? "");
            updatedUrlFun(uploadDetails?.s3Url);
            console.log("images",uploadDetails?.s3Url);
            imageAddFun(false);
          });
    
        }
        catch (error) {
          console.log("when handling image", error);
        }
       


      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );

      }
    }
  }
  const handlecheckbox = (e) => {
    if(e.target.checked)
    setCheckimage("Primary");
    else
    setCheckimage("");
  }


return(
    <div className="columnalign">
   <div className="alignrow"><div style={{ color: "#247A81" }}>Primary</div>
     <div> <input type="checkbox" value={checkimage} checked={checkimage=="Primary"} onChange={handlecheckbox} /></div></div>

   <div className="imageStyle" >
     <label className="label">
       <input type="file" name="uploadfile" onChange={handleImageFileSelect} required />
       <span>+ Image</span>
     </label>

     <img className="imageshowsize" src={imageurlcomp} />
   </div>
   </div>)

    

}

