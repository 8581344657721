import axios from "axios";
import history from "./history";
import {
  clearToken,
  getAccessToken,
  refreshAccessToken,
} from "../services/tokenService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  timeout: 20000,
});

instance.interceptors.request.use(
  function (config) {
    const token = getAccessToken();
    console.log("token is",token);
    
   // const token ='eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBzaG9waXplci5jb20iLCJhdWQiOiJhcGkiLCJleHAiOjIyODgzMjg3MzAsImlhdCI6MTY4MzUyODczMH0.P_KLUWk4FMuxP0PIgFz55Yf3jc1Maf9sBUB-htgb1ua4Hn4N5aIx0MtcBZ_Fhp0YJizC5kjoUOGJsd9cWgdMVQ'
    
    if (token) {
     config.headers.Authorization = `Bearer ${token}`;
   }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        // access token expired, call refresh api and set access token
        console.log("access token expired");
        
      } else if (error.response.status === 403) {
        //refresh token expired
        
        history.push("/login");
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
