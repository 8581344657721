import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import { Card } from "react-bootstrap";
import timeZones from "../assets/json/timezones.json";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
 function CountryRegion(props) {


  const [countries, setCountries] = useState([]);
  // const[selectedCountry,setSelectedCountry]=useState();
  const [imagefileupload, setImageFileUpload] = useState();
  const [imageres, setImageres] = useState({});
  const [imageUrl, setImageUrl] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [vat, setVAT] = useState();
  const [deliveryFee, setDeliveryFee] = useState();
  const [threshold, setThreshold] = useState();
  const [extraDiscount,setExtraDiscount] = useState()
  const [countryCode, setCountryCode] = useState();
  const [countryShort, setCountryShort] = useState();
  const [countryShortArabic, setCountryShortArabic] = useState();
  const [timings, setTimings] = useState();
  const [countryName, setCountryName] = useState();
  const [currency, setCurrency] = useState();
  const [save, setSave] = useState(false);
  const [timezone, setTimeZone] = useState();
  const [countrydet, setCountrydet] = useState({});
  const [postCountry, setPostCountry] = useState();
  const [editCountry, setEditCountry] = useState();
  const [viewEdit, setViewEdit] = useState(false);
  const[imageurlFlag,setImageUrlFlag]=useState(false);
  const [expectedDelivery, setExpectedDelivery] = useState();
  const [countryEditView, setCountryEditView] = useState(false);
  const[editCountryCode,setEditCountryCode]=useState();
  const [editFinal, setEditFinal] = useState(false);
  const[countryNameArabic,setCountryNameArabic]=useState();
  const[currencyArabic,setCurrencyArabic]=useState();
  const [codChargeValue,setCodChargeValue] = useState()
  const params = useParams();
  const viewCountryId = params.viewcountryId;
  const editCountryId = params.editcountryId;
  const [addFlag, setAddFlag] = useState(false);
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const[countryStatus,setCountryStatus]=useState(true);
  const[validationError,setValidationError]=useState({});
  const [customFee ,setCustomFee] = useState()
  const onhandleaddCountry = (e) => {
    setAddFlag(true);
  }
  useEffect(() => {
    if (params.viewcountryId) {
      console.log("viewCountryId", viewCountryId);
      setView(true);
      setViewEdit(true);

    }
  }, [viewCountryId])
 

  useEffect(() => {
    if (viewEdit) {
      if (view || edit) {
        try {
        //  console.log("edit", editCountryId);

          const countryIdViewEdit = view ? viewCountryId : params.editcountryId;
          console.log("id is", countryIdViewEdit);
          // API.get("/api/v1/country").then((res) => res.data).then((data) => setCountrydet((data).filter((country) => country.id == countryIdViewEdit)[0])).then(setCountryEditView(true));
          API.get(`/api/v1/country/${countryIdViewEdit}`).then((res)=>res.data).then((data)=>setCountrydet(data)).then(setCountryEditView(true))
        }
        catch (error) {
          console.log("error msg", error);
        }
      }

    }


  }, [viewEdit])






  useEffect(() => {
    if (countryEditView && countrydet) {
      console.log("country det", countrydet);
      setEmail(countrydet?.supportEmail);
      setPhone(countrydet?.supportContactNumber);
      setCountryCode(countrydet?.code);
      setCountryName(countrydet?.name);
      setCountryShort(countrydet?.shortName);
      setVAT(countrydet?.vat);
      setDeliveryFee(countrydet?.deliveryFee);
      setCurrency(countrydet?.currency);
      setThreshold(countrydet?.minThreshold);
      setExtraDiscount(countrydet?.prepaidDiscountPercentage)
      setTimeZone(countrydet?.timezone);
      setImageUrl(countrydet?.flagIcon);
      setImageUrlFlag(true);
      setExpectedDelivery(countrydet?.expextedDelivery);
      setTimings(countrydet?.supportTiming);
      setCountryStatus(countrydet?.supported)
      setCodChargeValue(countrydet?.codCharge);
      setCountryNameArabic(countrydet?.nameArabic)
      setCountryShortArabic(countrydet?.shortNameArabic)
      setCustomFee(countrydet?.customFee)
    }
  }, [countrydet, countryEditView])
  
  const isEmpty = (value) => {
    let emptyval=false;
   if(typeof(value) ==="undefined")
    emptyval=true;
   else if(value === "")
   emptyval=true;
   else if( value === null )
   emptyval=true;
   else if(value=="undefined")
   emptyval=true;
  return emptyval;
  }

  const validateAll=(email,phone,countryCode,countryName,countryShort,Vat,deliveryFee,currency,threshold,timeZone,image,expectedDelivery,timings,status,countryNameArabic,countryShortArabic)=>{
    const error={}
    if(isEmpty(email))
       error.email="Email is required field";
      if(isEmpty(phone))
      error.contact="Contact is required field";
      if(isEmpty(countryCode))
      error.countryCode="Country Code is required field";
      if(isEmpty(countryName))
      error.countryName="Country Name is required field";
      if(isEmpty(countryNameArabic))
        error.countryNameArabic="Country Name Arabic is required field";
      if(isEmpty(countryShort))
      error.countryShort="Country short is required field";
      if(isEmpty(countryShortArabic))
        error.countryShortArabic="Country short Arabic is required field";
      if(isEmpty(Vat))
      error.vat="VAT is required field";
      else if(Vat<0)
      error.vat="VAT should greater than 0";
      if(isEmpty(deliveryFee))
      error.deliveryFee="Delivery Fee is required field";
      if(deliveryFee<0)
      error.deliveryFee="Delivery Fee greater than 0";
      if(isEmpty(currency))
      error.currency="Currency is required field";
      if(isEmpty(threshold))
      error.threshold="Threshold is required field";
       if(threshold<0)
      error.threshold="Threshold greater than 0";
      if(isEmpty(timeZone))
      error.timeZone="TimeZone is required field";
      else if(timeZone=="Time Zone")
      error.timeZone="select valid field";
      if(isEmpty(image))
      error.image="Image is required field";
      if(isEmpty(expectedDelivery))
      error.expectDelivery="Expected Delivery is required field";
      if(expectedDelivery<0)
      error.expectDelivery="Expected Delivery greater than 0";
      if(isEmpty(timings))
      error.timings="Timings is required field";
      if(!view ){
        if(!status){
          error.status="status active while create";
      }
      }


  return error;

  }

  useEffect(() => {
    if(imagefileupload){
    const formData = new FormData();
    formData.append("file", imagefileupload);
    const config = {
      method: "POST",
      url: "/s3/file/upload",
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    }
    try {
      API.request(config).then(res => setImageres(res));

    }
    catch (error) {
      console.log("when handling image", error);
    }
  }
  }, [imagefileupload])

  useEffect(() => {
    if (imageres.data) {
      const uploadDetails = imageres?.data;
      console.log("upload", uploadDetails);
      
      setImageUrl(uploadDetails?.s3Url ?? "");
       if(uploadDetails?.s3Url){
           setValidationError({...validationError,"image":""});
       }
      setImageUrlFlag(true);
      // setLabelflag(false);
      console.log(uploadDetails?.s3Url);
    }
  }, [imageres])


  const handleImage = (event) => {
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      console.log("component", imageFile);
      try {
        // this.showLoading();
        setImageFileUpload(imageFile);


      } catch (error) {
        console.log(
          "handleImageFileSelect:: Error on uploading image file!",
          error
        );

      }
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,email:"Email is required filed"});

    }
    else{
      setValidationError({...validationError,email:""});
    }
  }

  // const handleContact = (e) => {
  //   setPhone(e.target.value);
  //   if(isEmpty(e.target.value)){
  //     setValidationError({...validationError,phone:"Contact is required field"});

  //   }
  //   else{
  //     setValidationError({...validationError,phone:""});
  //   }

  // }
  const handlefee = (e) => {
    setDeliveryFee(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,deliveryFee:"Delivery fee is required field"});

    }
    else{
      setValidationError({...validationError,deliveryFee:""});
    }

  }
  const handleVAT = (e) => {
    setVAT(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,vat:"VAT is required field"});

    }
    else{
      setValidationError({...validationError,vat:""});
    }
  }
  const handleThreshold = (e) => {
    setThreshold(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,threshold:"Threshold is required field"});

    }
    else{
      setValidationError({...validationError,threshold:""});
    }

  }

  const handleExtraDiscount = (e) =>{
    setExtraDiscount(e.target.value)
  }
  const handleCountryCode = (e) => {
    setCountryCode(e.target.value);
    console.log("country code",e.target.value);
    if(view)
    {
      setEditCountryCode(e.target.value);
    }
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,countryCode:"CountryCode is required field"});


    }
    else{
      setValidationError({...validationError,countryCode:""});
    }
  }
  const handleCountryName = (e) => {
    setCountryName(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,countryName:"Country Name is required field"});

    }
    else{
      setValidationError({...validationError,countryName:""});
    }
  }
  const handleCountryNameArabic= (e) => {
    setCountryNameArabic(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,countryNameArabic:"Country Name Arabic is required field"});

    }
    else{
      setValidationError({...validationError,countryNameArabic:""});
    }
  }

  const handleCountryShort = (e) => {
    setCountryShort(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,countryShort:"CountryShortCode is required field"});

    }
    else{
      setValidationError({...validationError,countryShort:""});
    }
    
  }
  const handleCountryShortArabic = (e) => {
    setCountryShortArabic(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,countryShortArabic:"CountryShortCode Arabic is required field"});

    }
    else{
      setValidationError({...validationError,countryShortArabic:""});
    }
    
  }
  const handleCurrency = (e) => {
    setCurrency(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,currency:"Currency is required field"});

    }
    else{
      setValidationError({...validationError,currency:""});
    }
  }
  const handleTimings = (e) => {
    setTimings(e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,timings:"Timings is required field"});

    }
    else{
      setValidationError({...validationError,timings:""});
    }
  }
  const handleTimezone = (e) => {
    setTimeZone(e.target.value);
    console.log("timezone",e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,timeZone:"TimeZone is required field"});

    }
    else if(e.target.value=="Time Zone")
    {
      setValidationError({...validationError,timeZone:"select valid field"});
    }
    else{
      setValidationError({...validationError,timeZone:""});
    }
  }
  const handleSave = (e) => {
    setSave(true);
  }

  const handleExpecteddelivery = (e) => {
    setExpectedDelivery(e.target.value);
    console.log("expected",e.target.value);
    if(isEmpty(e.target.value)){
      setValidationError({...validationError,expectDelivery:"ExpectedDelivery is required field"});

    }
    
    else {
      setValidationError({...validationError,expectDelivery:""});
    }

  }
  const handleCodCharge = (e) =>{
    setCodChargeValue(e.target.value)
  }

  const handleCustomFee = (e) =>{
    setCustomFee(e.target.value)
  }
  const handleCountryStatus=(e)=>{
    if(e.target.checked)
    setCountryStatus(true);
    else{
      setCountryStatus(false);
    }
    if(isEmpty(e.target.value))
    {
      setValidationError({...validationError,status:"Status is required field"});
    }
    else{
      setValidationError({...validationError,status:""});
    }

  }
  const numberCheck = async (value, data, event, formattedValue) => {
    if (value.slice(data.dialCode.length).length > 6) {
      const contact = data.dialCode + value.slice(data.dialCode.length);
      setPhone(contact);
  
      if (isEmpty(phone)) {
        setValidationError({ ...validationError, phone: "Contact is required field" });
      } else {
        setValidationError({ ...validationError, phone: "" });
      }
    } else {
      setPhone("");
    }
  }
  ;



  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });


  useEffect(() => {
   
   if (save) {
    const error= validateAll(email,phone,countryCode,countryName,countryShort,vat,deliveryFee,currency,threshold,timezone,imageUrl,expectedDelivery,timings,countryStatus,countryNameArabic,countryShortArabic);
     setValidationError(error);
    if(Object.keys(error).length==0) {
      setPostCountry({
        "isoCode": countryCode, "supported": countryStatus, "name": countryName, "vat": vat, "zones": [], "deliveryFee": deliveryFee, "flagIcon": imageUrl,
        "supportEmail": email, "supportContactNumber": phone, "supportTiming": timings, "timezone": timezone, "shortName": countryShort, "currency": currency, "estimatedDelivery": expectedDelivery,
        "minThreshold": threshold,"prepaidDiscountPercentage":extraDiscount,"codCharge": parseFloat(codChargeValue), "nameArabic":countryNameArabic,"shortNameArabic":countryShortArabic, "customFee":parseFloat(customFee)
      }

      );

    }
    else{
      showErrorNotification("Some of the Fields are Invalid");
      setSave(false)
    }
  }
  
  }, [save])

  useEffect(() => {
    
   
    if (editFinal) {
      const error= validateAll(email,phone,countryCode,countryName,countryShort,vat,deliveryFee,currency,threshold,timezone,imageUrl,expectedDelivery,timings,countryStatus,countryNameArabic,countryShortArabic);
     setValidationError(error);
      if(Object.keys(error).length==0){
      setEditCountry({
        "code": countryCode, "supported": countryStatus, "name": countryName, "vat": vat, "zones": [], "deliveryFee": deliveryFee, "flagIcon": imageUrl,
        "supportEmail": email, "expextedDelivery": expectedDelivery, "supportContactNumber": phone, "supportTiming": timings, "timezone": timezone, "shortName": countryShort, "currency": currency,
        "minThreshold": threshold,"prepaidDiscountPercentage": extraDiscount,"codCharge": parseFloat(codChargeValue),"nameArabic":countryNameArabic, "shortNameArabic":countryShortArabic, "customFee":parseFloat(customFee)
      });
    }
    else{
      showErrorNotification("Some of the fields are Invalid");
      setEditFinal(false)
    }
  }
  


  }, [editFinal])


  const handleEdit = (e) => {
    console.log("whbhbwhj")
    setEditFinal(true);
    setView(true)
  }



  useEffect(() => {

    if (postCountry) {
      try {
        if (save) {
          API.post("/api/v1/country", postCountry).then((res) =>{showSuccessNotification("Country Created Successfully");props.history.goBack(); });
        }
      }
      catch (error) {
        console.log("error is", error);
        showErrorNotification(
          "Error on updating collection " + error.message ?? ""
        );
      }
    }
  }, [postCountry])

  useEffect(() => {
    console.log("edit country",editCountry,editFinal,view)
    if (editCountry && editFinal) {
      try {
        if (view) {
          API.patch(`/api/v1/country/${viewCountryId}`, editCountry).then((res) => {showSuccessNotification("Country Updated Successfully");props.history.goBack();});
        }
      }
      catch (error) {
        console.log("error is", error);
        showErrorNotification(
          "Error on updating collection " + error.message ?? ""
        );
      }
    }
  }, [editCountry,editFinal,view])
  
  const handleCountryArabic=(e)=>{
setCountryNameArabic(e.target.value);
  }
  const handleCurrencyArabic=(e)=>{
    setCurrencyArabic(e.target.value);
  }


  return (


    <div>
      <div><Button
        onClick={() => { setEdit(false); setView(false);setImageUrlFlag(false); setCountryEditView(false); setViewEdit(false); props.history.goBack(); }}
        
        color="inherit"
        size="small"
      >
        <ArrowBackIcon />
      </Button></div>
      <br/>
      <Card body>
        <div className="fullcard">
          <div className="countryfirstblock">
            <div className="columnalignactive">
            <div className="imagecountryblock">
              
            {view? imageUrl?<div style={{alignItems:"center"}}><label for="img"> <div className="newvaraintbutton">Add Image</div></label><input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} /><br/> <img src={imageUrl} className="imageshowsize" /></div>:<div style={{alignItems:"center"}}> <label for="img"> <div className="newvaraintbutton">Add Image</div></label>
                <input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} /></div> : 
              imageurlFlag ?<div style={{alignItems:"center"}}><label for="img"> <div className="newvaraintbutton">Add Image</div></label><input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} />  <img src={imageUrl} className="imageshowsize" /></div>:
              !imageurlFlag ? <div style={{alignItems:"center"}}> <label for="img"> <div className="newvaraintbutton">Add Image</div></label>
                <input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} /></div> : 
              imageurlFlag ?  <img src={imageUrl} className="imageshowsize" /> : <></>}
            </div>
            <span className="help-block"  >{validationError.image}</span>
            </div>
            <div className="headingcolor"> <div>Time Zone</div>
            <div className="columnalignactive">
              <select value={timezone} onChange={handleTimezone}>
                <option  >Time Zone </option>
                {timeZones && Object.keys(timeZones).map((key, index) => (
                  <option key={index} value={timeZones[key]}>
                    {timeZones[key]}
                  </option>
                ))
                }
              </select>
              <span className="help-block"  >{validationError.timeZone}</span>
            </div>
            </div>

            <div className="headingcolor">
             <div> Operating Hours * </div>
             <div className="columnalignactive">
              <div>
                <input type="text" value={timings} onChange={handleTimings} placeholder="Operatong Hours" />
              </div>
              <span className="help-block">{validationError.timings}</span>
              </div>
            </div>
          </div>
          <div className="countrysecondblock">
          <div className="headingcolor"><div> Country Details & Currency</div> </div>
            <div className="alignrowCountry">
            <div className="columnalignactive">
            <div className="group"> <label for="name">Country Name *</label> <input className="countryplaceholder" value={countryName} type="text" placeholder="Country (English)" onChange={handleCountryName} /></div>
            <span className="help-block">{validationError.countryName}</span>
            </div>

            <div className="columnalignactive">
            <div className="group"> <label for="name">Country Name (Arabic) *</label> <input className="countryplaceholder" value={countryNameArabic} type="text" placeholder="Country (Arabic)" onChange={handleCountryNameArabic} /></div>
            <span className="help-block">{validationError.countryNameArabic}</span>
            </div>
           
            
            <div className="columnalignactive"><div>Active</div>
                    <div><input type="checkbox" value={countryStatus}
                    onChange={handleCountryStatus}  checked={countryStatus}/></div>
                     <span className="help-block">{validationError.status}</span>
                </div>
            
            
            </div>
            <div className="alignrowCountry">
            <div className="columnalignactive">
            <div className="group"> <label for="name">Country Short * </label> <input type="text" placeholder="Country in Short" value={countryShort} onChange={handleCountryShort} required /></div>
            <span className="help-block">{validationError.countryShort}</span>
            </div>
            <div className="columnalignactive">
            <div className="group"> <label for="name">Country Short (Arabic) * </label> <input type="text" placeholder="Country in Short Arabic" value={countryShortArabic} onChange={handleCountryShortArabic} required /></div>
            <span className="help-block">{validationError.countryShortArabic}</span>
            </div>
</div>
           
            <div className="alignrowCountry">
            <div className="columnalignactive">
            <div className="group"> <label for="name">Country Code *</label>  <input type="text" placeholder="Country code" value={countryCode} onChange={handleCountryCode} required /></div>
            <span className="help-block">{validationError.countryCode}</span>
             </div>
             <div className="columnalignactive">
            <div className="group"> <label for="name">Currency *</label>   <input type="text" placeholder="Currency (English)" value={currency} onChange={handleCurrency} /></div>
            <span className="help-block">{validationError.currency}</span>
            </div>         
            </div>
            <div className="headingcolor"><div> Delivery Fee & Vat * </div></div>
            <div className="alignrowCountry" style={{width:"145%"}}>
            <div className="columnalignactive">
            <div className="group"> <label for="name">VAT *</label>
              <input type="number" placeholder="VAT (%)" value={vat} onChange={handleVAT} onWheel={(e)=>e.target.blur()} required /></div>
              <span className="help-block">{validationError.vat}</span>
             </div>
             <div className="columnalignactive">
              <div className="group"> <label for="name">Delivery Fee *</label>  <input type="number" placeholder="Delivery Fee*" onWheel={(e)=>e.target.blur()} value={deliveryFee} onChange={handlefee} required /></div>
              <span className="help-block">{validationError.deliveryFee}</span>
              </div>
              <div className="columnalignactive">
              <div className="group"> <label for="name">Threshold *</label> <input type="number" placeholder="Free Delivery Threshold*" onWheel={(e)=>e.target.blur()} value={threshold} onChange={handleThreshold} required /></div>
              <span className="help-block">{validationError.threshold}</span>
            </div>
            <div className="columnalignactive" style={{width:"38%"}}>
              <div className="group" > <label for="name">Extra Prepaid Discount (%)</label> <input type="number" placeholder="Extra Prepaid Discount" onWheel={(e)=>e.target.blur()} value={extraDiscount} onChange={handleExtraDiscount}  /></div>
            </div>
            </div>
            <div className="alignrowCountry">
            <div className="columnalignactive">
            <div className="group"> <label for="name">Delivery Time *</label> <input type="number" value={expectedDelivery} placeholder="Ext. delivery time (in days)" onWheel={(e)=>e.target.blur()} onChange={handleExpecteddelivery} /></div>
            <span className="help-block">{validationError.expectDelivery}</span>
            </div>
            <div className="columnalignactive">
            <div className="group"> <label for="name">COD Charges</label> <input type="number" value={codChargeValue} placeholder="COD Charges" onWheel={(e)=>e.target.blur()} onChange={handleCodCharge} /></div>
            </div>
            <div className="columnalignactive">
            <div className="group"> <label for="name">Custom Fee</label> <input type="number" value={customFee} placeholder="Custom Fee" onWheel={(e)=>e.target.blur()} onChange={handleCustomFee} /></div>
            </div>
            </div>
            <div className="alignrowCountry">
              <div className="headingcolor"> <div>Communication (Contact us) *</div> <br/>

              <div className="columnalignactive">
              <PhoneInput
                    inputClass="font-poppins"
                    country={view? (editCountryCode?editCountryCode.toLowerCase():""):(countryCode?countryCode.toLowerCase():"ae")}
                    value={phone || ""}
                    placeholder={"Enter Phone Number"}
                    onChange={(value, data, event, formattedValue) => {
                      numberCheck(value, data, event, formattedValue);
                    }}
                  />
                <span className="help-block">{validationError.contact}</span>
                </div>
                </div>
              <div className="headingcolor"> <div>Communication(Email Id) *</div> <br/>
              <div className="columnalignactive">
                <input type="Email" placeholder="Email id" value={email} onChange={handleEmail} required /></div>
                <span className="help-block">{validationError.email}</span>
                </div>
            </div>
          </div>
        </div>
        <br />
        {view ? <div className="aligncenter"><input type="button" className="savebtn" value="Save" onClick={handleEdit} /></div> :
            <div className="aligncenter"><input type="button" className="savebtn" value="Save" onClick={handleSave} /></div>}
      </Card>

    </div>



  )

}



function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryRegion);