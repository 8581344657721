import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import DeleteIcon from "../../../../assets/img/delete.png";
import './faq.scss';

const updateArray = (array, index,fieldName, value) => {
    const newArray = array.map((item, i) => {
      if (i === index) {
        return { ...item, [fieldName]: value };
      }
      return item;
    });
    return newArray;
  };
export default function Faq({faqData,setFaqData,faqError}) {

 const onAddNewFaq = () =>{
    let newFaqData= {
        question:"",
        answer:"",
        questionArabic:"",
        answerArabic:"",
        isActive:true,
     }
     setFaqData([...faqData,newFaqData])
 }


 const onChangeFaqValue = ({fieldName,value,index}) => {
    const updatedArray = updateArray(faqData,index,fieldName,value);
    setFaqData(updatedArray);
 }

 const onDeleteFaq = (index) => {
    const filterItem = faqData.filter((_, i) => i !== index);
    setFaqData(filterItem)
 }




  return (
    <div className="faq-wrapper">
          <Card body>
            <div>
                <div className="faq-container">
                   
                    <label className="label-txt">FAQ (Frequently Ask Question)</label>
                    <div className="action-btn" onClick={onAddNewFaq}>+ Add Question</div>
                </div>
                {
                    faqData.map((data,index)=>{
                        console.log("datat++++",data.status)
                        return(
                            <>
                            
                           
                            <div className="faq-question-and-answer-container">
                            <div>
                                <div className="alignrow">
                                    <div className="columnalignactive">
                                        <div className="group">
                                            <label for="name" className="label-title-txt">Question*</label> 
                                            <input type="text" value={data.question|| ""} size="100"   onChange={(e)=>{
                                                onChangeFaqValue({
                                                    fieldName:"question",
                                                    value:e.target.value,
                                                    index:index
                                                })
                                            }}  style={{width:'100%'}} />
                                        </div>
                                    </div> 
                                    <div className="columnalignactive">
                                        <div className="group">
                                            <label for="name" className="label-title-txt">Question (Arabic)*</label> 
                                            <input type="text" value={data.questionArabic|| ""} size="100" className="ar-inputfieldsize" onChange={(e)=>{
                                                onChangeFaqValue({
                                                    fieldName:"questionArabic",
                                                    value:e.target.value,
                                                    index:index
                                                })
                                            }}  style={{width:'100%'}} />
                                        </div>
                                    </div> 
                                </div>
                                <br />
                                <div className="alignrow">
                                <div className="columnalignactive">
                                    <div className="group">
                                        <label for="name" className="label-title-txt">Answer*</label> 
                                        <textarea rows={30}  cols={100}  style={{ width: "100%" }}  value={data.answer || ""}  onChange={(e)=>{
                                                onChangeFaqValue({
                                                    fieldName:"answer",
                                                    value:e.target.value,
                                                    index:index
                                                })
                                            }}   />
                                    </div>
                                </div>
                                <div className="columnalignactive">
                                    <div className="group">
                                        <label for="name" className="label-title-txt">Answer (Arabic)*</label> 
                                        <textarea rows={30}  cols={100}  style={{height:"5rem",width:"100%"}} value={data.answerArabic || ""} className="ar-inputfieldsize" onChange={(e)=>{
                                                onChangeFaqValue({
                                                    fieldName:"answerArabic",
                                                    value:e.target.value,
                                                    index:index
                                                })
                                            }}   />
                                    </div>
                                </div>
                                </div>
                               
                            </div>
                            <div className="group status">
                                <div className="label-title-txt">Active</div>
                                <input type="checkbox" checked={data.isActive}  onChange={()=>{  onChangeFaqValue({
                                                    fieldName:"isActive",
                                                    value:!data.isActive,
                                                    index:index
                                                })}} class="checkbox-round"  />
                            </div> 
                            <div className="group action" onClick={()=>onDeleteFaq(index)}>
                                <img src={DeleteIcon} alt="delete-icon"/>
                            </div> 
                            
                            </div>
                            {faqError && faqError[index] && <span style={{fontSize:'12px',color:'red',marginTop:'10px'}} >{faqError[index]}</span>}
                            </>
                        )
                    })
                }
               
            </div>
          </Card>
    </div>
  )
}

                           
                           
