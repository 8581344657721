import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from "react-redux";

function QuestionCreateComponent(props) {

  const { prodid, addquestion } = props;
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [faqcreate, setfaqcreate] = useState({});
  const [doneStatus, setDoneStatus] = useState(false);
  const[validationError,setValidationError]=useState({});
  const[faqStatus,setFaqStatus]=useState(true);
  const[questionFlag,setQuestionFlag]=useState(false);
  const onhandlecreatequestion = (e) => {
    setQuestion(e.target.value);
    if(isEmpty(e.target.value))
    setValidationError({...validationError,question:"question field is required"});
    else 
    setValidationError({...validationError,question:""});

  }
  const onhandlecreateanswer = (e) => {
    setAnswer(e.target.value);
    if(isEmpty(e.target.value))
    setValidationError({...validationError,answer:"answer field is required"});
    else 
    setValidationError({...validationError,answer:""});
   
  }
   
  const isEmpty = (value) => {
    let emptyval=false;
   if(typeof(value) ==="undefined")
    emptyval=true;
   else if(value === "")
   emptyval=true;
   else if( value === null )
   emptyval=true;
  return emptyval;
  }

  const validateAll=(question,answer,status)=>{
   const validationError={};
    if(isEmpty(question))
    {
      validationError.question="Question Field is required";
    }
    if(isEmpty(answer))
    {
      validationError.answer="answer Field is required";

    }
    if(!status){
      validationError.status="status active";
    }
    return validationError;
  }
 

const handlefaqstatus=(e)=>{
  if(e.target.checked)
  setFaqStatus(true);
  else 
  setFaqStatus(false);
    if(!(e.target.value))
    setValidationError({...validationError,status:"status active"});
    else if(e.target.value)
    setValidationError({...validationError,status:""});
}


  const onhandleCreate = (e) => {

    const validate=validateAll(question,answer,faqStatus);
    setValidationError(validate);
    if(Object.keys(validate).length===0){
    if (props.mode == "EditCreate") {
      setfaqcreate(
        {
          "question": question,
          "answer": answer,
          "isActive": faqStatus,
          "modifiedAt": "1997-09-09T00:00:00",
          "createdAt": "1997-09-09T00:00:00",
          "productId": props.prodid
        })

    }
    if (props.mode == "Create") {
      setfaqcreate(
        {
          "question": question,
          "answer": answer,
          "isActive": faqStatus,
          "modifiedAt": "1997-09-09T00:00:00",
          "createdAt": "1997-09-09T00:00:00",
          "productId": ""
        })


    }

    setDoneStatus(true);
    setQuestionFlag(true);
  }
  }

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });



  useEffect(() => {
    try {
      if (faqcreate && doneStatus) {
        if (props.mode == "EditCreate") {
          API.post("/module/product/faqs", faqcreate).then((res) => console.log(res));
          showSuccessNotification("successfully saved");
        }
        if (props.mode == "Create") {
          console.log("create Review", faqcreate);
          props.allFaqs([...props.allFaq, faqcreate]);
          showSuccessNotification("successfully saved");
        }
        
        props.addfun(false);

      }
    }
    catch (error) {
      console.log("error is", error);
    }
    setDoneStatus(false);
  }, [faqcreate])


  return (<div className="faqblock">
    <div className="alignrow">
       <div className="columnalignactive">
       <div className="group"><label for="name">Question*</label>  <input type="text" size="100" placeholder="Question" onChange={onhandlecreatequestion} />
          </div>
   <span className="help-block">{validationError.question}</span></div> 
     <div className="columnalignactive"><div> Active </div> <div><input type="checkbox" onChange={handlefaqstatus} value={faqStatus} checked={faqStatus} /></div>
     <span className="help-block">{validationError.status}</span>
     </div>
     
     
     
     </div>
     <br />
     <div className="columnalignactive"><div className="group"><label for="name">Answer*</label> 
      <textarea rows="10" style={{ width: "90%" }} placeholder="Answer" onChange={onhandlecreateanswer} /></div>
     <span className="help-block">{validationError.answer}</span>
     </div>
    <div> <input className="donebtn" type="button" onClick={onhandleCreate} value="Done" disabled={questionFlag}/></div>
  </div>)
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionCreateComponent);