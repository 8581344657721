import React,{useState,useEffect} from 'react'
import { Card } from "react-bootstrap";
import {connect} from "react-redux"
import DataTable from "react-data-table-component";
import { Link ,useHistory} from "react-router-dom";
import API from "../../../utils/API"
import Search from '../../../components/Search/Search';

// import {fetchArticles,fetchAuthors,fetchArticleCategories,} from "../../../services/BlogServices"

const Articles = (props) => {

  const [searchTerm, setSearchTerm] = useState("");
  const [clearSearch, setClearSearch] = useState(false)
  const [articles,setArticles] = useState([]);
  const [articleCategories,setArticleCategories] = useState([])
  const [authors,setAuthors] = useState([])


const BASE_URL_ARTICLE_CATEGORIES = "/health-hub-category";
const BASE_URL_ARTICLES = "/health-hub";
const BASE_URL_AUTHORS = "/authors";
const history=useHistory();

  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      width: "100px",
      sortable: true,
      sortField: 'id',
     

    },
    {
      name: "Name",
      selector: "articleNameEnglish",
      sortable: true,
      wrap:true,

    },
    {
      name: "Category",
      selector: "healthHubCategoryName",
      sortable: true,
      sortField: 'categoryId',
      width:"200px",
      wrap:true,
      cell: (article) => {
        const matchingCategory = articleCategories.find((data) => article.healthHubCategory === data.id);
        return matchingCategory ? matchingCategory.categoryNameEnglish : 'N/A';
      }
    },
    {
      name: "Author",
      selector: "authorName",
      // sortable: true,
      sortField: 'authorId',
      wrap:true,
      cell:(author)=>{
        const matchAuthor = authors.find((data)=>data.id === author.author);
        return matchAuthor ? matchAuthor.userName : 'N/A';
      }

    },
    {
      name: "Rank",
      selector: "rank",
      sortable: true,
      center: true,
      wrap:true,
      // "maxWidth":"10px"

    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      sortField: 'id',
      wrap:true,
      // "maxWidth":"50px"

    },
    {
      name: "View",
      selector: "view",
      // sortable: true,
      sortField: 'id',
      wrap:true,
      cell:(article) => renderViewButton(article.id),
      width:'300px',
      // "maxWidth":"10px"

    },
  ]
  const  renderViewButton = (id) =>(
    <div>
        <button
          className="btn px-5"
          style={{ backgroundColor: "#247A81",color:"#FFF" }}
          onClick={() => {
            handleView(id);
          }}
        >
          View
        </button>
      </div>
   )
   const handleView = (id)=>{
    if(id){
      history.push(`/dashboard/article/${id}`)
   }
  }

   useEffect(()=>{
    Promise.all([
    fetchAuthors(),
    fetchArticleCategories(),
    fetchArticles()
     
    ]);
   },[])

  const fetchAuthors = async () => {
    try {
      // const authors = (await fetchAuthors()) ?? [];
      const authors = await API.get(BASE_URL_AUTHORS)
     
      setAuthors(authors.data)
    } catch (error) {
      showErrorNotification("Error on fetching authors!", error.message);
    }
  };
 console.log("authors",authors)
 
  const fetchArticleCategories = async () => {
    try {
      showLoading();
      const data =await API.get(BASE_URL_ARTICLE_CATEGORIES)
      const articleCategories = data.data
      articleCategories.forEach((it) => {
        delete it.healthHub;
        return it;
      });
      
      setArticleCategories(articleCategories)
    } catch (error) {
      showErrorNotification(
        "Error on loading article categories: ",
        error.message
      );
    
    } finally {
      hideLoading();
    }
  };
 

  const fetchArticles = async() =>{
    try{
      showLoading();
      const data = await API.get(BASE_URL_ARTICLES)
      const articles = data.data
    
      articles.forEach((article) => {
        const category = findArticleCategory(article.healthHubCategory);
        article.healthHubCategoryName = category
          ? category.categoryNameEnglish
          : "";
        const author = findAuthor(article.author);
        article.authorName = author
          ? `${author.first_name ?? ""} ${author.last_name ?? ""}`.trim()
          : "";
      });
     
      setArticles(articles)
    }
    catch (error) {
      showErrorNotification("Error on loading articles: ", error.message);
    
    } finally {
      hideLoading();
    }
    
   
  }


  const findArticleCategory = (categoryId) => {
    return articleCategories.find((it) => it.id === categoryId);
  };

  const  findAuthor = (id) => {
    return authors.find((it) => it.id === id);
  };



 



  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });

 
const showLoading = () => props.toggleLoading({ isLoading: true });

const hideLoading = () => props.toggleLoading({ isLoading: false });
const handleSearch = (val)=>{
  if (val) {
    setSearchTerm(val);
    setClearSearch(false);
  } else {
    setSearchTerm("");
    setClearSearch(true);
    fetchArticles();
  }
}
   const onSearchBtn = ()=>{
    const getFilteredArticles = filteredArticles(articles,searchTerm)
    setArticles(getFilteredArticles)
   }

   const filteredArticles = (articles,searchTerm) =>{
    if (!searchTerm) return articles;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return articles.filter((article) => {
      const { id, articleNameEnglish, rank } = article;
      return (
        (articleNameEnglish &&
          articleNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
   
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
   }
   const handleKeyPress = (event)=>{
    if (event.key === 'Enter') {
      onSearchBtn();
    }
   }

  return (
    <div>
       <div className="rightextreme">
       <div className="action-container">
       <div>
              <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={(val)=>handleSearch(val)}
                  clearSearch={()=>{setSearchTerm("");setClearSearch(true);  fetchArticles()}}
                  handleSearchExit={()=>{}}
                  onKeyPress={handleKeyPress}
                
                ></Search>
            </div>
            <div className="search-btn" onClick={onSearchBtn}>
              Search
            </div>
        <div>
          <Link to={"/dashboard/article/create"}> <button className="greenbackground" >Create Article</button></Link>
        </div>
        </div>
      </div>
   
      <br /><br />
      <Card body>
        <DataTable
          highlightOnHover
          columns={

            columns

          }
          data={articles}

          // onRowClicked={handlerowselect}
          
          
          // clearSelectedRows={this.state.toggledClearRows}
          pagination
          responsive
          title={
            searchTerm ? (
              <p className="table-header">
                {"Found " + articles.length + " results"}
              </p>
            ) : (
              ""
            )
          }



        />

      </Card>
    </div>
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles)