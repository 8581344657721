import React,{useState,useEffect} from 'react'
import { Card,Form ,Tab,Tabs} from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Divider, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BodyTextEditorCreate } from '../../../components/RichEditorCreateComp';
import API from "../../../utils/API"
import { Link } from "react-router-dom";
import { useParams,useHistory} from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner"


const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    label: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    label: "Inactive",
  },
]

const TREND_OPTIONS = [
  {
    key: "YES",
    value: true,
    label: "YES",
  },
  {
    key: "NO",
    value: false,
    label: "NO",
  },
]
const CreateArticle = (props) => {
console.log("props",props)
  const [isLoading , setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [articles,setArticles] = useState([])
  const [articleCategories,setArticleCategories] = useState([])
  const [authors,setAuthors] = useState([])
  const [products,setProducts] = useState([])
  const [article,setArticle] = useState({})
  const [errors, setErrors] = useState({})
  const [rankOptions, setRankOptions] = useState([])
  const [articleContent, setArticleContent] = useState('');
  const [view,setView] = useState('')
  const [selectedAuthor, setSelectedAuthor] = useState({})
  const [selectedCatgeory,setSelectedCategory] = useState({})
  const [selectedProduct , setSelectedProduct] = useState([])
  const params = useParams();
  const articleId = params.id 
  const BASE_URL_ARTICLES = "/health-hub";
  const BASE_URL_ARTICLE_CATEGORIES = "/health-hub-category";
  const BASE_URL_AUTHORS = "/authors";
  const BASE_URL_PRODUCTS = "module/search/products/frequently-bought-products?key="
  const history = useHistory();
  const handleImageUpload = (e) => {
    const image = e.target.files[0];
    const articledummy = {...article,image}
    setArticle(articledummy)
    if (image) {
      setImageFile(image)
    
    }
    readImage(image)
  };
  const readImage = (image)=>{
    const reader = new FileReader();
    reader.onloadend = function () {
      const articlenew= {
        ...article,
        image: [reader.result],
      };
    
    setArticle(articlenew)}.bind(this)
    reader.readAsDataURL(image);
  }
  useEffect(() => {
    const fetchData = async () => {
        try {
        
            await Promise.all([
                getArticles(),
                fetchArticleCategories(),
                fetchAuthors(),
                fetchProducts()
            ]);
          
        } catch (error) {
            showErrorNotification("Error on fetching data: ", error.message);
        } finally {
          
        }
    };

    fetchData();
}, []);



const fetchAuthors = async () => {
  try {
    // const authors = (await fetchAuthors()) ?? [];
    const authors = await API.get(BASE_URL_AUTHORS)
    setAuthors(authors.data)
  } catch (error) {
    showErrorNotification("Error on fetching authors!", error.message);
  }
};
const getArticles =async()=>{
  try{
  const res = await API.get(BASE_URL_ARTICLES)
  const data = res.data;
  setArticles(data)
  // createRankOptions(data,view)
  console.log("response",res)
  }
  catch{
    console.log("fetching the error in articles")
  }
}

const fetchArticleCategories = async () => {
  try {
    showLoading();
    const res =await API.get(BASE_URL_ARTICLE_CATEGORIES)
    const articleCategories = res.data
    articleCategories.map((it) => {
      delete it.healthHub;
      return it;
    });
    setArticleCategories(articleCategories)
  } catch (error) {
    showErrorNotification(
      "Error on loading article categories: ",
      error.message
    );
  
  } finally {
    hideLoading();
  }
};
const fetchProducts = async () => {
  try {
   showLoading()
    const res =await API.get(BASE_URL_PRODUCTS)
    if(res.status==200){
      hideLoading()
    }
    const products = res.data
  
    setProducts(products)
  } catch (error) {
    showErrorNotification(
      "Error on loading article categories: ",
      error.message
    );
  
  } finally {
   hideLoading()
  }
};
const viewArticle = async()=>{
  showLoading();
  try{
    const data =await API.get(`health-hub/by/${articleId}`)
    
    const res = data.data;
   
    const authorNames = authors.filter(data => res.author === data?.id)

    const categoryName = articleCategories.filter(data=> res.healthHubCategory === data.id)
   
    const productsName = products.filter(product => res.suggestedSupplements.includes(product.id))

    const newobj = {
      ...res , "image" : res["primaryImage"], 
    }

    delete newobj["primaryImage"]
   

    setArticle(newobj)
    setArticleContent(newobj.articleContentEnglish)
    setSelectedAuthor({label:authorNames[0].userName,value:authorNames[0].userName});
    setSelectedCategory({label:categoryName[0].categoryNameEnglish,value:categoryName[0].categoryNameEnglish})
    setSelectedProduct(productsName.map((data) => ({ label: data.name, value: data.name })));
 
  }
 
  catch{

  }
  finally{
    hideLoading();
  }
}

useEffect(()=>{
if(articleId){
  setView(true)
  viewArticle();
 
}
},[authors,articleCategories,products,articleId])

const createRankOptions = (view) => {
  const ranks =  articles
    .filter((it) => it.rank)
    .map((it) => it.rank);
  
  const minRank = Math.min(1, ...ranks) || 0;
  const maxRank = Math.max(...ranks) || 0;

  const until = view ? maxRank : maxRank + 1;
  const rankOptions = [];
  for (let i = minRank; i <= until; i++)
    rankOptions.push({ key: i, value: i, label: i });

  if (rankOptions.length === 0)
    rankOptions.push({ key: 1, value: 1, label: 1 });
  console.log("Rank Options: ", rankOptions);
  
  setRankOptions(rankOptions)
}

useEffect(()=>{
  createRankOptions(view)
  },[articles])
  

 const handleChange = (e) => {
      const { name, value } = e.target;
      const articleName = article;
      articleName[name] = value;
      setArticle(
        (prevArticle) => ({
        ...prevArticle,

        [name]: value,
       
      })
      
      );
  } 


const validate = (article)=>{
  const errors = {};
  const {articleNameEnglish,articleDescriptionEnglish,rank,status,seoUrl,metaTitle,metaDescription,keywords,image,author,healthHubCategory,isTrending} = article||{}
  if (!articleNameEnglish || articleNameEnglish.trim() === "")
  errors.name = "Name is a required field!";
if(!articleDescriptionEnglish){
  errors.description="Description is a required field!"
}

if (rank==undefined || rank==="")
  errors.rank = "Rank is a required field!";
  
  
if (status==undefined || status==="")
  errors.status = "Status is a required field!";
  

if (!image) errors.image = "Image cannot be blank!";
// else if (image && image.size && image.size > TWO_MEGA_BYTE)
//   errors.image = "Image should be less than 2 MB!";
if(!author) errors.author = "Author is a required field!"
if(!healthHubCategory) errors.healthHubCategory = "Category is a required field!"
if(isTrending==undefined || isTrending=== null) errors.isTrending = "Trend is a required field!"
if (!seoUrl || seoUrl.trim() === "")
  errors.seoUrl = "Seo URL is a required field!";
else if (seoUrl.length > 100)
  errors.seoUrl = "Seo URL can not contain more than 100 characters!";

if (metaTitle && metaTitle.length > 100)
  errors.metaTitle = "Title can not contain more than 100 characters!";

if (metaDescription && metaDescription.length > 200)
  errors.metaDescription =
    "Description can not contain more than 200 characters!";

if (keywords && keywords.length > 500)
  errors.keywords = "keywords can not contain more than 500 characters!";
   setErrors(errors);
return Object.keys(errors).length === 0 ? null : errors;

}
const handleSave = async () => {
  const errors = validate(article);

  if (errors) {
    showErrorNotification("There are some invalid form fields!");
    return;
  }
  const payload = { ...article};
  delete payload["imageSrc"];

  let formData = new FormData();
  const apiEndpoint = view ? `health-hub/${articleId}` : "health-hub";
  const method = view ? "PATCH" : "POST";
  if (typeof article.image !== "string") {
    delete payload["image"];
    formData.append("image", imageFile);
  }

  formData.append("health_hub", JSON.stringify(payload));

  try {
    const response = await (method === "PATCH" ? API.patch : API.post)(apiEndpoint, formData, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        // "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    if (response.status==200)
    { method==="PATCH" ? showSuccessNotification("Article is update successfully") : showSuccessNotification("Article is created successfully")
      props.history.goBack();
      const responseData = await response.json();
    
     
      console.log("Response Data:", responseData);
    }  else 
    {
      console.error("Request failed with status:", response.status);
    }
  } 
  catch (error) {
    console.error("Error:", error);
  }
};
  

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });
 

  const showLoading = () => props.toggleLoading({ isLoading: true });

const hideLoading = () => props.toggleLoading({ isLoading: false });

  const detailsForm = (
    <div className='container' style={{display:'flex'}}> 
    <div className='container mt-4' style={{ minWidth: '50%' }} >
        <div className='row'>
        <div className='form-group col-12'>
        <label for="name" >Name (English) *</label>
        <div className='input-group' style={{width:"100%"}}>
        <input
        style={{width:"80%"}}
        value={article.articleNameEnglish}
        onChange={handleChange}
        id="articleNameEnglish"
        name="articleNameEnglish"
        type="text"
        placeholder="Name in English"
        />
          
        </div>
        {errors.name && <span className="help-block" style={{display:"block"}}>{errors.name}</span>}
        </div>
        </div>
        <div className='row mt-2'>
        <div className='form-group col-'>
        <label htmlFor="articleDescriptionEnglish">Description *</label>
        <div className='input-group' style={{width:"100%"}}>
        <textarea 
        style={{width:"80%"}}
        className='col-6 '
        value={article.articleDescriptionEnglish}
        onChange={handleChange}
        id="articleDescriptionEnglish"
        name="articleDescriptionEnglish"
        placeholder="Description in English"

        />
         
        </div>
        {errors.description && <span className="help-block" style={{display:"block"}}>{errors.description}</span>} 
        </div>
        </div>

        <div className='row mt-2'>
        <div className='form-group col-5'>
        <label htmlFor='rank' >Rank *</label>
        <div className='' >
       <Select
      
        value={rankOptions.filter((data)=>article.rank==data.value)[0]}
        onChange={(selectoption)=>{
      
       
          setArticle(
            (prevArticle) => ({
            ...prevArticle,
            rank:selectoption.value
           
          })
          )

        }}
       
        id="rank"
        placeholder="Rank"
        options={rankOptions}/>
        
        </div>
        {errors.rank && <span className="help-block" style={{display:"block"}}>{errors.rank}</span>} 
        </div>
        <div className='form-group col-5'>
                <label htmlFor="status">Status *</label>
                <div className=''  style={{width:"100%"}}>
                  <Select
                    value={STATUS_OPTIONS.filter((data)=>article.status==data.value)[0]}
                    onChange={(selectoption)=>{
                      const dummyObj=article;
                      
                   
                      setArticle(
                        (prevArticle) => ({
                        ...prevArticle,
                        status:selectoption.value
                       
                      })
                      )

                    }}
                    id="status"
                    name="status"
                    placeholder="Status"
                    options={STATUS_OPTIONS}
                  />
                  
                </div>
                {errors.status && <span className="help-block" style={{display:"block"}}>{errors.status}</span>} 
              </div>
        </div>

        <div className='row mt-2'>

        <div className='form-group col-5'>
        <label htmlFor='trending' >Trending *</label>
        <div className=''>
      <Select
      value = {TREND_OPTIONS.filter((data)=>article.isTrending == data.value)[0]}
      onChange = {(selectoption)=>{
        setArticle(
          (prevArticle)=>({
            ...prevArticle,
            isTrending:selectoption.value
          })
        )
      }}
      id="trending"
      name="trending"
      placeholder='trending'
      options={TREND_OPTIONS}
      />
      {errors.isTrending && <span className="help-block" style={{display:"block"}}>{errors.isTrending}</span>} 
        
        </div>
        </div>

        <div className='form-group col-5'>
        <label htmlFor='category'>Category *</label>
        <div className=''>
       <Select
       value={(view) ? selectedCatgeory : articleCategories.categoryNameEnglish}
       onChange = {(selectoption)=>{
      
        setArticle(
          (prevArticle)=>({
            ...prevArticle,
            healthHubCategory:selectoption?.id
          })
        )
        setSelectedCategory(selectoption)
      }}
       id="category"
       name='category'
       placeholder='category'
       options={articleCategories.map((article)=>{return({label:article.categoryNameEnglish,value:article.categoryNameEnglish,id:article.id,key:article.id})})}
       />
       {errors.healthHubCategory && <span className="help-block" style={{display:"block"}}>{errors.healthHubCategory}</span>} 
        </div>
        </div>
        </div>


        <div className='row mt-2'>
        <div className='form-group col-5'>
        <label htmlFor='author'>Author *</label>
        <div className=''>
        <Select
       value={(view)?selectedAuthor:authors.userName}
       onChange = {(selectoption)=>{
        setArticle(
          (prevArticle)=>({
            ...prevArticle,
            author:selectoption?.id
          })
        )
        setSelectedAuthor(selectoption)
      }}
       id="author"
       name='author'
       placeholder='author'
       options={authors.map((author)=>{return({label:author.userName,value:author.userName,id:author.id})})}
       />
         
        </div>
        {errors.author && <span className="help-block" style={{display:"block"}}>{errors.author}</span>}
        </div>
        </div>
        </div>


        <div className='container mt-4'style={{maxWidth:"50%"}} >
      <div> <b>Image*</b></div><br />
      <div className="imagecountryblock" style={{width:"70%"}}>
       <div style={{backgroundColor:"#247A81",color:"#FFFFFF",fontSize: "12px"}}>
        <label style={{cursor:"pointer"}}> {article.image ? <img src="https://d25uasl7utydze.cloudfront.net/assets/edit.svg"/> : "+Image" }
        <input style={{display:"none"}}
          id="imageInput"
          name="image"
          type="file"
          className="variantbutton"
          accept="image/*"
          onChange={handleImageUpload} ></input>
          </label>
       </div>
       {article.image &&  (
                    <img
                     src={article.image}
                      alt="Selected Image"
                      style={{ width: '38%', height: 'auto' }}
                    />
                  )}
      </div>
      {errors.image && <span className="help-block" style={{display:"block"}}>{errors.image}</span>}

      <div className='row mt-2' >
        <div className='form-group col'  >
        <label htmlFor='product'>Related Products </label>
        <div className=' product-div col-md-6' style={{width:"70%"}} >
        <Select
        isMulti
       value={view ? selectedProduct : products.name}
     
       onChange = {(selectoption)=>{
        setArticle(
          (prevArticle)=>({
            ...prevArticle,
            suggestedSupplements: selectoption.map((option) => option.id)
          })
        )
        setSelectedProduct(selectoption)
      }}
       id="product"
       name='product'
       placeholder='product'
       options={products.map((product)=>{return({label:product.name,value:product.name,id:product.id})})}
       />
        
        </div>
        </div>
        </div>
        </div>
        </div>
  )
 
  const articleContentForm = (
    <>
       <div className="row">
          <div className="col">
            <div className="input-group mt-4 ml-2">
              <BodyTextEditorCreate
              style={{}}
              value={(articleContent)}
          
              setValue={(articleContentEnglish) => {
               setArticleContent(articleContentEnglish);
                setArticle((prevArticle) => ({
                  ...prevArticle,
                  articleContentEnglish,
                }));
              }}
              
              />
          
            </div>
          </div>
        </div>
    </>
  );

  const seoMarketingForm = (
    <div className="container mt-4">
      <div className="row">
        <div className="form-group col">
        <label htmlFor="seoUrl">Seo URL*</label>
        <div className="input-group">
          <input
          style={{width:"70%"}}
           value={article.seoUrl ?? ""}
           onChange={handleChange}
           id="seoUrl"
           name="seoUrl"
           type="text"
           placeholder="Seo URL"
          /> 
          
        </div>
        {errors.seoUrl && <span className="help-block" style={{display:"block"}}>{errors.seoUrl}</span>}
        </div>
        <div className="form-group col" >
        <label htmlFor="metaTitle">Meta Title</label>
        <div className="input-group">
          <input
            style={{width:"70%"}}
          value={article.metaTitle ?? ""}
          onChange={handleChange}
          id="metaTitle"
          name="metaTitle"
          type="text"
          placeholder="Meta Title"
          />
        </div>
        </div>
      </div>
      <div className="row" style={{marginTop:"20px"}}>
      <div className="form-group col">
        <label htmlFor="keywords">Keywords</label>
        <div className="input-group">
          <textarea
            style={{width:"70%",height:"100px"}}
         value={article.keywords ?? ""}
         onChange={handleChange}
         id="keywords"
         name="keywords"
         placeholder="Keywords"
          />
        </div>
        </div>
        <div className="form-group col">
        <label htmlFor="metaDescription">Meta Description</label>
        <div className="input-group">
          <input
            style={{width:"70%"}}
          value={article.metaDescription ?? ""}
          onChange={handleChange}
          id="metaDescription"
          name="metaDescription"
          type="text"
          placeholder="Meta Description"
          />
        </div>
        </div>
        
      </div>
    </div>
 
  )
  
  const formSearchEngine = (
    <div className="container mt-4">
        <div className="row" style={{marginRight:"",marginLeft:"50px"}}>
        <div className="form-group col">
        <label htmlFor="searchTags">Tags</label>
        <div className="input-group">
          <textarea
          style={{width:"90%"}}
           value={(article && article.searchTags) || ""}
           onChange={handleChange}
           id="searchTags"
           name="searchTags"
           placeholder="Tags"
          />
        </div>
        </div>
        </div>
    </div>
  )

  return (
    <>
    <div style={{ alignSelf: "flex-start",display:'flex',gap:'10px', }}>
            <Button
              onClick={() => props.history.goBack()}
              variant="outlined"
              color="inherit"
              size="large"
            >
              <ArrowBackIcon />
            </Button>
            {view ?<h5>Article</h5> : <h5>Create Article</h5>}
            <div className="col text-end">
            <Button
              
              variant="contained"
              size="large" 
              onClick={handleSave}
              color="success"
              sx={{
                bgcolor: "#247A81",
                color: "white",
                borderRadius: "20px",
                ":hover": {
                  bgcolor: "#125443",
               
                },
                textTransform: "capitalize",
              }}
            >
              {view ? "Update" : "Save"}
            </Button>
            </div>
        </div>
        <br/><br/>
        <div >
        <Card body>
          
         
        <Tabs id="tabs_article_categories" justify style={{backgroundColor:"#F1FAF8"}}>
        
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab eventKey="key_content_form" title="Content (English)">
                {articleContentForm}
              </Tab>
              <Tab eventKey="key_seo_marketing_form" title="SEO Marketing">
                {seoMarketingForm}
              </Tab>
              <Tab
                eventKey="key_search_engine"
                title="Search Engine"
              >
                {formSearchEngine}
              </Tab>
             
            </Tabs>
            </Card>
        </div>
     <Spinner isShow={isLoading} />
        </>
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateArticle);