import React, { useEffect, useState } from "react";
import Select from "react-select";
import API from "../../utils/API";
import { titleCase } from "../../utils/common";

const BASE_URL_LANGUAGES = "/api/v1/store/languages";
const SELECT_STYLES = {
  container: (base) => ({ ...base, flex: 1 }),
};

function SelectLanguage({ value, onChange = (f) => f }) {
  // const languages = [
  //   { id: 1, code: "en", name: "English", new: false },
  //   { id: 2, code: "ar", name: "Arabic", new: false },
  // ];
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const { data: languages = [] } = await API.get(BASE_URL_LANGUAGES);
        setLanguages(languages);
      } catch (error) {
        console.log("fetchLanguages:: Error on fetching languages!", error);
      }
    };
    fetchLanguages();
  }, []);

  const findLanguageByCode = (code) =>
    languages.find((language) => language.code === code);

  const languageOptions = languages.map(createOption);
  const selectedLanguage = createOption(findLanguageByCode(value));

  return (
    <Select
      key={`select-language-${value ?? ""}`}
      value={selectedLanguage}
      onChange={onChange}
      options={languageOptions}
      styles={SELECT_STYLES}
    />
  );
}

function createOption(language) {
  if (!language) return null;

  const { code, name } = language;
  return { key: code, value: code, label: titleCase(name) };
}

export default SelectLanguage;
