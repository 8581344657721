import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";
import { connect } from "react-redux";

 function EditReview(props) {

    const { name, heading, review, id, prodid,reviewStatus,reviewrating,editflag } = props;
    const [custName, setCustName] = useState();
    const [headline, setHeadline] = useState();
    const [reviewbody, setReviewbody] = useState();
    const [reviewid, setReviewid] = useState();
    const [reviewEdit, setReviewEdit] = useState({});
    const[rating,setRating]=useState();
    const[reviewStatusCheck,setReviewStatus]=useState(false);
    const[validationError,setValidationError]=useState({});
    const ratingValues=[1,2,3,4,5];
    
    const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
      return emptyval;
      }

    const validateAll=(headline,customer,review,rating)=>{
        const validationError={};

        if(isEmpty(headline)){
            validationError.headline="HeadLine is required field";

        }
        if(isEmpty(customer)){
            validationError.name="CustomerName is required field";

        }
        if(isEmpty(review)){
            validationError.body="review is required field";

        }
        if(isEmpty(rating)){
              validationError.rating="rating is required field";
        }
        return validationError;
    }

    const handleRating=(e)=>{
        setRating(e.target.value);
    }
    const onhandleEdit = (e) => {
       const error= validateAll(headline,custName,reviewbody) ;
       setValidationError(error);
       if(Object.keys(error).length==0)
       {

        setReviewEdit(
            {
                "customerName": custName,
                "headLine": headline,
                "reviewBody": reviewbody,
                "isActive": reviewStatusCheck,
                "rating":rating,
                "modifiedAt": "1997-09-09T00:00:00",
                "productId": prodid
            });
        setReviewid(e.target.id);
       }
    }
    useEffect(() => {
        if(Object.keys(reviewEdit).length>0){
        try {
            const url = `/module/product/review/${id}`;
            API.patch(url, reviewEdit).then((res) => showSuccessNotification("Edit Review Success"));
        }
        catch (error) {
            console.log("error is", error);
            showErrorNotification("Error in Edit Review");
        }
    }
}, [reviewEdit])


    useEffect(() => {
        setCustName(name);
        setHeadline(heading);
        setReviewbody(review);
        setReviewStatus(reviewStatus);
        setRating(reviewrating);
    }, [props])
    const handlecustomer = (e) => {
        setCustName(e.target.value)
    }
    const handleheadline = (e) => {
        setHeadline(e.target.value);
    }
    const handlereview = (e) => {
        setReviewbody(e.target.value)
    }
    const handleStatus=(e)=>{
        if(e.target.checked){
            setReviewStatus(true);
        }
        else{
            setReviewStatus(false);
        }
    }
    const handleDeleteReview=(id)=>{
        if(id !== "undefined" && id !==""){
   try{
   API.patch( `/module/product/review/${id}`, {
    "customerName": custName,
    "headLine": headline,
    "reviewBody": reviewbody,
    "rating":rating,
    "isActive": false,
    "modifiedAt": "1997-09-09T00:00:00",
    "productId": prodid
}).then((res)=>console.log(res));
editflag(true);
}
catch (error) {
    console.log("error is", error);
}
        }

    }

    const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);
  
  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);
  
  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

    return (
        <div>
            <div className="alignrow">
                <div className="columnalignactive">
<div className="group"><label for="name">Customer Name</label><input type="text"  value={custName} onChange={handlecustomer} /></div>
<span className="help-block">{validationError.name}</span>
</div>
<div className="columnalignactive">
<div className="group"><label for="name">HeadLine</label>               <input type="text" size="50" value={headline} onChange={handleheadline} /></div>
<span className="help-block">{validationError.headline}</span> 
   </div>     
   <div className="columnalignactive">Rating<select onChange={handleRating} value={rating}>
                {ratingValues.map((val)=><option value={val}>{val}</option>)}
                </select>
                <span className="help-block">{validationError.rating}</span>
                </div> 

                <div className="columnalignactive"> <div>Active</div> <div> <input type="checkbox" onChange={handleStatus} checked={reviewStatusCheck}/></div>
                
                </div>
                 <DeleteIcon onClick={ ()=>handleDeleteReview(id)}/> 
            </div>
            <br />
            <div className="columnalignactive">
            <div className="group">
            <label for="name">Review </label><textarea style={{ width: "85%" }} rows="10" placeholder="Review body" value={reviewbody} onChange={handlereview}></textarea>
            </div>
            <span className="help-block">{validationError.body}</span>
            </div>
          <div> <input className="donebtn" type="button" value="Edit" id={id} onClick={onhandleEdit} /></div> 
            <br/>
        </div>

    )
}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditReview);