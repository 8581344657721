import React,{useState,useEffect} from 'react'
import './importPopUp.scss';
import { connect } from "react-redux";
import API from "../../utils/API";
import * as XLSX from 'xlsx';

import ImportErrorPopUp from './ImportErrorPopUp';

const ImportPopUp = (props) => {
 const  setIsShowImportPopUp = props.setIsShowImportPopUp;
 const  activeCountries = props.countries || []
 console.log("activeCountries",activeCountries)
 const [uploadedFile, setUploadedFile] = useState(null);
 const [fileError, setFileError] = useState('');
 const [isShowImportErrorPopUp, setIsShowImportErrorPopUp] = useState(false);
 const [errorList ,setErrorList] = useState({})
 const [selectData,setSelectData] = useState()

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
  
    if (!file || (file.type !== 'application/vnd.ms-excel' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      setFileError('Invalid file format. Please upload a .xls or .xlsx file.');
      setUploadedFile(null);
      return;
    }
    setUploadedFile(file);
    setFileError('');
    console.log('File uploaded:', file);
  };
  const handleSampleDownloadForCountry = () => {
    // Define headers
    const headers = [
      ['SKU *', 'List Price *', 'Final Price *','Status *', 'Meta Title', 'Meta Title (Arabic)', 'Meta Description', 'Meta Description (Arabic)', 'SEO Keywords','SEO Keywords (Arabic)']
    ];
  
    // Add dummy data rows below headers
    const dummyData = [
      ['12345', 100, 90, 'active','Sample Product', 'منتج عينة', 'This is a sample product.', 'هذا منتج عينة.', 'sample, product', 'sapmle keywords', ],
      ['67890', 200, 180, 'inactive','Another Product', 'منتج آخر', 'This is another product.', 'هذا منتج آخر.', 'another, product','another keywords',]
    ];
  
    // Combine headers and data
    const data = [...headers, ...dummyData];
  
    // Create a worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SampleCountryDetails');
  
    // Create a binary Excel file
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  
    // Convert binary to Blob
    const blob = new Blob([s2ab(excelFile)], { type: 'application/octet-stream' });
  
    // Trigger download of the file
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'SampleCountryDetails.xlsx';
    link.click();
  };

  const handleSampleDownloadForProductDetails = () => {
    // Define headers
    const headers = [
      ['SKU *',`Title*`, 'SEO URL *', 'Status *','Title Arabic', 'Description', 'Description Arabic', 'Short Description', 'Short Description Arabic', 'Ingredients & Dosage', 'Ingredients & Dosage Arabic', 'Benefits', 'Benefits Arabic', 'Brand', 'Health goal 1', 'Health goal 2', 'Barcode', `Image Link 1`, `Image Link 2`, `Image Link 3`, `Image Link 4`, `Image Link 5`]
    ];
  
    // Add dummy data rows below headers
    const dummyData = [
      ['12345', 'Sample Product','product-sample', 'Active','منتج عينة', 'This is a sample product description.', 'هذا وصف منتج عينة.', 'Short Description', 'وصف قصير', 'Sample Ingredients & Dosage', 'عينة المكونات والجرعة', 'Sample Benefits', 'فوائد العينة', 'Brand A', 'Goal 1', 'Goal 2','123456789012', 'https://linktoimage1.com', 'https://linktoimage2.com', 'https://linktoimage3.com', 'https://linktoimage4.com', 'https://linktoimage5.com'],
      ['67890', 'Another Product','another-product', 'Active', 'منتج آخر', 'This is another product description.', 'هذا وصف منتج آخر.', 'Another Short Description', 'وصف قصير آخر', 'Ingredients & Dosage for Another Product', 'المكونات والجرعة لمنتج آخر', 'Another Product Benefits', 'فوائد منتج آخر', 'Brand B', 'Goal 1', 'Goal 2', '098765432109', 'https://linktoanotherimage1.com', 'https://linktoanotherimage2.com', 'https://linktoanotherimage3.com', 'https://linktoanotherimage4.com', 'https://linktoanotherimage5.com']
    ];
  
    // Combine headers and data
    const data = [...headers, ...dummyData];
  
    // Create a worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SampleProductDetails');
  
    // Create a binary Excel file
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  
    // Convert binary to Blob
    const blob = new Blob([s2ab(excelFile)], { type: 'application/octet-stream' });
  
    // Trigger download of the file
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'SampleProductDetails.xlsx';
    link.click();
  };
  
  // Helper function to convert string to array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  
  // Helper function to convert string to array buffer
  // const s2ab = (s) => {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  //   return buf;
  // };
  
const handleImport = async () => {
  try {
    if(!selectData){
      setFileError("please Select a Option");
      return;
    }
    if (!uploadedFile) {
      setFileError("please Select a file");
      return;
    }
   
    showLoading();
    const formData = new FormData();
    formData.append('file', uploadedFile);
    const timeoutConfig = { timeout: 600000 };
    const response = await API.post(`api/v2/import?type=${selectData}`, formData,timeoutConfig);
    debugger
    console.log("importData", response,response.data)
    if(response.status == 200 && response.data.length == 0){
      setIsShowImportPopUp(false)
      hideLoading();
      showSuccessNotification(" Product Imported SuccessFully")
      window.location.reload();
    }
    else{
    hideLoading();
    setIsShowImportErrorPopUp(true)
    setErrorList(response.data)
    }
  
  } catch (error) {
    console.log("importError",error)
    hideLoading();
    setIsShowImportErrorPopUp(true)
    setErrorList(error.response.data)

    // setIsShowImportPopUp(false)
  
    
  }
};

const handleSelect = (data) => {
  setSelectData(data)
}

console.log("selectData",selectData)

const showLoading = () => props.toggleLoading({ isLoading: true });

const hideLoading = () => props.toggleLoading({ isLoading: false });

const showSuccessNotification = (notificationMessage) =>
showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});

  return (
    <>
    <div>
    <div className='importPopUpContainer'>
      <div className='PopUp'>
        <div className='PopUpBody'>
        <div className='selectContainer'>
          <div className='productDetailssection'>
            <div style={{display:"flex",justifyContent:"space-between",}}>
            <div className='productTxt'>Product <span onClick={handleSampleDownloadForProductDetails}>Sample Download</span> </div> 
            <div className='closeBtn' onClick={()=>setIsShowImportPopUp(false)}>Close<img src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon%20(2).svg"/></div>
            </div>
            <div className='productDetailsSelectDiv'>
              <input type='checkbox' name='product' checked={selectData=="product"} onChange={()=>handleSelect('product')}/>Product Details
            </div>
          </div>
          <div className='selectCountySection'>
            <div className='selectCountyTxt'>Select Country <span onClick={handleSampleDownloadForCountry}>Sample Download</span></div>
            <div className='countyListDiv'>
            {activeCountries.map((data,index)=>{
              return(
                <div className='selectCountyInputDiv'>
                  <input type='checkbox' checked={selectData == data.id} onChange={()=> handleSelect(data.id)} />
                  {data.shortName}
                </div>
              )
            })}
            </div>
          </div>
        </div>

         <div className='importHeading'> Import Products by CSV</div>

         <div className='footer-div'>
        <div className='add-btn'>
        <input type="file" onChange={handleFileUpload} accept=".xlsx, .xls" className="importInput" id="fileInput" />
          <label htmlFor="fileInput" className="add-file-btn"> {uploadedFile ? 'Change' : `${"+ Add File"}`}</label>
          {uploadedFile && (
            <div className="uploaded-file-info">
              Uploaded File: {uploadedFile.name}
            </div>
          )}
        </div>
        <div className='import-btn' onClick={handleImport}>Upload</div>
        </div>
        {fileError && <div className='file-error' style={{color:'red',fontSize:"12px"}}>{fileError}</div>}
      
        </div>
        {/* <div className='footer-div'>
          <div className='import-btn' onClick={handleImport}>Upload and Preview</div>
          <div className='cancel-btn' onClick={()=>setIsShowImportPopUp(false)}>Cancel</div>

        </div> */}
      </div>

      <div className='importErrorPopUp'>
      {isShowImportErrorPopUp && <ImportErrorPopUp 
    setIsShowImportErrorPopUp={setIsShowImportErrorPopUp}
    errorList = {errorList}
    /> }
    </div>
    </div>
          
  
    </div>
    </>
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportPopUp);