import React, { useState, useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from "@mui/material";
import  VariantPrice  from "../components/VariantPrice";
import API from "../../src/utils/API";
import { VariantPriceCreate } from "./VariantPriceCreate";
import  VariantPriceEditCreate  from "./VariantPriceEditCreate";
import { connect } from "react-redux";
 function VariantCountryView(props) {
    const { mode, prodid,details,variantPriceDetails,priceprod, priceprodFun, varPrice, varPriceFun,allVariantdet,VariantViewAddFun,variantViewAdd,seoDetails} = props;
    console.log("prodid", prodid);
    const [price, setPrice] = useState({});
    const [finalPrice, setFinalPrice] = useState([]);
    const [variantpricecount, setVariantpricecount] = useState([0]);
    const [countries, setCountries] = useState([]);
    const [priceCheckbox, setPriceCheckbox] = useState(false);
    const [products, setProducts] = useState([]);
    const [countryid, setCountryId] = useState();
    const [deletecountry, setDeletecountry] = useState();
    const [productvariant, setProductvariant] = useState([]);
    const [prodprice, setProdPrice] = useState();
    const [listPrice, setListPrice] = useState();
    const [discountPrice, setDiscountPrice] = useState();
    const [metaDescription, setMetadescription] = useState();
    const [variantsDet, setVariantsDet] = useState([]);
    const[pricesAll,setPricesAll]=useState([]);
    const [seoUrl, setSEOURL] = useState();
    const[pageTitle,setPageTitle]=useState();
    const[editCountry,setEditCountry]=useState();
    const[editCountryFlag,setEditCountryFlag]=useState(false);
    const[countrySeoDetails,setCountrySeoDetails]=useState([]);
    const[validationError,setValidationError]=useState({});

    useEffect(() => {
        try {
          API.get("/api/v1/country").then((res) => setCountries(res.data));
          console.log("props",props);
          console.log("details",details);
          console.log("seo details",seoDetails);
          console.log("variantpricedet",variantPriceDetails);
          setPricesAll(variantPriceDetails.filter((variant)=>variant.length>0).flat());
          setVariantsDet(variantPriceDetails.filter((variant)=>variant.length>0).flat().filter((price)=>details.country==price.pricing.countryId));
         // setCountrySeoDetails(seoDetails.filter(seo=>seo.country));   
        
        }
        catch (error) {
          console.log("error msg", error);
        }
    
    
      }, [])
      useEffect(()=>{
        if(editCountryFlag){
        priceprodFun([...priceprod,editCountry])  ;
        console.log("checking in edit prod patch",editCountry);
        setEditCountryFlag(false);
      }
    }
      ,[editCountry])
     
      
      

    const handlecountryname = (e) => {
        setCountryId(e.target.value);
        console.log(e.target.value);
        //setselectCountry(1);
        //setselectCountry(e.target.value);
    }
    const handleListPrice = (e) => {
        setListPrice(e.target.value);
    }
    const handleDiscountPrice = (e) => {
        setDiscountPrice(e.target.value);
    }
    const handleFinalPrice = (e) => {
        setFinalPrice(e.target.value);
    }
    const handleSeoURL = (e) => {
        setSEOURL(e.target.value);
    }

    const onhandletextarea = (e) => {
        setMetadescription(e.target.value);
    }
    const onhandleaddVariant = (e) => {
        VariantViewAddFun(variantViewAdd+1);
        console.log("variant view add",variantViewAdd);
        
        
           
        setVariantpricecount([...variantpricecount,variantpricecount.length+1]);
        
        
    }
    const handledelete = (e) => {
        console.log("handle delete")
        setDeletecountry(e.target.id);

    }
    const handlePrice = (e) => {
        setProdPrice(e.target.value);
    }
    const handlePriceStatus = (e) => {
        if(e.target.checked)
        setPriceCheckbox("Active");
        else 
        setPriceCheckbox("Inactive");

    }

    const handleDeleteCountry=(country,prod)=>{

    }
    const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
       else if(value=="undefined")
       emptyval=true;
      return emptyval;
      }

    const validateAllFields=(country,list,discount,final,pageTitle,countrySeo,metaDescription)=>{
        const validationError={};
        console.log("country,discount,final",country,discount,final);
         if(isEmpty(country))
         validationError.country="Country Field is required";
         if(isEmpty(list))
             validationError.list="Retail price is required";
             if(isEmpty(discount))
             validationError.discount="Discount is required";
             if(isEmpty(final))
             validationError.final="Final price  is required";
             if(isEmpty(pageTitle))
             validationError.pageTitle="pageTitle is required";
             if(isEmpty(countrySeo))
             validationError.Seo="Seo is required";
             if(isEmpty(metaDescription))
            validationError.Description="Description is required";
            
        return validationError;
    }
    const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);
  
  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);
  
  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

    const handleSave = (e) => {
        const valid= validateAllFields(countryid,listPrice,discountPrice,finalPrice,pageTitle,seoUrl,metaDescription);
           setValidationError(valid);
        if(Object.keys(valid).length==0) 
        {
        setEditCountry({"country":countryid,
            "seo":{
                "seoUrl": seoUrl,
                    "country": countryid,
                    "productId": prodid,
                    "metaTitle": pageTitle,
                    "metaDescription": metaDescription,
            },

            "isActive":priceCheckbox=="Active"?true:false,
             "retailPrice": parseInt(listPrice),
            "discount": parseInt(discountPrice),
            "finalPrice": parseInt(finalPrice),
            "productId": parseInt(prodid),

        })
     
      setEditCountryFlag(true);
      showSuccessNotification("locally updated prices");
    }
     }
     
     
    const handlePageTitle=(e)=>{
        setPageTitle(e.target.value);
    }

    useEffect(()=>{

      //  console.log("country id",details.country);
      setCountryId(details?.country);
      
      setListPrice(details?.productPriceAmount);
      setFinalPrice(details?.productPriceSpecialAmount);
      setDiscountPrice(details?.productPriceAmount-details?.productPriceSpecialAmount);
      const seocountry=   seoDetails.filter((seo)=>details?.country==seo?.country);
     console.log("seo filter",seocountry,details?.country);
     if(seocountry.length>0){
      setPageTitle(seocountry[0]?.metaTitle);
      setMetadescription(seocountry[0]?.metaDescription);
      setSEOURL(seocountry[0]?.seoUrl);
     }
      
    },[details])


    useEffect(()=>{
     

    },[countrySeoDetails])


    return (

        <div className="countryblock">
            <div className="alignrow">
                <div > <select onChange={handlecountryname} value={countryid} style={{height:"3em"}}> 
                    {countries.map((ctry) => <option value={ctry.id}>{ctry.name}</option>)}
                
                </select></div>
                <div className="columnalignactive">
                <div className="group"><label>List</label>
                    <input type="number" style={{ width: "7em" }} value={listPrice} onChange={handleListPrice} placeholder="List Price" />
                </div>
                <span className="help-block">{validationError.list}</span>
                </div>
                <div className="columnalignactive">
                <div className="group"> <label>Discount</label>
                    <input type="number" style={{ width: "7em" }} value={discountPrice} onChange={handleDiscountPrice} placeholder="Discount Price" />
                </div>
                <span className="help-block">{validationError.discount}</span>
                </div>
                <div className="columnalignactive">
                <div className="group"><label>Final</label>
                    <input type="number" style={{ width: "7em" }} value={finalPrice} onChange={handleFinalPrice} placeholder="Final Price" />
                </div>
                <span className="help-block">{validationError.final}</span>
                </div>
                <div className="columnalignactive"><div>Active</div>
                    <div><input type="checkbox"  value={priceCheckbox}   checked={priceCheckbox=="Active"} onChange={handlePriceStatus} /></div>
                </div>
                
            </div>
               <br/>
            <div><b>SEO at Country Level </b>
                <div className="alignrow">
                    <div className="columnalign">
                      <div className="columnalignactive">
                        <div ><input type="text" placeholder=" Page Title" value={pageTitle} onChange={handlePageTitle}/></div>
                        <span className="help-block">{validationError.pageTitle}</span></div>
                        <div className="columnalignactive">
                        <div ><input type="text" placeholder="URL Handle"  value={seoUrl}onChange={handleSeoURL} /></div>
                        <span className="help-block">{validationError.Seo}</span>
                    </div>

                      </div>
                      <div className="columnalignactive">
                    <div><textarea rows={10} value={metaDescription} placeholder="Meta Description"
                        cols={50} onChange={onhandletextarea} /></div>
                        <span className="help-block">{validationError.Description}</span>
                      </div>

                        <div><input className="donebtn" type="button" value="Edit" onClick={handleSave} /></div>
                       <div> <Button onClick={ ()=>handleDeleteCountry(countryid,prodid)}> <DeleteIcon  fontSize="small"    /> </Button> </div> 

                </div>

            </div>

            <div>
                <input className="newvaraintbutton" type="button" value="+ Variants" onClick={onhandleaddVariant} /><br/> <br/>
            </div>
            <div>
             { mode=="View" && variantpricecount.length>1 ? [
                variantsDet.map((variant,index) => <VariantPrice id={index} name={variant.name} productId={prodid} variantid={variant.varid} countryid={variant?.pricing?.countryId} variantdet={variant?.pricing} variantPrices={varPrice} variantpriceFun={varPriceFun} allVariants={allVariantdet} allVarPrices={pricesAll} />),variantpricecount.map((count)=>{ if(count>0) return(<VariantPriceEditCreate mode="EditCreate"  countryId={details?.country} id={count} productId={prodid} allVarPrices={pricesAll} />)})
              ]     :
             mode == "View" ? variantsDet.map((variant,index) => <VariantPrice id={index} name={variant.name} productId={prodid} variantid={variant.varid} countryid={variant?.pricing?.countryId} variantdet={variant?.pricing} variantPrices={varPrice} variantpriceFun={varPriceFun} allVariants={allVariantdet} allVarPrices={pricesAll} />)
                : 
              <></>} 
              </div>

        </div>
    )



}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(VariantCountryView);