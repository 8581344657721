import React, { useEffect, useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import API from "../../utils/API";

const BASE_URL_CUSTOMERS = "/api/v1/private/customers";

function SelectCustomer() {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const isLoading = isOpen && options.length === 0;

  useEffect(() => {
    let active = true;

    const fetchCustomers = async () => {
      try {
        const { data = {} } = await API.get(BASE_URL_CUSTOMERS);
        const { customers = [] } = data;
        if (active) {
          setOptions(customers);
        }
      } catch (error) {
        console.log("Error on fetching customers!", error);
      }
    };
    if (!isLoading) {
      return undefined;
    }
    fetchCustomers();

    return () => {
      active = false;
    };
  }, [isLoading]);

  useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);

  return (
    <Autocomplete
      id="select-customer"
      sx={{ width: 300 }}
      open={isOpen}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Asynchronous"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default SelectCustomer;
