import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import SelectLanguage from "../../components/select/SelectLanguage";
import PhoneInput from "react-phone-input-2";

function EditContactInfoViewModal(props) {
  const {
    title = "",
    contactInfo: customerContactInfo = {},
    onHide = (f) => f,
    onSave = (f) => f,
    isVisible = false,
  } = props;
 
  const [state, setState] = useState("")
  const [contactInfo, setContactInfo] = useState(customerContactInfo);
  const [errors, setErrors] = useState({});
  useEffect(

    () => setContactInfo(customerContactInfo),
    [customerContactInfo, isVisible]
  );

  const numberCheck = async (value, data, event, formattedValue) => {
    if (value.slice(data.dialCode.length).length > 6) {
      const contact = await ("+" + data.dialCode + value.slice(data.dialCode.length));
      console.log("phone contact", contact);
      // setState({ contactInfo: { ...contactInfo, mobNumber: contact } });
      setContactInfo({ ...contactInfo, mobNumber: contact})
    } else {
      // setPhone("");
    }
  };

  const handleChange = ({ currentTarget: input }) => {
    setContactInfo((contactInfo) => ({
      ...contactInfo,
      [input.name]: input.value,
    }));
  };

  const validateAllFields = (contactInfo) => {
    const { firstName, lastName, emailAddress, mobNumber } = contactInfo;

    const errors = {};

    if (!firstName) {
      errors.firstName = "First Name is required.";
    }

    if (!lastName) {
      errors.lastName = "Last Name is required.";
    }

    if (!emailAddress) {
      errors.emailAddress = "Email is required.";
    } else if (!isValidEmail(emailAddress)) {
      errors.emailAddress = "Invalid email address.";
    }

    setErrors(errors);
    function isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    return Object.keys(errors).length === 0;
  };
  console.log("contactInfo", contactInfo)
  const handleSave = () => {
    if (validateAllFields(contactInfo)) {
      onSave(contactInfo);
    }
  };


  const formCustomerOverview = (
    <>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="firstName">First Name *</label>
          <input
            type="text"
            value={contactInfo.firstName ?? ""}
            onChange={handleChange}
            id="firstName"
            name="firstName"
            className="form-control"
          />
          {errors.firstName && (
            <div className="help-block">{errors.firstName}</div>
          )}
        </div>
        <div className="form-group col">
          <label htmlFor="lastName">Last Name *</label>
          <input
            type="text"
            value={contactInfo.lastName ?? ""}
            onChange={handleChange}
            id="lastName"
            name="lastName"
            className="form-control"
          />
          {errors.lastName && (
            <div className="help-block">{errors.lastName}</div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="language">Language</label>
          <SelectLanguage
            id="language"
            name="language"
            value={contactInfo.language}
            onChange={(option) => {
              setContactInfo((contactInfo) => ({
                ...contactInfo,
                language: option.value,
              }));
            }}
          />
          <span id="languageHelp" class="form-text text-muted">
            This customer will receive notifications in this language.
          </span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="emailAddress">Email *</label>
          <input
            type="emailAddress"
            value={contactInfo.emailAddress ?? ""}
            onChange={handleChange}
            id="emailAddress"
            name="emailAddress"
            className="form-control"
          />
          {errors.emailAddress && (
            <div className="help-block">{errors.emailAddress}</div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col">
          <label htmlFor="phone">Phone number</label>
          <PhoneInput
            inputClass="font-poppins"
            country={"ae"}
            value={contactInfo.mobNumber || ""}
            placeholder={"Enter Phone Number"}
            onChange={(value, data, event, formattedValue) => {
              numberCheck(value, data, event, formattedValue);
            }}
          />
        </div>
      </div>
    </>
  );

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{formCustomerOverview}</Modal.Body>
      <Modal.Footer>
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-md btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}}
          onClick={handleSave}
          type="button"
          className="btn btn-md btn-success ml-2"
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditContactInfoViewModal;
