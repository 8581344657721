import React, { useState, useEffect, useCallback } from "react";
import API from "../../utils/API";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { formatDate,formatAmount } from "../../utils/commons";
import { Link } from "react-router-dom";
import TableConfig from "../../utils/tableConfig";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Search from "../../components/Search/Search";
import CrossIcon from "../../assets/img/cross_icon.png"

const PopUpForFilters = ({
  isFilterPopUpVisible,
  setSelectedFilterCountry,
  setSelectedFilterCouponType, 
  setSelectedFilterStatus,
  countries,
  setIsFilterPopUpVisible,
  applyFilter,
  clearFilter,
  selectedFilterCountry,
  selectedFilterCouponType,
  selectedFilterStatus,}) =>{
  // console.log("countryData",countries)

  const handleCouponTypeFilter = (type) => {
    setSelectedFilterCouponType(prevSelectedTypes => {
      if (prevSelectedTypes?.includes(type)) {
        return prevSelectedTypes.filter(t => t !== type);
      } else {
        return [...prevSelectedTypes, type];
      }
    });
  };

  const handleStatusFilter = (status) => {
    setSelectedFilterStatus(prevSelectedStatus => {
      if (prevSelectedStatus?.includes(status)) {
        return prevSelectedStatus.filter(s => s !== status);
      } else {
        return [...prevSelectedStatus, status];
      }
    });
  };

  const handleCountryFilter = (country) => {
  setSelectedFilterCountry(prevSelectedCountries => {
      console.log("prevSelectedCountries",prevSelectedCountries)
      if (prevSelectedCountries?.includes(country.id)) {
        return prevSelectedCountries.filter(id => id !== country.id);
      } else {
        return [...prevSelectedCountries, country.id];
      }
    });
  };


return (
    <Modal show={isFilterPopUpVisible} onHide ={()=>setIsFilterPopUpVisible(false)} size="lg" centered >
      <Modal.Header closeButton>
        <Modal.Title>Select Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="filter-div" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div className="status-filter">
      <label>
        <b>Status</b>
      </label>
      <div className="filter-option">
        <div className="input-div">
          <input className="checkbox"
            type="checkbox"
            onChange={()=>handleStatusFilter(1)}
            checked={selectedFilterStatus?.includes(1)}
          />
          Active
        </div>
        <div className="input-div">
          <input className="checkbox"
            type="checkbox"
            onChange={()=>handleStatusFilter(0)}
            checked={selectedFilterStatus?.includes(0)}
          />
          Inactive
        </div>
      </div>
      </div>

      <div className="couponType-filter">
      <label>
        <b>Coupon Type</b>
      </label>
      <div className="filter-option">
        <div className="input-div">
          <input className="checkbox"
            type="checkbox"
            onChange={() => handleCouponTypeFilter('Fixed')}
            checked={selectedFilterCouponType.includes('Fixed')}
          />
          Fixed
        </div>
        <div className="input-div">
          <input className="checkbox"
            type="checkbox"
            onChange={() => handleCouponTypeFilter('Percentage')}
           checked={selectedFilterCouponType.includes('Percentage')}
          />
          Percentage
        </div>
      </div>
      
      </div>

      <div className="country-filter">
      <label>
        <b>Country</b>
      </label>
      <div className="filter-option">
      {countries.map((country)=> {
        return (
          <div className="input-div">
            <input className="checkbox"
              type="checkbox"
              onChange={()=>handleCountryFilter(country)}
              checked={selectedFilterCountry?.includes(country.id)}
            />
            {country.code}
          </div>
        )
      })}
       
      </div>

      </div>
      </div>
      </Modal.Body>

      <Modal.Footer>
        <button
        style={{ borderRadius: '20px' }}
        type="button"
        className="btn btn-md btn-outline-secondary"
        onClick={()=>clearFilter()}
        >
          Clear Filters
        </button>
        <button
         style={{ background: '#247A81', borderRadius: '20px' }}
         type="button"
         className="btn btn-md btn-success ml-2"
         onClick={(event) => applyFilter()}
        >
          Apply Filter
        </button>
      </Modal.Footer>
    </Modal>

  )

}
function Coupons(props) {
  const [couponsGet, setCouponsGet] = useState([]);
  const [selectRowId, setSelectRowId] = useState();
  const [couponDetails,setCouponDetails]=useState([]);
  const [countries,setCountries] = useState([])
  const [searchValue, setSearchValue] = useState("");
  const [ currentPage , setCurrentPage] = useState(0);
  const [ recordsTotal , setRecordsTotal]  = useState(0);
  const [ rowsPerPage, setRowPerPage] = useState(50);
  const [ totalPages , setTotalPages ] = useState(0);
  const [sortDirection , setSortDirection] = useState('desc');
  const [sortField , setSortField] = useState('id');
  const [clearSearch, setClearSearch] = useState(false)

  const [isFilterPopUpVisible,setIsFilterPopUpVisible] = useState(false)
  const [selectedFilterCountry, setSelectedFilterCountry] = useState([]);
  const [selectedFilterCouponType, setSelectedFilterCouponType] = useState([]);
  const [selectedFilterStatus,setSelectedFilterStatus] = useState([]);
  
  console.log("selectedFilterCountry",selectedFilterCountry);
  console.log("selectedFilterCouponType",selectedFilterCouponType);
  console.log("selectedFilterStatus",selectedFilterStatus)
  const columns = [
    {
      name: "Id",
      selector: "couponId",
      sortable: true,
      maxWidth: "100px",
      sortField:"id"
    },
    {
      name: "Coupon",
      selector: "couponCode",
      // sortable: true,
      maxWidth: "200px",
    },
    {
      name: "Country",
      selector: "country",
      // sortable: true,
      sortField: "country",
      maxWidth: "200px",
      cell: (row) => {
        const countryData = countries.find((data) => data.id === row.countryId);
        return countryData ? countryData.name : ""; 
      },
    },
    {
      name: "Discount Type",
      selector: "discountType",
      // sortable: true,
      maxWidth:"200px"
    },
    {
      name: "Amount",
      selector: "couponAmount",
      sortable: true,
      sortField: 'couponAmount',
      maxWidth: "200px",
      id:""
    },
    {
      name: "Expiry",
      selector: "couponExpiry",
      sortable: true,
      format: (coupon) => formatDate(coupon.couponExpiry),
      maxWidth: "200px",
      sortField:"couponExpiryDate"
    },
    {
      name: "Status",
      selector: "status",
      // sortable: true,
      maxWidth: "100px",
      cell: (row) => row.status ? "Active" : "Inactive",
    }
    ]
  const handlerowselect = (row) => {
    props.history.push(`/dashboard/coupon/view/${row.couponId}`);
  }

  useEffect(() => {
    API.get("api/v1/country").then((res)=>res.data).then(data  =>  setCountries(data))
   }, [])

  const getCouponData = async (filters={}) => {
      try {
        showLoading();
          const res = await API.get(`module/coupon/paginated/search?key=${searchValue}&page=${currentPage}&count=${rowsPerPage}&sort_by=${sortField}&sort_order=${sortDirection}&countryList=${filters.selectedFilterCountry || selectedFilterCountry}&couponTypes=${filters.selectedFilterCouponType || selectedFilterCouponType}&status=${filters.selectedFilterStatus || selectedFilterStatus}`);
          console.log("couponData", res.data);
          if(res.status == 200){
            hideLoading();
            setCouponsGet(res?.data.couponList)
            setRecordsTotal(res.data.recordsTotal)
            setTotalPages( res.data.totalPages )
          }
       
      } catch (error) {
        hideLoading();
          console.error("Error fetching coupon data:", error);
          
      }
  };

  useEffect(() => {
    getCouponData();
  }, [currentPage,rowsPerPage,sortField,sortDirection,])

  useEffect(() =>{
    if(clearSearch){
      getCouponData();
    }
  },[clearSearch])

  const applyFilter = () => {
    setIsFilterPopUpVisible(false)
    getCouponData();
  }
   const handleClearFilters = ()=>{
    setSelectedFilterCountry([]);
    setSelectedFilterCouponType([]);
    setSelectedFilterStatus([]);
    setIsFilterPopUpVisible(false);
    getCouponData({
      selectedFilterCouponType: [],
      selectedFilterCountry: [],
      selectedFilterStatus: [],
    });
  }

  const handleShowFilterPopUpVisible =()=>{
    setIsFilterPopUpVisible(true)
  }

  const handleHideFilterPopUpVisible = ()=>{
    setIsFilterPopUpVisible(false)
  }

  const handleSearch = (val) =>{
    if (val) {
      setSearchValue(val);
      setClearSearch(false);
    } else {
      setSearchValue("");
      setClearSearch(true);
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      getCouponData()
    }
}
const onSearchBtn = () =>{
  getCouponData()
}
const onChangePageClick = (page)=>{
  setCurrentPage(page-1)
}
const onChangeRowsPerPage = (currentRowsPerPage,currentPage) =>{
  setRowPerPage(currentRowsPerPage)
}

const handleSort = (column, sortDirection) =>{
   setCurrentPage(0)
   setSortField(column.sortField)
   setSortDirection(sortDirection)

}

const handlleRemoveCountryFilter = (countryId) =>{
     
  const updatedCountryFilter = selectedFilterCountry.filter(item => item !== countryId);
  setSelectedFilterCountry(updatedCountryFilter);
  getCouponData({
    selectedFilterCountry: updatedCountryFilter,
  });
}

const handleRemoveCouponTypeFilter = (couponType) => {
  const updatedCouponTypeFilter = selectedFilterCouponType.filter(item => item!== couponType);
  setSelectedFilterCouponType(updatedCouponTypeFilter);
  getCouponData({
    selectedFilterCouponType: updatedCouponTypeFilter,
  });
}

const handleRemoveStatusFilter = (status)=>{
  const updatedStatusFilter = selectedFilterStatus.filter(item => item!== status);
  setSelectedFilterStatus(updatedStatusFilter);
  getCouponData({
    selectedFilterStatus: updatedStatusFilter,
  });
}



  
  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });
  return (
  <>
  <div>
      <div className="header-div" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
      <div className="select-filter"  onClick={handleShowFilterPopUpVisible} style={{marginLeft:"10px"}}>
      <h6>Filter</h6>
      <div className="filter">
         Select Filter
         <img style={{marginLeft:"10px"}} src="https://dzoweysldjhvw.cloudfront.net/79b9254cb9d2cc4c405a.svg" ></img>
         </div>
      </div>
    <div className="rightextreme">
    <div className="action-container">
    <div>
              <Search
                  searchExpanded={true}
                  searchTerm={searchValue}
                  handleSearch={(val)=>handleSearch(val)}
                  clearSearch={()=>{setSearchValue("");setClearSearch(true)}}
                  handleSearchExit={()=>{}}
                  onKeyPress={handleKeyPress}
                ></Search>
            </div>
            <div className="search-btn" onClick={onSearchBtn}>
              Search
            </div>
      <div>
        <Link to={"/dashboard/Coupon/Create"}> <button className="greenbackground" >Add Coupon</button></Link>
      </div>
    </div>
    </div>
    </div>
    <br />

<div className="filter-result">
{selectedFilterCountry && (
  <p>
    {selectedFilterCountry.map(countryId => {
      const country = countries.find(data => data.id === countryId);
      return (
        <div className="filter-list-item" key={countryId}>
          <span>
            {country && country.name}
            {country && <img src={CrossIcon} alt="Cross Icon" 
            onClick={()=>handlleRemoveCountryFilter(country.id)}
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            />}
          </span>
        </div>
      );
    })}
  </p>
)}
{selectedFilterCouponType && (
  <p>
    {selectedFilterCouponType.map(couponType => {
      return (
        <div className="filter-list-item" key={couponType}>
          <span>
            {couponType}
           <img src={CrossIcon} alt="Cross Icon" 
            onClick={()=>handleRemoveCouponTypeFilter(couponType)}
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            />
          </span>
        </div>
      );
    })}
  </p>
)}
{selectedFilterStatus && (
  <p>
    {selectedFilterStatus.map(status => {
      const statusName= status==1 ? "Active" : "Inactive"
      return (
        <div className="filter-list-item" key={status}>
          <span>
            {statusName}
             <img src={CrossIcon} alt="Cross Icon" 
            onClick={()=>handleRemoveStatusFilter(status)}
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            />
          </span>
        </div>
      );
    })}
  </p>
)}
</div>
    <div>
      <Card >
        <DataTable
          highlightOnHover
          columns={columns}
          data={couponsGet}
          sortServer
          onSort={handleSort}
          defaultSortField="id"
          defaultSortAsc={false}
          onRowClicked={handlerowselect}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={recordsTotal}
          onChangePage={onChangePageClick}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationServerOptions={{
            totalPages:totalPages,
            totalRecords: recordsTotal,
            currentPage:currentPage
          }}
          paginationRowsPerPageOptions={TableConfig.ROWS_PER_PAGE_OPTIONS}
          pagination
          paginationServer
          responsive
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer', 
              },
            },
          }}
        />
      </Card>
    </div>
   

          <PopUpForFilters
          isFilterPopUpVisible={isFilterPopUpVisible}
          setSelectedFilterCountry={setSelectedFilterCountry}
          setSelectedFilterCouponType={setSelectedFilterCouponType}
          setSelectedFilterStatus={setSelectedFilterStatus}
          countries={countries}
          setIsFilterPopUpVisible={setIsFilterPopUpVisible}
          applyFilter={()=>applyFilter()}
          clearFilter ={()=>handleClearFilters()}
          selectedFilterCountry={selectedFilterCountry}
          selectedFilterCouponType={selectedFilterCouponType}
          selectedFilterStatus={selectedFilterStatus}

          />
</div>

  </>
)
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);


