import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const Search = ({
  searchExpanded,
  searchTerm,
  clearSearch,
  handleSearch,
  handleSearchExit,
  handleSearchEnter,
  onKeyPress
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (handleSearchEnter) {
      handleSearchEnter();
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="search">
        <input
          value={searchTerm ?? ""}
          onChange={(e) => handleSearch(e.target.value)}
          onBlur={(e) => handleSearchExit(e)}
          type="search"
          placeholder="Search"
          className={searchExpanded ? "active" : ""}
          style={{height:'39px',borderRadius:'4px'}}
          onKeyPress={onKeyPress} 
        />
        <div className="icons">
          <SearchIcon className="search-icon"></SearchIcon>
          {searchTerm ? (
            <ClearIcon style={{cursor:"pointer"}} onClick={() => clearSearch()} className="clear-icon" />
          ) : null}
        </div>
                
      </div>
    </form>
  );
};

export default Search;
