import React, { useCallback, useEffect, useState } from "react";
import API from "../../utils/API";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { RenderInputbox } from "../../components/renderInputbox";
import { connect } from "react-redux";

 function Inventory(props) {
  const [products, setProducts] = useState([]);
  const [productdetails, setProductdetails] = useState([]);
  const [storedproducts, setStoredproducts] = useState([]);
  const [finalprods, setfinalprods] = useState([]);
  const [prodnamesid, setprodnamesid] = useState([]);
  const [quantity, setQuantity] = useState();
  const [quantitychange, setQuantyityChange] = useState(false);
  const [productChange, setProductChange] = useState();
  const [updatevalues, setUpdateValue] = useState({});
  const [storeid, setStoreId] = useState();
  const[commited,setCommited]=useState();
  const[avaialble,setAvailable]=useState();
  const[onHand,setOnHand]=useState();
  const [prodids, setProdIds] = useState();
  const[saveFlag,setSaveFlag]=useState(false);
 const[UpdatedProducts,setUpdatedProducts]=useState([]);
  const[inventoryEdit,setInventoryEdit]=useState({ "id": 1,
  "onHand": 3,
  "availableQuantity": 5,
  "commitedQuantity":7})
  const handleStock = (prod, quant) => {
    console.log("Prod:", prod,quant)
    
    
      prod.quantity = quant;
      

      setProductChange(prod.productid);
      if(quant-prod.commitedQuantity<0){
        prod.availableQuantity=0;
      }
      else{
      prod.availableQuantity=quant-prod.commitedQuantity;
      }

       setInventoryEdit({"id":prod.productid,"onHand": prod.quantity,
       "availableQuantity": prod.availableQuantity,
       "commitedQuantity":prod.commitedQuantity})
     //setChange(true);
     
    } 
  
const handleAvaialble=(product)=>{
  console.log("product:", product)
  const {availableQuantity}= product
  console.log("shbhsb",availableQuantity)
   return (
    <>
   {availableQuantity}
    </>
   )
}
const handleProd=(product)=>{
 const remaingprods= UpdatedProducts.filter((prod)=>prod.id !== product.productid);
  setUpdatedProducts([...remaingprods.flat(),{"id":product.productid,"onHand": parseInt(product.quantity),
  "availableQuantity": product.availableQuantity,
  "commitedQuantity":product.commitedQuantity}])
}

const showSuccessNotification = (notificationMessage) =>
showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});
  const columns = [
    {
      name: "ID",
      selector: "productid",
      sortable: true,
      sortField: 'productid',
      maxWidth:"100px"
    },
   
    {
      name: "PRODUCT",
      selector: "name",
      sortable: true,
      sortField: 'name',
      wrap:true
    },

  {
      name: "Commited",
      selector: "commitedQuantity",
      sortable: true,
      maxWidth:"150px"

    },
    {
      name: "Available",
      selector: "availableQuantity",
      sortable: true,
      maxWidth: "150px",
      cell:(product)=>handleAvaialble(product)

    },
    {
      name: "On hand",
      selector: "quantity",
      sortable: true,
      sortField: 'quantity',
      cell: (product) => <RenderInputbox quantvalue={product.quantity} onQuantityChangeEdit={(number) => handleStock(product,number)} FinalProd={()=>handleProd(product)}/>,
      maxWidth: "200px",
    }


  ]
  useEffect(() => {
    try {
      API.get("/inventry/product/").then((res) => res.data).then((data) => setStoredproducts(data));
      //  API.get("/api/v1/products/").then((res)=>res.data).then((data)=>setProducts(data.products));

    } catch (error) {
      console.log("fetchProducts:: Error on fetching products!", error);
    }
  }, []);

  useEffect(() => {
    console.log("product of Inventory ", storedproducts);
  }, [storedproducts])
  useEffect(() => {
    setfinalprods(storedproducts.map((strprod) => { return (products.filter((prod) => strprod.id == prod.id)) }));
    setprodnamesid(finalprods.flat().map((fp) => { return ({ "id": fp.id, "name": fp.description?.title }) }));
  }, [products])


  useEffect(() => {

    setProductdetails(storedproducts.map((product) => {
      return ({
        "productid": product.id,
        "sku": product.sku,
        "commitedQuantity": product.commitedQuantity,
        "availableQuantity": product.onHand-product.commitedQuantity<0?0:product.onHand-product.commitedQuantity,
        "quantity": product.onHand,
        "name": product.name,
      })
    }))

  }, [storedproducts])

 
useEffect(() => {
    
    if(saveFlag){
    try {
     API.patch(`/inventry/product/`, UpdatedProducts).then((res) => console.log(res));
    }
    catch (error) {
      console.log("error is ", error);
    }
    setQuantyityChange(false);
   setSaveFlag(false);
   showSuccessNotification("updated Successfully")
  }
  }, [saveFlag,quantitychange])



  useEffect(() => {
    if (quantitychange) {
      setUpdateValue(
        {  "id":productChange,
          "onHand": onHand,
    "availableQuantity":avaialble,
    "commitedQuantity": commited
        })
    }

  }, [quantitychange])


const handleSave=(e)=>{
  setSaveFlag(true);
}

  return (
    <div>
      <div className="rowextreme">
        
          <button onClick={handleSave} disabled={saveFlag} className={saveFlag ? 'disabled-button' : 'savebtn'}>Save</button>
          <Link to={`/dashboard/products/`}> <button className="greenbackground" >View Products</button></Link>
        
        
      </div>
      <br/>
      <Card>
       
        <DataTable
          highlightOnHover
          columns={

            columns

          }
          data={productdetails}
           pagination
           responsive
/>

      </Card>
    </div>
  )





}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory)