import { clearToken } from "./tokenService";

export function getUserType() {
  const type = localStorage.getItem("8js2jbhca");

  // if (type === "k48s7vc54s") return "admin";
  // else if (type === "1b8sujsd87b") return "nutritionist";
  // else if (type === "y21s4sehbbw") return "crm";
  // else if (type === "8sdj23ybnsh") return "lab";
  // else if (type === "12345678910") return "homecare";
  // else return "admin";
  return "admin";
}

export function setUserType(type) {
  if (type === "admin") localStorage.setItem("8js2jbhca", "k48s7vc54s");
  else if (type === "nutritionist")
    localStorage.setItem("8js2jbhca", "1b8sujsd87b");
  else if (type === "crm") localStorage.setItem("8js2jbhca", "y21s4sehbbw");
  else if (type === "lab") localStorage.setItem("8js2jbhca", "8sdj23ybnsh");
  else if (type === "homecare")
    localStorage.setItem("8js2jbhca", "12345678910");
  else localStorage.setItem("8js2jbhca", null);
}

export function getUserName() {
  const type = localStorage.getItem("userName");

  if (type) return type;

  return null;
}

export function getUserId() {
  const type = localStorage.getItem("userId");

  if (type) return type;

  return null;
}

export function getUserEmail() {
  const type = localStorage.getItem("userEmail");

  if (type) return type;

  return null;
}

export function setUserId(type) {
  localStorage.setItem("userId", type);
}

export function setUserName(type) {
  localStorage.setItem("userName", type);
}

export function setUserEmail(type) {
  localStorage.setItem("userEmail", type);
}

export function isValidUser() {
  const type = getUserType();

  if (
    type === "k48s7vc54s" ||
    type === "1b8sujsd87b" ||
    type === "y21s4sehbbw" ||
    type === "8sdj23ybnsh"
  ) {
    return true;
  } else return false;
}

export function clearData() {
  clearToken();
  localStorage.removeItem("8js2jbhca");
  localStorage.removeItem("userName");
}
