import React, { useEffect } from 'react'
import './importErrorPopUp.scss';

function ImportErrorPopUp({setIsShowImportErrorPopUp,errorList}) {
    console.log("errorList",errorList)
    const fileError = errorList.fileErrorList || [];
    const rowError = errorList.rowErrorList || [];
    
  return (
    <div className='importErrorContainer'>
<div className='importErrorsection'>
  <div className='importErrorContent'>
    <div onClick={()=>setIsShowImportErrorPopUp(false)} className='crossIcon'><img src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon%20(2).svg"/></div>
    <div className='errorDiv'>
    {/* {Object.keys(fileError).length > 0 && (
    <div>
        <ul>
            <h5>FILE ERROR</h5>
            {Object.entries(fileError).map(([key, value]) => (
                <li key={key}>{`${key}:- ${value}`}</li>
            ))}
        </ul>
    </div>
)}

   {rowError.length >0 && <div>
      <ul>
                        <h5>ROW ERROR</h5>
                        {rowError.map((data, index) => (
                            <div key={index}>
                                {Object.entries(data).map(([key, value]) => (
                                    <li key={key}>{`${key}:- ${value}`}</li>
                                ))}
                                <br/>
                            </div>
                        ))}
                    </ul>
    </div>} */}
 <div>
        <ul>
            <h5>ERROR</h5>
            {errorList?.map((data) => (
                    <div>
                    <li><b>row no.{data.rowNo}</b> :- {data.errorMessage}</li>
                    {/* <li></li> */}
                    <br/>
                    </div>  
                     
            ))}
           
        </ul>
    </div>
    
    </div>            

</div>
</div>
    </div>
  )
}

export default ImportErrorPopUp
