import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import  EditReview  from "./EditReview";

import { ViewReview } from "./ViewReview";
import CreateReview from "./CreateReview";
export function ReviewComponent(props) {
    const { id, mode, add, addfun } = props;
    const [reviews, setReviews] = useState([]);
    const [reviewid, setReviewid] = useState();
    const [reviewcreate, setReviewCreate] = useState({});
    const [reviewedit, setReviewEdit] = useState({});
    const [customer, setCustomer] = useState();
    const [headline, setHeadline] = useState();
    const [reviewbody, setReviewbody] = useState();
    const[editFlag,setEditFlag]=useState(false);

    console.log(mode);

    useEffect(() => {
        const url = `/module/product/${id}/review`;
        try {
            API.get(url).then((res) => setReviews(res.data));
        }
        catch (error) {
            console.log("error is", error);
        }
    }, [add])
   
    useEffect(() => {
        if(editFlag){
        const url = `/module/product/${id}/review`;
        try {
            API.get(url).then((res) => setReviews(res.data));
            
        }
        catch (error) {
            console.log("error is", error);
        }
      setEditFlag(false);  
    }
    }, [editFlag])
    //post for add review

    useEffect(() => {
        try {
            API.post("/module/product/review", reviewcreate).then((res) => console.log(res));
        }
        catch (error) {
            console.log("error is", error);
        }
    }, [reviewcreate])

    //review patch









    return (<>
        { 
            mode == "View" && reviews && add ?
                [reviews.map((review) => {if(review.isActive) return (<EditReview name={review.customerName} reviewStatus={review.isActive} heading={review.headLine} review={review.reviewBody} reviewrating={review.rating} id={review.id} prodid={id} editflag={setEditFlag} />) }),


                <CreateReview prodid={id} mode ={"EditCreate"} addfun={addfun} />


                ]
                : mode == "View" && reviews ?
                    reviews.map((review) => { if(review.isActive)return ( 
                        <EditReview name={review.customerName} reviewrating={review.rating} reviewStatus={review.isActive} heading={review.headLine} review={review.reviewBody} id={review.id} prodid={id} editflag= {setEditFlag} />) })

                    : <CreateReview prodid={id} addReview={addfun} />
        }
    </>
    )



}