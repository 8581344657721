import moment from "moment";
const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";
const DEFAULT_YEAR_FORMAT="DD-MM-YYYY";


export const formatDate = (date, format = DEFAULT_DATE_FORMAT) =>
  moment(date).format(format);

export const formatAmount=(amount)=>parseInt(amount,10);

export const viewCoupondate=(date,format=DEFAULT_YEAR_FORMAT)=>moment(date).format(format);




