import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import { Redirect, useParams } from "react-router-dom";
import { Button, Divider, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { connect } from "react-redux";
import API from "../../../../src/utils/API";
import ProductDetails from "../Component/ProductDetails/ProductDetails";
import Media from "../Component/Media/Media";
import VariantManagement from "../Component/VariantManagement/VariantManagement";
import PricingOnCountryLevel from "../Component/PricingOnCountryLevel/PricingOnContryLevel";
import ProductOrganization from "../Component/ProductOrganization/ProductOrganization";
import InventoryManagement from "../Component/InventoryManagement/InventoryManagement";
import Faq from "../Component/Faq/Faq";
import Reviews from "../Component/Reviews/Reviews";
import ProductStatus from "../Component/ProductStatus/ProductStaus";
import FrequentlyBoughtItems from "../Component/FrequentlyBoughtItems/FrequentlyBoughtItems";
import './create-product.scss';
import Subscription from "../Component/SubscriptionFlow/Subscription";

const validateProductDetails = (formData) => {
  const errors = {};
  // console.log("validateProductDetails",formData.shortDescription.replace(/<[^>]+>/g, ''))
  if (!formData.title) {
    errors.title = 'Title is required.';
  }
  // if(!formData.titleArabic){
  //   errors.titleArabic = 'Arabic Title is required.';
  // }
  // if(formData.title && formData.title.length>180 ){
  //   errors.titleArabic = 'Arabic Title must be less than 180 characters';
  // }
  if(formData.title && formData.title.length>180 ){
    errors.title = 'Title must be less than 180 characters';
  }
  // if (!formData.description || formData.description == "<p><br></p>") {
  //   errors.description = 'Description is required.';
  // }
  // if (!formData.descriptionArabic || formData.descriptionArabic == "<p><br></p>") {
  //   errors.descriptionArabic = 'Arabic Description is required.';
  // }
  // if(!formData.shortDescription) {
  //   errors.shortDescription = 'Short Description is required.';
  // }
  // if(!formData.shortDescriptionArabic) {
  //   errors.shortDescriptionArabic = 'Arabic Short Description is required.';
  // }
  // if(formData?.shortDescription?.length>150){
  //   errors.shortDescription = 'Short Description must be less than 150 characters';
  // }
  return errors;
};

const validateSeoUrlDetails = (data) =>{
  if(!data){
    return "Seo URL is a required field!";
  }
}
const validateMediaDetails = (formData) => {
  const errors = {};

  if (!formData || formData.length === 0) {
    errors.media = 'Media is required.';
  } else {
    
    const hasImage = formData.some(item => item.image !== undefined && item.image !== null && item.image !== "");
    const hasDefaultImage = formData.some(item => item.isDefault === true);

    if (!hasImage) {
      errors.media = 'At least one image is required.';
    }

    if (!hasDefaultImage) {
      errors.media = 'At least one image should be marked as default.';
    }
  }

  return errors;
};

const validateSubscription=(formdata,subscriptionStatus)=>{
  const errors={};
  if(subscriptionStatus && !formdata.length>0){
    console.log("subscriptionerror",formdata);
    errors.subscriptionError="subscription is required";

  }
 return errors;
}
const validateVariantDetails = (formData) => {
  console.log("variantFormData",formData)
  const errors = {};
  if (formData && formData.length > 0) {
    formData.map((data,index)=>{
      if(
        // !data.variantImg ||
         !data.vaiantName  || !data.variantSku){
        errors[index]="All Fields are Mandatory"
      }
    })
  }
  return errors;

}

const validatePricingDetails = (formData) => {
  const errors = {};

  if (formData && formData.length > 0) {
      formData.forEach((data, index) => {
          if (!data.countryId || !data.finalPrice || !data.listPrice
            //  !data.pageTitle || !data.pageTitleArabic || !data.descriptionArabic || !data.description ||
// !data.urlHandle 
 ) {
              errors[index] = "All Fields are Mandatory";
          } else {
              // if (data.pageTitle && data.pageTitle.length > 150) {
              //     errors[index] =  "Page Title must be less than 150 characters" 
              // }
              // if (data.description && data.description.length > 300) {
              //     errors[index] =  "Description must be less than 300 characters" 
              // }
              // if (data.urlHandle && data.urlHandle.length > 150) {
              //     errors[index] =  "SEO URL must be less than 150 characters" ;
              // }

              if (parseInt(data.listPrice) < parseInt(data.finalPrice)) {
                  errors[index] = "Final price is wrong";
              } else if (Number(data.listPrice) < Number(data.discount)) {
                  errors[index] = "Discount is less than the list price";
              } else if (data.variantData && data.variantData.length > 0) {
                  data.variantData.forEach((variantData, i) => {
                      if (!variantData.listPrice || !variantData.finalPrice) {
                          errors[index] = "All Fields are Mandatory";
                      } else if (parseInt(variantData.listPrice) < parseInt(variantData.finalPrice)) {
                          errors[index] = "Variant Final price is wrong";
                      } else if (parseInt(variantData.listPrice) < parseInt(variantData.discount)) {
                          errors[index] = "Variant Discount is less than the list price";
                      }
                  });
              }

              const countryData = formData.map(obj => obj.countryId && parseInt(obj.countryId));
              const countryIds = countryData.filter(value => value !== '');
              const duplicatesCountry = countryIds.filter((value, idx, self) => self.indexOf(value) !== idx);
              if (duplicatesCountry.length > 0) {
                  errors[index] = "Duplicate Country";
              }
          }
      });
  }

  return errors;
}


const validateFaqDetails = (formData) => {
  console.log("formDataformData",formData)
  const errors = {};
  if (formData && formData.length > 0) {
    formData.map((data,index)=>{
      if(!data.question || !data.answer || !data.questionArabic || !data.answerArabic){
        errors[index]="All Fields are Mandatory"
      }
    })
  }
  return errors;

}

const validateReviewDetails = (formData) => {
  console.log("formDataformData",formData)
  const errors = {};
  if (formData && formData.length > 0) {
    formData.map((data,index)=>{
      if(!data.customerName  || !data.headline  || !data.rating|| !data.review 
        || !data.customerNameArabic || !data.headlineArabic || !data.reviewArabic
       ){
        errors[index]="All Fields are Mandatory"
      }
      else if(data.review.length > 100){
        errors[index] = "Please limit your review to 100 characters."
      }
      else if(data.headline.length > 40){
        errors[index] = "Please limit your heading to 40 characters."
      }
    })
  }
  return errors;

}
const validateInventoryDetails = (formData) => {
  console.log("formDataformData",formData)
  const errors = {};
  if (!formData.handQty || formData.handQty < 1) {
    errors.handQty = 'Quantity should greater than 0';
  }
  if(!formData.skuId){
    errors.skuId = "SKU is mandatory";
  }
  return errors;

}
const newPricingData= {
  status:true,
  countryId:"",
  listPrice:"",
  discount:"",
  finalPrice:"",
  pageTitle:"",
  description:"",
  urlHandle:"",
  keyword:"",
  variantData:[],
  
}
const CreateProduct = (props) =>{

  //data

  const params = useParams();
  console.log("paramss",params)
  const productId = params && params.productId || "";

  const [countryData , setCountryData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [types, setTypes] = useState([]);
  const [productOrganizationList , setProductOrganizationList] = useState({});
  const [listOfProduct, setListOfProduct] = useState([]);


  const [ productDetails , setProductDetails ] = useState([]);
  const [ productDetailsError , setProductDetailsError] = useState({});
  const [ mediaDetails , setMediaDetails] = useState([]);
  const [ mediaDetailsError , setMediaDetailsError] = useState([]);
  const [ variantData , setVariantData ] = useState([]);
  const [ variantDetailsError , setvariantDetailsError] = useState([]);
  const [pricingDetails , setPricingDetails] = useState([newPricingData]);
  const[subscriptionData,setSubscriptionData]=useState([]);
  const[subscriptionStatus,setSubscriptionStatus]=useState(false);
  const [pricingError , setPricingError] = useState({});
  const [inventoryManagement, setInventoryManagement] = useState({});
  const [inventoryManageError, setInventoryManagementError] = useState({});
  const [faqData , setFaqData] = useState([]);
  const [faqError, setFaqError] = useState([]);
  const [reviewData , setReviewData] = useState([]);
  const [reviewError, setReviewError] = useState([]);
  const [frequentlyBought, setFrequentlyBought] = useState([]);
  const [productOrganization , setProductOrganization] = useState({});
  const [productStatus , setProductStatus] = useState("Active");
  const[subscriptionError,setSubscriptionError]=useState({})
  const [editProductData , setEditProductData] = useState({});
  const [avgRating, setAvgRating] = useState();
  const [totalRating, setTotalRating] = useState()
  const [tags,setTags] = useState("")
  const [seoUrlError,setSeoUrlError] = useState("")

  const [productSeoUrl,setProductSeoUrl] = useState("")
  const [bestSellerTag, setBestSellerTag] = useState(false)
  console.log("faqDatafaqData++",faqData)



  console.log("variantDatavariantData",variantData)
  console.log("sspricingDetails",pricingDetails)

  
console.log("reviewData",reviewData)

  useEffect(()=>{
      getCountryData();
      getCollection();
      getTypes();
      getProducts();
      if(productId){
        getProductData();
      }
     
  },[])

 


  



  const getCountryData = () =>{
    API.get("/api/v1/active/countries").then((res) => setCountryData(res.data));


  }
// it gives the superCollection id and name
  const getCollection = () => {
    API.get("module/super/collection").then((res) => (res.data)).then((data) => setCollectionData(data.map((coll) => {
      return ({ "id": coll.id, "name": coll.name}) })));
  }

  // it gives the list of collection
  const getTypes = () =>{
    API.get("api/v1/product/types/all").then((res) => res.data).then((data) => data.list).then((list) => setTypes(list));
    
  }


  const getProducts = () =>{
  
    https://api.kuwa.bevaleo.dev/api/v1/frequent/products
    // API.get("/api/v1/products/").then((res) => res.data).then((data) => data.products).then((products) =>{
    //   hideLoading()
    //   setListOfProduct(products.map((product) => { return ({ "value": product.id, "label": product?.description?.name }) }))
    // } 
    // )
    
    API.get(`module/search/products/frequently-bought-products?key=${""}`)
    .then((res) => res.data)
    .then((data) => {
      const productIds = data.map((product) =>{return({ "value": product?.id, "label": product?.name }) });
      setListOfProduct(productIds); 
    })
  }



  // let editVariant = newVariants.map((data)=>{
  //   return(
  //     {
  //       "variantImg": data.variants.image,
  //       "vaiantName": data.variants.name,
  //       "qty": data.variants.quantity,
  //       "status": "active",
  //       "variantSku": data.variants.variantSku,
  //       "variantBarcode":data.variants.variantBarcode
  //   }
  //   )
  // })

  // const getVarainatData = () => {
  //   let variantDataUpdate = [];
  //   pricingDetails.map((data,index)=>{
    
  //     data.variantData.map((variant) => {

  //       variantData.map((vData,index)=>{
  //         if(vData.vaiantName === variant.vaiantName ){
          
            
  //           variantDataUpdate.push (
              
  //             {
  //              "variants": {
  //               "id":vData.id || "",
  //               "name": vData.vaiantName,
  //               "quantity": vData.qty,
  //               "isActive": vData.status,
  //               "image": vData.variantImg,
  //               "price": variant.finalPrice,
  //               "variantSku":vData.variantSku,
  //               "variantBarcode":vData.variantBarcode,
  //               "productId": "",
  //               "createdAt": "1997-03-04T00:00:00",
  //               "modifiedAt": "1997-09-09T00:00:00"
  //           }, 
  //           "pricings": 
  //           [
  //             {
  //               "id":variant?.id || "",
  //               "countryId": data.countryId,
  //               "retailPrice": variant.listPrice,
  //               "finalPrice": variant.finalPrice,
  //               "discount": variant.discount
  //             }
  //           ]
                  
  //           })
  //         }
  //       })
       
  //   })
  //   })
  //   return variantDataUpdate;
  
  
  // }

  const getVarainatData = () => {
    let variantDataUpdate = [];
    
    pricingDetails.map((data, index) => {
      
      data.variantData.map((variant) => {
       
        console.log("sbqhjhq",variant)
        const existingVariant = variantDataUpdate.find(
          (vData) => vData.variants.name === variant.vaiantName 
        );

          // const existingPricing = variantDataUpdate.find(
          //   (vData)=> vData.pricings.countryId === data.countryId
          // )
        if (existingVariant) {
          
          existingVariant.pricings.push({
            "id": variant?.priceId || "",
            "countryId": data.countryId,
            "retailPrice": variant.listPrice,
            "finalPrice": variant.finalPrice,
            "discount": variant.discount
          });
        } else {
          const vData = variantData.find((v) => v.vaiantName === variant.vaiantName);
          if (vData) {
            variantDataUpdate.push({
              "variants": {
                "id": vData.id || "",
                "name": vData.vaiantName,
                "availableQuantity": vData.qty,
                "isActive": vData.status,
                "image": vData.variantImg,
                "price": variant.finalPrice,
                "variantSku": (vData.variantSku).toUpperCase(),
                "variantBarcode": vData.variantBarcode,
                "productId": vData.productId,
                "createdAt": "1997-03-04T00:00:00",
                "modifiedAt": "1997-09-09T00:00:00",
                "recommended":vData.recommended
              },
              "pricings": [
                {
                  "id": variant?.priceId || "",
                  "countryId": data.countryId,
                  "retailPrice": variant.listPrice,
                  "finalPrice": variant.finalPrice,
                  "discount": variant.discount
                }
              ]
            });
          }
        }
      });
    });
    // console.log("variantDataUpdate",variantDataUpdate)
    return variantDataUpdate;
  };
  
  const getProductOrganizationData= () =>{
     let productOrganizationId = [];
    Object.entries(productOrganization).map(([category, items]) => ({
      category,
      ids: items.map(item => productOrganizationId.push({productId:item.value,rank:1}))
    }));

    return productOrganizationId;
  }


  const createPayloadForSave = async() => {
 
    console.log("subscriptiondata",subscriptionData);
    // showLoading()
    let data = {
      "available": (productStatus == "Active"),
      "canBePurchased": true,
      "categories": [
        { "id": 1, "code": "12" }
      ],
      "defaultImages":mediaDetails,
      // "image": mediaDetails,
      "variant": getVarainatData(),
      "productSeoUrl": productSeoUrl,
      "bestSellerTag":bestSellerTag,
      "barcode" : inventoryManagement.barcode,
      "sku" : (inventoryManagement.skuId).toUpperCase(),
      "priceSeo": pricingDetails.map((pricingDetails) => {
        return (
         {
                "id":pricingDetails.id || "",
                "country": pricingDetails.countryId,
                "seo": {
                    "id":pricingDetails.seoId || "",
                    "seoUrl": pricingDetails.urlHandle,
                    "country": pricingDetails.countryId,
                    "productId": "",
                    "metaTitle": pricingDetails.pageTitle,
                    "metaDescription": pricingDetails.description,
                    "keyword": pricingDetails.keyword,
                    "metaTitleArabic":pricingDetails.pageTitleArabic,
                    "metaDescriptionArabic":pricingDetails.descriptionArabic,
                    "keywordArabic":pricingDetails.keywordArabic,
                },
                "isActive": pricingDetails.status,
                "retailPrice": pricingDetails.listPrice,
                "discount": pricingDetails.discount,
                "finalPrice": pricingDetails.finalPrice,
                "productId": ""
         }
        )
      })
      ,
      "availability": {
        "available": (productStatus == "Active"),
        "onHandQuantity": parseInt(inventoryManagement.handQty),
        "availableQuantity": parseInt(inventoryManagement.availableQuantity),
        "commitedQuantity": parseInt(inventoryManagement.commitedQuantity),
        "productQuantityOrderMax": 3,
        "productQuantityOrderMin": 1,
        "status": 1,
        "region": "*",

      },
      "descriptions": [
        {
          "description": productDetails.description,
          "friendlyUrl": "string",
          "highlights": "string",
          "id": productDetails.id,
          "keyWords": "string",
          "language": "en",
          "metaDescription": "string",
          "name": productDetails.title,
          "title": productDetails.title,
          "shortDescription": productDetails.shortDescription,
          "titleArabic": productDetails.titleArabic,
          "nameArabic": productDetails.titleArabic,
          "shortDescriptionArabic": productDetails.shortDescriptionArabic,
          "descriptionArabic": productDetails.descriptionArabic
        }
      ],
      "identifier": "string",
      "manufacturer": "DEFAULT",
      "productSpecifications": {
        "dimensionUnitOfMeasure": "cm",
        "height": 0,
        "length": 0,
        "manufacturer": "DEFAULT",
        "model": "string",
        "weight": 0,
        "weightUnitOfMeasure": "g",
        "width": 0
      },
      "properties": [
        {
          "attributeDefault": true,
          "attributeDisplayOnly": true,
          "id": 0,
          "option": {
            "code": "0",
            "id": 0,
            "readOnly": true,
            "type": "string"
          },
          "optionValue": {
            "code": "string",
            "defaultValue": true,
            "descriptions": [
              {
                "description": "string",
                "friendlyUrl": "string",
                "highlights": "string",
                "id": 0,
                "keyWords": "string",
                "language": "en",
                "metaDescription": "string",
                "name": "string",
                "title": "string"
              }
            ],
            "id": 0,
            "image": "",
            "name": "string",
            "order": 0,
            "sortOrder": 0
          },
          "productAttributePrice": 0,
          "productAttributeWeight": 0,
          "productId": 0,
          "sortOrder": 0
        }
      ],
      "quantity": parseInt(inventoryManagement.handQty),
      "shipeable": true,
      "sortOrder": 0,
      type: null,
      "virtual": true,
      "visible": true,
      "ingredients":productDetails.ingrediants,
      "benifits":productDetails.benefits,
      "ingredientsArabic": productDetails.ingrediantsArabic,
      "benifitsArabic": productDetails.benefitsArabic,
      "metaTitle":"pageTitle",
      "metaDescription":"metaDescription",
      "dosages":productDetails.ingrediants,
      "freqBoughtTogether":frequentlyBought.map((data,index)=>data.value),
      "ranks":getProductOrganizationData(),
       "faqs":faqData.map((data,index)=>{
        console.log("faqDatadatadata",data)
        return(
            {
              "question": data.question,
              "answer": data.answer,
              "isActive":  data.isActive,
              "id":data.id,
              "questionArabic": data.questionArabic,
              "answerArabic": data.answerArabic,
            }
        )
       }),
       "reviews": reviewData.map((data)=>{
        return({
          "customerName": data.customerName,
          "headLine": data.headline,
          "reviewBody": data.review,
          "isActive": data.status,
          "modifiedAt": "2023-07-10T10:30:00",
          "rating": data.rating,
          "id": data.id,
          "customerNameArabic": data.customerNameArabic,
          "headlineArabic": data.headlineArabic,
          "reviewBodyArabic": data.reviewArabic,
          "countryId": Number(data.countryId),
        })
       }),
       "enableSubscription": subscriptionStatus ,
     
       "subscriptionItems":subscriptionStatus?subscriptionData.map((data)=>{
        console.log("subscription data is",data);
        return({
          "status": data.status,
          "noOfMonths": data.noOfMonths,
          "discountType": data.discountType,
          "discountAmount": data.discountAmount,
          "rank": data.rank,
          "id":data.id
          
          
        })
       }):[],
       "avgRating":avgRating,
       "totalRating":totalRating,
       "tags":tags
    }
    
    if(productId){
      data['id'] = productId;
    }
    // else{
    //   data['sku'] = inventoryManagement.skuId;
    // }

   
    // try {
    //   if(productId){
    //     console.log("product data",data);
    //     API.put(`api/v1/product/${productId}`, data).then((res) => (res.data)).then((data) =>{
    //       console.log("datata++++",data)
    //       props.showNotificationMessage({
    //         notificationMessage:"Product Updated successfully!",
    //         successMessage: true,
    //         showNotification: true,
    //       });
    //       hideLoading();
    //       window.location.reload()
    //       })
    //   }else{
    //     API.post("api/v1/private/product", data).then((res) => (res.data)).then((data) =>{
    //       console.log("datata++++",data)
    //       props.showNotificationMessage({
    //         notificationMessage:"Product Created successfully!",
    //         successMessage: true,
    //         showNotification: true,
    //       });
    //       hideLoading()
    //       setTimeout(()=>{
    //         props.history.goBack();
    //       },500)
    //       })
    //   }
      
    // } catch (error) {
    //   console.log("fetchProducts:: Error on sending products details!", error);
      
    // }

    
      if(productId){
        try{
          showLoading();
          const res =await API.put(`api/v1/product/${productId}`,data);
          if(res.status=== 200){
            hideLoading();
            showSuccessNotification("Product Update SuccessFully");
            window.location.reload()
          }
          else{
            hideLoading();
            showErrorNotification("Error in Product Updating")
          }
         
          console.log("hbha",data)
        }
        catch(error){
          hideLoading();
          showErrorNotification(error.response.data.message);
        }
      }
      else{
        try{
          showLoading();
          const res = await API.post("api/v1/private/product", data);
          if(res.status===200){
            hideLoading();
            const productId = res.data
            showSuccessNotification("Product Create SuccessFully")
            setTimeout(()=>{
              props.history.goBack()
            },500)
          }
          else{
            hideLoading();
            showErrorNotification("Error to Create a Product")
          }
          console.log("dbhhs",res)
        }
        catch(error){
          hideLoading();
          console.log("error",error)
          showErrorNotification(error.response.data.message)
        }
      }
    

  }
 

  useEffect(()=>{
    let productOrganizationList= {
    }
    // in collectionData we are storing the superCollection id and name
    // in types we are storing the  collection 
    // in productOrganizationList we are storing collection under the superCollection
    console.log("superCollection",collectionData);
    console.log("collectionData",types);
    if(collectionData && collectionData.length > 0 && types && types.length > 0){
      collectionData.map((data,index)=>{
        productOrganizationList[data.name] = []
        console.log("first",productOrganizationList)
      })
      collectionData.map((collection,index)=>{
        types.map((data,index)=>{
          if(data && data.superCollection && data.superCollection.length > 0 && data.visible===true){
              data['superCollection'].map((superCollection,index)=>{
                if(superCollection.id == collection.id ){
                   if(superCollection.name === collection.name){
                        productOrganizationList[collection.name].push({ "id": superCollection.id, "code": data.code, "name": data.code ,"label":data.code , "value": data.id})
                        console.log("collection",productOrganizationList) 
                   }
                }
              })
          }
        })
      })
    }

    console.log("productOrganizationListproductOrganizationList",productOrganizationList)
    setProductOrganizationList(productOrganizationList)
  },[collectionData, types])


  const onCreateProduct = () =>{
    
    const productDetailsError= validateProductDetails(productDetails);
    const mediaDetailsError = validateMediaDetails(mediaDetails);
    const validateVariantError = validateVariantDetails(variantData);
    const validatePricingError = validatePricingDetails(pricingDetails);
    const validateInventoryError = validateInventoryDetails(inventoryManagement);
    const validateFaqError = validateFaqDetails(faqData);
    const validateReviewError = validateReviewDetails(reviewData);
    const validateSeoUrlError = validateSeoUrlDetails(productSeoUrl)
   
    const validateSubscriptionError=subscriptionStatus?validateSubscription(subscriptionData,subscriptionStatus):{};
    console.log("productDetailsError",productDetailsError)
    console.log("validateSubscriptionError",validateSubscriptionError);
    if(Object.keys(productDetailsError).length > 0 || Object.keys(mediaDetailsError).length > 0 || Object.keys(validateVariantError).length > 0 || Object.keys(validatePricingError).length > 0
    // ||Object.keys(validateInventoryError).length > 0
    ||Object.keys(validateFaqError).length > 0
    // ||Object.keys(validateReviewError).length > 0 
    || Object.keys(validateSubscriptionError).length>0 || validateSeoUrlError ){
      setProductDetailsError(productDetailsError);
      setMediaDetailsError(mediaDetailsError);
      setvariantDetailsError(validateVariantError);
      setPricingError(validatePricingError);
      setInventoryManagementError(validateInventoryError)
      setFaqError(validateFaqError);
      setReviewError(validateReviewError);
      setSubscriptionError(validateSubscriptionError);
      setSeoUrlError(validateSeoUrlError);
    }else{
      createPayloadForSave()
    }


  }

  const onUpdateProduct = () => {
    onCreateProduct()
  }
  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });


  useEffect(()=>{
    if(editProductData && Object.keys(editProductData).length > 0){
      console.log("editProductDataeditProductData",editProductData)
      const {available="", ingredients="",benifits="",description={} , defaultImages=[],newVariants=[], faqs=[],reviews=[] , quantity=0, sku="",barcode="",freqBoughtTogether=[] , ranks=[], seoUrl={}, productPrices=[],subscriptionItems=[],enableSubscription,
      avgRating="",totalRating="",availableQuantity=0,commitedQuantity=0,tags="",ingredientsArabic="",benifitsArabic="",productSeoUrl="", bestSellerTag=false
      } = editProductData || {};
      console.log("available",available)
      let editProductDetails = {
        id:description.id || "",
        title:description.name,
        description:description.description,
        ingrediants:ingredients,
        benefits:benifits,
        shortDescription:description.shortDescription,
        titleArabic:description.nameArabic,
        descriptionArabic:description.descriptionArabic,
        benefitsArabic:benifitsArabic,
        ingrediantsArabic:ingredientsArabic,
        shortDescriptionArabic:description.shortDescriptionArabic,
      }



      let editVariant = newVariants.map((data)=>{
        return(
          {
            "id":data?.variants?.id || "",
            "productId":data?.variants.productId || "",
            "variantImg": data.variants.image,
            "vaiantName": data.variants.name,
            "qty": data.variants.availableQuantity,
            "status": data.variants.isActive,
            "variantSku": data.variants.variantSku,
            "variantBarcode":data.variants.variantBarcode,
            "recommended":data.variants.recommended
        }
        )
      })
      console.log("editVariant",editVariant)
      const uniqueVariants = [];
      const variantNamesSet = new Set();

      editVariant.forEach(item => {
       
    
        if (!variantNamesSet.has(item.vaiantName)) {
         
          variantNamesSet.add(item.vaiantName);
          uniqueVariants.push(item);
        }
      });

      console.log(uniqueVariants);

      let variantCountryPricing = [];
      newVariants.map((data,index)=>{
            data.pricings.map((pricingData,index)=>{
              variantCountryPricing.push({
                id:data.variants.id,
                priceId:pricingData.id,
                countryId:pricingData.countryId,
                discount: (pricingData && pricingData.discount && ((pricingData.discount).toString())) || 0,
                finalPrice:pricingData.finalPrice,
                listPrice: pricingData.retailPrice,
                qty: data.variants.availableQuantity,
                status: true,
                vaiantName: data.variants.name,
                variantImg: data.variants.image,
                variantSku: data.variants.variantSku,
                variantBarcode:data.variants.variantBarcode,
                
              })
            })
      })
      console.log("variantCountryPricing",variantCountryPricing)
      // console.log("variantCountryIdsvariantCountryIds",variantCountryIds)

      let editFrequentlyBoughtItems = [];

      console.log("listOfProductlistOfProduct",listOfProduct)

      
      if(freqBoughtTogether && freqBoughtTogether.length > 0){
        freqBoughtTogether.map((boughtTogether,i)=>{
          listOfProduct.map((data,index)=>{
            if(data.value == boughtTogether){
              editFrequentlyBoughtItems.push(data)
            }
          })
        })
      }


      // let  data = {
      //   "BRAND":brand.filter((data)=>data.vale,
      //   "GENERAL":[],
      //   "HEALTH GOAL":[]
      // }


      


     

      console.log("editVarianteditVariant",editVariant)

      let editMediaDetails = defaultImages.map((data) => ({
        id:data?.id ||"",
        image: data?.image,
        isDefault: data?.isDefault
      }));
      let editPricingDetails = []
 
      productPrices?.map((data,index)=>{
        const seoData = seoUrl.find((seoData,index)=>seoData.country == data.country);
        const variantPricingData = variantCountryPricing.filter((variantCountry,index)=>variantCountry.countryId == data.country);

        console.log("variantPricingDatavariantPricingData++",variantPricingData)
        editPricingDetails.push(
          {
            status:data.is_Active,
            id: data.id,
            countryId:data.country,
            listPrice:data.productPriceAmount,
            discount:(data.productPriceAmount - data.productPriceSpecialAmount).toString() ,
            finalPrice:data.productPriceSpecialAmount,
            pageTitle:seoData?.metaTitle,
            description:seoData?.metaDescription,
            urlHandle:seoData?.seoUrl,
            variantData:variantPricingData,
            seoId: seoData?.id,
            keyword:seoData?.keyword,
            pageTitleArabic:seoData?.metaTitleArabic,
            descriptionArabic:seoData?.metaDescriptionArabic,
            keywordArabic:seoData?.keywordArabic
          }
        )
      })

      console.log("editPricingDetails",editPricingDetails)
     

      let editInventoryManagement = {
        "handQty": quantity,
        "skuId": sku,
        "barcode":barcode,
        "availableQuantity":availableQuantity,
        "commitedQuantity":commitedQuantity
     }

     let editProductStatus = {}

   
      let editReviewData = reviews.map((data)=>{
        console.log("editReviewDataeditReviewData",data)
        return(
          {
            "customerName": data.customerName,
            "headline": data.headLine,
            "rating": data.rating,
            "review": data.reviewBody,
            "status": data.isActive,
            "id":data.id,
            "customerNameArabic": data.customerNameArabic,
            "headlineArabic": data.headlineArabic,
            "reviewArabic": data.reviewBodyArabic,
            "countryId":Number(data.countryId),
        }
        )
      })

      let editSubscriptionData=subscriptionItems.map((data)=>{
        return({
          "id":data.id,
          "status":data.status=="true"?true:false,
          "noOfMonths": data.noOfMonths,
          "discountType": data.discountType,
           "discountAmount": data.discountAmount,
                    "rank": data.rank,
                    
                    "productId": productId,
                    "createdAt": data.createdAt,
                    "modifiedAt": data.modifiedAt

        })
      })

      let editFaqData = faqs.map((data)=>{
        console.log("editFaqDataedit",data)
        return({
          answer:data.answer,
          question:data.question,
          isActive:data.isActive,
          id:data.id,
          questionArabic:data.questionArabic,
          answerArabic:data.answerArabic
        })
      })



      let editProductOrganizeData = {

      }
    if(collectionData && collectionData.length > 0 && types && types.length > 0){
      collectionData.map((data,index)=>{
        editProductOrganizeData[data.name] = []
      })
      ranks.map((rank,index)=>{
        
        types.map((type,index)=>{
          if(type && type.superCollection && type.superCollection.length > 0){
                if(rank.productId == type.id ){
                  console.log("typesuperCollection",type.superCollection)
                  type['superCollection'].map((superCollection,index)=>{
                    editProductOrganizeData[superCollection.name].push({ "id": type.id, "code": type.code, "name": type.code ,"label":type.code , "value": type.id})
                  })
                }
          }
        })
      })
    }

      setProductDetails(editProductDetails);
      setMediaDetails(editMediaDetails);
      setVariantData(uniqueVariants);
      setInventoryManagement(editInventoryManagement)
      setFaqData(editFaqData)
      setReviewData(editReviewData)
      setFrequentlyBought(editFrequentlyBoughtItems)
      setProductOrganization(editProductOrganizeData)
      setPricingDetails(editPricingDetails)
      setProductStatus((available)?"Active":"Inactive")
      setSubscriptionStatus(enableSubscription)
      setSubscriptionData(editSubscriptionData)
      setAvgRating(avgRating);
      setTotalRating(totalRating);
      setTags(tags);
      setProductSeoUrl(productSeoUrl);
      setBestSellerTag(bestSellerTag);

    }
  },[editProductData,listOfProduct,types])

  /* Edit flow */ 
  const getProductData = () =>{
    const urlstr = `/api/v1/product/${productId}?country=1`;
    try {
      API.get(urlstr).then((res) => res.data).then((data) => {
        console.log("Datata++++",data);
        setEditProductData(data)
      });
     } catch (error) {
      console.log("fetchProducts:: Error on fetching products!", error);
    }

  }
  const redirectToBack = ()=>{
    window.location.href = `/admin/dashboard/products`
  }

  const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);

const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });


  return (
    <>
          <div style={{ alignSelf: "flex-start" }}>
            <Button
              onClick={redirectToBack}
              color="inherit"
              size="large"
            >
              <ArrowBackIcon />
            </Button>
            <span
          className="mx-3 my-2"
          style={{ fontWeight: "600", fontSize: "1.25rem" }}
        >
          Add Product
        </span>
        </div>
    <div className="fullpage">
      <div className="firstblock">
        <ProductDetails productDetails={productDetails} setProductDetails={setProductDetails} productDetailsError={productDetailsError} setProductSeoUrl={setProductSeoUrl} productSeoUrl={productSeoUrl} seoUrlError={seoUrlError} setBestSellerTag={setBestSellerTag} bestSellerTag={bestSellerTag}/>
        <Media mediaDetails={mediaDetails} setMediaDetails={setMediaDetails} mediaDetailsError={mediaDetailsError} />
        <Card body>
        <InventoryManagement  inventoryManagement={inventoryManagement} setInventoryManagement={setInventoryManagement} inventoryManageError={inventoryManageError}/>
        <VariantManagement mediaDetails={mediaDetails} variantData={variantData} setVariantData={setVariantData} variantDetailsError={variantDetailsError} inventoryManagement={inventoryManagement} />
        </Card>
        <PricingOnCountryLevel inventoryManagement={inventoryManagement} pricingDetails={pricingDetails} countryData={countryData} variantData={variantData} setPricingDetails={setPricingDetails} pricingError={pricingError} />
         <Subscription subscriptionData={subscriptionData} setSubscriptionData={setSubscriptionData} subscriptionStatus={subscriptionStatus} setSubscriptionStatus={setSubscriptionStatus} subscriptionError={subscriptionError}/>
        
        <Faq faqData={faqData} setFaqData={setFaqData} faqError={faqError} />
        <Reviews reviewData={reviewData} setReviewData={setReviewData} reviewError={reviewError} avgRating={avgRating} setAvgRating={setAvgRating} totalRating={totalRating} setTotalRating={setTotalRating} countryData={countryData}/>
      </div>
      <div className="secondblock">
        <ProductStatus setProductStatus={setProductStatus} productStatus={productStatus} />
        <FrequentlyBoughtItems listOfProduct={listOfProduct} frequentlyBought={frequentlyBought}  setFrequentlyBought={setFrequentlyBought} />
        <ProductOrganization productOrganizationList={productOrganizationList}  collectionData={collectionData} setProductOrganization={setProductOrganization} productOrganization={productOrganization}/>
        <div className="searchTagDiv">
          <label className="tagTxt" for="tags">Search Tags</label>
          <input type="text" id="tags" value={tags} onChange={(e)=>setTags(e.target.value)}/>
        </div>
      </div>
      <div className="footer-btn-container">
        {
          productId ? <div className="save-btn" onClick={onUpdateProduct}>Update</div> :  <div className="save-btn" onClick={onCreateProduct}>Save</div>
        }
       
      </div>

     
    </div>
    </>
  )
}


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);

