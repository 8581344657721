import React,{useState,useEffect} from "react";
import { useParams,Link } from "react-router-dom";
import API from "../../utils/API";
import { setAccessToken } from "../../services/tokenService";
import { connect } from "react-redux";
 function Emailpassword(props){
    const[password,setPassword]=useState();
    const[email,setEmail]=useState();
    const[login,setLogin]=useState(false);
    const[userdetails,setUserdetails]=useState({})
     const params=useParams();
     const[token,setToken]=useState()

     const showSuccessNotification = (notificationMessage) =>
     showNotification(notificationMessage, true);
     
     const showErrorNotification = (notificationMessage) =>
     showNotification(notificationMessage, false);
     
     const showNotification = (notificationMessage, isSuccessMessage) =>
     props.showNotificationMessage({
       notificationMessage,
       successMessage: isSuccessMessage,
       showNotification: true,
     });

     const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
       else if(value=="undefined")
       emptyval=true;
      return emptyval;
      }




  useEffect(()=>{
    
    if(login){
        try{
            
            
      API.post("/api/v1/private/login",userdetails).then((response)=>response.data).then((data)=>{setToken(data.token);setAccessToken(data.setAccessToken);showSuccessNotification("successfully login")});
       
    }
        catch(error){
            console.log("login ", error);
            showErrorNotification("Error on  create account " + error.message ?? "")
        }
    }
        setLogin(false);   
   },[userdetails])

   const handlelogin=(e)=>{
    
    setLogin(true);
    setUserdetails({"password":password,
    "username":params.mailid})
   }
   const handlepassword=(e)=>{
    setPassword(e.target.value);
   }

    return(
        <div className="signup">

      <div className="shopifysignup">
       <div className="Namestyle"><b>Kuwa Admin</b> </div>
       <div> <div className="createsen">Login </div>
       <div className="subhead">Continue to Kuwa Admin Account</div>
       </div>
    <div className="alignEnd">
        <div>{params.mailid}</div>
        <div> <Link to="/admin/Login" >Change Email</Link></div>
    </div>

    <div>
        <div>Password</div>
        <div> <input className="Emailinput inputpadding" type="password" onChange={handlepassword} /></div>
    </div>
    <div>
        <button className="emailcontinue Emailinput" onClick={handlelogin}>Log&nbsp;in</button>
    </div>
       </div>
       </div>
    )
}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Emailpassword);