import React ,{useState , useEffect } from 'react'
// import { Card, Modal, } from 'react-bootstrap';
import "react-toggle/style.css"
import { connect } from "react-redux";
import Toggle from 'react-toggle';
import API from "../../utils/API"
import Select from 'react-select';
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import CartPageCollectionModal from './CartPageCollectionModal';
import CartPageProductModal from './CartPageProductModal';
import "./cartPageProducts.scss"


const CartPageProducts = (props) => {
  const [countries, setCountries] = useState([]);
  const [selectCountry, setSelectCountry] = useState({ label: 'Bahrain', value: 8,id:8 });
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [productToDelete, setProductToDelete] = useState({});
  const [sortColumn, setSortColumn] = useState("productId");
  const[sortDirection,setSortDirection]=useState("asc");
  const [isShowProductModal,setIsShowProductModal] = useState(false);
  const [isShowCollcetionModal,setIsShowCollectionModal] = useState(false)
  const [allActiveProducts,setAllActiveProducts] = useState([]);
  const [allActiveCollections,setAllActiveCollections] = useState([])
  const [cartProducts,setCartProducts] = useState([]);
  const [heading,setHeading] = useState("");
  const [headingArabic,setHeadingArabic] = useState("");
  const [cartPageId,setCartPageId] =useState("")
  const [selectedCollection,setSelectedCollection] = useState([])
  const [errors,setErrors] = useState({})

  const columns  = [
    {
      name: "",
      cell: () => <DragIcon className="drag-handle"></DragIcon>,
      width: "50px",
    },
    {
      name: "ProductID",
      selector: "productId",
      sortable: true,
      width: "132px",
    },
    {
      name: "VariantID",
      selector: "variantId",
      sortable: true,
      width: "132px",
    },
    {
      name: "Product Name",
      width: "151px",
      cell: (row) => {
        return row.variantName ? `${row.productName} pack of ${row.variantName}` : row.productName;
      },
      // selector: "productName",
      // sortable: true,
      wrap: true,
    },
    {
      name: "Rank",
      width: "84px",
      selector: "rank",
      // maxWidth: "100px",
      // center: true,
      sortable: "true",
    },
    {
      name: "Actions",
      width: "90px",
      // maxWidth: "100px",
      center: true,
      allowOverflow: true,
      cell: (product) => renderActionBar(product),
    },
  ]

  const hideConfirmDeleteModal = () => {
    setIsConfirmDeleteModalVisible(false);
  }
  const showConfirmDeleteModal = () => {
    setIsConfirmDeleteModalVisible(true);
  }

  const renderActionBar = (dealProduct) => {
    return (
      <ActionBar alignRight className="action-menu valeo-dropdown">
        <ActionBar.Toggle variant="">
          <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
        </ActionBar.Toggle>
        <ActionBar.Menu
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
          }}
        >
          <ActionBar.Item
            onClick={() => {
              setProductToDelete(dealProduct.productId);
              showConfirmDeleteModal();
            }
            }
          >
            <DeleteIcon className="icon-small" style={{ fill: "red" }} />
            <span style={{ color: "red" }}>Delete</span>
          </ActionBar.Item>
        </ActionBar.Menu>
      </ActionBar>
    )

  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      updateRank(fromIndex, toIndex);
    },
    nodeSelector: ".rdt_TableRow",
    handleSelector: ".drag-handle",
    enableScroll: true,
  }
  const updateRank = async (fromIndex, toIndex) => {
    const updatedProducts = [...cartProducts];
    const movedProduct = updatedProducts.splice(fromIndex, 1)[0];
    updatedProducts.splice(toIndex, 0, movedProduct);
  
    updatedProducts.forEach((product, index) => {
      product.rank = index + 1;
    });
  
  
    setCartProducts(updatedProducts);
  };

  const deleteCartProduct = (productIdToDelete) => {
    const updatedDealProducts = cartProducts.filter(product => product.productId !== productIdToDelete);
    let updatedProductsWithRanks=[];
     updatedDealProducts.map((product, index) =>{
       let item=  {
         ...product,
         rank: index + 1,
       }
        updatedProductsWithRanks.push(item);
   
     })
    setCartProducts(updatedProductsWithRanks);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await API.get("api/v1/active/countries");
        setCountries(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchCountries();
  }, []);

  const countryOptions = countries.map(country => ({
    label: country.name,
    value: country.id,
    id: country.id
  }));


  const fetchAllProducts = async()=>{
    try{
      showLoading()
      let timeoutConfig = { timeout: 60000 };
      const res = await API.get(`/api/v1/cart-page/admin/search?searchKey=${""}&countryId=${selectCountry.id}`,timeoutConfig);
      if(res.status == 200){
        hideLoading();
        setAllActiveProducts(res?.data);
        setIsShowProductModal(true);
        
      }
      else{
        hideLoading();
      }
    }
    catch(err){
      hideLoading();
      console.log(err);
    }
  }

  const handleAddNewProduct = async() => {
    fetchAllProducts();
  }

  const fetchAllCollections = async()=>{
    try{
      showLoading()
      const res = await API.get(`/api/v1/deals/collections/search?searchKey=${""}&countryId=${selectCountry.id}`);
      console.log("collectionsData",res)
      if(res.status == 200){
        hideLoading();
        setAllActiveCollections(res?.data);
        setIsShowCollectionModal(true);
      }
      else{
        hideLoading();
      }
    }
    catch(err){
      hideLoading();
      console.log(err);
    }
  }
  

  const handleAddNewCollection = () =>{
    fetchAllCollections();
  }

  const handleCollectionSelect = async (collectionData = []) => {
    setIsShowCollectionModal(false);
    console.log("collectionData", collectionData);

    const allProducts = [];
    const ranks = cartProducts?.length > 0 ? cartProducts.map((it) => it.rank) : [];
    const addedProductIds = new Set(cartProducts.map(product => product.variantId || product.productId)); 
    const successfullyAddedProducts = [];
    const alreadyAddedProducts = [];

    collectionData.forEach((collection) => {
        const { productDtoList } = collection;

        productDtoList.forEach((product) => {
            if (product.variantList && product.variantList.length > 0) {
                product.variantList.forEach((variant) => {
                    if (!addedProductIds.has(variant.variantId)) {
                        let productData = {
                            productId: variant.productId,
                            variantId: variant.variantId,
                            productName: variant.variantName,
                            rank: ranks.length > 0 ? Math.max(...ranks) + 1 : 1,
                        };

                        allProducts.push(productData);
                        successfullyAddedProducts.push(variant);
                        ranks.push(productData.rank);
                        addedProductIds.add(variant.variantId);
                    } else {
                        alreadyAddedProducts.push(variant);
                    }
                });
            } else {
                if (!addedProductIds.has(product.productId)) {
                    let productData = {
                        productId: product.productId,
                        productName: product.productName,
                        rank: ranks.length > 0 ? Math.max(...ranks) + 1 : 1,
                    };

                    allProducts.push(productData);
                    successfullyAddedProducts.push(product);
                    ranks.push(productData.rank);
                    addedProductIds.add(product.productId);
                } else {
                    alreadyAddedProducts.push(product);
                }
            }
        });
    });

    if (successfullyAddedProducts.length > 0) {
        showSuccessNotification(`Products were added successfully`);

        setTimeout(() => {
            if (alreadyAddedProducts.length > 0) {
                const productIds = alreadyAddedProducts.map(product => product.variantId || product.productId).join(', ');
                showErrorNotification(`Error: The following products are already added ${productIds}`);
            }
        }, 5000);
    } else if (alreadyAddedProducts.length > 0) {
        const productIds = alreadyAddedProducts.map(product => product.variantId || product.productId).join(', ');
        showErrorNotification(`Error: The following products are already added ${productIds}`);
    }

    console.log("All products", allProducts);
    setCartProducts([...cartProducts, ...allProducts]);
};




const handleProductSelect = (productData = []) => {
  setIsShowProductModal(false);
  const allProducts = [];
  const ranks = cartProducts?.length > 0 ? cartProducts.map((it) => it.rank) : [];
  const addedProductVariantIds = new Set(cartProducts.map(product => `${product.productId}-${product.variantId || 'no-variant'}`)); // Track already added product-variant combinations
  const successfullyAddedProducts = [];
  const alreadyAddedProducts = [];

  productData.forEach((product) => {
      const productVariantKey = `${product.productId}-${product.variantId || 'no-variant'}`;
      if (!addedProductVariantIds.has(productVariantKey)) {
          if (product.variantId) {
              let variantData = {
                  productId: product.productId,
                  variantId: product.variantId,
                  productName: product.variantName,
                  rank: ranks.length > 0 ? Math.max(...ranks) + 1 : 1,
              };
              allProducts.push(variantData);
              successfullyAddedProducts.push(product);
              addedProductVariantIds.add(productVariantKey);
              ranks.push(variantData.rank);
          } else {
              let productData = {
                  productId: product.productId,
                  productName: product.productName,
                  rank: ranks.length > 0 ? Math.max(...ranks) + 1 : 1,
              };
              allProducts.push(productData);
              successfullyAddedProducts.push(product);
              addedProductVariantIds.add(productVariantKey);
              ranks.push(productData.rank);
          }
      } else {
          alreadyAddedProducts.push(product);
      }
  });

  if (successfullyAddedProducts.length > 0) {
      showSuccessNotification(`Products were added successfully`);

      setTimeout(() => {
          if (alreadyAddedProducts.length > 0) {
              const productIds = alreadyAddedProducts.map(product => product.productId).join(', ');
              showErrorNotification(`Error: The following products are already ${productIds}`);
          }
      }, 5000);
  } else if (alreadyAddedProducts.length > 0) {
      const productIds = alreadyAddedProducts.map(product => product.productId).join(', ');
      showErrorNotification(`Error: The following products are already added  ${productIds}`);
  }

  setCartProducts([...cartProducts, ...allProducts]);
};



const getCartProducts = async()=>{
  try{
    showLoading()
    const res = await API.get(`/api/v1/cart-page/admin/all?countryId=${selectCountry.id}`);
    if(res.status == 200){
      hideLoading();
     const data = res.data[0];
     console.log("cart Products",data)
     if(data){
      const {heading,headingArabic,countryId,id} = data.cartPage
      setHeading(heading);
      setHeadingArabic(headingArabic);
     //  setSelectCountry(countryId);
      setCartProducts(data.cartPageProductVariantList)
      setCartPageId(id)
     }
     else{
      setHeading("");
      setHeadingArabic("");
      setCartProducts([]);
      setCartPageId("")
     }
     }
   
    else{
      hideLoading();
    }
  }
  catch(err){
    hideLoading();
    console.log(err);
  }
}

useEffect(()=>{
  getCartProducts();
},[selectCountry])

const handleValidationError = () =>{
  let newError = {};
  if(!heading){
    newError.heading = 'Heading is required';
  }
  if(!headingArabic){
    newError.headingArabic = 'Heading Arabic is required';
  }
  setErrors(newError);
  return Object.keys(newError).length === 0;
}

const handleSaveCartProducts = async()=>{
  const validate = handleValidationError();
  if(validate){
    const payload = {
      "cartPage":{
        id:cartPageId,
        heading:heading,
        headingArabic:headingArabic,
        countryId:selectCountry.id,
      },
      "cartPageProductVariantList":cartProducts
    }
    if(cartPageId){
      try{
        showLoading();
        const data =await  API.put('api/v1/cart-page/admin/all',payload)
        if(data.status== 200){
          showSuccessNotification("Cart Products updated Successfully")
          getCartProducts()
        }
      }
      catch(err){
        hideLoading()
        console.log(err)
      }
    }
    else{
      try{
        showLoading();
        const data = await API.post('api/v1/cart-page/admin/all',payload);
        if(data.status== 200){
          showSuccessNotification("Cart Products Created Successfully")
          getCartProducts()
        }
       }  
       catch(err){
        hideLoading()
         console.log(err);
       }
    }
  }
 
}
  const showLoading = () => props.toggleLoading({ isLoading: true });
  
  const hideLoading = () => props.toggleLoading({ isLoading: false });

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  return (
    <>
    <div className='cartPageProducts'>
     <div className='alignrow'>
     <div className='columnalignactive'>
              <div className='group'>
                <label className='labelTitle' for="heading">Heading*</label>
                <input name="heading" type="text" placeholder="Heading" value = {heading} onChange={(e)=>setHeading(e.target.value)}/>
                {errors.heading && <span style={{ fontSize: '12px', color: 'red' }}>{errors.heading}</span>}
              </div>
            </div>
            <div className='columnalignactive'>
              <div className='group'>
                <label className='labelTitle' for="heading">Heading (Arabic)*</label>
                <input name="heading" type="text" placeholder="Heading" value={headingArabic} onChange={(e)=>setHeadingArabic(e.target.value)}/>
                {errors.headingArabic && <span style={{ fontSize: '12px', color: 'red' }}>{errors.headingArabic}</span>}
              </div>
            </div>
            <div className='selectCountryDiv'>
            <div className='selectCountry'>
          <label>Select Country*</label>
          <Select
            onChange={(countryOptions) => {
              setSelectCountry(countryOptions);
            }}
            value={selectCountry}
            options={countryOptions}
          />
        </div>
        </div>
        <div className='saveProductBtn' onClick={handleSaveCartProducts}>Save</div>
      </div>

      <div className="container px-0 py-4">
        <div className="row">
          <div className="form-group col">
            <Card style={{paddingTop:"10px"}}>
              <ReactDragListView {...dragProps}>
                <div className="" style={{ display: "flex", backgroundColor: "#F1FAF8", paddingBottom: "10px" }}>
                  <button
                    style={{

                      marginLeft: "24px",
                      width: "200px",
                      cursor: "pointer",
                    }}
                    onClick={handleAddNewProduct}
                    className="greenbackground"
                  >
                    Add Product
                  </button>
                  <button
                    style={{

                      marginLeft: "24px",
                      width: "200px",
                      cursor: "pointer",
                    }}
                    onClick={handleAddNewCollection}
                    className="greenbackground"
                  >
                    Add Collection
                  </button>
                </div>
                <DataTable
                  highlightOnHover
                  columns={columns}
                  data={cartProducts ?? []}
                  onSort={(sortColumn, sortDirection) => {

                    setSortColumn(sortColumn.selector);
                    setSortDirection(sortDirection);
                  }
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                // sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>

            </Card>
          </div>
        </div>
        <Modal
          show={isConfirmDeleteModalVisible}
          onHide={hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                deleteCartProduct(productToDelete);
                hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
{isShowProductModal && <CartPageProductModal setIsShowProductModal={setIsShowProductModal} isShowProductModal={isShowProductModal} selectCountry={selectCountry} allActiveProducts={allActiveProducts} handleProductSelect={handleProductSelect}/>}
{isShowCollcetionModal && <CartPageCollectionModal isShowCollcetionModal ={isShowCollcetionModal} setIsShowCollectionModal={setIsShowCollectionModal} allActiveCollections={allActiveCollections} handleCollectionSelect={handleCollectionSelect} selectCountry={selectCountry} setSelectedCollection={setSelectedCollection} selectedCollection={selectedCollection}/>}
      </div>
    </>
   
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPageProducts);