import React, { useState, useEffect } from "react";
import Select from "react-select";
import API from "../../src/utils/API";
import { connect } from "react-redux";

 function CollectionComponent(props) {
    const { name, resflag,responseId,brands,brandFun, brandNameFun } = props;
    console.log("brand name", name);
    const [categoryInputvalue, setCategoryInputValue] = useState("");
    const [selectedoption, setSelectedoption] = useState([]);
    const [categoryDetails, setCategorydetails] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showtextarea, setShowtextarea] = useState(false);
    const [selectedvals, setSelectedvals] = useState([]);
    const [savecoll, setSaveColl] = useState(false);
    const [collectionid, setcollectionid] = useState([]);
    const [superCollectionDetails, setSuperCollectionDetails] = useState([]);
    const [brandId, setBrandId] = useState({});
    const [healthId, setHealthId] = useState({});
    const[generalId,setGeneralId]=useState({});
    const [healthTypeCollection, setHealthTypeCollection] = useState([]);
    const defaultoption = { "value": { name }, "label": { name } }
    const [brandTypeCollection, setBrandTypeCollection] = useState([]);
    const [listAllCollections, setListAllCollections] = useState([]);
    const [finalCollectionIds, setFinalCollectionIds] = useState([]);
    const [finalCollectionNames, setFinalCollectionNames] = useState([]);
    const[finalProds,setFinalProds]=useState([]);
    const[prods,setProds]=useState({});
    const[putprod,setPutProd]=useState([]);
    const[collectionDetails,setCollectionDetails]=useState({});
    
    const[finalCollDetails,setFinalCollDetails]=useState([]);
    const[ finalSendingPayload,setFinalSendingPayload]=useState([]);
    const[count,setCount]=useState(0);
    const[finalCount,setFinalCount]=useState(0);
   const[collFlag,setCollFlag]=useState(false);
    const[validationError,setValidationError]=useState({"collection":""});
    console.log("name is",name);
    useEffect(() => {
        try {
            API.get("module/super/collection").then((res) => (res.data)).then((data) => setSuperCollectionDetails(data.map((coll) => { return ({ "id": coll.id, "name": coll.name }) })));
        
            API.get("api/v1/product/types").then((res) => res.data).then((data) => data.list).then((list) => setListAllCollections(list));

        }
        catch (error) {
            console.log("error is", error)

        }
    }, [])

    useEffect(() => {
        if (superCollectionDetails.length > 0) {
            console.log("supercoll", superCollectionDetails);
            setBrandId(superCollectionDetails.filter((supercollection) => supercollection.name == "BRAND")[0]);
           
            setHealthId(superCollectionDetails.filter((supercollection) => supercollection.name == "HEALTH GOAL")[0]);
            setGeneralId(superCollectionDetails.filter((supercollection) => supercollection.name == "GENERAL")[0])
        }

    }, [superCollectionDetails])
    const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);
  
  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);
  
  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  



    useEffect(() => {
        if (savecoll) {

            console.log("selected cat", selectedoption);
            setFinalCollectionIds(selectedoption.map((coll) => coll.value));
            setFinalCollectionNames(selectedoption.map((coll) => coll.label));
            showSuccessNotification("collections saved");
        }
    }

        , [savecoll])

    useEffect(() => {
        if (finalCollectionIds.length > 0) {
            
            console.log("finalCollectionIds",finalCollectionIds)
            finalCollectionIds.map((coll)=>{
                API.get(`/api/v1/private/product/type/${coll}`).then((res) =>res.data).then((data)=> {
                    setCollectionDetails({...collectionDetails,"collid":coll,"data":data});
                     setProds({...prods,"collid":coll,"prods":data.products[0].product});
                     console.log("data is",data);
                                    })
            });
           
           setCollFlag(true);
         
        }
        setSaveColl(false);
    }, [finalCollectionIds])

    useEffect(()=>{
        if(prods.collid){
            setFinalProds([...finalProds,prods]);
            console.log("in first",finalProds);
        }

    },[prods])
    useEffect(()=>{
      if(Object.keys(collectionDetails).length>0){
        console.log("coll",finalCollDetails,collectionDetails);
        setFinalCollDetails([...finalCollDetails,collectionDetails]);}
    },[collectionDetails])


     const minRank=(arr)=>{
        let min;
        console.log("arr",arr);
        min=arr.length>0?arr[0]:1;

        for(let i=0;i<arr.length;i++){
           
            
            if(min>arr[i+1]){
                min=arr[i+1];
                
            }
        }
        console.log("min",min);
        return min;
    }
     
  


     useEffect(()=>{
        if(finalProds.length==finalCollectionIds.length && finalProds.length>0){
            console.log("finalprods",finalProds);
           brandFun( finalProds.map((coll)=>{return({"productId":coll.collid,"rank":minRank(coll.prods.map((prod)=>prod.rank))==null?1:minRank(coll.prods.map((prod)=>prod.rank))})}));
           brandNameFun(finalCollectionNames);
           setPutProd(finalProds.map((coll)=>{return({"collid":coll.collid,"prod":coll.prods.map((prod)=>{
            return({"productId": prod.id,
            "rank": prod.rank==null?1:prod.rank+1,
            
        })
    }
           )})}))
        }
     },[finalProds])

     useEffect(()=>{
        
        if( finalCollDetails.length== finalCollectionIds.length && putprod.length==finalCollectionIds.length && putprod.length>0){
            console.log("finalcollputprod",finalCollDetails,putprod,brands);
  
           setFinalSendingPayload( finalCollDetails.map((coll)=>{return({ "collid":coll.collid,"payload":{"code": coll.data.code,
           "visible": coll.data.visible,
           "allowAddToCart": coll.data.allowAddToCart,
           "descriptions":[{
            "language": coll.data.description.language,
            "name": coll.data.description.name,
            "friendlyUrl": coll.data.description.friendlyUrl,
            "keyWords": coll.data.description.keyWords,
            "highlights": coll.data.description.highlights,
            "metaDescription":coll.data.description.metaDescription,
            "title": coll.data.description.title,
            "image": coll.data.description.image
        }],
            "superCollections":coll.data.superCollections,
            "ranks":putprod.filter((prod)=>prod.collid==coll.collid)[0].prod
        }

        })}))

           
            
        }

     },[finalCollDetails,putprod])
      useEffect(()=>{
       if(finalSendingPayload.length==finalCollectionIds.length && finalSendingPayload.length>0){
        if(resflag){
        finalSendingPayload.map((coll)=>API.put(`api/v1/private/product/type/${coll.collid}`,coll.payload).then((res)=>{console.log(res);setCount(count+1)}));
        }   

    }

      },[finalSendingPayload,resflag])
useEffect(()=>{
    if(count>0){
    console.log("finalcount");
    setFinalCount(finalCount+1);}
},[count])
useEffect(()=>{
    console.log("checking",finalCount);
    let validCollection=false;
    if(finalCount>0){

if(finalCount==finalCollectionIds.length && validCollection && finalCount>0 && resflag && brands.length==finalCollectionIds.length && brands.length>0){
    console.log("checking",finalCount);
    console.log("brands are",brands);
    
    //API.post(`/module/super/collection/type/${responseId}`,brands ).then((res) => console.log(res));
}
}
}
,[finalCount])

    useEffect(() => {
        if (listAllCollections.length > 0) {
            if (name == "Brandnames")
                setBrandTypeCollection(listAllCollections.filter((coll) => coll?.superCollection[0]?.id == brandId.id));
            if (name == "HealthGoals")
                setBrandTypeCollection(listAllCollections.filter((coll) => coll?.superCollection[0]?.id == healthId.id));
            if(name=="General"){
                console.log("name,generalId",name,listAllCollections)
            setBrandTypeCollection(listAllCollections.filter((coll) => coll?.superCollection[0]?.id == generalId.id));
            }
        }
    }, [listAllCollections])

    useEffect(() => {
        if (brandTypeCollection.length > 0) {
            console.log("brandtypecoll", brandTypeCollection);
            setCategorydetails(brandTypeCollection.map((category) => { return ({ "id": category.id, "code": category.code, "name": category.code }) }))
        }
    }, [brandTypeCollection])




    useEffect(() => {
        if (categoryDetails) {
            console.log("category det", categoryDetails);
            setCategories(categoryDetails.map((category) => { return ({ "value": category.id, "label": category.code }) }));

        }
    }, [categoryDetails])

    useEffect(() => {
        if (categoryInputvalue == "") {
            setCategories(categoryDetails.map((category) => { return ({ "value": category.id, "label": category.code }) }));
        }
        else {
            setCategories([...categories.filter((category) => { return (category.label.includes(categoryInputvalue)); })]);
        }
    }, [categoryInputvalue]);

    const handleInputCategoryChange = (value, e) => {
        if (e.action === "input-change") {
            console.log(value);
            console.log(categoryDetails);
            console.log(categories);
            setCategoryInputValue(value);
        }

    }

    const handleSelectedoption = (obj) => {
        console.log(obj);



    }
    const handlecollectionsave = (e) => {
        console.log("save coll");
        setSaveColl(true);
        
    }


    return (
        <div className="prodselect">
            <div> Add {name}</div>
            <Select
                isMulti
                name={name}
                options={categories}
                className="basic-multi-select"
                classNamePrefix="select"
                oncloseMenuOnSelect={false}

                
               
                onChange={setSelectedoption}

                style={{ width: "100%" }}
            />
            <br />
            <div> <input className="donebtn" type="button" value="save" onClick={handlecollectionsave} disabled={collFlag} /></div>
            <div><span>{validationError.collection}</span></div>
        </div>
    )
}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CollectionComponent);