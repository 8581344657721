import './spinner.scss';

const Spinner = ({isShow=false}) => {
    if(!isShow)
    return
     
    return(
        <div className="spinnerWrapper" >
            <div className="spinner"></div>
        </div> 
    )
}

export default Spinner;

  