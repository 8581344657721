import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import moment from "moment";
import API from '../../../src/utils/API';
import TableConfig from '../../utils/tableConfig';

const RatingsAndReviews = (props) => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [rowsPerPage, setRowPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortField, setSortField] = useState('id');

  const fetchAllReview = async () => {
    try {
      showLoading();
      const data = await API.get('api/v1/review/all');
      if (data.status === 200) {
        hideLoading();
        setReviews(data.data);
      } else {
        hideLoading();
        console.log("Error in fetching the data");
      }
    } catch (error) {
      hideLoading();
      console.log("Error fetching all reviews", error);
    }
  };

  const handleReview = async (review) => {
    const updatedStatus = review.status === "APPROVED" ? "UNAPPROVED" : "APPROVED";
    const updatedReview = { ...review, status: updatedStatus };
    console.log("review", updatedReview);

    try {
      const data = await API.put('api/v1/review', updatedReview);
      if (data.status === 200) {
        showSuccessNotification("Review updated successfully")
        fetchAllReview();
      } else {
        console.log("Error in updating review status");
      }
    } catch (error) {
      console.log("Error in updating review", error);
    }
  };

  useEffect(() => {
    fetchAllReview();
  }, []);

  const columns = [
    {
      name: "ReviewId",
      selector: "id",
      sortField: "reviewId",
      minWidth: "80px",
      maxWidth: "150px",
      sortable: true,
    },
    {
      name: "ProductId",
      selector: "productId",
      sortField: "id",
      minWidth: "130px",
      maxWidth: "150px",
      sortable: true,
    },
    {
      name: "Email Id",
      selector: "customerEmail",
      width: "200px",
    },
    {
      name: "Review Date",
      selector: "modifiedAt",
      width: "150px",
      cell: (row) => getFormattedPurchasedDate(row.modifiedAt),
    },
    {
      name:<div>Verification <br /> Status</div> ,
      selector: "verified",
      width: "130px",
      style: {
        whiteSpace: 'pre-wrap',
      },
      cell: (row) => row.verified ? "Verified" : "Non-Verified",
    },
    {
      name: "Country",
      selector: "countryName",
      maxWidth:"200px",
      minWidth: "100px",
    },
    {
      name: <div>Quality of<br />Supplements</div>,
      selector: "qualityOfSupplements",
      style: {
        whiteSpace: 'pre-wrap',
      },
    },
    {
      name: "Effectiveness",
      selector: "effectiveness",
      width: "140px",
    },
    {
      name: <div>Ease of<br />Use</div>,
      selector: "easeOfUse",
      style: {
        whiteSpace: 'pre-wrap',
      },
    },
    {
      name: <div>Value for<br />Money</div>,
      selector: "valueForMoney",
      style: {
        whiteSpace: 'pre-wrap',
      },
    },
    {
      name: <div>Delivery<br />experience</div>,
      selector: "deliveryExperience",
      style: {
        whiteSpace: 'pre-wrap',
      },
    },
    {
      name: <div>Overall<br />Rating</div>,
      selector: "rating",
      sortable: true,
      style: {
        whiteSpace: 'pre-wrap',
      },
    },
    {
      name: <div>Review<br />Title</div>,
      selector: "headLine",
      style: {
        whiteSpace: 'pre-wrap',
      },
      cell:(row)=> renderReviewTitle(row.headLine)
    },
    {
      name: "Review Content",
      selector: "reviewBody",
      style: {
        whiteSpace: 'pre-wrap',
      },
      width: "200px",
      cell: (row) => renderReviewBody(row.reviewBody),
    },
    {
      name: "Status",
      selector: "status",
      width: "130px",
      cell: (row) => renderStatus(row.status),
    },
    {
      name: "",
      cell: (row) => renderReviewBtn(row),
    }
  ];

  const renderReviewTitle = (reviewTitle) =>{
    return (
      <div style={{
        maxHeight: "60px",
        overflowY: "auto",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
      }}>
        {reviewTitle}
      </div>
    )
  }

  const renderReviewBody = (reviewBody) => {
    return (
      <div style={{
        maxHeight: "60px",
        overflowY: "auto",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
        textOverflow: "ellipsis",
      }}>
        {reviewBody}
      </div>
    );
  };

  const renderStatus = (status) => {
    const statusStyle = {
      color: status === "APPROVED" ? "rgb(53, 195, 89)" : "rgb(255, 0, 0)",
      fontWeight: "700"
    };
    return <div style={statusStyle}>{status}</div>;
  };
  const getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  const renderReviewBtn = (review) => {
    const isApproved = review.status === "APPROVED";
    return (
      <div
        style={{
          backgroundColor: isApproved ? "#247A81" : "rgb(36, 122, 129)",
          color: "rgb(255, 255, 255)",
          padding: "4px 8px",
          cursor: "pointer",
          width:"200px",
          textAlign: "center"
        }}
        onClick={() => handleReview(review)}
      >
        {isApproved ? "Unapprove" : "Approve"}
      </div>
    );
  };

  const handleSort = (column, sortDirection) => {
    setCurrentPage(0);
    setSortField(column.sortField);
    setSortDirection(sortDirection);
  };

  const onChangePageClick = (page) => {
    setCurrentPage(page - 1);
  };

  const onChangeRowsPerPage = (currentRowsPerPage) => {
    setRowPerPage(currentRowsPerPage);
  };

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  const showLoading = () => props.toggleLoading({ isLoading: true });
  const hideLoading = () => props.toggleLoading({ isLoading: false });

  return (
    <div>
      <Card>
        <DataTable
          highlightOnHover
          sortServer
          columns={columns}
          data={reviews}
          defaultSortField="id"
          defaultSortAsc={false}
          onSort={handleSort}
          onChangePage={onChangePageClick}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={recordsTotal}
          paginationPerPage={rowsPerPage}
          paginationServerOptions={{
            totalPages: totalPages,
            totalRecords: recordsTotal,
            currentPage: currentPage,
          }}
          paginationRowsPerPageOptions={TableConfig.ROWS_PER_PAGE_OPTIONS}
          pagination
          paginationServer
          responsive
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer',
              },
            },
          }}
        />
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingsAndReviews);
