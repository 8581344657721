import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Card, Tab, Tabs } from 'react-bootstrap';
import { Button, Divider, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import API from "../../../utils/API"
import Spinner from "../../../components/Spinner/Spinner"
import Select from "react-select";
// import SelectCountry from '../../../components/select/SelectCountry';
// import { useLocation } from 'react-router-dom';
import { useParams,useHistory} from "react-router-dom";
import "./category.scss"
const BASE_URL_COUNTRIES = "/api/v1/active/countries";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    label: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    label: "Inactive",
  },
]

const CreateArticleCategory = (props) => {
  const [articleCategories,setArticleCategories] = useState([])
  const [articleCategory, setArticleCategory] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({})
  const [rankOptions, setRankOptions] = useState([])
  const [view, setView] = useState(false)
  const params = useParams();


  const categoryId = params.id


  const handleImageUpload = (e) => {
    const image = e.target.files[0];
    const articleCategorydummy = {...articleCategory,image}
    setArticleCategory(articleCategorydummy)
    if (image) {
      setImageFile(image)
      console.log("imageFile",imageFile)
    }
    readImage(image)
  };
  const readImage = (image)=>{
    const reader = new FileReader();
    reader.onloadend = function () {
      const articleCategorynew= {
        ...articleCategory,
        image: [reader.result],
      };
    
    setArticleCategory(articleCategorynew)}.bind(this)
    reader.readAsDataURL(image);
  }

  useEffect(()=>{
    const getArticleCategories = async()=>{
      showLoading();
      try{
       const data = await API.get(`/health-hub-category`)
       showLoading();
       if(data.status === 200){
        hideLoading()
        const res = data.data
        setArticleCategories(res)
        // createRankOptions(res);
       }
       else{
        hideLoading();
        console.log("Error in fetching the data")
       }
      
    
      }
      catch{
        hideLoading()
      }
     }
     getArticleCategories()
     if(categoryId){
      setView(true)
      viewArticleCategory();
      }
  },[])

const viewArticleCategory = async() =>{
    showLoading();
    try{
      const data =await API.get(`health-hub-category/${categoryId}`)
      const res = data.data;
      const propertyToDelete = 'healthHub';
    
    // Check if the property exists before attempting to delete it
    if (res.hasOwnProperty(propertyToDelete)) {
      delete res[propertyToDelete];
    }
      
      
      setArticleCategory(res)
    }
    catch{

    }
  }

  useEffect(()=>{
    createRankOptions()
  },[articleCategories])

    
  const createRankOptions = () => {
   
    const ranks =  articleCategories
      .filter((it) => it.rank)
      .map((it) => it.rank);
     
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    
    const until = view? maxRank: maxRank + 1;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, label: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, label: 1 });
    console.log("Rank Options: ", rankOptions);
    
    setRankOptions(rankOptions)
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const category = articleCategory;
    category[name] = value;
   
    setArticleCategory(
      (prevArticleCategory) => ({
      ...prevArticleCategory,
      [name]: value,
    })
  //  {...category}

    );
  };

  const validate = (articleCategory)=>{
    const errors = {};
    const {categoryNameEnglish,rank,status,seoUrl,metaTitle,metaDescription,keywords,image} = articleCategory||{}
   
    if (!categoryNameEnglish || categoryNameEnglish.trim() === "")
    errors.name = "Name is a required field!";
  
  
  if (rank==undefined || rank==="")
    errors.rank = "Rank is a required field!";
    
    
  if (status==undefined || status==="")
    errors.status = "Status is a required field!";
    

  if (!image) errors.image = "Image cannot be blank!";
  // else if (image && image.size && image.size > TWO_MEGA_BYTE)
  //   errors.image = "Image should be less than 2 MB!";

  if (!seoUrl || seoUrl.trim() === "")
    errors.seoUrl = "Seo URL is a required field!";
  else if (seoUrl.length > 100)
    errors.seoUrl = "Seo URL can not contain more than 100 characters!";

  if (metaTitle && metaTitle.length > 100)
    errors.metaTitle = "Title can not contain more than 100 characters!";

  if (metaDescription && metaDescription.length > 200)
    errors.metaDescription =
      "Description can not contain more than 200 characters!";

  if (keywords && keywords.length > 500)
    errors.keywords = "keywords can not contain more than 500 characters!";
     setErrors(errors);
  return Object.keys(errors).length === 0 ? null : errors;

  }


  const handleSave = async () => {
    const errors = validate(articleCategory);
  
    if (errors) {
      showErrorNotification("There are some invalid form fields!");
      return;
    }
    const payload = { ...articleCategory};
    delete payload["imageSrc"];
  
    let formData = new FormData();
    const apiEndpoint = view ? `health-hub-category/${categoryId}` : "health-hub-category";
    const method = view ? "PATCH" : "POST";
    if (typeof articleCategory.image !== "string") {
      delete payload["image"];
      formData.append("image", imageFile);
    }
  
    formData.append("health_hub_cat", JSON.stringify(payload));
    console.log("payload",payload)
  
    try {
      const response = await (method === "PATCH" ? API.patch : API.post)(apiEndpoint, formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          // "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      });
  
      if (response.status==200)
      {
        (method=="PATCH" ? showSuccessNotification("ArticleCategory is update successfully") : showSuccessNotification("ArticleCategory is created successfully"))
        props.history.goBack();
        const responseData = await response.json();
      
       
        console.log("Response Data:", responseData);
      }  else
      {
        console.error("Request failed with status:", response.status);
      }
    } 
    catch (error) {
      console.error("Error:", error);
    }
  };
  
  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });
  

  

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
   

    
  return (
    <>
      <div style={{ alignSelf: "flex-start", display: 'flex', gap: '10px', }}>
        <Button
          onClick={() => props.history.goBack()}
          variant="outlined"
          color="inherit"
          size="large"
        >
          <ArrowBackIcon />
        </Button>
         {view ? <h5>Category</h5> : <h5>Create Category</h5> }
        <div className="col text-end">
          <Button

            variant="contained"
            size="large"
            color="success"
            onClick={handleSave}
            sx={{
              bgcolor: "#247A81",
              color: "white",
              borderRadius: "20px",
              ":hover": {
                bgcolor: "#125443",
              
              },
              textTransform: "capitalize",
            }}
          >
            {view ? "Update" : "Save"}
            
          </Button>
        </div>
      </div>
      <br /><br />
      <Card body>
      <div className='container mt-4'>
      <div className='categoryDetails' >
            <div className='row' style={{width:"70%"}}>
              <div className='form-group col'>
                <label for="name" >Name *</label>
                <div className='input-group'>
                  <input className='col-6 '
                    type="text"
                    id="name"
                    name="categoryNameEnglish"
                    placeholder='name'
                    onChange={handleChange}
                    value={articleCategory.categoryNameEnglish}
                  /> 
                  
                 
                </div>
                {errors.name && <span className="help-block categoty-error" style={{display:"block"}}>{errors.name}</span>} 
              </div>
            </div>


            <div className='row mt-4'>
              <div className='form-group col-3' style={{width:"18%"}}>
                <label htmlFor='rank' >Rank *</label>
                <div className='' style={{marginTop:"10px",}}>
                  <Select
                 
                    value={rankOptions.filter((article)=>articleCategory.rank==article.value)[0]}
                    onChange={(selectoption)=>{
                     
                   
                      setArticleCategory(
                        (prevArticleCategory) => ({
                        ...prevArticleCategory,
                        rank:selectoption.value
                       
                      })
                      )

                    }}
                    id="rank"
                    placeholder="Rank"
                    options={rankOptions}
                  />
                  </div>
             
                {errors.rank && <span className="help-block" style={{display:"block"}}>{errors.rank}</span>} 
              </div>
              {/* <div className=''> */}
              <div className='form-group col-3' style={{width:"18%" }}>
                <label htmlFor="status">Status *</label>
                <div className='' style={{marginTop:"10px"}}>
                  <Select
                    value={STATUS_OPTIONS.filter((article)=>articleCategory.status==article.value)[0]}
                    onChange={(selectoption)=>{
                      const dummyObj=articleCategory;
                   
                   
                      setArticleCategory(
                        (prevArticleCategory) => ({
                        ...prevArticleCategory,
                        status:selectoption.value
                       
                      })
                      )

                    }}
                    id="status"
                    name="status"
                    placeholder="Status"
                    options={STATUS_OPTIONS}
                  />
                  
                </div>
                {errors.status && <span className="help-block" style={{display:"block"}}>{errors.status}</span>} 
              </div>
              {/* </div> */}
              <div className='form-group col-3' style={{marginLeft:"14%"}}>
                <div> <b>Image*</b></div><br />

                <div className="imagecountryblock" style={{width:"100%"}} >
                  <div style={{ backgroundColor: "#247A81", color: "#FFFFFF", fontSize: "12px" }}>
                    <label htmlFor="imageInput" style={{cursor:"pointer"}}> {articleCategory.image ? <img src="https://d25uasl7utydze.cloudfront.net/assets/edit.svg" /> : "+Image"}
                      <input style={{ display: "none",width:"100%" }}
                        id="imageInput"
                        name="image"
                        type="file"
                        className="variantbutton"
                        accept="image/*"
                        onChange={handleImageUpload} ></input>

                    </label>
                  </div> 
                   
                  {articleCategory.image &&  (
                    <img
                     src={articleCategory.image}
                      alt="Selected Image"
                      style={{ width: '40%', height: 'auto' }}
                    />
                    
                  )}
                </div>
                {errors.image && <span className="help-block" style={{display:"block"}}>{errors.image}</span>}

              </div>
            </div>



      </div>

      <div className='marketingField'>

            <div className='container mt-4'>
              <div><b> SEO Marketing</b></div>
              <div className="container mt-4">
              <div className="row">
                <div className="" style={{width:"50%"}}>
                  <label htmlFor="seoUrl">Seo URL*</label>
                 < div className="input-group " style={{width:"70%"}}>
                          <input
                          style={{width:"100%"}}
                            value={articleCategory.seoUrl}
                            onChange={handleChange}
                            id="seoUrl"
                            name="seoUrl"
                            type="text"
                            placeholder="Seo URL"
                          />
                          
                        </div>
                        {errors.seoUrl && <span className="help-block" style={{display:"block"}}>{errors.seoUrl}</span>} 
                </div>
                <div className="form-group col">
                  <label htmlFor="metaTitle">Meta Title</label>
                  <div className="input-group">
                          <input
                           style={{width:"70%"}}
                            value={articleCategory.metaTitle}
                            onChange={handleChange}
                            id="metaTitle"
                            name="metaTitle"
                            type="text"
                            placeholder="Meta Title"
                          />
                        </div>
                </div>
              </div>
              <div className="row mt-2">
              <div className="form-group col">
                  <label htmlFor="keywords">Keywords</label>
                  <div className="input-group">
                          <textarea
                          style={{width:"70%"}}
                            value={articleCategory.keywords}
                            onChange={handleChange}
                            id="keywords"
                            name="keywords"
                            placeholder="Keywords"
                          />
                        </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="metaDescription">Meta Description</label>
                  <div className="input-group">
                          <input
                           style={{width:"70%"}}
                            value={articleCategory.metaDescription}
                            onChange={handleChange}
                            id="metaDescription"
                            name="metaDescription"
                            type="text"
                            placeholder="Meta Description"
                          />
                        </div>
                </div>
                
              </div>
            </div>
       
      
            
         


          

            </div>

      </div>
      </div>
      </Card>
      <Spinner isShow={isLoading} />

    </>
  )
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateArticleCategory);