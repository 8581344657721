import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import  VariantViewNew  from "./VariantViewNew";
import  CreateVariant  from "./CreateVariant";
import { EditVariant } from "./EditVariant";
import { Modal } from "react-bootstrap";
import { popoverClasses } from "@mui/material";
import { Button } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


export function VariantComponent(props) {
  const { id, mode, add, addfun,variant,variantFun ,variantImages,popupFlag, popFun} = props;
  const [variantname, setvariantname] = useState();
  const [variantquantity, setvariantquantity] = useState();
  const [variantstatus, setvariantstatus] = useState(true);
  const [variantdetails, setvariantdetails] = useState();
  const [donestatus, setdonestatus] = useState(false);
  const [change, setchange] = useState(false);
  const [variantgetdetails, setVariantgetdetails] = useState([]);
  const [imageurl, setImageUrl] = useState();
  const[product,setProduct]=useState();
  const[variantModalPopup,setVariantModalPopup]=useState(true)
  const[deleteFlag,setDeleteFalg]=useState(false);
  const[temporaryVariant,setTemporaryVariantImage]=useState([]);
  const[borderImageIds,setBorderImageIds]=useState([]);
 const[selectedImages,setSelectedImages]=useState([]);
  
  useEffect(() => {
    try {
      
      API.get("api/v1/products").then((res) => res.data).then((data) => data.products).then((products)=>setProduct(products.filter((prod)=>prod.id==id)[0]));
      
    }
    catch (error) {
      console.log(error);
    }


  }, [add])
   
  useEffect(() => {
    if(deleteFlag){
    try {
      
      API.get("api/v1/products").then((res) => res.data).then((data) => data.products).then((products)=>setProduct(products.filter((prod)=>prod.id==id)[0]));
      
    }
    catch (error) {
      console.log(error);
    }
    setDeleteFalg(false);
  }

  }, [deleteFlag])



  useEffect(()=>{
    if(product!=null){
      setVariantgetdetails(product.newVariants);
      variantFun(product.newVariants.map((variant)=>variant.variants));
    }

  },[product])


  useEffect(() => {
    if (donestatus) {
      setvariantdetails(
        {
          "name": variantname,
          "quantity": variantquantity,
          "status": variantstatus,
          "image": "1997-04-04",
          "price": 234.0,
          "productId": 12,
          "createdAt": "1997-03-04T00:00:00",
          "modifiedAt": "1997-09-09T00:00:00"
        }
      );
      setchange(true);
      addfun(false);
    }

  }, [donestatus])

  useEffect(() => {
    if (change) {
      try {
        API.post("/module/variants", variantdetails).then((res) => console.log(res));
      }
      catch (error) {
        console.log("error", error);
      }



    }
  }, [variantdetails])

  const hideVariantModal=()=>{
    popFun(false);
  }
  const handlevariantname = (e) => {
    setvariantname(e.target.value);
  }
  const handlevariantquantity = (e) => {
    setvariantquantity(e.target.value)
  }
  const handlevariantstatus = (e) => {
    setvariantstatus(e.target.checked)
  }
  const handlevariantdetails = (e) => {
    setdonestatus(true);

  }
  const handleDeleteVariantImage=(img)=>{
    const temp=temporaryVariant.filter((image)=>img!==image);
    setTemporaryVariantImage(temp);
  }
  const handleImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      console.log(URL.createObjectURL(e.target.files[0]));
    }
  }
  const handleEditSelect=(img,id)=>{
    console.log("ids for select",img,id,borderImageIds);
    if(temporaryVariant.includes(img)){
      const images=temporaryVariant.filter((image)=>img!==image)
    setTemporaryVariantImage(images);
    }
    else{

   setTemporaryVariantImage([...temporaryVariant,img]);
    }
   if(borderImageIds.includes(id)){
    const selectedImageIds=borderImageIds.filter((imageId)=>imageId!==id);
    setBorderImageIds(selectedImageIds);
   }
    
   else{
   setBorderImageIds([...borderImageIds,id]);
   }
  }
  
  const handleVariantEditSaveImages=()=>{
    setSelectedImages([...temporaryVariant]);
  }
  
  const variantEditForm=(<>
    <div className="gridalign">
      { variantImages.map((img,index)=><div onClick={()=>handleEditSelect(img,index)}><img className="imageshowsizeborder" style={(borderImageIds.includes(index)?{border:'1px solid blue'}:{border:'1px solid black'})} src={img}   /><Button  className="aligniconborder"  onClick={ ()=>handleDeleteVariantImage(img)}> <HighlightOffIcon  fontSize="small"    /> </Button> </div> )}
      </div>
      <div><input type="button" className="newvaraintbutton"  value="Save" onClick={handleVariantEditSaveImages}/></div>
   
    </>)
     

  return (<>
    { mode == "View"  && add ?
        [variantgetdetails.map((variant) => { return (<VariantViewNew prodid={variant.variants?.productId} image={variant.variants?.image} id={variant.variants?.id} name={variant.variants?.name} qty={variant.variants?.quantity} status={variant.variants?.isActive}  editall ={variant} editallfun={variantFun} deletefalg={setDeleteFalg} imagesView={variantImages} />) }),
        <Modal
        size="lg"
       show={popupFlag}
       onHide={hideVariantModal}
     >
         <Modal.Header closeButton>
             <Modal.Title className="aligncenter">
                 <div>Variant Image</div> 
             </Modal.Title>
         </Modal.Header>
         <Modal.Body>{variantEditForm}</Modal.Body>


     </Modal>,
           
       selectedImages.map((img)=>{return( <CreateVariant mode={"EditCreate"} prodid={id} addfun={addfun} imagesView={variantImages} imgname={img} />)})
        ]

    
    
    
    :mode == "View" ?variantgetdetails.map((variantdet) => {
      return (
        <VariantViewNew prodid={variantdet.variants?.productId} image={variantdet.variants?.image} id={variantdet.variants?.id} name={variantdet.variants?.name} qty={variantdet.variants?.quantity} status={variantdet.variants?.isActive}  deletefalg={setDeleteFalg} imagesView={variantImages}  />)

    })
      

        : mode == "Edit" && variantgetdetails ?
          variantgetdetails.map((variant) => { return (<EditVariant prodid={variant.productId} image={variant.image} id={variant.id} name={variant.name} qty={variant.quantity} />) })



          

            
            : <></>

    }

  </>

  )

}