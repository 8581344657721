import React, { useState, useEffect } from "react";
import API from "../../src/utils/API";
import { connect } from "react-redux";

 function VariantPriceEditCreate(props) {
    
    const{mode,countryId, id, productId, allVarPrices}=props

    const [variantname, setVariantname] = useState();
    const [variantList, setVariantList] = useState();
    const [variantDiscount, setVariantDiscount] = useState();
    const [variantFinal, setVariantFinal] = useState();
    const[variantDet,setVariantDet]=useState([]);
    const[specificVariant,setSpecificVariant]=useState([]);
    const[variantSpecificPrice,setVariantSpecificPrice]=useState([]);
    const[editDetails,setEditDetails]=useState();
    const[editFlag,setEditFlag]=useState(false);
    const[validationError,setValidationError]=useState({});
    const[priceFlag,setPriceFlag]=useState(false);
    const [variantprice, setVariantprice] = useState({
        "countryId": "", "name": "", "retailPrice": 0, "discount": 0, "finalPrice": 0
    })
    const handleVariantname = (e) => {
        setVariantname(e.target.value);
    }

    useEffect(()=>{
        const urlstr =  `/api/v1/product/${productId}?country=1`;
        API.get(urlstr).then((res)=>res.data).then((data)=>data.newVariants).then((variant)=>setVariantDet(variant.map((vardet)=>vardet.variants)));

    },[])

    const handleSave=(e)=>{
       setSpecificVariant( variantDet.filter((variant)=>variant.name==variantname));
       setPriceFlag(true);
     
    }
    useEffect(()=>{
        if(specificVariant.length>0){
        console.log("specific variant",specificVariant)
        setVariantSpecificPrice(allVarPrices.filter((finalVariant)=>finalVariant.pricing
    .variantId==specificVariant[0].id ));
        
     }
    },[specificVariant])

    const isEmpty = (value) => {
        let emptyval=false;
       if(typeof(value) ==="undefined")
        emptyval=true;
       else if(value === "")
       emptyval=true;
       else if( value === null )
       emptyval=true;
      return emptyval;
      }

    const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);
    
    const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);
    
    const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });



    useEffect(()=>{
        if(specificVariant.length>0 && priceFlag){
     const error  =validateAll(variantname,variantDiscount,variantFinal,variantList);
     setValidationError(error);
     if(Object.keys(error).length==0){
        if(priceFlag && variantSpecificPrice){

            console.log("variant specific price",variantSpecificPrice);
        setEditDetails({"variants":{"name":specificVariant[0]?.name,
        "quantity": specificVariant[0]?.quantity,
    "image": specificVariant[0]?.image,
    "price": 234,
    "productId": productId,
    "createdAt": "1997-03-04T00:00:00",
    "modifiedAt": "1997-09-09T00:00:00",
    "isActive":true,
    },
    "pricings":[variantSpecificPrice.map((price)=>{
        return(
        {"id":price.pricing.id,
        "countryId":price.pricing.countryId,
        "retailPrice":price.pricing.retailPrice,
        "finalPrice":price.pricing.finalPrice,
        "discount":price.pricing.discount,
        "variantId":price.pricing.variantId})}),
        {"countryId":countryId,
        "retailPrice":parseInt(variantList),
        "finalPrice":parseInt(variantFinal),
        "discount":parseInt(variantDiscount),
        "variantId":specificVariant[0]?.id}].flat()

    }
    )
}
    setPriceFlag(false);
    setEditFlag(true);
}
  } },[specificVariant])

    useEffect(()=>{
        if(editFlag && editDetails){
            try{ 

                 console.log("Edit details",editDetails,specificVariant[0]?.id);
                API.patch(`/module/variants/${specificVariant[0]?.id}`,editDetails).then((res)=>console.log(res));
                }
                catch(error)
                {
                    console.log("error",error);
                }
              setEditFlag(false);  
            }

        

    },[editDetails])

    const validateAll=(name,Discount,Final,List)=>{
        const validationError={}
         if(isEmpty(name))
         validationError.name="variant name is required field";
         if(isEmpty(Discount))
         validationError.discount="Discount is required field";
         if(isEmpty(Final))
         validationError.final="Final is required field";
         if(isEmpty(List))
            validationError.list ="List is required field";        
       return validationError;
    }

    const handleVariantDisc = (e) => {
        setVariantDiscount(e.target.value);
    }
    const handleVariantFinal = (e) => {
        setVariantFinal(e.target.value);
    }

    const handleVariantList = (e) => {
        setVariantList(e.target.value);
    }

    return (
        <div className="alignrow variantpriceblock">
        <div className="columnalignactive">            <div className="group"><label for="name">Variant Name</label>
            <input type="number" placeholder="variant name" value={variantname} onChange={handleVariantname} />
               </div>
               <span className="help-block">{validationError.name}</span>
            </div>   
            <div className="columnalignactive"  > <div className="group"><label for="name">List Price</label>
             <input type="number" style={{ width: "7em" }} value={variantList} onChange={handleVariantList} placeholder="list price" />
            </div>
            <span className="help-block">{validationError.list}</span>
            </div>

            <div className="columnalignactive"> <div className="group"><label for="name">Discount</label>
                <input type="number" style={{ width: "7em" }} value={variantDiscount} onChange={handleVariantDisc} placeholder="discount price" />
            </div>
            <span className="help-block">{validationError.discount}</span>
            </div>
            <div> <div className="group"><label for="name">Final Price</label>
                <input type="number" style={{ width: "7em" }} value={variantFinal} onChange={handleVariantFinal} placeholder="final price" />
            </div>
            <span className="help-block">{validationError.final}</span>
            </div>
            <div><input className="donebtn" type="button" value="Save" onClick={handleSave} /></div>
        </div>
    )



}
function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(VariantPriceEditCreate);