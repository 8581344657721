import React, {useEffect, useState } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import API from "../../../utils/API"
import _ from "lodash";
import ProductSearchModal from "./ProductSearchModal";

const ProductSection = (props) => {
  const {products,setProducts} = props
  const [productData, setProductData] = useState({});
  const [isAddEditModalVisible,setIsAddEditModalVisible]=useState(false);
  const [isConfirmDeleteModalVisible,setIsConfirmDeleteModalVisible]=useState(false);
  const [productToDelete,setProductToDelete] = useState({});
  const [errors,setErrors] = useState({});
  const [sortColumn,setSortColumn] = useState("productId");
  const [sortDirection,setSortDirection]=useState("asc");
  const [showProductsModal,setShowProductsModal] = useState(false);
  const [allProducts,setAllProducts] = useState([])

  const columns = [
    {
      name: "",
      cell: () => <DragIcon className="drag-handle"></DragIcon>,
      width: "50px",
    },
    {
      name: "ProductID",
      selector: "id",
      sortable: true,
      width: "132px",
    },
    {
      name: "ProductName",
      selector: "name",
      sortable: true,
      width: "400px",
    },
    {
      name: "Rank",
      width:"84px",
      selector: "rank",
      // maxWidth: "100px",
      // center: true,
      sortable: "true",
    },
    {
      name: "Actions",
      width:"90px",
      // maxWidth: "100px",
      center: true,
      allowOverflow: true,
      cell: (product) =>  renderActionBar(product),
    },
  ]
  const hideConfirmDeleteModal = () =>{
    setIsConfirmDeleteModalVisible(false);
  }
   const showConfirmDeleteModal = ()=>{
     setIsConfirmDeleteModalVisible(true);
   }

   const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      updateRank(fromIndex, toIndex);
    },
    nodeSelector: ".rdt_TableRow",
    handleSelector: ".drag-handle",
    enableScroll: true,
  }

  const updateRank = async (fromIndex, toIndex) => {
    const updatedProducts = [...products];
    const movedProduct = updatedProducts.splice(fromIndex, 1)[0];
    updatedProducts.splice(toIndex, 0, movedProduct);
  
    updatedProducts.forEach((product, index) => {
      product.rank = index + 1;
    });
  
  
    setProducts(updatedProducts);
  };


  const renderActionBar = (product)=>{
    return(
      <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
            <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
          </ActionBar.Toggle>
          <ActionBar.Menu
            popperConfig={{
              strategy: "fixed",
              onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
            }}
          >
            {/* <ActionBar.Item onClick={() => handleEdit(dealProduct.productId)}>
              <EditIcon className="icon-small" />
              Edit
            </ActionBar.Item> */}
            <ActionBar.Item
              onClick={() =>{
                setProductToDelete(product.id);
                showConfirmDeleteModal();
              }
              }
            >
              <DeleteIcon className="icon-small" style={{ fill: "red" }} />
              <span style={{ color: "red" }}>Delete</span>
            </ActionBar.Item>
          </ActionBar.Menu>
  </ActionBar>
    )
   
  };

  const deleteProduct = (productIdToDelete) => {
    const updatedProducts = products.filter(product => product.id !== productIdToDelete);
    updatedProducts.forEach((product, index) => {
      product.rank = index + 1;
    });
    setProducts(updatedProducts);
    showSuccessNotification("Product deleted successfully")
  };



  const handleAddNew = ()=>{
    setShowProductsModal(true);
  }
  const handleProductSelect = async (data) => {
    const existingProductIds = products.map(product => product.id);
  
    const newProducts = [];
    const duplicateProducts = [];
  
    data.forEach(item => {
      if (existingProductIds.includes(item.id)) {
        duplicateProducts.push(item);
      } else {
        newProducts.push({
          id: item.id,
          name: item.name,
          rank: 0, 
        });
      }
    });
  
    if (duplicateProducts.length > 0) {
      const duplicateNames = duplicateProducts.map(product => product.name).join(', ');
      showErrorNotification(`This products are already added: ${duplicateNames}`);
    }
  
    if (newProducts.length === 0) {
      setShowProductsModal(false);
      return;
    }
  
    console.log("selectedProductData", newProducts);
  
    setShowProductsModal(false);
  
    const existingRanks = products?.length > 0 ? products.map(it => it.rank) : [];
    const highestRank = existingRanks.length > 0 ? Math.max(...existingRanks) : 0;
  
    // Assign rank to each new product
    newProducts.forEach((product, index) => {
      product.rank = highestRank + index + 1;
    });
  
    setProducts(prevProducts => [...prevProducts, ...newProducts]);
  };
  
  console.log("productTable",products)
  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

 const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

 const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  return (
    <>
    <div className="container px-0">
                  <div className="row">
                    <div className="form-group col">
                      <Card>
                        <ReactDragListView {...dragProps}>
                          <div className="" style={{display:"flex",justifyContent:"space-between",backgroundColor: "#F1FAF8",paddingBottom:"10px"}}>
                        <button
                        style={{
                          
                          marginLeft:"24px",
                          width:"200px",
                          cursor:"pointer",
                        }}
                          onClick={handleAddNew}
                          className="greenbackground"
                        >
                          Add Product
                        </button> 
                          </div>                        
                          <DataTable
                            highlightOnHover
                            columns={columns}
                            data={products ?? []}
                            onSort={(sortColumn, sortDirection) =>{
                    
                              setSortColumn( sortColumn.selector);
                              setSortDirection(sortDirection);
                            }
                          }
                            pagination
                            responsive
                            defaultSortField={sortColumn}
                            // sortIcon={<ArrowDownward></ArrowDownward>}
                          />
                        </ReactDragListView>
                      
                      </Card>
                    </div>
                  </div>
                  <Modal
                    show={isConfirmDeleteModalVisible}
                    onHide={hideConfirmDeleteModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Delete Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete?</Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-secondary rounded-pill px-4 py-1"
                        onClick={hideConfirmDeleteModal}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          deleteProduct(productToDelete);
                          hideConfirmDeleteModal();
                        }}
                        className="btn btn-danger rounded-pill px-4 py-1"
                      >
                        Yes
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <ProductSearchModal showProductsModal={showProductsModal} setShowProductsModal={setShowProductsModal} handleProductSelect ={(data)=>{handleProductSelect(data)}}/>
                </>
             
  )
}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductSection);