import React,{useState,useEffect} from "react";
import { Card } from 'react-bootstrap';
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import ArchiveIcon from '@mui/icons-material/Archive';
import ActionBar from "react-bootstrap/Dropdown";
import DeleteIcon from "@mui/icons-material/Delete"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Dropdown } from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css'
import _ from "lodash";
export default function SubscriptionForm({subscriptionData=[],setSubscriptionData={},index,isEditable}){
      const[subscription,setSubscription]=useState({});
      const[isAddEditModalVisible,setIsAddEditModalVisible]=useState(false);
      const[isConfirmDeleteModalVisible,setIsConfirmDeleteModalVisible]=useState(false);
      const[errors,setErrors]=useState({});
      const[editables,setEditables]=useState({});
      const[sortDirection,setSortDirection]=useState("asc");
      const[sortColumn,setSortColumn]=useState("id");
     const[subscriptionToDelete,setSubscriptionToDelete]=useState({});
      const[subscriptions,setSubscriptions]=useState(subscriptionData);
     const handleAddNewSubscription=()=>{
        const subscription = { status: false };
        setSubscription(subscription);
                 setIsAddEditModalVisible(true);
      }
      const clearForm = () => {
        setErrors({});
        setSubscription({});
      };
      const hideAddEditModal=()=>{
        setIsAddEditModalVisible(false);
        clearForm();
      }

      const numberArray = Array.from({ length: 12 }, (_, index) => index + 1);

      const selectedSubscriptionFrequencies = subscriptions.length>0?subscriptions.map(
        (subscription) => subscription.noOfMonths
      ):[];
      const SUBSCRIPTION_FREQUENCY_OPTIONS = numberArray.map(
        (number) => ({
          key: number,
          value: number,
          text: number > 1 ? `${number} Months` : `${number} Month`,
        })
      );
   

    const  onSubscriptionUpdatedLocally = (subscriptionData) => {
        //clearForm();
        console.log("subcriptiondata",subscription);
        console.log("subscriptiondata",subscriptionData);
        const newSubscriptiondata=  subscriptionData.map((subscription) => {
            const q = { ...subscription };
            if (q.is_new) {
              delete q.id;
              delete q.is_new;
            }
            return q;
          })
          console.log("newsubscription",newSubscriptiondata.length);
        setSubscriptionData(newSubscriptiondata.map((data)=>data));
        
      };

    const  deleteSubscription = (id) => {
        
        const subscriptionToDelete = subscriptions.find((it) => it.id === id) || {};
        const newSubscriptions = subscriptions
          .filter((it) => it.id !== id)
          .map((it) =>
            it.rank > subscriptionToDelete.rank ? { ...it, rank: it.rank - 1 } : it
          );
        // setSubscriptionData( newSubscriptions );
        setSubscriptions(newSubscriptions);
         // this.showSuccessNotification("Subscription Deleted Successfully!");
          onSubscriptionUpdatedLocally(newSubscriptions);
      
      }

    const  toggleStatus = (id) => {
        
        const newSubscriptions = subscriptions.map((it) =>
          it.id === id
            ? { ...it, status: it.status? false : true }
            : it
        );
        setSubscriptions(newSubscriptions.map((data)=>data ));
        console.log("subscriptiondata status",newSubscriptions);
         // showSuccessNotification("Subscription Updated Successfully!");
          onSubscriptionUpdatedLocally(newSubscriptions);
        
      };

    const  handleStatusChange = (id) => toggleStatus(id);
    const showAddEditModal = () => {
      setIsAddEditModalVisible(true);
    };
    const hideConfirmDeleteModal = () => {
      setIsConfirmDeleteModalVisible(false);
    };
   const  showConfirmDeleteModal = () => {
      setIsConfirmDeleteModalVisible(true) ;
    };

     const  handleEdit = (id) => {
       // clearForm();
        console.log("id is ",id);
        
        const subscriptionEdit = subscriptions.find((it) => it.id === id);
      
        if (!subscriptionEdit) return; 
        
        else {
        setSubscription(subscriptionEdit);
          showAddEditModal();}
      };

      const STATUS_OPTIONS = [
        {
          key: "Active",
          value: true,
          text: "Active",
        },
        {
          key: "Inactive",
          value: false,
          text: "Inactive",
        },
      ];

      const DISCOUNT_TYPE_OPTIONS = [
        {
          key: "Fixed",
          value: "Fixed",
          text: "Fixed",
        },
        {
          key: "Percentage",
          value: "Percentage",
          text: "Percentage",
        },
      ];

    const columns= [
            {
              name: "",
              width: "50px",
              cell: () => <DragIcon className="drag-handle"></DragIcon>,
            },
            {
              name: "ID",
              selector: "id",
              sortable: true,
              minWidth: "50px",
              maxWidth: "100px",
              
            },
            {
              name: "Subscription Frequency",
              selector: "noOfMonths",
              sortable: true,
              wrap: true,
              width:"250px",
              center:true,
              format: (subscription) => (
                <div className="text-overflow-ellipsis">
                  {subscription.noOfMonths
                    ? SUBSCRIPTION_FREQUENCY_OPTIONS.find(
                        (it) => it.value === subscription.noOfMonths
                      )?.text
                    : ""}
                </div>
              ),
            },
            {
              name: "Discount Type",
              selector: "discountType",
              sortable: true,
              wrap: true,
              minWidth:"200px",
              center:true,
              format: (subscription) => (
                <div className="text-overflow-ellipsis">
                  {subscription.discountType ?? ""}
                </div>
              ),
            },
            {
              name: "Discount Amount",
              selector: "discountAmount",
              sortable: true,
              wrap: true,
              center:true,
              minWidth:"200px",
              format: (subscription) => (
                <div className="text-overflow-ellipsis">
                  {subscription.discountAmount ?? ""}
                </div>
              ),
            },
            {
              name: "Rank",
              selector: "rank",
              minWidth: "100px",
              
              center: true,
              sortable: "true",
              wrap:true,
            },
            {
              name: "Status",
              selector: "status",
              sortable: true,
              center: true,
              maxWidth: "150px",
              cell: (subscription) => 
                subscription.status? "Active": "Inactive"
              ,
            },
            {
              name: "Actions",
              maxWidth: "100px",
              center: true,
              allowOverflow: true,
              cell: (subscription) => renderActionBar(subscription),
            },
          ];
             
         const setEditable = (field) => {
            const editables = { ...editables };
            editables[field] = true;
            setEditables();
          };
         const renderPencilIcon = (fieldName) =>
          isEditable ? (
            <div
              onClick={() => setEditable(fieldName)}
              style={{ cursor: "pointer" }}
              className="input-group-append"
            >
              <i className="input-group-text fa fa-pencil"></i>
            </div>
          ) : (
            ""
          );

          const updateRank =(fromIndex, toIndex) => {
            console.log("fromIndex,toIndex",fromIndex,toIndex);

            if (
              fromIndex === null ||
              fromIndex === undefined ||
              toIndex === null ||
              toIndex === undefined
            )
              return;
        
            
            const sortedSubscriptions = _.sortBy(
              subscriptions,
              sortColumn,
              sortDirection
            );
            const fromSubscription = { ...sortedSubscriptions[fromIndex] };
            const toSubscription = { ...sortedSubscriptions[toIndex] };
        
            if (fromSubscription && toSubscription) {
              const tempRank = fromSubscription.rank;
              fromSubscription.rank = toSubscription.rank;
              toSubscription.rank = tempRank;
        
              const newSubscriptions = subscriptions.map((it) => {
                if (it.id === fromSubscription.id) return fromSubscription;
                else if (it.id === toSubscription.id) return toSubscription;
                else return it;
              });
              setSubscriptions( newSubscriptions )
               // this.showSuccessNotification("Rank Updated Successfully!");
                onSubscriptionUpdatedLocally(newSubscriptions);
              
            } else {
             // this.showErrorNotification("Rank Updation Failed!");
            }
          };
         const dragProps = {
            onDragEnd(fromIndex, toIndex) {
              updateRank(fromIndex, toIndex);
            },
            nodeSelector: ".rdt_TableRow",
            handleSelector: ".drag-handle",
            enableScroll: true,
          };
         const handleChange = ({ currentTarget: input }) => {
          const updateSubscription = { ...subscription };
          if (input.name === "discountAmount") {
            updateSubscription[input.name] = input.value ? Number(input.value) : null;
          } 
          setSubscription(updateSubscription);
        };
        
         const renderActionBar = (subscription) => (
            <ActionBar alignRight className="action-menu valeo-dropdown">
              <ActionBar.Toggle variant="" disabled={isEditable}>
                <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
              </ActionBar.Toggle>
          <ActionBar.Menu
          popperConfig={{
            strategy: "fixed",
            onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
          }}
          >
              <ActionBar.Item onClick={() => handleEdit(subscription.id)}>
                  <EditIcon className="icon-small" />
                  Edit
                </ActionBar.Item>
                <ActionBar.Item
                  onClick={() => handleStatusChange(subscription.id)}
                >
                  {subscription.status  ? (
                    <ArchiveIcon className="icon-small" />
                  ) : (
                    <CheckCircleOutlineIcon className="icon-small" />
                  )}
                  {subscription.status  ? "Inactive" : "Active"}
                </ActionBar.Item>
                <ActionBar.Item
        
          onClick={() =>{
           setSubscriptionToDelete(subscription.id);
              showConfirmDeleteModal();
          }
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item>
               
             </ActionBar.Menu>
            </ActionBar>
          );
    const handleDropdown=(event,value,fieldName) =>{
                  
      setSubscription({
         ...subscription,
         [fieldName]: value.value,
       })
     
   }
     const form=(<>
     <div className="row">
          <div className="modalinput col">
            <label className="modallabel">Subscription Frequency*</label><br/>
            <div className="input-group">
            <Dropdown
                value={subscription.noOfMonths}
                onChange={(event, value) => 
                  handleDropdown(event,value,"noOfMonths")
                  
                }
                id="noOfMonths"
                name="noOfMonths"
                placeholder="Subscription Frequency"
                search
                selection
                
                options={SUBSCRIPTION_FREQUENCY_OPTIONS.filter((it) => {
                  if (subscription.noOfMonths === it.value) return true;
                  return !selectedSubscriptionFrequencies.includes(it.value)
                })}
                
                className={
                  "form-control" +(errors.noOfMonths ?"has-error":"")
                }
              />
              {renderPencilIcon("noOfMonths")}
            </div>
            <span className="help-block">{errors.noOfMonths}</span>
          </div>
          <div className="modalinput col">
            <label className="modallabel">Status</label>
            <div className="input-group">
              <Dropdown
                value={subscription?.status}
                onChange={(event, value) => 
                  handleDropdown(event,value,"status")
                }
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="modalinput  col">
            <label className="modallabel">Discount Type*</label>
            <div className="input-group">
            <Dropdown
                value={subscription.discountType}
                onChange={(event,value)=>handleDropdown(event,value,"discountType")}
                id="discountType"
                name="discountType"
                placeholder="Discount Type"
                
                search
                selection
                options={DISCOUNT_TYPE_OPTIONS}
                
                className={
                  "form-control" + (errors.discountType ? " has-error" : "")
                }
              />
              {renderPencilIcon("discountType")}
              </div>
            <span className="help-block">{errors.discountType}</span>
          </div>
          <div className="modalinput col">
            <label className="modallabel">Discount Amount*</label>
            <div className="input-group">
              <input
                value={subscription.discountAmount ?? ""}
                onChange={handleChange}
                id="discountAmount"
                name="discountAmount"
                type="number"
                placeholder="Discount Amount"
                className={
                  "form-control py-2" +
                  (errors.discountAmount ? " has-error" : "")
                }
               
              />
              {renderPencilIcon("discountAmount")}
            </div>
            <span className="help-block">{errors.discountAmount}</span>
          </div>
        </div>

     </>)
     const createSubscription = () => {
      
      const ids =
        subscriptions.length > 0 ? subscriptions.map((it) => it.id) : null;
      subscription.id = ids ? Math.max(...ids) + 1 : 1;
       
      const ranks =
        subscriptions.length > 0
          ? subscriptions.filter((it) => it.rank).map((it) => it.rank)
          : null;
      subscription.rank = ranks ? Math.max(...ranks) + 1 : 1;
      subscription.is_new = true;
  
 
       // this.showSuccessNotification("Subscription Created Successfully!");
         console.log("subscription id is",subscription.id);
      
        if(subscriptions.length>0){
          setSubscriptions([...subscriptions,subscription] ) ;
        onSubscriptionUpdatedLocally([...subscriptions,subscription]);
        }
      else
        
{  setSubscriptions( [subscription] ) ;
     onSubscriptionUpdatedLocally([subscription]);
}       

    };
    const updateSubscription = (id) => {
      console.log("subscription is",subscription,subscriptions);
      const newSubscriptions = subscriptions.map((it) =>
        it.id === id ? subscription : it
      );

      setSubscriptions( newSubscriptions)
      //  this.showSuccessNotification("Subscription Updated Successfully!");
        onSubscriptionUpdatedLocally(newSubscriptions);
      
    };

   const validate = () => {
      const errors = {};
      const { noOfMonths, status, discountType, discountAmount } =
        subscription || {};
        console.log("values,",noOfMonths, status, discountType, discountAmount);
      if (
        noOfMonths === null ||
        noOfMonths === undefined ||
        noOfMonths.toString().trim() === ""
      )
        errors.noOfMonths = "Subscription Frequency cannot be blank";
      else if (Number.isNaN(Number(noOfMonths)))
        errors.noOfMonths = "Subscription Frequency should be a valid number";
  
      if (!discountType) errors.discountType = "Discount Type cannot be blank";
  
      if (
        discountAmount === null ||
        discountAmount === undefined ||
        discountAmount.toString().trim() === ""
      )
        errors.discountAmount = "Discount Value cannot be blank";
      else if (Number.isNaN(Number(discountAmount)))
        errors.discountAmount = "Discount Value should be a valid number";
      else if (!isInBetween(Number(discountAmount), 0, 99999999))
        errors.discountAmount = "Discount Value should be bewteen 0 to 99999999";
  
     
       setErrors(errors);
       return Object.keys(errors).length === 0 ? null : errors;
    };

  const  isInBetween = (number, start, end) => number >= start && number <= end;

    const handleSubmit = (event) => {
      event.preventDefault();
     console.log("in save",subscription);
     const errors = validate();
     
      if (errors) {
        console.log("errors",errors);
        console.log("some invalid form field");
       // this.showErrorNotification("There are some invalid form fields!");
        return;
      }
  
         subscription.index=index;
      if (subscription.id === null || subscription.id === undefined)
    {    createSubscription();
      console.log("subscription create",subscription);}
      else updateSubscription(subscription.id);
      hideAddEditModal();
    };

    return(
     <>
        <div className="row">
          <div className="form-group col px-0">
            <Card>
              <ReactDragListView {...dragProps}>
               {subscriptions.length>0? <DataTable
                  highlightOnHover
                  columns={columns}
                  data={subscriptions || []}
                  onSort={(sortColumn, sortDirection) =>{
                    
                      setSortColumn( sortColumn.selector);
                      setSortDirection(sortDirection);
                    }
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownwardIcon/>}
                />:<></>}
             </ReactDragListView>
              <button
                onClick={handleAddNewSubscription}
                disabled={isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={isAddEditModalVisible}
          onHide={hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {subscription.id === null || subscription.id === undefined
                ? "Add Subscription"
                : "Edit Subscription"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {subscription.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isConfirmDeleteModalVisible}
          onHide={hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the subcription ?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                deleteSubscription(subscriptionToDelete);
                hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    
    

    )
}