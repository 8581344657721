import React, { useEffect, useState, useCallback } from "react";
import { Card } from 'react-bootstrap';
import './inventory-management.scss';
import { CheckBox } from "@mui/icons-material";



export default function InventoryManagement({inventoryManagement,setInventoryManagement,inventoryManageError}) {



  const onInventoryDetailsChange = ({fieldName, value}) => {
    let productData = {
      [fieldName] : value
    }
      setInventoryManagement({...inventoryManagement,...productData})
    
  }

  const {skuId="",barcode="",handQty=0,availableQuantity=0,commitedQuantity=0  } = inventoryManagement || {};



  return (
    <div className="media-wrapper">
          <Card body>
            <div className="alignrow">
              <div className="management_section" style={{ width: "50%" }}>Inventory & Variant Management </div>
              {/* <div><input type="checkbox"  /> Track Quantity</div> */}
            </div>
            <br />
            <div>
              {/* <input type="checkbox"  /> Continue selling when out of stock */}
            </div>
            <div className="single-list"> Single Listing</div>
              <div className="media-details-products">
            <div className="group">
            <label for="sku" className="label-title-txt">SKU (Stock Keeping Unit)*</label>
            {/* <div className="alignrow"> */}
              <div><input className="input-media" type="text"  value={skuId} onChange={(e)=>onInventoryDetailsChange({fieldName:"skuId",value:e.target.value})}  placeholder="SKU (Stock Keeping Unit)" required /></div>
              {inventoryManageError.skuId && <div style={{fontSize:'12px',color:'red'}} >{inventoryManageError.skuId}</div>}
            {/* </div>  */}
            </div>
            <div className="group">
            <label for="barcode" className="label-title-txt">Barcode</label>
            <div><input className="input-media" type="text" style={{width:"15rem"}} value={barcode} onChange={(e)=>onInventoryDetailsChange({fieldName:"barcode",value:e.target.value})}  placeholder="Barcode (ISBN, UPC, GTIN, etc.)" /></div>
            </div>
            </div>
            <br />
            {/* <div>
              Quantity*
            </div><br /> */}
            <div className="alignrow">
              {/* <div style={{ width: "50%" }}>Location</div> */}
              <div style={{ width: "15%" }}> Commited</div>
              <div style={{ width: "10%" }}> Available</div>
              <div style={{ width: "10%" }}>On hand *</div>

            </div><br />
            <div className="alignrow">
              {/* <div style={{ width: "50%" }}>1JLT-Nook-178 One JLT Plot No: DMCC-EZ1- 1AB, Jumeirah Lakes Towers, Dubai, UAE</div> */}
              <div style={{ width: "15%" }}>{commitedQuantity || 0}</div>
              <div style={{ width: "10%" }}>
              <input style={{ width: "100%" }} onWheel={(e)=>e.target.blur()} type="number" value={availableQuantity} onChange={(e)=>onInventoryDetailsChange({fieldName:"availableQuantity",value:e.target.value})} />

                </div>
              <div style={{ width: "10%" }}>
                <input style={{ width: "100%" }} onWheel={(e)=>e.target.blur()} type="number" value={handQty} onChange={(e)=>onInventoryDetailsChange({fieldName:"handQty",value:e.target.value})} />
              
              </div>
              {inventoryManageError.handQty && <div style={{fontSize:'12px',color:'red'}} >{inventoryManageError.handQty}</div>}
             
            </div>
          </Card>
    </div>
  )
}
