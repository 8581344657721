import React,{useEffect, useState} from 'react'
import Table from 'react-bootstrap/Table';
import { Modal } from "react-bootstrap";
import API from "../../utils/API"


const ListOfCollection = ({selectedCollection={},collection={},index=0,toggleCollectionSelection})=>{
    console.log("ListOfCollection",collection)
    const {collectionId="", collectionName ="" ,collectionStatus,productDtoList=[]} = collection
    // const isSelected = selectedCollection.some(selectedCollection => selectedCollection.collectionId == collectionId);
    return (
        <Table hover size="lg">
        <tbody>
            <tr>
            <td colSpan={2} style={{width:"1%"}} >
            <input type='checkbox'   onChange={() => toggleCollectionSelection(collection)} />
            </td>
            <td colSpan={2} style={{width:"20%",}}>{collectionName}</td>
            </tr>
        </tbody>
    </Table>
    )
}

const CollectionModal = ({showCollectionModal,setShowCollectionModal,handleCollectionSelect, selectCountry,allActiveCollections}) => {

    console.log("allActiveCollections",allActiveCollections)
    const [searchTerm,setSearchTerm] = useState();
    const [isSearching , setIsSearching] = useState(false);
    const [listOfCollection,setListOfCollection] =useState(allActiveCollections);
    const [selectedCollecton,setSelectedCollection] = useState([])

    useEffect(() => {
        if (showCollectionModal) {
          setListOfCollection(allActiveCollections);
        }
      }, [showCollectionModal, allActiveCollections]);
    

    const getCollection = async()=>{
        try{
            setIsSearching(true);
            const res = await API.get(`/api/v1/deals/collections/search?searchKey=${searchTerm}&countryId=${selectCountry.id}`);
            if(res.status == 200){
                const data = res?.data || {}
                setListOfCollection(data);
                setIsSearching(false)
            }
            else{
                setIsSearching(false)
                console.log("Error in fetching the data")
            }
        }
        catch(error){
            setIsSearching(false)
            console.log("Error in fetching the data",error)
        }
    }
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        getCollection(searchTerm);
      }
    };
    const toggleCollectionSelection = (collection) => {
        const isAlreadySelected = selectedCollecton.some(selectedCollecton => selectedCollecton.collectionId === collection.collectionId);
        if (isAlreadySelected) {
          setSelectedCollection(selectedCollecton.filter(selectedCollecton => selectedCollecton.collectionId !== collection.collectionId));
        } else {
          setSelectedCollection([...selectedCollecton, collection]);
        }
      };

  return (
    <Modal show={showCollectionModal} onHide={()=>setShowCollectionModal(!showCollectionModal)} size="lg" centered>
    <Modal.Header>
        <div>All Collection</div>
    </Modal.Header>
    <Modal.Body>
        {
            <>
             <div className="row">
            <div className="form-group col">
            <div className="input-group mb-3">
            <input type="text" className="form-control"
            id="search-bar"
            name="search-bar"
            placeholder="Search Products"
            value={searchTerm}
            onChange={(e)=>{setSearchTerm(e.target.value)}}
            onKeyPress={handleKeyPress}
            />
            </div>
          
            </div>
            </div>
            <Table>
        <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
            <th style={{width:"5%"}}> </th>
            <th style={{width:"16%",}}>Collection Name</th>
        </thead>
            </Table>
            {
                isSearching ? 
                <div style={{height:'50vh',overflow:"scroll"}}>
                    <div className="search-spinner" ></div> 
                </div> :
                (!isSearching && listOfCollection && listOfCollection.length ==0) ?
                <div>No results found for "{searchTerm}"</div>:
                <div style={{height:'50vh',overflow:"scroll"}}>
                        {
                            listOfCollection && listOfCollection.map((data,index)=>{
                                return <ListOfCollection 
                                selectedCollecton={selectedCollecton} 
                                collection={data} index={index}
                                toggleCollectionSelection={toggleCollectionSelection}/>
                            })
                        }
                </div>
            }
         </>
        }
    </Modal.Body>
    <Modal.Footer>
        <div  style={{display:'flex',flexDirection:'row',justifyContent:"flex-end",gap:"10px",width:'100%'}}>
        <div>
                <button
                type="button"
                className="btn btn-sm btn-success ml-2"
                onClick={()=>(handleCollectionSelect(selectedCollecton),setSearchTerm(""),setListOfCollection([]),setSelectedCollection([]))}
                style={(Object.keys(selectedCollecton).length>0)?{}:{opacity:0.2,backgroundColor:'#247A81'}}
                >
                    Add
                </button>
            </div>
            <div>
                <button style={{borderRadius:"20px"}}
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={()=>setShowCollectionModal(!showCollectionModal)}>
                    Cancel
                </button>
            </div>
          
        </div>
    </Modal.Footer>
</Modal>
  )
}

export default CollectionModal