// import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";

// function EditNotesViewModal(props) {
//   const {
//     title = "",
//     notes = "",
//     onHide = (f) => f,
//     onSave = (f) => f,
//     isVisible = false,
//   } = props;
//   const [customerNotes, setCustomerNotes] = useState(notes);

//   useEffect(() => setCustomerNotes(notes), [notes, isVisible]);

//   return (
//     <Modal show={isVisible} onHide={onHide} size="lg" centered>
//       <Modal.Header closeButton>
//         <Modal.Title>{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <>
//           <div className="row">
//             <div className="form-group col">
//               <textarea
//                 value={customerNotes ?? ""}
//                 onChange={(event) => setCustomerNotes(event.target.value)}
//                 id="customerNotes"
//                 name="customerNotes"
//                 placeholder="Add a note"
//                 className="form-control py-2"
//               ></textarea>
//             </div>
//           </div>
//         </>
//       </Modal.Body>
//       <Modal.Footer>
//         <button
//           onClick={onHide}
//           type="button"
//           className="btn btn-md btn-outline-secondary"
//         >
//           Cancel
//         </button>
//         <button
//           onClick={(event) => onSave(customerNotes)}
//           type="button"
//           className="btn btn-md btn-success ml-2"
//         >
//           Save
//         </button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// export default EditNotesViewModal;


import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function EditNotesViewModal(props) {
  const {
    title = "",
    notes = "",
    onHide = () => {},
    onSave = () => {},
    isVisible = false,
  } = props;
  const [customerNotes, setCustomerNotes] = useState(notes);
  const [showNotes, setShowNotes] = useState(false);

  useEffect(() => setCustomerNotes(notes), [notes, isVisible]);

  const handleSave = () => {
    onSave(customerNotes);
    setShowNotes(true);
  };

  return (
    <Modal show={isVisible} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="row">
            <div className="form-group col">
              <textarea
                value={customerNotes ?? ""}
                onChange={(event) => setCustomerNotes(event.target.value)}
                id="customerNotes"
                name="customerNotes"
                placeholder="Add a note"
                className="form-control py-2"
              ></textarea>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <button style={{borderRadius:"20px"}}
          onClick={onHide}
          type="button"
          className="btn btn-md btn-outline-secondary"
        >
          Cancel
        </button>
        <button style={{background:"#247A81",borderRadius:"20px"}}
          onClick={handleSave}
          type="button"
          className="btn btn-md btn-success ml-2"
        >
          Save
        </button>
      </Modal.Footer>
      {showNotes && (
        <Modal.Body>
          <h4>Notes:</h4>
          <p>{customerNotes}</p>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default EditNotesViewModal;
