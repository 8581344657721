import React,{useState,useEffect} from 'react'
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
// import API from "../"
import API from "../../../utils/API"


import { Link, useHistory } from "react-router-dom";
import Search from '../../../components/Search/Search';
import { Category } from '@mui/icons-material';

const ArticleCategory = (props) => {



  const [searchTerm, setSearchTerm] = useState("");
  const [clearSearch, setClearSearch] = useState(false)
  const [articleCategories,setArticleCategories] = useState([])

  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      sortField: 'id',
      wrap:true,
      center: true,
      width: "100px",
     

    },
    {
      name: "Name",
      selector: "categoryNameEnglish",
      // sortable: true,
      sortField: 'id',
      wrap:true,
      // center: true,
      width:"300px"
     

    },
    {
      name: "Rank",
      selector: "rank",
      sortable: true,
      sortField: 'id',
      wrap:true,
     right:true,
      center: true,

    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      sortField: 'id',
      wrap:true,
      center: true,
      // cell: (category) => statusColor(category.status)
      

    },
    {
      name: "View",
      selector: "view",
      sortField: 'id',
      center: true,
      width:'200px',
      cell:(category) => renderViewButton(category.id)
      
    },
  
  ]




 const  renderViewButton = (id) =>(
  <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#247A81",color:"#FFF" }}
        onClick={() => {
          handleView(id);
        }}
      >
        View
      </button>
    </div>
 )
 const history=useHistory();


 const getArticleCategories = async()=>{
  showLoading();
  try{
   const data = await API.get(`/health-hub-category`)
   showLoading();
   if(data.status === 200){
    hideLoading()
    const res = data.data
    setArticleCategories(res)
   }
   else{
    hideLoading();
    console.log("Error in fetching the data")
   }
  

  }
  catch{
    hideLoading()
  }
 }
 
 useEffect(()=>{
  getArticleCategories()
 },[])
 const handleView = (id) =>{

    if(id){
    history.push(`/dashboard/category/${id}`)

 }
}
const showLoading = () => props.toggleLoading({ isLoading: true });

const hideLoading = () => props.toggleLoading({ isLoading: false });


const handleSearch = (val)=>{
  if (val) {
    setSearchTerm(val);
    setClearSearch(false);
  } else {
    setSearchTerm("");
    setClearSearch(true);
    getArticleCategories();
  }
}


const onSearchBtn = () => {
  const filteredCategories = filterArticleCategories(articleCategories, searchTerm);
  setArticleCategories(filteredCategories);
};

const filterArticleCategories = (categories, searchTerm) => {
  if (!searchTerm) return categories;

  const newValue = searchTerm.trim().toLowerCase();
  return categories.filter((articleCategory) => {
    const { id, categoryNameEnglish, rank } = articleCategory;
    return (
      (categoryNameEnglish &&
        categoryNameEnglish.toLowerCase().includes(newValue)) ||
      (id && id.toString().includes(newValue)) ||
      (rank && rank.toString().includes(newValue))
    );
  });
};
const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    onSearchBtn();
  }
};


 
  return (
    <div>
       <div className="rightextreme">
       <div className="action-container">
       <div>
              <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={(val)=>handleSearch(val)}
                  clearSearch={()=>{setSearchTerm("");setClearSearch(true);  getArticleCategories()}}
                  handleSearchExit={()=>{}}
                  onKeyPress={handleKeyPress}
                
                ></Search>
            </div>
            <div className="search-btn" onClick={onSearchBtn}>
              Search
            </div>
        <div>
          <Link to={"/dashboard/category/create"}> <button className="greenbackground" >+ Add Category</button></Link>
        </div>
      </div>
      </div>
     
      <br /><br />
      <Card body>
        <DataTable
          highlightOnHover
          columns={

            columns

          }
          data={articleCategories}

          // onRowClicked={handlerowselect}
          
          
          // clearSelectedRows={this.state.toggledClearRows}

          responsive
          title={
            searchTerm ? (
              <p className="table-header">
                {"Found " + articleCategories.length + " results"}
              </p>
            ) : (
              ""
            )
          }

          pagination
          // paginationServer
          customStyles={{
            rows: {
              style: {
                cursor: 'pointer', 
              },
            },
          }}

        />

      </Card>
    </div>
  )
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCategory)