import React, { Component } from "react";
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import { Card } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import _ from "lodash";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


class CollectionProdRank extends Component {
  constructor(props) {
    super(props);

    const { Products = [], onProductsUpdated = (f) => f } = props;
    this.onProductsUpdated = onProductsUpdated;
    this.state = {
      Products,
      Product: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [

      {
        name: "",
        cell: () => <DragIndicatorIcon className="drag-handle" fontSize="medium" />,
        width: "50px",
      },

      {
        name: "Id",
        selector: "id",
        sortable: true,

        maxWidth: "50px",

      },
      {
        name: "",
        selector: "image",
        cell: tableProps => {

          return <img src={tableProps?.image} width={60}
            alt='Player' />
        },

        maxWidth: "50px",

      },

      {
        name: "Product",
        selector: "title",
        sortable: true,
        wrap:true,

      },
      {
        name: "Rank",
        selector: "rank",
        sortable: true,
        maxwidth: "30px",

      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.Products !== this.state.Products) {
      this.setState({ Products: this.props.Products });
    }
  }


  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;
    console.log("from index ", fromIndex, toIndex)
    const { Products = [], sortColumn, sortDirection } = this.state;
    const sortedProducts = _.sortBy(
      Products,
      sortColumn,
      sortDirection
    );
    const fromProduct = { ...sortedProducts[fromIndex] };
    const toProduct = { ...sortedProducts[toIndex] };
    console.log("prods", fromProduct, toProduct);
    if (fromProduct && toProduct) {
      console.log("ranks", fromProduct.rank, toProduct.rank);
      const tempRank = fromProduct.rank;
      fromProduct.rank = toProduct.rank;
      toProduct.rank = tempRank;
      console.log("exchange", fromProduct, toProduct);
      const newProducts = Products.map((it) => {
        if (it.id === fromProduct.id) return fromProduct;
        else if (it.id === toProduct.id) return toProduct;
        else return it;
      });
      this.setState({ Products: newProducts }, () => {
        //  this.showSuccessNotification("Rank Updated Successfully!");
        this.onProductsUpdatedLocally();
      });

    }
  }
  handlePageChange = (page) => {
    console.log("OrdersDashboard:: handlePageChange:", { page });
   
  };
  onProductsUpdatedLocally = () => {

    this.onProductsUpdated(
      this.state.Products.map((product) => {
        console.log("products", product);
        const p = { ...product };
        // if (p.is_new) {
        //   delete p.id;
        //   delete p.is_new;
        // }
        return p;
      })
    );
  };
  render() {
    const {
      Products = [],
      Product = {},
      editables,
      errors,
      sortColumn,
    } = this.state;



    return (<Card body>
      <ReactDragListView {...this.dragProps}>
        <DataTable
      
      
      data={Products}
      columns={this.columns}
      defaultSortField="rank"
      defaultSortAsc={true}
      sortIcon={<ArrowDownwardIcon/>}
    
      highlightOnHover
      
      responsive
      
    
      
    />
      </ReactDragListView>
    </Card>);
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionProdRank);